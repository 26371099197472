<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    allsubjectslength: 0,
    show: false,
    password: "Password",
    access: [{ text: "Manager" }, { text: "Technician" }],
    headers: [
      { text: "SubjectCode", align: "left", value: "subject_code" },
      { text: "SubjectName", value: "subject_name" },
      { text: "Teacher", value: "teacher" },
    //   { text: "Actions", value: "action", sortable: false },
    //   { text: "More..", value: "data-table-expand" },
    ],
    editedIndex: -1,
    editedItem: {
      subjectCode: "",
      subjectName: "",
      is_active: true,
    },
    defaultItem: {
      subjectCode: "",
      subjectName: "",
    },
    valid: true,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Name must contain 3 or more characters",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
  }),

  computed: {
    Reminder() {
      if (this.expanded.length > 0) {
        return this.expanded;
      } else {
        return this.schoolsubjects;
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Subject" : "Edit Subject";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(["schoolsubjects"]),
    ...mapGetters(["schoolteachers"]),
  },

  watch: {
    countrycode(val) {
      this.countrycode = val;
      setTimeout(() => {
        this.countrycode = "us";
      }, 500);
    },
    dialog(val) {
      val || this.close();
      // this.countrycode == val
    },
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.$store.dispatch("fetchschoolsubjects", this.user.dealerId);
    this.$store.dispatch("fetchschoolteachers", this.user.dealerId);
  },

  methods: {
    getSubjectteacher(subjectCode) {
    const myteachers = [];
    this.schoolteachers.forEach((teacher) => {
      if (teacher.teach_subjects) {
        const teachersubjects = JSON.parse(teacher.teach_subjects);
        teachersubjects.forEach((subject) => {
          if (subject === subjectCode) {
            myteachers.push(teacher.TeacherName);
          }
        });
      }
    });
    return myteachers.join(", ");
  },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius: 20px">
      <v-data-table
        dense
        :headers="headers"
        :items="Reminder"
        item-key="subject_id"
        sort-by="subject_name"
        :search="search"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template slot="item.teacher" slot-scope="{ item }">
          <p>{{ getSubjectteacher(item.subject_code) }}</p>
        </template>
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2 {
  color: #0ae2e2 !important;
  border-bottom: 1px dashed white;
}
.content1 {
  color: white !important;
  margin-left: 8px;
}
.off-icon {
  color: white;
  background: #00a3a3;
  padding: 2px;
  border-radius: 10px;
}
</style>