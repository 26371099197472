<script>
//import getInfo from "../scripts/getInfo";
import { mapGetters } from 'vuex'
import Vue from 'vue';
import {
    StripeElementPayment,
    StripeElementCard,
} from "@vue-stripe/vue-stripe";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
export default {
    data() {
        return {
            grandTotal: 0,
            paymentstatus: '',
            selectedDealership: null,
            dealershipinvoices: [],
            description: '',
            devicemodel: '',
            quantity: '',
            Dshiptype: '',
            // select: ['Vuetify', 'Programming'],
            Dshipmode: ['subscription', 'owned'],
            qytlist: ['1', '2', '3', '4', '5', '6'],
            modellist: [],
            user: JSON.parse(localStorage.getItem("user")),
            dialog: false,
            dialog2: false,
            dialog3: false,
            dshipdlg: false,
            nodshipdlg: false,
            search2: null,
            dealership: true,
            dealerships: [],
            models: null,
            selected: [],
            states: [
                "AL",
                "AK",
                "AS",
                "AZ",
                "AR",
                "CA",
                "CO",
                "CT",
                "DE",
                "DC",
                "FM",
                "FL",
                "GA",
                "GU",
                "HI",
                "ID",
                "IL",
                "IN",
                "IA",
                "KS",
                "KY",
                "LA",
                "ME",
                "MH",
                "MD",
                "MA",
                "MI",
                "MN",
                "MS",
                "MO",
                "MT",
                "NE",
                "NV",
                "NH",
                "NJ",
                "NM",
                "NY",
                "NC",
                "ND",
                "MP",
                "OH",
                "OK",
                "OR",
                "PW",
                "PA",
                "PR",
                "RI",
                "SC",
                "SD",
                "TN",
                "TX",
                "UT",
                "VT",
                "VI",
                "VA",
                "WA",
                "WV",
                "WI",
                "WY",
                "INDIA"
            ],
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            search: "",
            expanded: [],
            show: false,
            password: "Password",
            access: [{ text: "Admin" }, { text: "dealer" }],
            headers: [
                // { text: "ID", value: "dealerId" },
                // { text: "Dealership", value: "Dship", sortable: false },
                { text: "Devicemodel", align: "left", value: "devicemodel", sortable: false },
                { text: "Type", value: "type", sortable: false },
                { text: "Amount(USD)", value: "amount", sortable: false },
                { text: "Invoice", value: "payment", sortable: false },
                // { text: "Phone 2", value: "dealerMobile2" },
                // { text: "Access Level", value: "accesslevel" },
                // { text: "Actions", value: "action", sortable: false },
                // { text: "Delete", value: "delete", sortable: false },
                // { text: "Add Office", value: "addmore", sortable: false },

                // { text: "More..", value: "data-table-expand" }

            ],
            e1: 1,
            items: [],
            selectedDealer: { "dealerId": "", "dealerName": "", "dealerEmail": "", "dealerMobile1": "", "dealerMobile2": "", "dealerPassword": "", "dealerAddress": "", "accesslevel": "", "state": "", "city": "", "zipcode": "" },
            editedIndex: -1,
            countrycode: 'us',
            pk: process.env.VUE_APP_STRIPE_KEY,
            // pk: "pk_test_51Kl5mQD2VBUtaWz7ViJJnZpjStbKM6nD40JBGUsuSYtfDEd1c9ne0BZ7QMs33Pg2O3cjEnVfUzlX5YFGKYRttgfM00KCuHxxvp",
            elementsOptions2: {
                appearance: {},
                clientsecret:
                    "pi_3LCLgTD2VBUtaWz70jK1Kd1v_secret_djSefkeS6okGLEu5NV9wpo7Pt", // appearance options
            },
            editedItem: {
                dealerName: "",
                dealerId: "",
                dealerEmail: "",
                dealerMobile1: "",
                dealerMobile2: "",
                officehome: "",
                officework: "",
                dealerhome: "",
                dealerwork: "",
                addofficework: "",
                addofficehome: "",
                accesslevel: "dealer",
                dealerPassword: "",
                is_active: true,
                officeName: "",
                officeId: "",
                officeMobile1: "",
                officeMobile2: "",
                officeemail: "",
                officepassword: "",
                officeaddress: "",
                city: "",
                state: "",
                zipcode: "",
                isbar: "1",
                officelicense: "",
            },
            defaultItem: {
                dealerName: "",
                dealerId: "",
                dealerEmail: "",
                dealerMobile1: "",
                dealerMobile2: "",
                officehome: "",
                officework: "",
                dealerhome: "",
                dealerwork: "",
                addofficework: "",
                addofficehome: "",
                accesslevel: "dealer",
                dealerPassword: "",
                is_active: true,
                officeName: "",
                officeId: "",
                officeMobile1: "",
                officeMobile2: "",
                officeemail: "",
                officepassword: "",
                officeaddress: "",
                city: "",
                state: "",
                zipcode: "",
                isbar: "1",
                officelicense: "",
            },
            valid: true,
            payingamount: 0,
            payind2: '',
            cityRules: [
                v => !!v || 'City is required',
                v => v && v.length >= 3 || 'City must contain 3 or more characters',
            ],
            stateRules: [
                v => !!v || 'Select State',
            ],
            zipRules: [
                v => !!v || 'Zip is required',
                v => v && v.length == 5 || 'Enter valid zip code',
            ],
            dealernameRules: [
                v => !!v || 'Name is required',
                v => v && v.length >= 3 || 'Name must contain 3 or more characters',
                v => v && v.length <= 50 || 'Name must be less than 50 characters',
                v =>
                    !this.dealers.find(device => device.dealerName == v) ||
                    this.editedIndex != -1 ||
                    "Dealer with this name already exists use different one"
            ],
            officenameRules: [
                v => !!v || 'Name is required',
                v => v && v.length >= 3 || 'Name must contain 3 or more characters',
                v => v && v.length <= 50 || 'Name must be less than 50 characters',
                v =>
                    !this.offices.find(device => device.officeName == v) ||
                    this.editedIndex != -1 ||
                    "Office with this name already exists use different one",
                v =>
                    !this.offices.find(device => device.officeName == v) ||
                    this.editedIndex != -11 ||
                    "Office with this name already exists use different one"
            ],
            idRules: [
                v => !!v || 'Id is required',
            ],
            //  phoneRules: [
            //     v => !!v || "Phone is Required",
            //     v => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
            //     v => v != 0 || "Phone Number can't be zeoro!"
            //   ],
            // phone2Rules: [
            //     v => !!v || "Phone is Required",
            //     v => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
            //     v => v != 0 || "Phone Number can't be zeoro!"
            //   ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => v && v.length >= 8 || 'Password must contain 8 or more charactors'
            ],
            addressRules: [
                v => !!v || 'Address is required',
            ],
            licenseRules: [
                v => !!v || 'Bar License is required',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v =>
                    (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
                    "E-mail must be valid"
            ],
            dealerRules: [
                v => !!v || 'Select Dealer',
            ],
            accessRules: [
                v => !!v || 'Select access level',
            ],
            phoneRules: [
                v => !!v || 'Phone is required',
                v => v && v.length > 9 && v.length < 11 || 'Enter valid phone number',
            ],
            phone2Rules: [
                v => !!v || 'Phone2 is required',
                v => v && v.length > 9 && v.length < 11 || 'Enter valid phone number',
            ],
            phone2Rulese: [
                v => !!v || 'Phone2 is required',
                v => v && v.length > 9 && v.length < 15 || 'Enter valid phone number',
            ],
            phoneRulese: [
                v => !!v || 'Phone is required',
                v => v && v.length > 9 && v.length < 15 || 'Enter valid phone number',
            ],
        }
    },
    components: {
        StripeElementPayment,
    },
    mounted() {
        Vue.axios.get('https://robopower.xyz/us/cadealerpayments/getdealershipdetails?dealer=' + this.user.dealerId)
            .then((res) => {
                // console.log(res.data)
                this.dealerships = res.data
                res.data.map((item) => {
                    if (item.type == "owned") {
                        this.price = item.unit_price * item.qty
                    } else {
                        this.price = item.subscription_price * item.qty
                    }
                })
                this.calculateTotalPaidAmount();
            })
    },
    watch: {
        // Watch for changes in the list of invoices
        dealershipinvoices: {
            handler() {
                // Call the method to calculate total paid amount whenever the list changes
                this.calculateTotalPaidAmount();
            },
            deep: true
        }
    },
    methods: {
        downloadInvoice() {
            console.log(this.user.userName)
            var filename = this.user.userName+'.pdf'
            var dom = document.getElementById('downloadinvoices');
            html2canvas(dom).then(function (canvas) {
                var img = canvas.toDataURL("image/png");
                var doc = new jsPDF();
                doc.addImage(img, 'JPEG', 0, 0);
                doc.save(filename);

            }).catch((err) => {
                this.$store.dispatch("alert/error", err).then(() => {
                    setTimeout(() => {
                        this.$store.dispatch("alert/clear")
                    }, 2000)
                })
            })
        },
        calculateTotalPaidAmount() {
            // Filter invoices with "paid" status and sum their total amounts
            this.grandTotal = this.dealershipinvoices
                .filter(item => item.paymentstatus === 'paid')
                .reduce((total, item) => total + parseFloat(item.total), 0);
        },
        closedlg2() {
            this.dialog2 = false;
        },
        pay() {
            const that = this;
            that.$refs.paymentRef.submit();
        },
        payclose() {
            if (this.$refs.paymentRef && this.$refs.paymentRef.clear) {
                this.$refs.paymentRef.clear();
            }
            // console.log(this.payind1)
            this.dialog3 = false;
            Vue.axios
                .get(
                    "https://robopower.xyz/us/payments/cancelintentnew?dealerid="+this.user.dealerId+"&intent=" +
                    this.payind1
                )
                .then((res) => {
                    if (res.status == 200) {
                        this.selectedDealership = null;
                        this.selectedDealer = { "dealerId": "", "dealerName": "", "dealerEmail": "", "dealerMobile1": "", "dealerMobile2": "", "dealerPassword": "", "dealerAddress": "", "accesslevel": "", "state": "", "city": "", "zipcode": "" };
                        this.payingamount = 0;
                        this.dealershipinvoices = [];
                        this.$store
                            .dispatch("alert/error", "Payment Cancelled")
                            .then(() => {
                                setTimeout(() => {
                                    this.$store.dispatch("alert/clear");
                                }, 2000);
                            });
                    }
                })
                .catch((err) => {
                    this.$store.dispatch("alert/error", err).then(() => {
                        setTimeout(() => {
                            this.$store.dispatch("alert/clear");
                        }, 2000);
                    });
                });
        },
        async generatePaymentIntent2(obj) {
            // this.addclr = obj;
            var amt = this.payingamount.toString();
            amt = amt.replace(/\./g, "");
            await Vue.axios
                .get("https://robopower.xyz/us/payments/getsecretkeynew?dealerid="+this.user.dealerId+"&amount=" + amt)
                .then((response) => {
                    if (response.data.client_secret != "") {
                        var that = this;
                        that.payind2 = response.data.id;
                        that.elementsOptions2.clientSecret = response.data.client_secret;
                        that.iscreated2 = true;
                    }
                })
                .catch((err) => {
                    this.$store.dispatch("alert/error", err).then(() => {
                        setTimeout(() => {
                            this.$store.dispatch("alert/clear");
                        }, 2000);
                    });
                });
        },
        payNow() {
            this.dialog3 = true
            // this.payingamount = this.currentamountpaid;
            this.generatePaymentIntent2();
        },
        unpaid(item) {
            this.selectedDealership = item
            // console.log(this.selectedDealership,'data')
            Vue.axios.get('https://robopower.xyz/us/cadealers/getuniquedealer?dealerId=' + this.user.dealerId)
                .then((res) => {
                    if (res.data != null) {
                        this.selectedDealer = res.data[0];
                        // console.log(this.selectedDealer, 'selected dealer')
                        this.dialog2 = true;
                        Vue.axios.get('https://robopower.xyz/us/cadealerpayments/getdealershipinvoices?dealershipid=' + item.id)
                            .then((resp) => {
                                if (resp.data != null) {
                                    this.dealershipinvoices = resp.data;
                                    // console.log(this.dealershipinvoices)
                                    let sum = resp.data.filter(transaction => transaction.paymentstatus === 'unpaid').reduce((accumulator, currentValue) => {
                                        return accumulator + parseFloat(currentValue.total);
                                    }, 0);
                                    this.payingamount = sum;
                                }
                            })
                    }

                })
        }
    },
    computed: {
        formTitle2() {
            return this.editedIndex === -1 ? "Add Invoice" : "Edit Invoice";
        },
    }
};
</script>
<template>
    <v-container fluid>
        <v-card style="border-radius:20px !important">
            <v-data-table dense :headers="headers" item-key="dealerId" :items="dealerships" sort-by="dealerId"
                :search="search" class="elevation-1" single-expand :expanded.sync="expanded">
                <template v-slot:top>
                    <v-card color="grey lighten-4" flat>
                        <v-toolbar flat class="mb-3" dark
                            style="background:#00A3A3 !important;border-radius:20px !important">
                            <span style="font-size:40%">
                                <v-toolbar-title>DEALERSHIP</v-toolbar-title>
                            </span>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                        hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-dialog v-model="dialog3" width="1000px">
                                <v-card>
                                    <v-row class="justify-center">
                                        <v-col class="col-md-5" align="center">
                                            <div
                                                style="background: black;color: white;border-radius: 20px;padding: 10px;">
                                                <v-icon color="green" class="mr-3">mdi-cash-multiple</v-icon>You are
                                                paying
                                                the amounts {{ payingamount }}
                                            </div>
                                            <div class="container" v-if="payind2">
                                                &nbsp;&nbsp;
                                                <stripe-element-payment ref="paymentRef" :pk="pk"
                                                    :elements-options="elementsOptions2" :confirm-params="{
                                                        return_url:
                                                            `https://alcobrakeadmin.com/successdealershiptransaction?dealershipid=`
                                                            + selectedDealership.id +
                                                            `&dealerid=` + user.dealerId +
                                                            `&paymentmethod=online&paymentindent=` + payind2 + `&logintype=dealer`
                                                    }" />
                                                <br />
                                                <v-layout justify-center>
                                                    <!-- dialog paynow -->
                                                    <v-btn class="mr-4" @click="payclose" dark
                                                        style="background: #ff3d41; color: white">Cancel</v-btn>
                                                    <v-btn class="primary" left @click="pay">Pay Now</v-btn>
                                                </v-layout>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-dialog>

                            <v-dialog v-model="dialog2" width="700px">
                                <v-card color="grey lighten-4" text style="overflow: hidden;">
                                    <v-toolbar text color="blue-grey" class dark dense>
                                        <v-toolbar-title>INVOICE</v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-form id="downloadinvoices">
                                            <v-container style="background-color: white;">
                                                <div>
                                                    <div class="row green lighten-4" style="margin-top: -2%;">
                                                        <div class="col s12 top-heading">
                                                            <img src="/old_logo.png" height="25" />
                                                        </div>
                                                    </div>

                                                    <div class="row" style="color: black;margin-left: 1%;">
                                                        <div class="col s6 push-s1">
                                                            To,
                                                            <br /> {{ selectedDealer.dealerName }} <br />
                                                            {{ selectedDealer.dealerAddress }}

                                                            <br />{{ selectedDealer.state }}, {{ selectedDealer.city }},
                                                            {{ selectedDealer.zipcode }} <br />Phone:
                                                            {{ selectedDealer.dealerMobile1 }}
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <div class="col s5 right-align">
                                                            <div class="col s6 push-s1">
                                                                Alco Brake LLC
                                                                <br />1421 Champion Drive <br />Ste. 352,
                                                                Carrollton <br />75006 <br />Phone: +1 844
                                                                425 2627
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="section row" style="margin-left: 3%;color: black;">
                                                        Date:{{ new Date().toISOString().substr(0, 10) }}
                                                    </div>
                                                    <div class="divider"></div>
                                                    <v-card
                                                        style="background-color: #f5f5f5;margin-top: 5%;overflow: hidden;width: fit-content;">
                                                        <div class="section row">
                                                            <div class="col s12">
                                                                <table class="striped highlight">
                                                                    <thead>
                                                                        <tr style="color: black;">
                                                                            <th>INVOICEID</th>
                                                                            <th>AMOUNT</th>
                                                                            <th>DISCOUNT</th>
                                                                            <th>DESCRIPTION</th>
                                                                            <th>TOTAL</th>
                                                                            <th class="hide-print">STATUS</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="(item, index) in dealershipinvoices"
                                                                            :key="index"
                                                                            style="background-color: #e0e0e0;">
                                                                            <td>
                                                                                {{ item.id }}
                                                                                <!-- <v-text-field :value="item.id" solo dense readonly>
                                                                            </v-text-field> -->
                                                                            </td>
                                                                            <td>
                                                                                {{ parseFloat(item.total) +
                                                                                    parseFloat(item.discount) }}
                                                                                <!-- <v-text-field
                                                                                :value="parseFloat(item.total) + parseFloat(item.discount)"
                                                                                required solo dense
                                                                                readonly></v-text-field> -->
                                                                            </td>
                                                                            <td>
                                                                                {{ item.discount }}
                                                                                <!-- <v-text-field solo dense readonly
                                                                                :value="item.discount"></v-text-field> -->
                                                                            </td>
                                                                            <td>
                                                                                <!-- {{ item.description }} -->
                                                                                <textarea style="padding-bottom: -2%"
                                                                                    :value="item.description" required
                                                                                    readonly solo dense></textarea>
                                                                            </td>
                                                                            <td>
                                                                                {{ '$' + item.total }}
                                                                                <!-- <v-text-field :value=" '$' + item.total" readonly
                                                                                solo dense>
                                                                            </v-text-field> -->
                                                                            </td>
                                                                            <td>
                                                                                {{ item.paymentstatus }}
                                                                                <!-- <v-text-field :value="item.paymentstatus"
                                                                                readonly solo dense>
                                                                            </v-text-field> -->
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                    <div style="margin-top: 2%;color: black;"
                                                        v-if="payingamount != 0 && paymentstatus !== 'paid'"
                                                        class="col s12 right-align">
                                                        Grand Total: ${{ payingamount }}
                                                    </div>
                                                    <div v-else style="margin-top: 2%;color: black;"
                                                        class="col s12 right-align">
                                                        Grand Total: $ {{ grandTotal }}
                                                    </div>
                                                    <div style="margin-top: 2%;color: black;"
                                                        class="col s12 right-align" v-if="payingamount != 0">
                                                        Amount paid:
                                                        <v-text-field readonly :value="'$' + payingamount" solo dense>
                                                        </v-text-field>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn text @click="downloadInvoice"
                                            style="background: #009688;color: white;margin-right: 1%;">Download</v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="closedlg2"
                                            style="background: #ff3d41;color: white;margin-right: 1%">Cancel</v-btn>
                                        <v-btn text v-if="payingamount != 0" @click="payNow"
                                            style="background: #009688;color: white;">Pay</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </v-card>
                </template>
                <template slot=item.amount slot-scope="{ item }">
                    <span v-if="item.type == 'owned'">
                        {{ parseInt(item.qty) * parseFloat(item.unit_price) }}
                    </span>
                    <span v-else>
                        {{ parseInt(item.qty) * parseFloat(item.subscription_price) }}
                    </span>
                </template>
                <template slot=item.payment slot-scope="{ item }">
                    <span v-if="item.paymentstatus == 'paid'">
                        <v-btn
                            style="border-radius: 20px;color: aliceblue;background-color: green;height: 70%;width:25%;justify-content: center;"
                            @click="unpaid(item)">VIEW</v-btn>
                    </span>
                    <span v-else>
                        <v-btn @click="unpaid(item)"
                            style="border-radius: 20px;color: aliceblue;background-color: red;height: 70%;width:25%;justify-content: center;">PAY</v-btn>
                    </span>
                </template>

            </v-data-table>
        </v-card>
    </v-container>
</template>








<div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Privacy Policy
        </v-card-title>

        <v-card-text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>






<style>
tbody tr:nth-of-type(odd) {
    background-color: rgba(243, 243, 243, 0.973);
}

/* thead {
  background-color: lightslategray;
} */
.center-text {
    text-align: center;
}

/* Add spacing between th and td */
/* td,
th {
    padding: 4px;

} */
th,
td {
    text-align: center;
    /* Center-align text inside both th and td elements */
}
</style>
