<template>
  <div v-if="!isMobile()">
    <div id="map2"></div>

    <!-- <div class="zoom-control">
      <button @click="zoomIn">+</button>
      <v-divider></v-divider>
      <button @click="zoomOut">-</button>
    </div> -->
    <div class="zoom-control">
      <button @click="zoomIn" style="text-align: center;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;">+</button>
      <v-divider style="border-color: black !important;margin-top: 10%;"></v-divider>
      <button @click="zoomOut" style="text-align: center;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;">-</button>
    </div>
  </div>
  <div v-else>
    <div id="map2" class="mobile-map"></div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import engrun from "../assets/engrun.png";
import engoff from "../assets/engoff.png";
import engon from "../assets/engon.png";

export default {
  name: "Checkfullmap",
  props: ["vehdata"],
  data() {
    return {
      map2: null,
      futureArray: [],
      zoomval: 5,
      onsingle: false,
    };
  },
  watch: {
    vehdata(val) {
      if (val.length && !this.map2) {
        this.onInit(); // Ensure map is initialized after vehdata is loaded
      }
    },
  },
  methods: {
    mapUpdate(data) {
      this.futureArray = data.map((ele) => ({
        coordinates: [parseFloat(ele.Gps.lon), parseFloat(ele.Gps.lat)],
        icon: ele.Engine.enginerunstate == 1 && parseFloat(ele.Gps.speed) > 0 ? "engrun" : ele.Engine.enginerunstate == 1 && parseFloat(ele.Gps.speed) == 0.0 ? "engon" : "engoff",
        regnum: ele.Vehicle.regnum
      }));
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    zoomIn() {
      this.map2.zoomIn();
    },
    zoomOut() {
      this.map2.zoomOut();
    },
    onInit() {
      if (!this.vehdata.length) return; // Ensure vehdata is available

      const firstValidVehicle = this.vehdata.find(v => v.Gps.lat && v.Gps.lon);
      const [long, lati] = [parseFloat(firstValidVehicle.Gps.lon), parseFloat(firstValidVehicle.Gps.lat)];

      mapboxgl.accessToken = "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
      this.map2 = new mapboxgl.Map({
        container: "map2",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [long, lati],
        zoom: this.zoomval,
        attributionControl: false,
      });

      this.map2.on("load", () => {
        this.mapUpdate(this.vehdata); // Load initial markers
        this.addMarkers(); // Add markers after load

        setTimeout(() => {
          this.map2.resize(); // Ensure map is rendered correctly
        }, 100);
      });

      setInterval(() => this.updateMarkers(), 8000); // Simulate marker updates
    },
    addMarkers() {
      this.futureArray.forEach((marker) => {
        this.map2.loadImage(
          marker.icon === "engrun" ? engrun : marker.icon === "engoff" ? engoff : engon,
          (error, image) => {
            if (error) throw error;
            if (this.map2.hasImage(marker.icon)) {
              this.map2.removeImage(marker.icon);
            }
            this.map2.addImage(marker.icon, image);
            this.map2.addSource(marker.regnum, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [{
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: marker.coordinates,
                  },
                }],
              },
            });
            this.map2.addLayer({
              id: `marker-${marker.regnum}`,
              type: "symbol",
              source: marker.regnum,
              layout: {
                "icon-image": marker.icon,
                "icon-size": 0.20,
                "text-field": " ",
                "text-size": 12,
              },
            });
            const popup = new mapboxgl.Popup({ closeOnClick: false })
                    .setLngLat(marker.coordinates)
                    .addTo(this.map2)
                    .setOffset([0, -15])
                    .setHTML('<div style="text-align:center;font-size:10px; color: black; width:80px;height:10px">' + marker.regnum + '</div>')
              
          }
        );
      });
    },
    updateMarkers() {
      this.futureArray.forEach((marker) => {
        this.map2.getSource(marker.regnum).setData({
          type: "FeatureCollection",
          features: [{
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: marker.coordinates,
            },
          }],
        });
      });
    },
  },
  mounted() {
    if (this.vehdata.length) {
      this.onInit(); // Initialize the map only if vehdata is present
    }
  },
};
</script>

<style scoped>
#map2 {
  height: 600px;
  width: 100%; /* Adjusted width to prevent overflowing */
  margin-left: 0;
}
.mobile-map {
  height: 600px;
  width: 100%;
}
.zoom-control {
  position: absolute;
  bottom: 25%;
  left: 96%;
  border: 1px solid black;
  padding: 5px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.8);
}
.zoom-control {
  position: absolute;
  bottom: 25%;
  left: 96%;
  border: 1px solid black;
  padding: 5px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.8);
}
</style>
