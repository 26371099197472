<template>
  <div>
    <v-app-bar color="#006633" dark>
      
      <v-img
        max-height="80px"
        max-width="200px"
        src="../../assets/alcobrake.jpg"
      ></v-img>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
            <v-badge color="red" overlap bordered class="animated tada">
              <template v-slot:badge>
                <span>{{ notificationcount }}</span>
              </template>
              <v-tooltip>
                <template v-slot:activator="{ attrs, on }">
                  <v-icon v-bind="attrs" v-on="on">mdi-bell</v-icon>
                </template>
                <span>Notifications</span>
              </v-tooltip>
            </v-badge>
          </v-btn>
        </template>
        <v-toolbar color="sky" dark>
          <v-toolbar-title>Notifications</v-toolbar-title>
          <!-- <v-spacer></v-spacer>
          <v-btn color="green" class="mr-2" rounded >
            CLOSE
          </v-btn> -->
        </v-toolbar>
        <v-list style="max-height:80vh" class="overflow-y-auto">
          <v-list-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-content>
              <v-list-item-title
                class="font-weight-bold"
                
              >School: {{ n.officeName }}</v-list-item-title>
              <v-list-item-title >Message: {{ n.message }}</v-list-item-title>
              <!-- <v-list-item-title v-text="n.bacvalue"></v-list-item-title>
              <v-list-item-subtitle v-text="n.rtime"></v-list-item-subtitle> -->
              <v-divider></v-divider>
            </v-list-item-content>

            <v-btn fab dark small color="primary">
              <v-icon dark @click="removeNoification(n.id)"
                >mdi-close</v-icon
              ></v-btn
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        class="ma-2 white--text"
        @click="onLogOut"
        left
        v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
      >
        <v-icon left>
          mdi-logout
        </v-icon>
        Logout
      </v-btn>
      <v-icon large left @click="onLogOut" v-else>
        mdi-power
      </v-icon>
    </v-app-bar>
    <v-img style="height:80rem;" src="../../assets/kids.png">
      <v-container>
        <v-card-text style="fontWeight:bold; fontSize:25px"
          >Student : {{ studentdet.studentName }}</v-card-text
        >
        <v-main class="fill-height" fluid style=";opacity:0.92;">
          <v-card class="fill-height">
            <v-tabs>
              <v-tab>
                <v-icon left>
                  mdi-information-variant
                </v-icon>
                Info
              </v-tab>
              <v-tab>
                <v-icon left>
                  mdi-crosshairs-gps
                </v-icon>
                Tracking
              </v-tab>
              <v-tab>
                <v-icon left>
                  mdi-book-open
                </v-icon>
                NOTE
              </v-tab>

              <v-tab-item v-if="studentinf && vehicleinf">
                <v-card flat style="background-color:teal">
                  <v-card-text>
                    <student-info :sdata="studentinf" :vdata="vehicleinf"></student-info>
                  </v-card-text>
                </v-card>
                <v-card flat style="background-color:teal">
                  <v-card-text>
                    <driver-info :vdata="vehicleinf" :sdata="studentinf"></driver-info>
                  </v-card-text>
                </v-card>
                <v-card flat style="background-color:teal">
                  <v-card-text>
                    <progress-info :vdata="vehicleinf" :sdata="studentinf"></progress-info>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item v-else>
                <v-card flat style="background-color:teal">
                  <v-card-text><h1>Loading</h1></v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat style="background-color:teal">
                  <v-card-text>
                    <bus-locator :vdata="vehicleinf" :sdata="studentinf"></bus-locator>
                  </v-card-text>
                </v-card>
                <v-card flat style="background-color:teal">
                  <v-card-text>
                    <progress-info :vdata="vehicleinf" :sdata="studentinf"></progress-info>
                  </v-card-text>
                </v-card>
                <v-card flat style="background-color:teal">
                  <v-card-text>
                    <v-card
              class="mt-6 mx-auto" style="border-radius:10px;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);">
                    <alcodata-info  :vdata="vehicleinf" :sdata="studentinf"></alcodata-info>
                    </v-card>
                    
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item class="pa-2" style="background-color:teal">
                <v-textarea
                  v-model="comment"
                  solo
                  name="input-7-4"
                  label="Write a complaint"
                ></v-textarea>
                <v-btn class="primary" v-if="comment != ''" @click="sendNote">Submit</v-btn>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-main>
      </v-container>
    </v-img>
  </div>
</template>
<script>
import Vue from "vue";
import emailjs from "emailjs-com";
import moment from "moment";
// import router from '../../router';
export default {
  data() {
    return {
      notifications: [],
      notificationcount: 0,
      studentinf: null,
      vehicleinf: null,
      routeinf: null,
      studentdet: JSON.parse(localStorage.getItem("alcotrackerparentuser")),
      value: 1,
      active_tab: 0,
      comment: '',
    };
  },
  methods: {
    
    removeNoification(id){
Vue.axios
        .get(
          "https://robopower.xyz/app/schoolnotify/deletenotify/" +
            id
        )
        .then(response => {
          if(response.data){
          this.$store.dispatch("alert/success", "Notification Removed").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
  })
}
        })

        

    },
    
    onLogOut() {
      this.$store.dispatch("authentication/parentlogout");
    },
    sendNote() {
      var mdata = moment(new Date()).format(
              "MM/DD/YYYY HH:mm:ss"
            );
      const info = new FormData();
    info.append('studentId',this.studentdet.studentId)
    info.append('officeId',this.studentdet.clientId)
    info.append('message',this.comment)
    info.append('updated_time',mdata)
      Vue.axios
    .post("https://robopower.xyz/app/parentnotify/addnotify", info)
    .then(response => {
      if(response.status == 200){
        this.comment = ''
        this.$store.dispatch("alert/success", "Notification Sent Successfully").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
      })
    }
      

    })
      // var templateParams = {
      //   to_name: "Alcobrake",
      //   from_name: this.studentinf.parentName,
      //   message: this.comment,
      //   reply_to: this.studentinf.parentEmail
      // };

      // emailjs
      //   .send(
      //     "service_id6etgu",
      //     "template_eyhhpjr",
      //     templateParams,
      //     "user_JI1gqvTRtYtrqTOVj0mP5"
      //   )
      //   .then(
      //     function(response) {
      //       console.log("SUCCESS!", response.status, response.text);
      //     },
      //     function(error) {
      //       console.log("FAILED...", error);
      //     }
      //   );
    }
  },
  
  mounted() {
    console.log(this.studentdet)
    Vue.axios
        .get(
          "https://robopower.xyz/app/schoolnotify/getstandardnotifications?standard=" +
            this.studentdet.standard
        )
        .then(response => {
          if(response.data){
          this.notifications =response.data;
          this.notificationcount = response.data.length;
          }
        })
    const myPromise = new Promise((resolve, reject) => {
      if (this.studentdet) {
        resolve({ ok: true, response: this.studentdet });
      } else {
        reject("Client id Not Found");
      }
    });
    myPromise.then(res => {
      Vue.axios
        .get(
          "https://robopower.xyz/app/students/getuniquestudent?studentId=" +
            res.response.studentId
        )
        .then(response => {
          this.studentinf = response.data[0];
        });
    });
    myPromise.then(res2 => {
      Vue.axios
        .get(
          "https://robopower.xyz/us/fullclients/getregnumfullclient?regnum=" +
            res2.response.regId
        )
        .then(response => {
          this.vehicleinf = response.data[0];
          // this.getLog(response.data[0])
        });
    });
  },
  components: {
    studentInfo: () => import("../parent/dashboards/studentinfo"),
    driverInfo: () => import("../parent/dashboards/driverinfo"),
    progressInfo: () => import("../parent/dashboards/bus-stop-progress"),
    busLocator: () => import("../parent/dashboards/buslocator"),
    alcodataInfo: () => import("../parent/dashboards/alcodata.vue")
  }
};
</script>
<style scoped></style>
