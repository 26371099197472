<script>
import {mapGetters} from 'vuex';
import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
Vue.use(Vuex);
export default {
  data: () => ({
    user  : JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    show: false,
    classNames:[],
    mergedExamsClasses:[],
    headers: [
        { text: "Semester", value: "semester" },
        { text: "Class", value: "className" },
        { text: "Dealer", value: "dealerName" },
        { text: "Exams", value: "exams" }
      ],
  
  }),

  computed: {
    ...mapGetters(['schoolexams']),
    ...mapGetters(['schoolallclasses']),
  },

  watch: {
    mergedExamsClasses(val){
        this.mergedExamsClasses = val
    },
  },

  created() {
    this.initialize();
  },
  mounted() {
    setTimeout(()=>{
        this.getClassNames();
    },1000)
      this.$store.dispatch('fetchschoolExams',this.user.dealerId)
      this.$store.dispatch('fetchschoolallclasses',this.user.dealerId)
  },

  methods: {
  getClassNames() {
    Vue.axios
      .get('https://robopower.xyz/app/students/getallclasses?dealerId=' + this.user.dealerId)
      .then((response) => {
        if (response.data != null) {
          const sortedClasses = response.data.sort((a, b) => a.id - b.id);
          this.classNames = sortedClasses;
          const schoolexams = this.schoolexams.map(exam => {
            const matchedClass = sortedClasses.find(c => c.id === exam.class);
            return {
              ...exam,
              className: matchedClass.class,
              dealerName:this.user.userName
            };
          });

          this.mergedExamsClasses = schoolexams
        }
      })
      .catch((error) => {
        console.error('Error fetching classes:', error);
      });
  }
}

};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius:20px" color="white">
      <v-data-table
      :headers="headers"
      :items="mergedExamsClasses"
      item-value="semester_id"
      class="elevation-1"
    >
    <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>
        
      <template slot="item.exams" slot-scope="{ item }">
        <!-- <v-list>
          <v-list-item v-for="exam in item.exams" :key="exam.date">
            <v-list-item-content>
              <v-list-item-title style="font-size: 12px;">{{ exam.subject }}</v-list-item-title>
              <v-list-item-subtitle style="font-size: 12px;">{{ exam.day }} - {{ exam.date }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
        <v-row>
            <v-col v-for="exam in item.exams" :key="exam.date" cols="12" sm="6" md="4">
                <v-list>
                <v-list-item>
            <v-list-item-content>
              <v-list-item-title style="font-size: 12px;">{{ exam.subject }}</v-list-item-title>
              <v-list-item-subtitle style="font-size: 12px;">{{ exam.day }}</v-list-item-subtitle>
              <v-list-item-subtitle style="font-size: 12px;">{{ exam.date }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          </v-list>
            </v-col>
          </v-row>
      </template>
    </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2
{
  color:#0ae2e2 !important;
  border-bottom:1px dashed white
}
.content1
{
  color: white !important;
  margin-left:8px;
}
.off-icon
{
  color:white;
  background:#00A3A3;
  padding:2px;
  border-radius:10px;
}

</style>