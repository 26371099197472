<template>
  <div>
    <!-- Main Row: Vehicle List and Map Panel -->
    <v-row>
      <!-- Vehicle List with Scroll -->
      <v-col cols="7" class="">
  <!-- Scrollable Container for Vehicles -->
  <div class="vehicle-list scrollable">
    <v-row>
      <v-col v-for="vehicle in vehicles" :key="vehicle.id" cols="6" class="card-spacing">
        <v-card class="vehicle-item-card" outlined>
          <!-- Vehicle Icon and Driver Info -->
          <v-row>
            <v-col cols="4" class="vehicle-icon-section">
              <img :src="getBusIcon(vehicle)" class="vehicle-icon" />
            </v-col>

            <v-col cols="8" class="driver-info">
              <p style="font-size: 20px;color: black;"><strong style="color: grey;">Driver Name:</strong> {{ vehicle.Vehicle.client }}</p>
              <p style="font-size: 20px;color: black;"><strong style="color: grey;">Driver Mobile:</strong> {{ vehicle.Vehicle.devId }}</p>
              <v-btn @click="changeDriver(vehicle)" outlined color="#2596BE" class="change-driver-btn" >
                <p style="font-size: 15px;font-weight: bold;">Change Driver</p>
              </v-btn>
            </v-col>
          </v-row>

          <!-- Alcohol and Speed Data -->
          <v-row class="data-section">
            <v-col cols="7">
              <h3 class="data-header">Alcohol Data</h3>
              <Barchart ref="alcoholdata" :devid="vehicle"></Barchart>
            </v-col>

            <v-col cols="4" style="margin-bottom: 10px;">
              <h3 class="data-header">Speed Data</h3>
              <Speedometer :speed="vehicle.Gps.speed" />
            </v-col>
          </v-row>

          <!-- Engine Status, Speed, and Buttons -->
          <v-row class="status-section">
            <v-row>
  <v-col cols="6" class="vehicle-status" style="margin-left: 20px;">
    <p style="font-size: 20px; color: gray;"><strong>Engine Status:</strong> {{ vehicle.Engine.enginerunstate == "0" ? "OFF" : "ON" }}</p>
  </v-col>

  <v-col cols="5" class="vehicle-speed" style="margin-left: 15px;">
    <p style="font-size: 20px; color: gray;"><strong>Speed:</strong> {{ Math.round(vehicle.Gps.speed) }} km/h</p>
  </v-col>
</v-row>

<v-row>

            <v-col cols="5" style="margin-top: -20px; margin-left: 50px;">
              <v-btn @click="openEvents(vehicle)" outlined color="white" class="view-events-btn">
                View Events
              </v-btn>
            </v-col>

            <v-col cols="5" style="margin-top: -20px;margin-bottom: 20px;">
              <v-btn @click="openMap(vehicle)" outlined color="white" class="view-map-btn">
                View Map
              </v-btn>
            </v-col>
          </v-row>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</v-col>

      <!-- Map Panel on the Right -->
      <v-col cols="5">
        <!-- <h3>Live Map</h3> -->

        <!-- Display Map for Selected Vehicle -->
        <transition name="slide-fade">
          <div v-if="selectedVehiclemap" class="map-panel">
            <v-btn @click="closeMap" class="close-btn" color="primary">Close Map</v-btn>
            <div :id="`map-${selectedVehiclemap.gtime}`" class="map"></div>
          </div>
        </transition>

        <!-- Geofences and Outbound Vehicle Count -->
        <v-toolbar flat>
          <template v-if="geofences.length > 0">
            <v-btn color="red" outlined>
              ALL: {{ vehicles.length }}
            </v-btn>
            <v-btn color="teal" v-for="(geofence, index) in geofences" :key="index" outlined>
              {{ geofence.name }}: {{ geofenceCount(geofence.name) }}
            </v-btn>
            <v-btn color="red" outlined>
              OUTBOUND: {{ outboundvehicles }}
            </v-btn>
            
          </template>
        </v-toolbar>
        <FullLiveTrack ref="mytracking2" :vehdata.sync="vehicles" />
      </v-col>
    </v-row>

    <!-- Events Panel -->
    <transition name="slide-fade">
      <div v-if="selectedVehicleevents" class="events-panel">
        <v-btn @click="closeEvents" class="primary close-btn">Close Events</v-btn>
        <div class="events-content">
          <AlcoholRecordsChart :events="selectedVehicleevents" />
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import Vue from "vue";
import mapboxgl from "mapbox-gl";
import Barchart from "./volvobarchart";
import FullLiveTrack from "./FullLiveTrack";
import AlcoholRecordsChart from './AlcoholRecordsChart.vue';
import Speedometer from "./Speedometer.vue";

export default {
  name: "VehicleStatus",
  props: ["vehicles"],
  components: {
    Barchart,
    FullLiveTrack,
    AlcoholRecordsChart,
    Speedometer
  },
  data() {
    return {
      volvouser: JSON.parse(localStorage.getItem("volvouser")),
      currentspeed:50,
      geofences: [],
      geofenceslocs: null,
      outboundvehicles: 0,
      selectedVehicleevents:null,
      selectedVehiclemap:null
    };
  },
  mounted() {
    console.log(this.vehicles[0],"ggghghghghhg")
    // Fetch geofences and vehicle data
    Vue.axios
      .get(
        "https://robopower.xyz/us/geofence/getdealergeofences?dealerId=" +
          this.volvouser.dealerId
      )
      .then((res) => {
        if (res.data) {
          this.geofences = JSON.parse(res.data[0].geometry);
        }
      });

    Vue.axios
      .get(
        "https://robopower.xyz/us/geofence/getdealergeofenceslocs?dealerId=" +
          this.volvouser.dealerId
      )
      .then((res) => {
        if (res.data) {
          this.geofenceslocs = res.data;
          this.outboundvehicles = this.geofenceslocs.outsideGeofences.length;
        }
      });
  },
  methods: {
    initMap(vehicle) {
      mapboxgl.accessToken = "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";

      const map = new mapboxgl.Map({
        container: `map-${vehicle.gtime}`,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [parseFloat(vehicle.lon), parseFloat(vehicle.lat)],
        zoom: 12
      });

      new mapboxgl.Marker()
        .setLngLat([parseFloat(vehicle.lon), parseFloat(vehicle.lat)])
        .addTo(map);
    },
    closeMap() {
      this.selectedVehiclemap = null;
    },
    closeEvents() {
      this.selectedVehicleevents = null;
    },
    geofenceCount(name) {
      if (this.geofenceslocs.geofences[name]) {
        return this.geofenceslocs.geofences[name].length;
      } else {
        return 0;
      }
    },
    getBusIcon(vehicle) {
      if (vehicle.Engine.enginerunstate === "0") {
        return require("../assets/busoff.png");
      } else if (vehicle.Engine.enginerunstate === "1" && parseFloat(vehicle.Gps.speed) > 0) {
        return require("../assets/busrun.png");
      } else if (vehicle.Engine.enginerunstate === "1" && parseFloat(vehicle.Gps.speed) === 0) {
        return require("../assets/buson.png");
      }
      return "";
    },
    openMap(vehicle) {
  console.log(vehicle);
  this.selectedVehiclemap = vehicle.Gps;
  this.selectedVehicleevents = null; // Close events if map is opened

  // Initialize the map after the DOM is updated
  this.$nextTick(() => {
    this.initMap(vehicle.Gps); // Pass the correct vehicle object to the map
  });
},
    openEvents(vehicle) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/gethundredlogs?cbid=" + vehicle.Vehicle.cbid)
        .then((res) => {
          if (res.data != null) {
            this.selectedVehicleevents = res.data;
          }
        });
      this.selectedVehiclemap = null;
    },
  },
};
</script>

<style scoped>
.vehicle-list-wrapper {
  background-color: #d4f1ff; /* Light blue background as in the image */
  padding: 20px;
  
}

.vehicle-list {
  display: flex;
  flex-direction: column; /* Stack vehicle cards vertically */
  gap: 20px; /* Add spacing between each card */
  overflow-y: auto; /* Enable vertical scrolling */
  height: 730px; /* Set a fixed height to make the container scrollable */
  overflow-y: scroll; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
}
.vehicle-list::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.vehicle-item-card {
  background-color: white;
  border-radius: 20px; /* Reduce the border radius for a more compact look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%; /* Reduce the width of the card */
  height: auto; /* Let the height adjust automatically based on the content */
  padding: 10px; /* Adjust padding for smaller content spacing */
  margin: 10px auto; /* Add margin to create space between cards */
  display: flex;
  flex-direction: column;
  
}
.card-spacing {
  margin-bottom: 20px; /* Space between cards */
}

.vehicle-icon-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;

}

.vehicle-icon {
  width: 600px; /* Decrease icon size to fit the smaller card */
  height: 90px;
}

.driver-info p {
  font-size: 12px; /* Decrease font size */
  margin: 5px 0; /* Reduce space between text lines */
}

.data-section h3, .vehicle-status p {
  font-size: 12px; /* Decrease header and status font size */
}

.data-section, .status-section {
  gap: 5px; /* Reduce gaps between sections */
}

.v-btn {
  font-size: 12px; /* Reduce button font size */
  padding: 4px 6px; /* Reduce button padding */
}



.driver-info {
  text-align: left;
  
}

.driver-info p {
  margin: 0;
}

.change-driver-btn {
  margin-top: 10px;
  margin-left: 50px;
  border-radius: 10px;


}

.data-section {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.data-header {
  font-size: 1.1rem;
  text-align: left;
}

.vehicle-status {
  text-align: left;
  padding-top: 10px;
}

.status-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 10px; */
  margin-top: -50px;
}

.view-events-btn, .view-map-btn {
  width: 80%; /* Full width buttons */
  border-radius: 20px;
  background-color: #2596BE;
}





.data-header {
  font-size: 1.1rem;
  text-align: left;
}

.vehicle-status {
  text-align: left;
  padding-top: 10px;
}



.card-spacing {
  margin-bottom: 0px; /* Add space below each card */
  margin-left: 0px;
}


.map-panel, .events-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 800px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0,0,0,0.3);
  overflow: hidden;
  z-index: 1000;
  border-radius: 12px 0 0 12px;
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.map-panel .map, .events-panel .events-content {
  height: calc(100% - 60px);
  padding: 20px;
}

.map-panel .close-btn, .events-panel .close-btn {
  margin: 15px;
  padding: 10px;
  border-radius: 20px;
  background-color: #f44336;
  color: white;
}

.scrollable {
  overflow-y: auto;
}

.column-content {
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #ddd;
  transition: background-color 0.3s, transform 0.3s;
}

.column-content:hover {
  background-color: #e1f5fe;
  transform: translateY(-3px);
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
