<script lang="js">
import Vue from "vue";

export default {
  name: "Home",
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    notifications: [],
    notificationcount: 0,
    dialog: false,
    drawer: null,
    isAdmin: false,
    items: [],
    dealer: {
      accesslevel: null,
      dealerEmail: null,
      dealerId: null,
      dealerMobile1: null,
      dealerMobile2: null,
      dealerName: null,
      dealerPassword: null
    },
    // client: null,
    // clientId: null,
    // clientName: null,
    // clientMobile: null,
    // clientType: null,
    // clientEmail: null,
    // clientPassword: null,
    // clientAddress: null,
    // clienttype: null,
    devicemode: null,
    lat: null,
    lon: null,
    role: null,
    profiles: [],
    grantAccess: false,
    dealership: [],
  }),
  watch: {
    isAdmin(newValue) {
      this.isAdmin = newValue;
      this.mountedData();
    }
  },
  computed: {
    crumbs: function () {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: this.$route.matched[idx].meta.breadCrumb || path
        });
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    }
  },
  beforeMount() {
    Vue.axios
      .get(
        "https://robopower.xyz/app/parentnotify/getschoolnotifications?dealerId=" +
        this.user.dealerId
      )
      .then(response => {
        if (response.data) {
          this.notifications = response.data;
          this.notificationcount = response.data.length;
        }
      });
    Vue.axios.get("https://robopower.xyz/us/cadealerpayments/checkdealerpayment?dealer=" +
      this.user.dealerId).then((res) => {
        // console.log("FFFFF", res);
        if (res.data == "1") {
          // console.log(res.data);
          this.grantAccess = true;
        }
        else {
          // console.log("MOunika");
          this.grantAccess = false;
        }
      });
  },
  mounted() {
    setTimeout(() => {
      this.mountedData();
    }, 2000);

  },
  methods: {
    mountedData() {
      /* eslint-disable no-console */
      if (this.user.role == "Admin") {
        this.items = [
          { icon: "mdi-view-grid", text: "DASHBOARD", link: "/dashboard" },
          { icon: "mdi-account-tie", text: "DEALERS", link: "/dashboard/dealers" },
          // { icon: "mdi-office-building", text: "OFFICES", link:"/dashboard/offices" },
          // { icon: "mdi-account-multiple", text: "TECHNICIAN", link: "/dashboard/technicians" },
          { icon: "mdi-account-group", text: "CLIENTS", link: "/dashboard/clients" },
          { icon: "mdi-cellphone-text", text: "OLEDDEVICES", link: "/dashboard/oleddevices" },
          { icon: "mdi-server-network", text: "GEOFENCING", link: "/dashboard/geofencing" },
          // { icon: "mdi-cards-variant", text: "PROHIBITIONS", link: "/dashboard/prohibitions" },
          // { icon: "mdi-car-multiple", text: "VEHICLES", link: "/dashboard/vehicles" },
          // { icon: "mdi-seat-recline-normal", text: "DRIVERS", link: "/dashboard/drivers" },
          { icon: "mdi-cellphone-link", text: "DEVICES", link: "/dashboard/devices" },
          { icon: "mdi-desktop-classic", text: "CALIBRATIONKIT", link: "/dashboard/calibrationkit" },

          // { icon: "mdi-celtic-cross", text: "CALIBRATIONS", link: "/dashboard/calibrations" },
          // { icon: "mdi-file-document-edit", text: "CONTRACTS" },
          { icon: "mdi-file-document", text: "LOG FILES", link: "/dashboard/log_files" },
          // { icon: "mdi-memory", text: "FIRMWARES", link: "/dashboard/firmwares" },      
          // { icon: "mdi-cellphone-settings", text: "INSTALLATIONS", link: "/dashboard/installations" },
          // { icon: "mdi-magnify", text: "SEARCH INVOICES" },
          // { icon: "mdi-server-network", text: "SERVICES", link: "/dashboard/services" },
          // { icon: "mdi-bell-circle", text: "NOTIFICATIONS", link: "/dashboard/Notifications" },
          { icon: "mdi-lead-pencil", text: "REPORTS", link: "/dashboard/reports" },
          { icon: "mdi-arrow-right-bold-hexagon-outline", text: "REMOVALS", link: "/dashboard/removals" },
          { icon: "mdi-backup-restore", text: "BACKUPS", link: "/dashboard/backups" },
          { icon: "mdi-file-import", text: "GATEWAY", link: "/dashboard/gateway" },
          { icon: "mdi-cash", text: "PAYMENTMODE", link: "/dashboard/PaymentMode" },
          // { icon: "mdi-file-pdf", text: "OPERATIONS", link: "/dashboard/operations" }
          // { icon: "mdi-group", text: "COMPONENTS", link: "/dashboard/components" },
          // { icon: "mdi-cash-usd", text: "PAYMENTS", link: "/dashboard/payments" },
          // { icon: "mdi-file-pdf", text: "ACCEPTANCE", link: "/dashboard/acceptance"},
          // { icon: "mdi-account", text: "PROFILE", link: "/dashboard/Profile"}
        ]
      } else if (this.user.role == "superadmin") {
        this.items = [
          { icon: "mdi-view-grid", text: "DASHBOARD", link: "/dashboard" },
          { icon: "mdi-account-tie", text: "DEALERS", link: "/dashboard/dealers" },
          { icon: "mdi-office-building", text: "OFFICES", link: "/dashboard/offices" },
          { icon: "mdi-account-multiple", text: "TECHNICIAN", link: "/dashboard/technicians" },
          { icon: "mdi-account-group", text: "CLIENTS", link: "/dashboard/clients" },
          { icon: "mdi-cellphone-text", text: "OLEDDEVICES", link: "/dashboard/oleddevices" },
          // { icon: "mdi-cards-variant", text: "PROHIBITIONS", link: "/dashboard/prohibitions" },
          // { icon: "mdi-car-multiple", text: "VEHICLES", link: "/dashboard/vehicles" },
          // { icon: "mdi-seat-recline-normal", text: "DRIVERS", link: "/dashboard/drivers" },
          { icon: "mdi-cellphone-link", text: "DEVICES", link: "/dashboard/devices" },
          { icon: "mdi-desktop-classic", text: "CALIBRATIONKIT", link: "/dashboard/calibrationkit" },

          // { icon: "mdi-celtic-cross", text: "CALIBRATIONS", link: "/dashboard/calibrations" },
          // { icon: "mdi-file-document-edit", text: "CONTRACTS" },
          { icon: "mdi-file-document", text: "LOG FILES", link: "/dashboard/log_files" },
          { icon: "mdi-memory", text: "FIRMWARES", link: "/dashboard/firmwares" },
          // { icon: "mdi-cellphone-settings", text: "INSTALLATIONS", link: "/dashboard/installations" },
          // { icon: "mdi-magnify", text: "SEARCH INVOICES" },
          // { icon: "mdi-server-network", text: "GEOFENCING", link: "/dashboard/geofencing" },
          // { icon: "mdi-bell-circle", text: "NOTIFICATIONS", link: "/dashboard/Notifications" },
          { icon: "mdi-lead-pencil", text: "REPORTS", link: "/dashboard/reports" },
          { icon: "mdi-arrow-right-bold-hexagon-outline", text: "REMOVALS", link: "/dashboard/removals" },
          { icon: "mdi-backup-restore", text: "BACKUPS", link: "/dashboard/backups" },
          { icon: "mdi-file-import", text: "GATEWAY", link: "/dashboard/gateway" },

          // { icon: "mdi-file-import", text: "FILE-TRANSLATOR", link: "/dashboard/filetranslator" },
          // { icon: "mdi-group", text: "COMPONENTS", link: "/dashboard/components" },
          // { icon: "mdi-cash-usd", text: "PAYMENTS", link: "/dashboard/payments" },
          // { icon: "mdi-file-pdf", text: "ACCEPTANCE", link: "/dashboard/acceptance"},

          // { icon: "mdi-account", text: "PROFILE", link: "/dashboard/Profile"}
        ]
      } else if (this.user.role == "dealer" && this.grantAccess && this.user.state!="INDIA") {
        // console.log("DEALER");

        this.items = [
          { icon: "mdi-view-grid", text: "DASHBOARD", link: "/dashboard" },
          { icon: "mdi-account-tie", text: "DEALERSHIP", link: "/dashboard/dealership" },
          { icon: "mdi-office-building", text: "OFFICES", link: "/dashboard/offices" },
          // { icon: "mdi-account-multiple", text: "TECHNICIAN", link: "/dashboard/technicians" },
          { icon: "mdi-account-group", text: "CLIENTS", link: "/dashboard/clients" },
          // { icon: "mdi-cards-variant", text: "PROHIBITIONS", link: "/dashboard/prohibitions" },
          // { icon: "mdi-car-multiple", text: "VEHICLES", link: "/dashboard/vehicles" },
          // { icon: "mdi-seat-recline-normal", text: "DRIVERS", link: "/dashboard/drivers" },
          { icon: "mdi-cellphone-link", text: "DEVICES", link: "/dashboard/devices" },
          { icon: "mdi-desktop-classic", text: "CALIBRATIONKIT", link: "/dashboard/calibrationkit" },
          // { icon: "mdi-celtic-cross", text: "CALIBRATIONS", link: "/dashboard/calibrations" },
          // { icon: "mdi-file-document-edit", text: "CONTRACTS" },
          { icon: "mdi-file-document", text: "LOG FILES", link: "/dashboard/log_files" },
          // { icon: "mdi-memory", text: "FIRMWARES", link: "/dashboard/firmwares" },      
          // { icon: "mdi-cellphone-settings", text: "INSTALLATIONS", link: "/dashboard/installations" },
          // { icon: "mdi-magnify", text: "SEARCH INVOICES" },
          // { icon: "mdi-server-network", text: "SERVICES", link: "/dashboard/services" },
          // { icon: "mdi-bell-circle", text: "NOTIFICATIONS", link: "/dashboard/Notifications" },
          { icon: "mdi-account-multiple", text: "TECHNICIAN", link: "/dashboard/technicians" },
          { icon: "mdi-lead-pencil", text: "REPORTS", link: "/dashboard/reports" },
          { icon: "mdi-arrow-right-bold-hexagon-outline", text: "REMOVALS", link: "/dashboard/removals" },
          { icon: "mdi-file-import", text: "GATEWAY", link: "/dashboard/gateway" },
        //  { icon: "mdi-file-pdf", text: "OPERATIONS", link: "/dashboard/operations" }
          // { icon: "mdi-backup-restore", text: "BACKUPS", link: "/dashboard/backups" },
          // { icon: "mdi-file-import", text: "FILE-TRANSLATOR", link: "/dashboard/filetranslator" },
          // { icon: "mdi-group", text: "COMPONENTS", link: "/dashboard/components" },
          // { icon: "mdi-cash-usd", text: "PAYMENTS", link: "/dashboard/payments" },
          // { icon: "mdi-file-pdf", text: "ACCEPTANCE", link: "/dashboard/acceptance"},

          // { icon: "mdi-account", text: "PROFILE", link: "/dashboard/Profile"}
        ]
        if (this.user.emailId == "dmv@california.com") {
          this.items = [
            { icon: "mdi-view-grid", text: "DASHBOARD", link: "/dashboard" },
            { icon: "mdi-account-group", text: "CLIENTS", link: "/dashboard/clients" },
            { icon: "mdi-book-open", text: "LOG FILES", link: "/dashboard/log_files" },
          ]
        }
        //          this.items = [
        //     // { icon: "mdi-account-tie", text: "DEALERS", link: "/dashboard/dealers" },
        //   { icon: "mdi-view-grid", text: "DASHBOARD", link: "/dashboard" },
        //   { icon: "mdi-office-building", text: "OFFICES", link:"/dashboard/offices" },
        //   { icon: "mdi-account-group", text: "CLIENTS", link: "/dashboard/clients" },
        //   // { icon: "mdi-account-multiple", text: "USERS", link: "/dashboard/technicians" },
        //   // { icon: "mdi-tablet-cellphone", text: "DEVICES", link: "/dashboard/devices" },
        //   // { icon: "mdi-cards-variant", text: "PROHIBITIONS", link: "/dashboard/prohibitions" },
        //   // { icon: "mdi-celtic-cross", text: "CALIBRATIONS", link: "/dashboard/calibrations"},
        //   { icon: "mdi-book-open", text: "LOG FILES", link: "/dashboard/log_files" },
        //   // { icon: "mdi-lead-pencil", text: "REPORTS", link: "/dashboard/reports"},
        // ] 
        //   }
      } else if (this.user.role == "dealer" && !this.grantAccess) {
        console.log(this.grantAccess);
        this.items = [
          { icon: "mdi-view-grid", text: "DASHBOARD", link: "/dashboard" }
        ]
      }  else if (this.user.role == "dealer" && this.grantAccess && this.user.state=="INDIA") {
        // console.log("DEALER");

        this.items = [
          { icon: "mdi-view-grid", text: "DASHBOARD", link: "/dashboard" },
          { icon: "mdi-office-building", text: "OFFICES", link: "/dashboard/offices" },
          // { icon: "mdi-account-multiple", text: "TECHNICIAN", link: "/dashboard/technicians" },
          { icon: "mdi-account-group", text: "CLIENTS", link: "/dashboard/clients" },
          { icon: "mdi-cellphone-link", text: "DEVICES", link: "/dashboard/devices" },
          { icon: "mdi-desktop-classic", text: "CALIBRATIONKIT", link: "/dashboard/calibrationkit" },
          { icon: "mdi-lead-pencil", text: "REPORTS", link: "/dashboard/reports" },
          { icon: "mdi-arrow-right-bold-hexagon-outline", text: "REMOVALS", link: "/dashboard/removals" },
        ]
      }
      else if (this.user.role == "neoway") {
        this.items = [
          
          { icon: "mdi-view-grid", text: "GPSDASHBOARD", link: "/gpsdashboard" },
          { icon: "mdi-account-tie", text: "REPORTS", link: "/fleetreports" },
        ]
      }
      else if (this.user.role == "neoway") {
        this.items = [
          // { icon: "mdi-account-tie", text: "DEALERS", link: "/dashboard/dealers" },
          { icon: "mdi-view-grid", text: "GeoFence", link: "/geofence" },
        ]
      } else if (this.user.role == "uploader") {
        this.items = [
          //     { icon: "mdi-view-grid", text: "DASHBOARD", link: "/dashboard" },
          // { icon: "mdi-account-group", text: "CLIENTS", link: "/dashboard/clients" },
          // { icon: "mdi-cellphone-link", text: "DEVICES", link: "/dashboard/devices" },

          { icon: "mdi-file-import", text: "FILE-TRANSLATOR", link: "/filetranslator" },
          // { icon: "mdi-group", text: "COMPONENTS", link: "/dashboard/components" },
          // { icon: "mdi-cash-usd", text: "PAYMENTS", link: "/dashboard/payments" },
          // { icon: "mdi-file-pdf", text: "ACCEPTANCE", link: "/dashboard/acceptance"},

          // { icon: "mdi-account", text: "PROFILE", link: "/dashboard/Profile"}
        ]
      }
      else if (this.user.role == "school") {
        this.items = [
          { icon: "mdi-view-grid", text: "DASHBOARD", link: "/dashboard" },
          { icon: "mdi-office-building", text: "DEPARTMENTS", link: "/dashboard/offices" },
          { icon: "mdi-car-multiple", text: "VEHICLES", link: "/dashboard/clients" },
          { icon: "mdi-celtic-cross", text: "ROUTES", link: "/dashboard/routes" },
          { icon: "mdi-account-multiple", text: "TECHNICIAN", link: "/dashboard/technicians" },
          { icon: "mdi-teach", text: "TEACHERS", link: "/dashboard/teachers" },
          // { icon: "mdi-cellphone-link", text: "DEVICES", link: "/dashboard/devices" },
          { icon: "mdi-account-supervisor-circle", text: "STUDENTS", link: "/dashboard/students" },
          // { icon: "mdi-book-open", text: "LOG FILES", link: "/dashboard/log_files" },
          // { icon: "mdi-memory", text: "FIRMWARES", link: "/dashboard/firmwares" },  
          { icon: "mdi-memory", text: "SERVICES", link: "/dashboard/services" },
    
        ]
      }
      else {
        this.items = [
          // { icon: "mdi-account-tie", text: "DEALERS", link: "/dashboard/dealers" },
          { icon: "mdi-view-grid", text: "DASHBOARD", link: "/dashboard" },
          // { icon: "mdi-office-building", text: "OFFICES", link:"/dashboard/offices" },
          { icon: "mdi-account-group", text: "CLIENTS", link: "/dashboard/clients" },
          // { icon: "mdi-account-multiple", text: "USERS", link: "/dashboard/technicians" },
          { icon: "mdi-tablet-cellphone", text: "DEVICES", link: "/dashboard/devices" },
          // { icon: "mdi-cards-variant", text: "PROHIBITIONS", link: "/dashboard/prohibitions" },
          { icon: "mdi-celtic-cross", text: "CALIBRATIONS", link: "/dashboard/calibrations" },
          { icon: "mdi-book-open", text: "LOG FILES", link: "/dashboard/log_files" },
          { icon: "mdi-arrow-right-bold-hexagon-outline", text: "REMOVALS", link: "/dashboard/removals" },
          // { icon: "mdi-lead-pencil", text: "REPORTS", link: "/dashboard/reports"},
        ]
      }
    },
    openProfile() {
      Vue.axios
        .get("https://robopower.xyz/us/cadealers/getuniquedealer?dealerId=" + this.user.dealerId)
        .then(response => {
          var prodata = response.data[0]
          this.dealer = prodata;
          this.dialog = true;
        }).catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        })
        Vue.axios.get('https://robopower.xyz/us/cadealerpayments/getdealershipdetails?dealer=' + this.user.dealerId)
        .then(res => {
          console.log(res)
          this.dealership = res.data;
          console.log(this.dealership)
        })
    },
    signout() {
      this.$store.dispatch("authentication/logout");
    },
  },
};
</script>

<template>
  <v-container fluid class="">
    <v-navigation-drawer width="230" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app
      v-if="this.user.role != 'uploader'" style="background:#00A3A3 !important;">
      <div align="center" class="mt-4"><img src="../assets/alcobrake.jpg" style="height:40px;padding-top:10px" /></div>
      <!-- eslint-disable -->
      <v-list dense nav class="active_list mt-4">
        <template v-for="item in items">
          <v-list-group v-if="item.children" :key="item.text" v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="" no-action
            style="background:#007575 !important">
            <template v-slot:activator>

              <v-list-item-title v-text="item.text"></v-list-item-title>
            </template>

            <v-list-item v-for="(child, i) in item.children" :key="i" link>

              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="child.text"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.title" link :to="item.link">

            <v-list-item-icon>
              <v-icon class="nav-menu" style="color:white !important">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="nav-menu" style="color:white !important">{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="#00A3A3" dark>
      <v-toolbar-title style="width: 350px" class="ml-0 pl-0">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="this.user.role != 'uploader'">
        </v-app-bar-nav-icon>
        <span>{{ user.userName }}</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- <v-switch
        v-if="user.role == `dealer`"
        label="Admin Mode"
        v-model="isAdmin"
        :input-value="false"
        @change="changeState()"
    ></v-switch> -->
      <!-- <v-spacer></v-spacer> -->
      <v-tooltip bottom>

        <template v-slot:activator="{ on, attrs }">
          <button @click="openProfile" icon v-bind="attrs" v-on="on" class="mr-8">
            <v-icon>mdi-account-circle</v-icon>
          </button>
        </template>
        <span>Profile</span>
      </v-tooltip>

      <template>

        <v-dialog v-model="dialog" max-width="500px">
          <v-card style="border-radius:20px">
            <v-toolbar color="#00A3A3" dark dense style="border-radius:20px">
              <v-toolbar-title>Profile</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="dialog = false" style="background:black;border-radius:20px">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-col>
              <v-row>
                <v-col>

                  <div class="logo"> <v-img src="https://www.alcobrake.com/assets/images/old_logo.png"></v-img></div>



                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col class="col-md-8">
                  <v-card style="border-radius:20px;background:#334c3e;margin-top: -20%;">
                    <v-card-text class="p-right"><b class="p-left"><v-icon class="off-icon">mdi-account</v-icon>
                        FullName
                        :</b> {{ dealer.dealerName }}</v-card-text>
                    <v-card-text class="p-right"><b class="p-left"><v-icon
                          class="off-icon">mdi-email-outline</v-icon>Email :</b> {{ dealer.dealerEmail }}</v-card-text>
                    <v-card-text class="p-right"><b class="p-left"><v-icon
                          class="off-icon">mdi-cellphone-iphone</v-icon>Mobile :</b>
                      {{ dealer.dealerMobile1 }}</v-card-text>
                    <v-card-text class="p-right"><b class="p-left"><v-icon
                          class="off-icon">mdi-account-multiple</v-icon>AccessLevel:</b>
                      {{ dealer.accesslevel }}</v-card-text>
                    <v-card-text class="p-right">
                      <div v-for="(item, index) in dealership" :key="index" >
                        <div style="display: inline-flex;width: 95%;justify-content: space-around;text-align: left;">
                          <p>{{ item.devicemodel }}</p>
                          <p>{{ item.type }}</p>
                          <p>{{ item.qty }}</p>
                          
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>



              </v-row>
            </v-col>

          </v-card>
        </v-dialog>
      </template>
      <!-- <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn> -->
      <!-- <v-tooltip>
      <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" color="white" to="/login">
        mdi-logout
      </v-icon>
      </template>
      <span>Signout</span>
      </v-tooltip> -->
      <v-menu v-if="user.role == 'school'" bottom left offset-y origin="top right" transition="scale-transition">

        <template v-slot:activator="{ attrs, on }">
          <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
            <v-badge color="red" overlap bordered class="animated tada">
              <template v-slot:badge>
                <span>{{ notificationcount }}</span>
              </template>
              <v-tooltip>

                <template v-slot:activator="{ attrs, on }">
                  <v-icon v-bind="attrs" v-on="on">mdi-bell</v-icon>
                </template>
                <span>Notifications</span>
              </v-tooltip>
            </v-badge>
          </v-btn>
        </template>
        <v-toolbar color="sky" dark>
          <v-toolbar-title>Notifications</v-toolbar-title>
          <!-- <v-spacer></v-spacer>
          <v-btn color="green" class="mr-2" rounded >
            CLOSE
          </v-btn> -->
        </v-toolbar>
        <v-list style="max-height:80vh" class="overflow-y-auto">
          <v-list-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">School: {{ n.dealerName }}</v-list-item-title>
              <v-list-item-title>Message: {{ n.message }}</v-list-item-title>
              <!-- <v-list-item-title v-text="n.bacvalue"></v-list-item-title>
              <v-list-item-subtitle v-text="n.rtime"></v-list-item-subtitle> -->
              <v-divider></v-divider>
            </v-list-item-content>

            <v-btn fab dark small color="primary">
              <v-icon dark @click="removeNoification(n.id)">mdi-close</v-icon></v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn color="black" class="ma-2 white--text" @click="signout"
        v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg" style="border-radius:20px">
        <v-icon left>
          mdi-logout
        </v-icon>
        Signout
      </v-btn>
      <v-icon left @click="signout" v-else style="background:black;padding:5px;border-radius:50%">
        mdi-power
      </v-icon>

    </v-app-bar>
    <v-content>
      <div v-if="this.user.role != 'uploader'" class="container ma-0 pa-0">
        <v-breadcrumbs :items="crumbs">

          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.to">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>
      <router-view></router-view>
    </v-content>
  </v-container>
</template>



<style scoped>
.logo {
  display: block;
  width: 100%;
  height: 10rem;
  object-fit: cover;
}

.pro-icon {
  background: #00A3A3;
  padding: 5px;
  border-radius: 50%;
  color: white;
}

.pro-cont {
  color: #00A3A3;
}

.pro-cont2 {
  color: white;
  margin-left: 5px
}

.pro-cont3 {
  font-size: 15px;
  color: white;
}

.off-icon {
  color: white;
  background: #00A3A3;
  padding: 2px;
  border-radius: 10px;
  margin-right: 5px;
}

.p-left {
  color: white;
  margin-right: 5px;
  font-weight: bold;
}

.p-right {
  color: #00A3A3 !important;
  font-weight: bold;
}
</style>