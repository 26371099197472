import { userService } from "../auth/userservice";
import router from "../router";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { usermail, userpassword }) {
      commit("loginRequest", { usermail });
      userService.login(usermail, userpassword).then(
        token => {
          // console.log("success login"+usermail)
          commit("loginSuccess", token);
          var data = localStorage.getItem("user");
          var myrole = JSON.parse(data).role
          console.log(JSON.parse(localStorage.getItem("user")).role)
          setTimeout(()=>{
            if(usermail == "neoway@gmail.com"){
              router.push("gpsdashboard");
            } else if(myrole=="uploader"){
              router.push("dashboard/filetranslator");
            } else{
              router.push("dashboard");
            }
            
          },700)
        },
        error => {
          // console.log("failed login")
          commit("loginFailure", error);
          dispatch("alert/error", "Invalid Login Credentials", { root: true }).then(()=>{
            setTimeout(()=>{
              this.$store.dispatch("alert/clear")
            },2000)
            })
        }
      );
    },
    parentlogin({ dispatch, commit }, { studentid, parentmobile }) {
      commit("loginRequest", { studentid, parentmobile });

      userService.parentlogin(studentid, parentmobile).then(
        token => {
          commit("loginSuccess", token);
          router.push("pdashboard");
        },
        error => {
          commit("loginFailure", error);
          dispatch("alert/error", "Invalid Login Credentials", { root: true });
        }
      );
    },
    fleetlogin({ dispatch, commit }, { email, password }) {
      commit("loginRequest", { email, password });
console.log("r")
      userService.fleetlogin(email, password).then(
        token => {
          commit("loginSuccess", token);
          router.push("gpsdashboard");
        },
        error => {
          commit("loginFailure", error);
          dispatch("alert/error", "Invalid Login Credentials", { root: true });
        }
      );
    },
    volvologin({ dispatch, commit }, { email, password }) {
      commit("loginRequest", { email, password });
console.log("r")
      userService.volvologin(email, password).then(
        token => {
          commit("loginSuccess", token);
          router.push("dashboard_volvo");
        },
        error => {
          commit("loginFailure", error);
          dispatch("alert/error", "Invalid Login Credentials", { root: true });
        }
      );
    },
    logout({ commit }) {
      userService.logout();
      commit("logout");
      router.push("login");
    },
    parentlogout({ commit }) {
      userService.parentlogout();
      commit("logout");
      router.push("login");
    },
    fleetlogout({ commit }) {
      userService.fleetlogout();
      commit("logout");
      router.push("login");
    },
    volvologout({ commit }) {
      userService.volvologout();
      commit("logout");
      router.push("login");
    }
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    }
  }
};
