<template>
  <div v-if="loading" class="preloader-container">
    <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    // Simulate loading duration
    hidePreloader() {
      setTimeout(() => {
        this.loading = false;
      }, 3000); // Hides the preloader after 3 seconds
    },
  },
  mounted() {
    this.hidePreloader();
  },
};
</script>

<style scoped>
.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(135deg, #74ebd5, #acb6e5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: relative;
}

.spinner div {
  position: absolute;
  width: 64px;
  height: 64px;
  border: 8px solid white;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
  border-color: white transparent transparent transparent;
}

.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
