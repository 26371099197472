<script>
import {mapGetters} from 'vuex';
export default {
  data: () => ({
    user  : JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    show: false,
    password: "Password",
    access: [{ text: "Manager" }, { text: "Technician" }],
    headers: [
      {
        text: "Student Name",
        align: "start",
        value: "studentName",
      },
      { text: "Student Mobile", value: "studentMobile" },
      { text: "Parent Name", value: "parentName" },
      { text: "Parent Mobile", value: "parentMobile" },
      { text: "Regnum", value: "regId" },
      { text: "Routeid", value: "routeId" },
      { text: "Stop Name", value: "stopname" },
      { text: "edit", value: "action" },
      { text: "delete", value: "delete" },
      { text: "More..", value: "data-table-expand" },
    ],
    editedIndex: -1,
    classes: [],
    allgrade: [
      { id: "1", grade: "A" },
      { id: "2", grade: "B" },
      { id: "3", grade: "C" },
    ],
    defcountry:'us',
    editedItem: {
      studentId:"",
      dealerId:"",
      studentName: "",
      rollnumber: "",
      officehome:"",
      studentMobile: "",
      studentEmail: "",
      parentName: "",
      parentMobile: "",
      standard:"",
      parentEmail: "",
      studentAddress: "",
      class: "",
      grade: "",
      routeId: "",
      stopid: "",
      regId: "",
      stopName:''
    },
    defaultItem: {
      studentId:"",
      dealerId:"",
      studentName: "",
      rollnumber: "",
      officehome:"",
      studentMobile: "",
      studentEmail: "",
      parentName: "",
      parentMobile: "",
      standard:"",
      parentEmail: "",
      studentAddress: "",
      class: "",
      grade: "",
      routeId: "",
      stopid: "",
      regId: "",
      stopName:''
    },
    valid: true,
    cityRules: [
      (v) => !!v || "City is required",
      (v) => (v && v.length >= 3) || "City must contain 3 or more characters",
    ],
    stateRules: [(v) => !!v || "Select State"],
    zipRules: [
      (v) => !!v || "Zip is required",
      (v) => (v && v.length == 5) || "Enter valid zip code",
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Name must contain 3 or more characters",
      (v) => (v && v.length <= 50) || "Name must be less than 50 characters",
    ],
    idRules: [(v) => !!v || "Id is required"],
    phoneRules: [
      (v) => !!v || "Phone is required",
      (v) =>
        (v && v.length >= 10 && v.length <= 14) || "Enter valid phone number",
    ],
    phoneRulesa: [
      (v) => !!v || "Phone is required",
      (v) =>
        (v && v.length >= 10 && v.length <= 11) || "Enter valid phone number",
    ],
    phone2Rulesa: [
      (v) => !!v || "Phone is required",
      (v) =>
        (v && v.length >= 10 && v.length <= 11) || "Enter valid phone number",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length >= 8) || "Password must contain 8 or more charactors",
    ],
    addressRules: [(v) => !!v || "Address is required"],
    licenseRules: [(v) => !!v || "Bar License is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
        "E-mail must be valid",
    ],
    phone2Rules: [
      (v) => !!v || "Phone2 is Required",
      (v) => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
      (v) => v != 0 || "Phone Number can't be zeoro!",
    ],
  }),

  computed: {
     Reminder() {
      if(this.expanded.length >0){
        return this.expanded
      } else{
      return this.students
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Student" : "Edit Student";
    },
     buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(["students"]),
    ...mapGetters(["dealers"]),
    ...mapGetters(["studentsClasses"]),
    ...mapGetters(["routes"]),
    ...mapGetters(["routestops"]),
    ...mapGetters(["routestopsvehicles"]),
  },

  watch: {
    defcountry(val) {
      this.defcountry = val;
      setTimeout(() => {
        this.defcountry = "us";
      }, 500);
    },
    dialog(val) {
      val || this.close();
      // this.countrycode == val
    }
  },

  created() {
    this.initialize();
  },
 
  mounted() {
    if (this.user.role == "school") {
      this.editedItem.dealerName = this.user.dealerId;
    }
    this.mountdata();
  },

  methods: {
    mountdata() {
      this.$store.dispatch("fetchDealers");
      if (this.user.role == "school") {
        this.$store.dispatch("fetchallschoolStudents", this.user.dealerId);
        this.$store.dispatch("fetchstudentClasses");
        this.$store.dispatch("fetchallschoolRoutes", this.user.dealerId);
      } else {
        this.$store.dispatch("fetchbranchStudents", this.user.officeId);
      }
    },
        isNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    deleteStudent(student) {
      if (this.user.emailId != 'Justin@alcobrake.com') {
        /* eslint-disable no-console */
        const Swal = require("sweetalert2");
        Swal.fire({
          title: 'Are you sure? You want to delete Student - ' + student.studentName,
          text: 'You can\'t revert your action',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes Delete it!',
          cancelButtonText: 'No, Keep it!',
          showCloseButton: true,
          showLoaderOnConfirm: true
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch('DeleteStudent', student.studentId)
            // this.$swal('Deleted', 'You successfully deleted this file', 'success')
            this.$store.dispatch("alert/success", 'You successfully deleted this Student').then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          } else {
            // this.$swal('Cancelled', 'Your file is still intact', 'info')
            this.$store.dispatch("alert/error", "Your file is still intact").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          }
        }).catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        })
      } else {
           this.$store.dispatch("alert/success","YOU DONT HAVE PERMISSION TO DO THIS ACTION").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      }
    },
    selectstopid(id) {
      this.$store.dispatch("fetchRoutestops", id);
      this.$store.dispatch("fetchRoutestopVehicles", id);
    },

    loadFormData() {
      var object = {
            'rollnumber': this.editedItem.rollnumber,
            "studentName": this.editedItem.studentName,
            'studentMobile': this.editedItem.officehome + this.editedItem.studentMobile,
            'studentEmail': this.editedItem.studentEmail,
            'standard': this.editedItem.standard,
            'grade': this.editedItem.grade,
            'parentName': this.editedItem.parentName,
            'parentMobile': this.editedItem.parentMobile,
            'parentEmail': this.editedItem.parentEmail,
            'studentAddress': this.editedItem.studentAddress,
            'dealerId': this.user.dealerId,
            'routeId': this.editedItem.routeId,
            'stopid': this.editedItem.stopid,
            'stopName': this.editedItem.stopName,
            'regId': this.editedItem.regId,
        }
        return JSON.stringify(object);
    },
    initialize() { },
    onSelect({ dialCode }) {
      // console.log(dialCode)
      this.editedItem.officehome = dialCode;
    },
    editItem(item) {
      // console.log(item)
      if (this.user.emailId != 'Justin@alcobrake.com') {
        this.editedIndex = this.students.indexOf(item);
        this.editedItem.studentName = item.studentName;
        this.editedItem.parentName = item.parentName;
        this.editedItem.rollnumber = item.rollnumber;
        this.editedItem.studentId = item.studentId;
        this.editedItem.studentEmail = item.studentEmail;
        this.editedItem.studentMobile = item.studentMobile;
        this.editedItem.parentMobile = item.parentMobile;
        this.editedItem.grade = item.grade;
        this.editedItem.parentEmail = item.parentEmail;
        this.editedItem.standard = item.standard;
        this.editedItem.studentAddress = item.studentAddress;
        this.editedItem.dealerId = this.user.dealerId,
        this.editedItem.routeId = item.routeId;
        this.editedItem.stopid = item.stopid;
        this.editedItem.stopName = item.stopname;
        this.editedItem.regId = item.regId;
        this.selectstopid(item.routeId)
        this.dialog = true;
      } else {
           this.$store.dispatch("alert/success","YOU DONT HAVE PERMISSION TO DO THIS ACTION").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      }
    },

    deleteItem() {
    },

    close() {
      this.dialog = false;
      this.defcountry = 'US'
      this.$refs.form.reset();
      this.editedIndex = -1;
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if(this.editedItem.studentMobile == undefined)
        this.editedItem.studentMobile = '';
      const fd = this.loadFormData();
      if (this.editedIndex == -1) {
        this.$store.dispatch('createStudents',fd)
      } else {
        const editfd = this.loadFormData()
        var postdata={
          devdata:editfd,
          id:this.editedItem.studentId
        }
        this.$store.dispatch('editStudents',postdata)
      }
          this.close();
    }
  }
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius:20px">
      <v-data-table
        dense
        :headers="headers"
        :items="Reminder"
        item-key="studentId"
        sort-by="studentName"
        :search="search"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-card color="grey lighten-4" text style="border-radius:20px">
            <v-toolbar text color="#00A3A3" dark dense style="border-radius:20px">
              <span v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
              <v-toolbar-title style="font-size:22px">STUDENTS</v-toolbar-title>
              </span>
               <span v-else>
              <v-toolbar-title style="font-size:15px">STUDENTS</v-toolbar-title>
              </span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-row>
                <v-col cols="12" lg="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              </v-col>
              </v-row>        

              <v-dialog v-model="dialog" max-width="600px">
                <template v-slot:activator="{ on }">
         <v-row>
          <v-col cols="12">
           <v-layout justify-end>
                <v-btn v-if="user.emailId != 'Justin@alcobrake.com'" v-on="on" style="border-radius:20px;background:black"> 
                  <h2>+</h2><v-icon size="20px" color="dark">mdi-account-box-outline</v-icon>
                </v-btn>             
           </v-layout>
              </v-col>
                  </v-row>
          </template>
               
                <v-card style="border-radius:20px">
                  <v-toolbar text color="#00A3A3" class="" dark dense style="border-radius:20px">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-form
                      autocomplete="off"
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                  <v-card-text>
                    <v-container>
                      <v-row>
                
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            required
                            outlined
                            v-on:keypress="isLetter($event)"
                            :rules="nameRules"
                            dense
                            v-model="editedItem.studentName"
                            label="StudentName*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            outlined
                            required
                            v-on:keypress="isLetter($event)"
                            v-model="editedItem.rollnumber"
                            label="RollNumber"
                          ></v-text-field>
                        </v-col>
                        <div class="left-btn" v-if="editedIndex == -1">
                          <vue-country-code
                            v-model="editedItem.studentMobile"
                            @onSelect="onSelect"
                            :enabledCountryCode="true"
                            :defaultCountry="defcountry"
                            style="border: none"
                            :preferredCountries="['in', 'us', 'gb']"
                          >
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              placeholder="phone number"
                            />
                          </vue-country-code>
                        </div>

                        <div class="left-btn ml-2" v-if="editedIndex == -1">
                          <v-text-field
                            dense
                            outlined
                            required
                            :rules="phoneRulesa"
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.studentMobile"
                            label="Mobile*"
                            style="width: 200px"
                          ></v-text-field>
                        </div>
                        <v-col cols="12" sm="12" md="6" v-else>
                          <v-text-field
                            dense
                            outlined
                            required
                            :rules="phoneRules"
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.studentMobile"
                            label="Mobile*"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            outlined
                            required
                            :rules="emailRules"
                            v-model="editedItem.studentEmail"
                            label="Email*"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            outlined
                            required
                            :rules="addressRules"
                            v-model="editedItem.parentName"
                            label="ParentName*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            outlined
                            required
                            :rules="addressRules"
                            v-model="editedItem.parentMobile"
                            label="ParentMobile*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            outlined
                            required
                            :rules="addressRules"
                            v-model="editedItem.parentEmail"
                            label="ParentEmail*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            outlined
                            required
                            :rules="addressRules"
                            v-model="editedItem.studentAddress"
                            label="StudentAddress*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense
                            outlined
                            required
                            :rules="stateRules"
                            v-model="editedItem.standard"
                            :items="studentsClasses"
                            item-text="class"
                            item-value="class"
                            label="Select Standards*"
                          ></v-autocomplete>
            
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense
                            outlined
                            required
                            :rules="stateRules"
                            v-model="editedItem.grade"
                            :items="allgrade"
                            item-text="grade"
                            item-value="grade"
                            label="Select Grade*"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense
                            outlined
                            required
                            @change="selectstopid"
                            :rules="stateRules"
                            v-model="editedItem.routeId"
                            :items="routes"
                            item-text="routeId"
                            item-value="routeId"
                            label="Select RouteId*"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense
                            outlined
                            required
                            :rules="stateRules"
                            v-model="editedItem.stopid"
                            :items="routestops"
                             item-text="stopname"
                            item-value="stopid"
                            label="Select StopId*"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense
                            outlined
                            required
                            :rules="stateRules"
                            v-model="editedItem.regId"
                            :items="routestopsvehicles"
                            item-text="regnum"
                            item-value="regnum"
                            label="Select RegId*"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text @click="close" style="background:#ff3d41;color:white"
                      >Cancel</v-btn>
                    <v-btn color="blue darken-1" text :disabled="!valid" @click="save">{{buttonTitle}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
               
            </v-toolbar>
          </v-card>
        </template>
        <template slot=item.action slot-scope="{ item }">
          <v-icon class="mr-2 mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
          <!-- <v-icon color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
        <template slot="item.delete" slot-scope="{ item }">
                <v-icon
                  class="mr-2 mt-2"
                  larger
                  color="red"
                  @click="deleteStudent(item)"
                >
                  mdi-delete-circle
                </v-icon>
              </template>
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item}" >
          <td :colspan="headers.length" class="ma-0 pa-0" align="center">
        
            <v-col class="col-md-6">
                  <v-card class="mt-2 mb-2" color="#334c3e" style="border-radius:20px;padding:10px" >
                    <v-list dense class="ma-0 pa-0" style="background:#334c3e">
                  <v-list-item>
                    <v-icon class="off-icon">mdi-clipboard-account</v-icon>
                    <v-list-item-content class="content1">ID:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.studentId }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-account</v-icon>
                    <v-list-item-content class="content1">Name:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.studentName }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-email-outline</v-icon>
                    <v-list-item-content class="content1">Email:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.studentEmail }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-cellphone-iphone</v-icon>
                    <v-list-item-content class="content1">Phone:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.studentMobile }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-cellphone-basic</v-icon>
                    <v-list-item-content class="content1">ParentName:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.parentName }}</v-list-item-content>
                  </v-list-item><v-list-item>
                    <v-icon class="off-icon">mdi-account-key</v-icon>
                    <v-list-item-content class="content1">StudentAddress:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.studentAddress }}</v-list-item-content>
                  </v-list-item>

              </v-list>

                  </v-card>
            </v-col>
       
               
              
           </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2
{
  color:#0ae2e2 !important;
  border-bottom:1px dashed white
}
.content1
{
  color: white !important;
  margin-left:8px;
}
.off-icon
{
  color:white;
  background:#00A3A3;
  padding:2px;
  border-radius:10px;
}

</style>