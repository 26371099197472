import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
import 'moment-timezone';
import { alert } from "./alertmodule";
import { authentication } from "./authmodule";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("user")),
    operations: [],
    dealerlatestdata: [],
    postcommands:[],
    dashboard: {
      totaldevices: 0,
      availabledevices: 0,
      currentrevenue: 0,
      totalrevenue: 0,
      newclients: 0,
      totalclients: 0,
      newinstallations: 0,
      removalthismonth: 0,
      calibrations: 0,
      saleslabels: 0,
      salescount: 0,
      officeId: null,
      officeName: null,
    },
    splitlogs:[],
    students: [],
    routes: [],
    removals: [],
    remlogs: {},
    offices: [],
    dealers: [],
    components: [],
    duplogs: [],
    logs: [],
    logsbefore: [],
    users: [],
    vehicles: [],
    clients: [],
    clientcount: {},
    officeclients: [],
    uniqueclient: "",
    prohibitions: [],
    uniquevehicle: "",
    clientprohibitions: [],
    devices: [],
    oledDevices:[],
    calibrationkit:[],
    kitmodels:[],
    drivers: [],
    clientdevices: [],
    officedevices: [],
    devicecount: {},
    calibrations: [],
    logfiles: [],
    invoices: [],
    firmwares: [],
    form920: [],
    form922: [],
    cbfirmwares: [],
    cbv2firmwares: [],
    catftfirmwares: [],
    cazigfirmwares: [],
    tftfirmwares: [],
    pressfirmwares: [],
    guifirmwares: [],
    officeinvoices: [],
    totalrevenue: {},
    invoiceservices: [],
    installations: [],
    lockouts: [],
    services: [],
    reports: [],
    uniqueinvoice: [],
    uniqueinvoice2: [],
    fullclients: [],
    kvclients: [],
    templogs: [],
    s3firmwares: [],
    cbindiafirmwares: [],
    sampindiafirmwares: [],
    neowayfirmwares: [],
    //school
    schoolteachers:[],
    schoolsubjects:[],
    studentsClasses: [],
    routestops: [],
    routestopsvehicles: [],
    studentsActivites:[],
    schoolallclasses:[],
    schoolstudents:[],
    schoolholidays:[],
    schoolexams:[],
    schoolfees:[],
    classes: [],
    createteachers:[],
    //paymentgateways

    paymentgateway: { },
    createpaymentgateway:{},


  },
  getters: {
    //operations
    operations: (state) => {
      return state.operations;
    },
    postcommands: (state) => {
      return state.postcommands;
    },
    //offices
    offices: (state) => {
      return state.offices;
    },
    //kvclients
    kvclients: (state) => {
      return state.kvclients;
    },
    //dealerlatestdata
    dealerlatestdata: (state) => {
      return state.dealerlatestdata;
    },
    //dealers
    dealers: (state) => {
      return state.dealers;
    },
    //students
    students: (state) => {
      return state.students;
    },
    //routes
    routes: (state) => {
      return state.routes;
    },
    //components
    components: (state) => {
      return state.components;
    },
    //duplogs
    duplogs: (state) => {
      return state.duplogs;
    },
    //users
    users: (state) => {
      return state.users;
    },
    //clients
    clients: (state) => {
      return state.clients;
    },
    fullclients: (state) => {
      return state.fullclients;
    },
    officeclients: (state) => {
      return state.officeclients;
    },
    clientcount: (state) => {
      return state.clientcount;
    },
    uniqueclient: (state) => {
      return state.uniqueclient;
    },
    //vehicles
    vehicles: (state) => {
      return state.vehicles;
    },
    //drivers
    drivers: (state) => {
      return state.drivers;
    },
    //prohibitions
    prohibitions: (state) => {
      return state.prohibitions;
    },
    clientprohibitions: (state) => {
      return state.clientprohibitions;
    },
    uniqueprohibition: (state) => {
      return state.uniqueprohibition;
    },
    //devices
    devices: (state) => {
      return state.devices;
    },
    oledDevices: (state) => {
      return state.oledDevices;
    },
    calibrationkit:(state)=>{
      return state.calibrationkit
    },
    kitmodels:(state)=>{
      return state.kitmodels
    },
    clientdevices: (state) => {
      return state.clientdevices;
    },
    devicecount: (state) => {
      return state.devicecount;
    },
    officedevices: (state) => {
      return state.officedevices;
    },
    //calibrations
    calibrations: (state) => {
      return state.calibrations;
    },
    uniqueinvoice: (state) => {
      return state.uniqueinvoice;
    },
    uniqueinvoice2: (state) => {
      return state.uniqueinvoice2;
    },
    //logfiles
    logfiles: (state) => {
      return state.logfiles;
    },
    //invoices
    invoices: (state) => {
      return state.invoices;
    },
    invoiceservices: (state) => {
      return state.invoiceservices;
    },
    officeinvoices: (state) => {
      return state.officeinvoices;
    },
    totalrevenue: (state) => {
      return state.totalrevenue;
    },
    //installations
    installations: (state) => {
      return state.installations;
    },
    //services
    services: (state) => {
      return state.services;
    },
    lockouts: (state) => {
      return state.lockouts;
    },
    //logfiles
    reports: (state) => {
      return state.reports;
    },
    templogs: (state) => {
      return state.templogs;
    },
    logs: (state) => {
      return state.logs;
    },
    logsbefore: (state) => {
      return state.logsbefore;
    },
    removals: (state) => {
      return state.removals;
    },
    //dashboard
    dashboard: (state) => {
      return state.dashboard;
    },
    form920: (state) => {
      return state.form920;
    },
    form922: (state) => {
      return state.form922;
    },
    //firmwares
    firmwares: (state) => {
      return state.firmwares;
    },
    cbfirmwares: (state) => {
      return state.cbfirmwares;
    },
    cbv2firmwares: (state) => {
      return state.cbv2firmwares;
    },
    cbtftfirmwares: (state) => {
      return state.cbtftfirmwares;
    },
    //routes

    s3firmwares: (state) => {
      return state.s3firmwares;
    },
    cbindiafirmwares: (state) => {
      return state.cbindiafirmwares;
    },
    sampindiafirmwares: (state) => {
      return state.sampindiafirmwares;
    },
    cazigfirmwares: (state) => {
      return state.cazigfirmwares;
    },
    tftfirmwares: (state) => {
      return state.tftfirmwares;
    },
    pressfirmwares: (state) => {
      return state.pressfirmwares;
    },
    catftfirmwares: (state) => {
      return state.catftfirmwares;
    },
    guifirmwares: (state) => {
      return state.guifirmwares;
    },
    remlogs: (state) => {
      return state.remlogs;
    },
    splitlogs: (state) => {
      return state.splitlogs;
    },
    neowayfirmwares: (state) => {
      return state.neowayfirmwares;
    },
    schoolteachers: (state)=>{
      return state.schoolteachers
    },
    schoolsubjects: (state)=>{
      return state.schoolsubjects
    },
    studentsClasses: (state) => {
      return state.studentsClasses;
    },
    routestops: (state) => {
      return state.routestops;
    },
    routestopsvehicles: (state) => {
      return state.routestopsvehicles;
    },
    studentsActivites: (state) => {
      return state.studentsActivites;
    },
    schoolallclasses: (state)=>{
      return state.schoolallclasses
    },
    schoolstudents: (state)=>{
      return state.schoolstudents
    },
    schoolholidays: (state)=>{
      return state.schoolholidays
    },
    schoolexams: (state)=>{
      return state.schoolexams
    },
    schoolfees: (state)=>{
      return state.schoolfees
    },
    classes: (state) => {
      return state.classes;
    },
    createteachers: (state) => {
      return state.createteachers;
    },
    //paymentgateway
    paymentgateway: (state) => {
      return state.paymentgateway;
    },
    createpaymentgateway: (state) => {
      return state.createpaymentgateway;
    },

  },
  mutations: {
    FETCH_DEALERLATESTDATA(state, dealerlatestdata) {
      return (state.dealerlatestdata = dealerlatestdata);
    },
    FETCH_POSTCOMMANDS(state, postcommands) {
      return (state.postcommands = postcommands);
    },
    //kvclients
    CREATE_KVCLIENT(state, kvclients) {
      state.kvclients.unshift(kvclients);
    },
    //firmwares
    CREATE_FIRMWARE(state, firmwares) {
      state.firmwares.unshift(firmwares);
    },
    FETCH_FIRMWARES(state, firmwares) {
      return (state.firmwares = firmwares);
    },
    FETCH_920(state, form920) {
      return (state.form920 = form920);
    },
    FETCH_922(state, form922) {
      return (state.form922 = form922);
    },
    CREATE_CBFIRMWARE(state, cbfirmwares) {
      state.cbfirmwares.unshift(cbfirmwares);
    },
    CREATE_CBV2FIRMWARE(state, cbv2firmwares) {
      state.cbv2firmwares.unshift(cbv2firmwares);
    },
    CREATE_CAZIGFIRMWARE(state, cazigfirmware) {
      state.cazigfirmwares.unshift(cazigfirmware);
    },
    CREATE_TFTFIRMWARE(state, tftfirmwares) {
      state.cbfirmwares.unshift(tftfirmwares);
    },
    CREATE_PRESSFIRMWARE(state, pressfirmwares) {
      state.cbfirmwares.unshift(pressfirmwares);
    },
    FETCH_STUDENTS(state, students) {
      return (state.students = students);
    },
    FETCH_OPERATIONS(state, operations) {
      return (state.operations = operations);
    },
    FETCH_ROUTES(state, routes) {
      return (state.routes = routes);
    },
    FETCH_CBFIRMWARES(state, cbfirmwares) {
      return (state.cbfirmwares = cbfirmwares);
    },
    FETCH_CBV2FIRMWARES(state, cbv2firmwares) {
      return (state.cbv2firmwares = cbv2firmwares);
    },
    FETCH_CATFTFIRMWARES(state, catftfirmwares) {
      return (state.catftfirmwares = catftfirmwares);
    },
    FETCH_CAZIGFIRMWARES(state, cazigfirmwares) {
      return (state.cazigfirmwares = cazigfirmwares);
    },
    FETCH_S3FIRMWARES(state, s3firmwares) {
      return (state.s3firmwares = s3firmwares);
    },

    FETCH_CBINDIAFIRMWARES(state, cbindiafirmwares) {
      return (state.cbindiafirmwares = cbindiafirmwares);
    },
    FETCH_SAMPINDIAFIRMWARES(state, sampindiafirmwares) {
      return (state.sampindiafirmwares = sampindiafirmwares);
    },
    FETCH_NEOWAYFIRMWARES(state, neowayfirmwares) {
      return (state.neowayfirmwares = neowayfirmwares);
    },
    FETCH_TFTFIRMWARES(state, tftfirmwares) {
      return (state.tftfirmwares = tftfirmwares);
    },
    FETCH_GUIFIRMWARES(state, guifirmwares) {
      return (state.guifirmwares = guifirmwares);
    },
    FETCH_PRESSFIRMWARES(state, pressfirmwares) {
      return (state.pressfirmwares = pressfirmwares);
    },
    //offices
    CREATE_OFFICE(state, office) {
      state.offices.unshift(office);
    },
    EDIT_OFFICE(state, office) {
      state.offices.unshift(office);
    },
    FETCH_OFFICES(state, offices) {
      return (state.offices = offices);
    },
    FETCH_DEALEROFFICES(state, offices) {
      return (state.offices = offices);
    },
    FETCH_REMLOGS(state, remlogs) {
      return (state.remlogs = remlogs);
    },

    //dealers
    CREATE_DEALER(state, dealer) {
      state.dealers.unshift(dealer);
    },
    EDIT_DEALER(state, dealer) {
      state.dealers.unshift(dealer);
    },
    FETCH_DEALERS(state, dealers) {
      return (state.dealers = dealers);
    },
    FETCH_UNIQUEDEALER(state, dealer) {
      return (state.dealers = dealer);
    },
    //Components
    CREATE_COMPONENTS(state, components) {
      state.components.unshift(components);
    },
    EDIT_COMPONENTS(state, components) {
      state.components.unshift(components);
    },
    FETCH_COMPONENTS(state, components) {
      return (state.components = components);
    },
    FETCH_UNIQUECOMPONENTS(state, components) {
      return (state.components = components);
    },
    FETCH_DUPLOGS(state, duplogs) {
      return (state.duplogs = duplogs);
    },
    //users
    CREATE_USER(state, user) {
      state.users.unshift(user);
    },
    EDIT_USER(state, user) {
      state.users.unshift(user);
    },
    FETCH_USERS(state, users) {
      return (state.users = users);
    },
    //clients
    CREATE_CLIENT(state, client) {
      state.clients.unshift(client);
    },
    EDIT_CLIENT(state, client) {
      state.clients.unshift(client);
    },
    FETCH_CLIENTS(state, clients) {
      return (state.clients = clients);
    },
    FETCH_KVCLIENTS(state, kvclients) {
      return (state.kvclients = kvclients);
    },
    FETCH_REMOVALS(state, removals) {
      return (state.removals = removals);
    },
    FETCH_FULLCLIENTS(state, fclients) {
      return (state.fullclients = fclients);
    },
    FETCH_CLIENTCOUNT(state, client) {
      return (state.clientcount = client);
    },
    FETCH_OFFICECLIENTS(state, offcclients) {
      return (state.officeclients = offcclients);
    },
    FETCH_UNIQUECLIENT(state, unclient) {
      return (state.uniqueclient = unclient);
    },
    FETCH_TEMPLOGS(state, templogs) {
      /* eslint-disable no-console */
      return (state.templogs = templogs);
    },
    //logs
    FETCH_LOGS(state, log) {
      /* eslint-disable no-console */
      return (state.logs = log);
    },
    CBIDFETCH_LOGS(state, log) {
      /* eslint-disable no-console */
      return (state.logs = log);
    },
    CBIDFETCHSPLIT_LOGS(state, splitlog) {
      /* eslint-disable no-console */
      return (state.splitlogs = splitlog);
    },
    SAMPLERFETCH_LOGS(state, log) {
      /* eslint-disable no-console */
      return (state.logs = log);
    },
    SAMPLERFETCHSPLIT_LOGS(state, log) {
      /* eslint-disable no-console */
      return (state.splitlogs = log);
    },
    FETCH_LOGSSENSOR(state, log) {
      /* eslint-disable no-console */
      return (state.logs = log);
    },
    FETCH_LOGSBEFORE(state, log) {
      /* eslint-disable no-console */
      return (state.logs = log);
    },
    FETCH_LOGSBEFORESENSOR(state, log) {
      /* eslint-disable no-console */
      return (state.logs = log);
    },
    //prohibitions
    CREATE_PROHIBITION(state, prohib) {
      state.prohibitions.unshift(prohib);
    },
    EDIT_PROHIBITION(state, prohib) {
      state.prohibitions.unshift(prohib);
    },
    FETCH_PROHIBITIONS(state, prohib) {
      return (state.prohibitions = prohib);
    },
    FETCH_VEHICLES(state, vehicles) {
      return (state.vehicles = vehicles);
    },
    FETCH_DRIVERS(state, drivers) {
      return (state.drivers = drivers);
    },
    FETCH_CLIENTPROHIBITIONS(state, prohib) {
      return (state.clientprohibitions = prohib);
    },
    FETCH_UNIQUEVEHICLE(state, prohib) {
      return (state.uniqueprohibition = prohib);
    },
    //devices
    CREATE_DEVICE(state, device) {
      state.devices.unshift(device);
    },
    EDIT_DEVICE(state, device) {
      state.devices.unshift(device);
    },
    FETCH_DEVICECOUNT(state, device) {
      return (state.devicecount = device);
    },
    FETCH_DEVICES(state, devices) {
      return (state.devices = devices);
    },
    FETCH_OLEDDEVICES(state,oledDevices){
      return (state.oledDevices = oledDevices);
    },
    FETCH_CALIBRATIONKIT(state,calibrationkit){
      return (state.calibrationkit = calibrationkit)
    },
    FETCH_KITMODELS(state,kitmodels){
      return (state.kitmodels = kitmodels)
    },
    FETCH_CLIENTDEVICES(state, clientdevices) {
      return (state.clientdevices = clientdevices);
    },
    FETCH_OFFICEDEVICES(state, officedevices) {
      return (state.officedevices = officedevices);
    },
    //calibrations
    CREATE_CALIBRATION(state, calibration) {
      state.calibrations.unshift(calibration);
    },
    FETCH_CALIBRATIONS(state, calibrations) {
      return (state.calibrations = calibrations);
    },
    //invoices
    CREATE_INVOICE(state, invoice) {
      state.invoices.unshift(invoice);
    },
    FETCH_INVOICES(state, invoices) {
      return (state.invoices = invoices);
    },
    FETCH_UNIQUE_INVOICE(state, invoices) {
      return (state.uniqueinvoice = invoices);
    },
    FETCH_UNIQUE_INVOICE2(state, invoices) {
      return (state.uniqueinvoice2 = invoices);
    },
    FETCH_OFFICEINVOICES(state, officeinvoices) {
      return (state.officeinvoices = officeinvoices);
    },
    FETCH_TOTALREVENUE(state, totalrevenue) {
      return (state.totalrevenue = totalrevenue);
    },
    FETCH_INVOICESERVICES(state, invoiceservices) {
      return (state.invoiceservices = invoiceservices);
    },

    //installations
    CREATE_INSTALLATION(state, installation) {
      state.installations.unshift(installation);
    },
    EDIT_INSTALLATION(state, installation) {
      state.installations.unshift(installation);
    },
    FETCH_INSTALLATIONS(state, installations) {
      return (state.installations = installations);
    },
    //services
    CREATE_SERVICE(state, service) {
      state.services.unshift(service);
    },
    EDIT_SERVICE(state, service) {
      state.services.unshift(service);
    },
    FETCH_SERVICES(state, services) {
      return (state.services = services);
    },
    FETCH_LOCKOUT(state, lockout) {
      return (state.lockouts = lockout);
    },
    //dashboard
    FETCH_DASHBOARD(state, dashboard) {
      return (state.dashboard = dashboard);
    },
    //reports
    CREATE_REPORT(state, report) {
      state.reports.unshift(report);
    },
    FETCH_REPORTS(state, reports) {
      return (state.reports = reports);
    },
    //school
    FETCH_SCHOOLTEACHERS(state, schoolteachers) {
      return (state.schoolteachers = schoolteachers);
    },
    FETCH_SCHOOLSUBJECTS(state, schoolsubjects) {
      return (state.schoolsubjects = schoolsubjects);
    },
    FETCH_STUDENTSCLASSES(state, studentsClasses) {
      return (state.studentsClasses = studentsClasses);
    },
    FETCH_ROUTESTOPS(state, routestops) {
      return (state.routestops = routestops);
    },
    FETCH_ROUTESTOPSVEHICLES(state, routestopsvehicles) {
      return (state.routestopsvehicles = routestopsvehicles);
    },
    FETCH_STUDENTSACTIVITES(state, studentsActivites) {
      return (state.studentsActivites = studentsActivites);
    },
    FETCH_SCHOOLALLCLASSES(state, schoolallclasses) {
      return (state.schoolallclasses = schoolallclasses);
    },
    FETCH_SCHOOLSTUDENTS(state, schoolstudents) {
      return (state.schoolstudents = schoolstudents);
    },
    FETCH_SCHOOLHOLIDAYS(state, schoolholidays) {
      return (state.schoolholidays = schoolholidays);
    },
    FETCH_SCHOOLEXAMS(state, schoolexams) {
      return (state.schoolexams = schoolexams);
    },
    FETCH_SCHOOLFEES(state, schoolfees) {
      return (state.schoolfees = schoolfees);
    },
    FETCH_SCHOOLCLASSES(state, classes) {
      return (state.classes = classes);
    },
    CREATE_TEACHER(state, createteachers) {
      state.createteachers.unshift(createteachers);
    },

    //paymentgateways
    FETCH_PAYMENTGATEWAY(state, paymentgateway) {
      return (state.paymentgateway = paymentgateway);
    },
    CREATE_PAYMENTGATEWAY(state, createpaymentgateway) {
      state.createpaymentgateway.unshift(createpaymentgateway);
    },
  
  },
  actions: {
    createPostcommand({ dispatch }, payload) {
      
      Vue.axios
        .post("https://robopower.xyz/app/queuecommands", payload)
        .then(() => {
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
            dispatch("fetchpostCommands", payload.clientid);
          
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editPostcommand({ dispatch },payload) {
      console.log(payload)
      Vue.axios
        .post("https://robopower.xyz/app/queuecommands/editqueue/"+payload.id, payload.data)
        .then(() => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
            dispatch("fetchpostCommands", payload.data.clientid);
          
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchpostCommands({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/queuecommands/getclientcommands?clientId=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_POSTCOMMANDS", response.data);
          } else {
            commit("FETCH_POSTCOMMANDS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },      
    fetchdealerLatestdata({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/v2data/getdealerlatestupdates2?dealerId=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_DEALERLATESTDATA", response.data);
          } else {
            commit("FETCH_DEALERLATESTDATA", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchRemovals({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/fullclients/getallremovals")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_REMOVALS", response.data);
          } else {
            commit("FETCH_REMOVALS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },

    fetchdealerRemovals({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/fullclients/getdealerremovals?dealerName=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_REMOVALS", response.data);
          } else {
            commit("FETCH_REMOVALS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createfullClient({ dispatch }, client) {
      Vue.axios
        .post("https://robopower.xyz/us/fullclients/addfullclient", client)
        .then(() => {
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchfullClients");
          } else {
            dispatch("fetchdealerfullClients", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editfullProhib({ dispatch }, data) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/fullclients/editfullprohib/" + data.id,
          data.cdata
        )
        .then((response) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchfullClients");
          } else {
            dispatch("fetchdealerfullClients", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editfullClient({ dispatch }, data) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/fullclients/editfullclient/" + data.id,
          data.cdata
        )
        .then((response) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchfullClients");
          } else {
            dispatch("fetchdealerfullClients", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deletefullClient({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/caclients/deleteclient/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchClients");
          } else {
            dispatch("fetchdealerClients", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteFullclientprohib({ dispatch }, item) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/fullclients/deletefullclientprohibs/" +
            item.id
        )
        .then((response) => {
          if (response.data == "Success Moving to removals") {
            Vue.axios
              .get(
                "https://robopower.xyz/us/fullclients/deletefullclient/" +
                  item.id
              )
              .then((response) => {
                if (response.data == "delete success") {
                  dispatch(
                    "alert/success",
                    " Successfully Moved to Removals"
                  ).then(() => {
                    setTimeout(() => {
                      dispatch("alert/clear");
                    }, 2000);
                  });
                } else {
                  dispatch("alert/error", "Failed Moving").then(() => {
                    setTimeout(() => {
                      dispatch("alert/clear");
                    }, 2000);
                  });
                }
              })
              .catch((err) => {
                this.$store.dispatch("alert/error", err);
              });
          }

          if (this.state.user.role == "Admin") {
            dispatch("fetchfullClients");
          } else {
            dispatch("fetchdealerfullClients", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    restoreFullclientprohib({ dispatch }, item) {
      Vue.axios
        .get("https://robopower.xyz/us/fullclients/moveremoval/" + item.id)
        .then((response) => {
          if (response.data == "Success Moving to clients") {
            Vue.axios
              .get(
                "https://robopower.xyz/us/fullclients/deleteremoval/" + item.id
              )
              .then((response) => {
                if (response.data == "delete success") {
                  dispatch(
                    "alert/success",
                    " Successfully Restored to Clients"
                  ).then(() => {
                    setTimeout(() => {
                      dispatch("alert/clear");
                    }, 2000);
                  });
                } else {
                  dispatch("alert/error", "Failed Moving").then(() => {
                    setTimeout(() => {
                      dispatch("alert/clear");
                    }, 2000);
                  });
                }
              })
              .catch((err) => {
                this.$store.dispatch("alert/error", err);
              });
          }

          if (this.state.user.role == "Admin") {
            dispatch("fetchRemovals");
          } else {
            dispatch("fetchdealerRemovals", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //kvclients
    createKvclient({ dispatch, commit }, client) {
      Vue.axios
        .post("https://robopower.xyz/v2/kvclients/addclient", client)
        .then(() => {
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchkvClients", this.state.user.dealerId);
        });
    },
    editKvclient({ dispatch, commit }, client) {
      Vue.axios
        .post(
          "https://robopower.xyz/v2/kvclients/editclient/" + client.id,
          client.devdata
        )
        .then(() => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchkvClients", this.state.user.dealerId);
        });
    },
    fetchkvClients({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/v2/kvclients/getdealerclients?dealer=" + id)
        .then((res2) => {
          if (res2.data != null) {
            var cdata = res2.data;
            let proms = cdata.map((red) => {
              return Vue.axios
                .get(
                  "https://robopower.xyz/v2/kvpayments/getclientpayments?client=" +
                    red.FullName
                )
                .then((res) => {
                  if (res.data != null) {
                    var fdata = res.data;
                    var tamt = 0.0;
                    fdata.map((res) => {
                      tamt = tamt + parseFloat(res.balance);
                    });
                    red["balance"] = tamt;
                    return red;
                  } else {
                    red["balance"] = "0.00";
                    return red;
                  }
                });
            });
            Promise.all(proms).then((res) => {
              commit("FETCH_KVCLIENTS", res);
            });
          } else {
            alert("No Payments Found");
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },

    //clients
    createClient({ dispatch }, client) {
      Vue.axios
        .post("https://robopower.xyz/us/caclients/addclient", client)
        .then(() => {
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchClients");
          } else {
            dispatch("fetchdealerClients", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editClient({ dispatch }, data) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/caclients/editclient/" + data.id,
          data.cdata
        )
        .then((response) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchClients");
          } else {
            dispatch("fetchdealerClients", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteClient({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/caclients/deleteclient/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchClients");
          } else {
            dispatch("fetchdealerClients", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchClients({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/caclients/getallclients")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_CLIENTS", response.data);
          } else {
            commit("FETCH_CLIENTS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchallschoolStudents({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/app/students/getallstudents?dealerId=" + id)
        .then((response) => {
          commit("FETCH_STUDENTS", response.data);
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchbranchStudents({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/students/getschoolstudents?officeId=" + id
        )
        .then((response) => {
          commit("FETCH_STUDENTS", response.data);
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchallschoolRoutes({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/vehicleroutes/getdealerallroutes?dealerId=" +
            id
        )
        .then((response) => {
          if(response.data){
          commit("FETCH_ROUTES", response.data);
          }else{
            commit("FETCH_ROUTES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchbranchRoutes({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/vehicleroutes/getofficeallroutes?officeId=" +
            id
        )
        .then((response) => {
          commit("FETCH_ROUTES", response.data);
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchfullClients({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/fullclients/getallfullrclients")
        .then((response) => {
          var newArr = [];
          if (response.data != null) {
            var std = response.data;
            // std.map((red) => {
            //   Vue.axios
            //     .get(
            //       "https://robopower.xyz/us/cainvoices/getuniquerinvoice?regnum=" +
            //         red.regnum
            //     )
            //     .then((response) => {
            //       if (response.data != null) {
            //         var invos = {
            //           invs: 0,
            //           files: 0,
            //           totalamount: 0,
            //           paidamount: 0,
            //           pending: 0,
            //         };
            //         // console.log(response.data)
            //         var val = response.data;
            //         if (val.length > 0) {
            //           invos.invs = val.length;
            //           val.map((res) => {
            //             if (res.amountpaid != "") {
            //               invos.paidamount =
            //                 parseFloat(invos.paidamount) +
            //                 parseFloat(res.amountpaid);
            //             }
            //             if (res.total != "") {
            //               invos.totalamount =
            //                 invos.totalamount + parseFloat(res.total);
            //             }
            //             if (res.prevbalance != "") {
            //               invos.pending =
            //                 invos.pending + parseFloat(res.prevbalance);
            //             }
            //           });
            //           // console.log(invos);
            //           var obj = {
            //             id: red.id,
            //             client: red.client,
            //             officeId: red.officeId,
            //             regdate: red.regdate,
            //             clientphone1: red.clientphone1,
            //             clientphone2: red.clientphone2,
            //             email: red.email,
            //             dob: red.dob,
            //             clientAddress: red.clientAddress,
            //             city: red.city,
            //             state: red.state,
            //             zipcode: red.zipcode,
            //             regnum: red.regnum,
            //             sno: red.sno,
            //             installDate: red.installDate,
            //             calibrationDate: red.calibrationDate,
            //             nextcmonitordate: red.nextcmonitordate,
            //             make: red.make,
            //             model: red.model,
            //             year: red.year,
            //             color: red.color,
            //             vin: red.vin,
            //             tag: red.tag,
            //             tagstate: red.tagstate,
            //             DLnum: red.DLnum,
            //             prohibitionnumber: red.prohibitionnumber,
            //             prohibstart: red.prohibstart,
            //             prohibend: red.prohibend,
            //             conviction: red.conviction,
            //             calibrationperiod: red.calibrationperiod,
            //             isresidsame: red.isresidsame,
            //             rclientaddress: red.rclientaddress,
            //             rspace: red.rspace,
            //             rcity: red.rcity,
            //             rstate: red.rstate,
            //             rzipcode: red.rzipcode,
            //             manufacturer: red.manufacturer,
            //             isinstalledandworking: red.isinstalledandworking,
            //             driverisincompliance: red.driverisincompliance,
            //             isunavailablevehicle: red.isunavailablevehicle,
            //             installer: red.installer,
            //             suffix: red.suffix,
            //             space: red.space,
            //             uregnum: red.uregnum,
            //             umake: red.umake,
            //             uyear: red.uyear,
            //             uvin: red.uvin,
            //             facedetection: red.facedetection,
            //             issms: red.issms,
            //             routeid:red.routeid,
            //             autorenewal:red.autorenewal,
            //             autorenewal_id: red.autorenewal_id,
            //             devId: red.devId,
            //             cbid: red.cbid,
            //             status: red.status,
            //             invoc: invos,
            //           };
            //           newArr.push(obj);
            //         }
            //       }  else {
                      
            //         var obj1 = {
            //           id: red.id,
            //           client: red.client,
            //           officeId: red.officeId,
            //           regdate: red.regdate,
            //           clientphone1: red.clientphone1,
            //           clientphone2: red.clientphone2,
            //           email: red.email,
            //           dob: red.dob,
            //           clientAddress: red.clientAddress,
            //           city: red.city,
            //           state: red.state,
            //           zipcode: red.zipcode,
            //           regnum: red.regnum,
            //           sno: red.sno,
            //           installDate: red.installDate,
            //           calibrationDate: red.calibrationDate,
            //           nextcmonitordate: red.nextcmonitordate,
            //           make: red.make,
            //           model: red.model,
            //           year: red.year,
            //           color: red.color,
            //           vin: red.vin,
            //           tag: red.tag,
            //           tagstate: red.tagstate,
            //           DLnum: red.DLnum,
            //           prohibitionnumber: red.prohibitionnumber,
            //           prohibstart: red.prohibstart,
            //           prohibend: red.prohibend,
            //           conviction: red.conviction,
            //           calibrationperiod: red.calibrationperiod,
            //           isresidsame: red.isresidsame,
            //           rclientaddress: red.rclientaddress,
            //           rspace: red.rspace,
            //           rcity: red.rcity,
            //           rstate: red.rstate,
            //           rzipcode: red.rzipcode,
            //           manufacturer: red.manufacturer,
            //           isinstalledandworking: red.isinstalledandworking,
            //           driverisincompliance: red.driverisincompliance,
            //           isunavailablevehicle: red.isunavailablevehicle,
            //           installer: red.installer,
            //           suffix: red.suffix,
            //           space: red.space,
            //           uregnum: red.uregnum,
            //           umake: red.umake,
            //           uyear: red.uyear,
            //           uvin: red.uvin,
            //           facedetection: red.facedetection,
            //           issms: red.issms,
            //           routeid:red.routeid,
            //           autorenewal:red.autorenewal,
            //           autorenewal_id: red.autorenewal_id,
            //           devId: red.devId,
            //           cbid: red.cbid,
            //           status: red.status,
            //           invoc: invos,
            //         };
            //         newArr.push(obj1);
            //       }
            //     });
            // });
            commit("FETCH_FULLCLIENTS", response.data);
          // } else {
          //   commit("FETCH_FULLCLIENTS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerfullClients({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/fullclients/getdealerfullrclients?dealerName=" +
            id
        )
        .then((response) => {
          var newArr = [];
          if (response.data != null) {
            var std = response.data;
          //   var cd = JSON.parse(localStorage.getItem("user"));
          //   if(cd.state != "INDIA"){
          //   std.map((red) => {
          //     Vue.axios
          //       .get(
          //         "https://robopower.xyz/us/cainvoices/getuniquerinvoice?regnum=" +
          //           red.regnum
          //       )
          //       .then((response) => {
          //         if (response.data != null) {
          //           var invos = {
          //             invs: 0,
          //             files: 0,
          //             totalamount: 0,
          //             paidamount: 0,
          //             pending: 0,
          //           };
          //           // console.log(response.data)
          //           var val = response.data;
          //           if (val.length > 0) {
          //             invos.invs = val.length;
          //             val.map((res) => {
          //               if (res.amountpaid != "") {
          //                 invos.paidamount =
          //                   parseFloat(invos.paidamount) +
          //                   parseFloat(res.amountpaid);
          //               }
          //               if (res.total != "") {
          //                 invos.totalamount =
          //                   invos.totalamount + parseFloat(res.total);
          //               }
          //               if (res.prevbalance != "") {
          //                 invos.pending =
          //                   invos.pending + parseFloat(res.prevbalance);
          //               }
          //             });
          //             // console.log(invos);
          //             var obj = {
          //               id: red.id,
          //               client: red.client,
          //               officeId: red.officeId,
          //               regdate: red.regdate,
          //               clientphone1: red.clientphone1,
          //               clientphone2: red.clientphone2,
          //               email: red.email,
          //               dob: red.dob,
          //               clientAddress: red.clientAddress,
          //               city: red.city,
          //               state: red.state,
          //               zipcode: red.zipcode,
          //               regnum: red.regnum,
          //               sno: red.sno,
          //               installDate: red.installDate,
          //               calibrationDate: red.calibrationDate,
          //               nextcmonitordate: red.nextcmonitordate,
          //               make: red.make,
          //               model: red.model,
          //               year: red.year,
          //               color: red.color,
          //               vin: red.vin,
          //               tag: red.tag,
          //               tagstate: red.tagstate,
          //               DLnum: red.DLnum,
          //               prohibitionnumber: red.prohibitionnumber,
          //               prohibstart: red.prohibstart,
          //               prohibend: red.prohibend,
          //               conviction: red.conviction,
          //               calibrationperiod: red.calibrationperiod,
          //               isresidsame: red.isresidsame,
          //               rclientaddress: red.rclientaddress,
          //               rspace: red.rspace,
          //               rcity: red.rcity,
          //               rstate: red.rstate,
          //               rzipcode: red.rzipcode,
          //               manufacturer: red.manufacturer,
          //               isinstalledandworking: red.isinstalledandworking,
          //               driverisincompliance: red.driverisincompliance,
          //               isunavailablevehicle: red.isunavailablevehicle,
          //               installer: red.installer,
          //               suffix: red.suffix,
          //               space: red.space,
          //               uregnum: red.uregnum,
          //               umake: red.umake,
          //               uyear: red.uyear,
          //               uvin: red.uvin,
          //               facedetection: red.facedetection,
          //               issms: red.issms,
          //               routeid: red.routeid,
          //               autorenewal: red.autorenewal,
          //               autorenewal_id: red.autorenewal_id,
          //               devId: red.devId,
          //               cbid: red.cbid,
          //               status: red.status,
          //               invoc: invos,
          //             };
          //             newArr.push(obj);
          //           }
          //         }  else {
                      
          //           var obj1 = {
          //             id: red.id,
          //             client: red.client,
          //             officeId: red.officeId,
          //             regdate: red.regdate,
          //             clientphone1: red.clientphone1,
          //             clientphone2: red.clientphone2,
          //             email: red.email,
          //             dob: red.dob,
          //             clientAddress: red.clientAddress,
          //             city: red.city,
          //             state: red.state,
          //             zipcode: red.zipcode,
          //             regnum: red.regnum,
          //             sno: red.sno,
          //             installDate: red.installDate,
          //             calibrationDate: red.calibrationDate,
          //             nextcmonitordate: red.nextcmonitordate,
          //             make: red.make,
          //             model: red.model,
          //             year: red.year,
          //             color: red.color,
          //             vin: red.vin,
          //             tag: red.tag,
          //             tagstate: red.tagstate,
          //             DLnum: red.DLnum,
          //             prohibitionnumber: red.prohibitionnumber,
          //             prohibstart: red.prohibstart,
          //             prohibend: red.prohibend,
          //             conviction: red.conviction,
          //             calibrationperiod: red.calibrationperiod,
          //             isresidsame: red.isresidsame,
          //             rclientaddress: red.rclientaddress,
          //             rspace: red.rspace,
          //             rcity: red.rcity,
          //             rstate: red.rstate,
          //             rzipcode: red.rzipcode,
          //             manufacturer: red.manufacturer,
          //             isinstalledandworking: red.isinstalledandworking,
          //             driverisincompliance: red.driverisincompliance,
          //             isunavailablevehicle: red.isunavailablevehicle,
          //             installer: red.installer,
          //             suffix: red.suffix,
          //             space: red.space,
          //             uregnum: red.uregnum,
          //             umake: red.umake,
          //             uyear: red.uyear,
          //             uvin: red.uvin,
          //             facedetection: red.facedetection,
          //             issms: red.issms,
          //             routeid: red.routeid,
          //             autorenewal: red.autorenewal,    
          //             autorenewal_id: red.autorenewal_id,                  
          //             devId: red.devId,
          //             cbid: red.cbid,
          //             status: red.status,
          //             invoc: invos,
          //           };
          //           newArr.push(obj1);
          //         }
          //       });
          //   });
          //   commit("FETCH_FULLCLIENTS", newArr);
          // } else{
            commit("FETCH_FULLCLIENTS", response.data);
          // }
          } else {
            commit("FETCH_FULLCLIENTS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerClients({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caclients/getdealerclients?dealerName=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_CLIENTS", response.data);
          } else {
            commit("FETCH_CLIENTS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchfullofficeClients({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/fullclients/getofficefullclients?officeId=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            var count = 0;
            var data = {
              allclients: 0,
              latestclients: count,
            };

            commit("FETCH_OFFICECLIENTS", response.data);
            var alldata = response.data;
            if (alldata != null) {
              (data.allclients = alldata.length),
                alldata.map((element) => {
                  var today = moment();
                  //last 30days clients
                  var nextdate = moment(element.regdate, "YYYY-MM-DD");
                  var existdate = moment(nextdate).format("YYYY-MM-DD");
                  var gap = today.diff(existdate, "days");
                  if (gap <= 30) {
                    count++;
                  }
                });
              data.latestclients = count;
            }
            commit("FETCH_CLIENTCOUNT", data);
          } else {
            var data2 = {
              allclients: 0,
              latestclients: 0,
            };
            commit("FETCH_OFFICECLIENTS", []);
            commit("FETCH_CLIENTCOUNT", data2);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchofficeClients({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caclients/getofficeclients?officeId=" + id
        )
        .then((response) => {
          var count = 0;
          var data = {
            allclients: 0,
            latestclients: count,
          };
          if (response.data != null) {
            commit("FETCH_OFFICECLIENTS", response.data);
            var alldata = response.data;
            if (alldata != null) {
              (data.allclients = alldata.length),
                alldata.map((element) => {
                  var today = moment();
                  //last 30days clients
                  var nextdate = moment(element.regdate, "YYYY-MM-DD");
                  var existdate = moment(nextdate).format("YYYY-MM-DD");
                  var gap = today.diff(existdate, "days");
                  if (gap <= 30) {
                    count++;
                  }
                });
              data.latestclients = count;
            }
            commit("FETCH_CLIENTCOUNT", data);
          } else {
            commit("FETCH_OFFICECLIENTS", []);
            commit("FETCH_CLIENTCOUNT", data);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchuniquefullClient({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/fullclients/getuniquefullclient?clientId=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            /* eslint-disable no-console */
            commit("FETCH_UNIQUECLIENT", response.data[0]);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchuniqueClient({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caclients/getuniqueclient?clientId=" + id
        )
        .then((response) => {
          /* eslint-disable no-console */
          commit("FETCH_UNIQUECLIENT", response.data[0]);
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //devices
    createDevice({ dispatch, commit }, device) {
      Vue.axios
        .post("https://robopower.xyz/us/cadevices/adddevice", device)
        .then(() => {
          commit("FETCH_DEVICES");
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchDevices");
          } else {
            dispatch("fetchdealerDevices", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createKit({ dispatch, commit }, device) {
      Vue.axios
        .post("https://robopower.xyz/us/cacalkits/addkit", device)
        .then(() => {
          commit("FETCH_CALIBRATIONKIT");
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchCalibrationkit");
            dispatch("fetchdealerDevices", this.state.user.dealerId);
          } else {
            dispatch("fetchdealerDevices", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createOLEDDevice({ dispatch, commit }, device) {
      Vue.axios
        .post("https://robopower.xyz/app/v2data/addoled", device)
        .then(() => {
          console.log(device,"DEVICSSS")
          commit("FETCH_OLEDDEVICES");
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchOLEDDevices");
          } else {
            dispatch("fetchdealerDevices", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editDevice({ dispatch }, device) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/cadevices/editdevice/" + device.id,
          device.devdata
        )
        .then((res) => {
          if (res.data) {
            dispatch("alert/success", res.data).then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
            if (this.state.user.role == "Admin") {
              dispatch("fetchDevices");
            } else {
              dispatch("fetchdealerDevices", this.state.user.dealerId);
            }
          } else {
            dispatch("alert/error", "Edit Device Failed").then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editOLEDDevice({ dispatch }, device) {
      Vue.axios
        .post("https://robopower.xyz/app/v2data/editoled/" + device.id,
          device.devdata
        )
        .then((res) => {
          if (res.data) {
            dispatch("alert/success", res.data).then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
            if (this.state.user.role == "Admin") {
              dispatch("fetchOLEDDevices");
            } else {
              dispatch("fetchdealerDevices", this.state.user.dealerId);
            }
          } else {
            dispatch("alert/error", "Edit Device Failed").then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editDevicemode({ dispatch }, device) {
      Vue.axios
        .post("https://robopower.xyz/us/cadevices/editdevicemode", device)
        .then((res) => {
          if (res.data) {
            dispatch("alert/success", res.data).then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
            if (this.state.user.role == "Admin") {
              dispatch("fetchDevices");
            } else {
              dispatch("fetchdealerDevices", this.state.user.dealerId);
            }
          } else {
            dispatch("alert/error", "Edit Device Failed").then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editDevicemode2({ dispatch }, device) {
      Vue.axios
        .post("https://robopower.xyz/us/cadevices/editdevicemode2", device)
        .then((res) => {
          if (res.data) {
            dispatch("alert/success", res.data).then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
            if (this.state.user.role == "Admin") {
              dispatch("fetchDevices");
            } else {
              dispatch("fetchdealerDevices", this.state.user.dealerId);
            }
          } else {
            dispatch("alert/error", "Edit Device Failed").then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //   edittimezone({ dispatch }, device) {
    //   // console.log(device)
    //   Vue.axios.post("https://robopower.xyz/us/cadevices/edittimezone",device)
    //       .then(res => {
    //         // console.log(res.data)
    //         if(res.data){
    //           dispatch('alert/success',res.data).then(()=>{
    //             setTimeout(()=>{
    //               dispatch("alert/clear")
    //             },2000)
    //             })
    //           if(this.state.user.role=="Admin"){
    //             dispatch("fetchDevices")
    //           } else{
    //             dispatch("fetchdealerDevices",this.state.user.dealerId)
    //           }
    //         } else{
    //           dispatch('alert/error',"Edit Device Failed").then(()=>{
    //             setTimeout(()=>{
    //               dispatch("alert/clear")
    //             },2000)
    //             })
    //         }
    //       }).catch(err => {
    //         dispatch('alert/error',err).then(()=>{
    //           setTimeout(()=>{
    //             dispatch("alert/clear")
    //           },2000)
    //           })
    //   })
    // },
    deleteDevice({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/cadevices/deletedevice/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchDevices");
          } else {
            dispatch("fetchdealerDevices", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteOLEDDevice({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/app/v2data/deleteoled/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          
            dispatch("fetchOLEDDevices");
          
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchDevices({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/cadevices/getalldevices")
        .then((response) => {
          if (response.data != null) {
            var alldevicedata = response.data;
            commit("FETCH_DEVICES", alldevicedata);
          } else {
            commit("FETCH_DEVICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchOLEDDevices({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/app/v2data/getoleddevices")
        .then((response) => {
          if (response.data != null) {
            var alldevicedata = response.data;
            commit("FETCH_OLEDDEVICES", alldevicedata);
          } else {
            commit("FETCH_OLEDDEVICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerCalibrationkit({ dispatch, commit },id) {
      Vue.axios
        .get("https://robopower.xyz/us/cacalkits/getdealerkits?dealerId="+id)
        .then((response) => {
          if (response.data != null) {
            var alldevicedata = response.data;
            commit("FETCH_CALIBRATIONKIT", alldevicedata);
          } else {
            commit("FETCH_CALIBRATIONKIT", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchCalibrationkit({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/cacalkits/getallkits")
        .then((response) => {
          if (response.data != null) {
            var alldevicedata = response.data;
            commit("FETCH_CALIBRATIONKIT", alldevicedata);
          } else {
            commit("FETCH_CALIBRATIONKIT", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchkitmodels({ dispatch, commit }){
      Vue.axios
      .get("https://robopower.xyz/us/cadevices/getdevicemodels")
      .then((response) => {
        if (response.data != null) {
          var alldevicedata = response.data;
          commit("FETCH_KITMODELS", alldevicedata);
        } else {
          commit("FETCH_KITMODELS", []);
        }
      })
      .catch((err) => {
        dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            dispatch("alert/clear");
          }, 2000);
        });
      });
    },
    fetchdealerDevices({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cadevices/getdealerdevices?dealerName=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_DEVICES", response.data);
          } else {
            commit("FETCH_DEVICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },

    fetchclientDevices({ dispatch, commit }, item) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cadevices/getclientdevices?clientId=" +
            item.client
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_CLIENTDEVICES", response.data);
          } else {
            commit("FETCH_CLIENTDEVICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchofficeDevices({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cadevices/getofficedevices?officeId=" + id
        )
        .then((response) => {
          commit("FETCH_OFFICEDEVICES", response.data);
          var alldata = response.data;
          var data = {
            alldevices: 0,
            availabledevices: 0,
            servicedevices: 0,
            installeddevices: 0,
          };
          if (alldata != null) {
            data.alldevices = alldata.length;
            let servicedevices = [];
            let availabledevices = [];
            let installeddevices = [];
            alldata.map((element) => {
              if (element.status == "Sent to Service") {
                servicedevices.push(element);
              } else if (element.status == "Available") {
                availabledevices.push(element);
              } else if (element.status == "Installed") {
                installeddevices.push(element);
              }
            });
            data.availabledevices = availabledevices.length;
            data.servicedevices = servicedevices.length;
            data.installeddevices = installeddevices.length;
          }
          commit("FETCH_DEVICECOUNT", data);
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //offices
    createOffice({ dispatch }, office) {
      Vue.axios
        .post("https://robopower.xyz/us/caoffices/addoffice", office)
        .then(() => {
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchOffices");
          } else {
            dispatch("fetchdealerOffices", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editOffice({ dispatch }, office) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/caoffices/editoffice/" + office.id,
          office.odata
        )
        .then((res) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchDealers");
          if (this.state.user.role == "Admin") {
            dispatch("fetchOffices");
          } else {
            dispatch("fetchdealerOffices", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteOffice({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/caoffices/deleteoffice/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchDealers");
          if (this.state.user.role == "Admin") {
            dispatch("fetchOffices");
          } else {
            dispatch("fetchdealerOffices", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deletedealerOffices({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/cadealers/deletedealeroffices/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchDealers");
          if (this.state.user.role == "Admin") {
            dispatch("fetchOffices");
          } else {
            dispatch("fetchdealerOffices", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    clearclientlogs({ dispatch }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/v2/eeprom/deletealllogs?deviceId=" + id.devId
        )
        .then((response) => {
          dispatch("alert/success", "Log Cleared Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin" || this.state.user.role == "superadmin") {
            dispatch("fetchfullClients");
          } else {
            dispatch("fetchdealerfullClients", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    clearcbidclientlogs({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/deletecballLogs?cbid=" + id.cbid)
        .then((response) => {
          dispatch("alert/success", "Log Cleared Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin" ||this.state.user.role == "superadmin") {
            dispatch("fetchfullClients");
          } else {
            dispatch("fetchdealerfullClients", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchOffices({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/caoffices/getalloffices")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_OFFICES", response.data);
          } else {
            commit("FETCH_OFFICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerOffices({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caoffices/getdealeroffices?dealerName=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_DEALEROFFICES", response.data);
          } else {
            commit("FETCH_DEALEROFFICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //dealers
    createDealer({ dispatch, commit }, dealer) {
      Vue.axios
        .post("https://robopower.xyz/us/cadealers/adddealer", dealer)
        .then((res) => {
          console.log(res.data)
          commit("CREATE_DEALER", res.data[0]);
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editDealer({ dispatch }, dealer) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/cadealers/editdealer/" + dealer.id,
          dealer.ddata
        )
        .then((res) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchDealers");
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteDealer({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/cadealers/deletedealer/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchDealers");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchDealers({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/cadealers/getalldealers")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_DEALERS", response.data);
            dispatch("alert/error").then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          } else {
            commit("FETCH_DEALERS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err);
        });
    },
    fetchuniqueDealer({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cadealers/getuniquedealer?dealerName=" + id
        )
        .then((response) => {
          /* eslint-disable no-console */
          commit("FETCH_UNIQUEDEALER", response.data[0]);
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    // Components
    createComponents({ dispatch, commit }, component) {
      Vue.axios
        .post("https://robopower.xyz/us/a200comps/addcomponent", component)
        .then((res) => {
          commit("CREATE_COMPONENTS", res.data[0]);
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editComponents({ dispatch }, component) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/a200comps/editcomponent/" + component.id,
          component.cdata
        )
        .then((res) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchComponents");
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteComponents({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/a200comps/deletecomponent/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchComponents");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchDuplogs({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/getduplicates")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            var arr2 = arr.sort(
              (a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
            );
            var newArr = [];
            if (arr2 != null) {
              var repdata = arr2.reverse();
              repdata.map((element) => {
                // if(element.typeofevent !="101"){
                if (element.typeofevent != "29") {
                  if (element.typeofevent != "56") {
                    var eventType, Result, Bacvalue;
                    if (element.typeofevent == 254) {
                      //initialtest
                      if (
                        element.randominitial == 2 ||
                        element.randominitial == 0
                      ) {
                        eventType = "INITIAL TEST";
                      } else if (
                        element.randominitial == 3 ||
                        element.randominitial == 1
                      ) {
                        eventType = "RANDOM TEST";
                      } else {
                        eventType = "NA";
                      }
                      if (element.passfailstatus == 1) {
                        Result = "PASS";
                      } else if (element.passfailstatus == 0) {
                        Result = "FAIL";
                      } else {
                        Result = " ";
                      }
                    } else if (element.typeofevent == 15) {
                      if (element.enginerunstate == 0) {
                        eventType = "ENGINE OFF";
                      } else if (element.enginerunstate == 1) {
                        eventType = "ENGINE ON";
                      } else {
                        eventType = "NA";
                      }
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 66) {
                      eventType = "HARD_BLOW";
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 21) {
                      eventType = "CAR_BATTERY_TAMPERING";
                    } else if (element.typeofevent == 19) {
                      eventType = "VIOLATION_LOCKOUT";
                    } else if (element.typeofevent == 4) {
                      eventType = "BYPASS";
                    } else if (element.typeofevent == 18) {
                      eventType = "TEST_REFUSED_RR_TEST";
                    } else if (element.typeofevent == 28) {
                      eventType = "TAMPERED";
                    } else if (element.typeofevent == 22) {
                      eventType = "CALIBRATION DONE";
                    } else if (element.typeofevent == 29) {
                      eventType = "START_TEST_ATTEMPT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 27) {
                      eventType = "COOL_SAMPLE";
                    } else if (element.typeofevent == 25) {
                      eventType = "HOT_SAMPLE";
                    } else if (element.typeofevent == 8) {
                      eventType = "ONE_TIME_PASS_CODE";
                    } else if (element.typeofevent == 6) {
                      eventType = "SERVICE_PERIOD_SET";
                    } else if (element.typeofevent == 7) {
                      eventType = "VIOLATION_RESET";
                    } else if (element.typeofevent == 11) {
                      eventType = "LOG_READ";
                    } else if (element.typeofevent == 220) {
                      eventType = "LOCKOUT 0";
                    } else if (element.typeofevent == 221) {
                      eventType = "LOCKOUT 1";
                    } else if (element.typeofevent == 222) {
                      eventType = "LOCKOUT 2";
                    } else if (element.typeofevent == 223) {
                      eventType = "LOCKOUT 3";
                    } else if (element.typeofevent == 224) {
                      eventType = "LOCKOUT 4";
                    } else if (element.typeofevent == 225) {
                      eventType = "LOCKOUT 5";
                    } else if (element.typeofevent == 226) {
                      eventType = "LOCKOUT 6";
                    } else if (element.typeofevent == 227) {
                      eventType = "LOCKOUT 7";
                    } else if (element.typeofevent == 56) {
                      eventType = "TAKE_A_PICTURE";
                    } else if (element.typeofevent == 101) {
                      eventType = "PICTURE_CAPTURED";
                    } else if (element.typeofevent == 60) {
                      eventType = "BYPASS_START";
                    } else if (element.typeofevent == 61) {
                      eventType = "BYPASS_STOP";
                    } else if (element.typeofevent == 14) {
                      eventType = "SERVICE_ALERT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 62) {
                      eventType = "SAMPLER_DISCONNECTED";
                    } else if (element.typeofevent == 10) {
                      eventType = "CAR_BATTERY_OFF";
                    } else if (element.typeofevent == 20) {
                      eventType = "CAR_BATTERY_ON";
                    }
                    if (element.bacvalue != 255) {
                      Bacvalue = parseFloat(element.bacvalue) / 1000;
                    } else {
                      Bacvalue = "";
                    }
                    var obj = {
                      devid: element.devid,
                      controlId: arr[0].controlId,
                      // bacvalue: Bacvalue,
                      rtime: element.rtime,
                      typeofevent: eventType,
                      randominitial: arr[0].randominitial,
                      enginerunstate: arr[0].enginerunstate,
                      count: arr[0].count,
                    };
                    newArr.push(obj);
                  }
                }
                // }
              });

              commit("FETCH_DUPLOGS", newArr);
            } else {
              commit("FETCH_DUPLOGS", []);
            }
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchComponents({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/a200comps/getallcomponents")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_COMPONENTS", response.data);
          } else {
            commit("FETCH_COMPONENTS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchuniqueComponents({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/a200comps/getuniquecomponent?id=" + id)
        .then((response) => {
          /* eslint-disable no-console */
          commit("FETCH_UNIQUECOMPONENTS", response.data[0]);
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //users
    createUser({ dispatch, commit }, user) {
      Vue.axios
        .post("https://robopower.xyz/us/causers/adduser", user)
        .then((res) => {
          commit("CREATE_USER", res.data[0]);
          if (this.state.user.role == "Admin") {
            dispatch("fetchUsers");
          } else {
            dispatch("fetchdealerUsers", this.state.user.dealerId);
          }
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editUser({ dispatch }, user) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/causers/edituser/" + user.id,
          user.udata
        )
        .then((res) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchUsers");
          } else {
            dispatch("fetchdealerUsers", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteUser({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/causers/deleteuser/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchUsers");
          } else {
            dispatch("fetchdealerUsers", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchUsers({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/causers/getallusers")
        .then((response) => {
          var cd = JSON.parse(localStorage.getItem("user"));
          if (response.data != null) {
            commit("FETCH_USERS", response.data);
          } else {
            commit("FETCH_USERS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerUsers({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/causers/getdealerusers?dealerName=" + id)
        .then((response) => {
          var cd = JSON.parse(localStorage.getItem("user"));
          if (response.data != null) {
            commit("FETCH_USERS", response.data);
          } else {
            commit("FETCH_USERS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //vehicles
    fetchVehicles({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/caprohibs/getallvehicles")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_VEHICLES", response.data);
          } else {
            commit("FETCH_VEHICLES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerVehicles({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caprohibs/getdealervehicles?dealerName=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_VEHICLES", response.data);
          } else {
            commit("FETCH_VEHICLES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },

    fetchclientVehicles({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caprohibs/getclientvehicles?clientId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_VEHICLES", response.data);
          } else {
            commit("FETCH_VEHICLES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //DRIVERS
    fetchDrivers({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/caprohibs/getalldrivers")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_DRIVERS", response.data);
          } else {
            commit("FETCH_DRIVERS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerDrivers({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caprohibs/getdealerdrivers?dealerName=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_DRIVERS", response.data);
          } else {
            commit("FETCH_DRIVERS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //prohibitions
    createProhibition({ dispatch }, prohib) {
      Vue.axios
        .post("https://robopower.xyz/us/caprohibs/addprohibition", prohib)
        .then(() => {
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchProhibitions");
          } else {
            dispatch("fetchdealerProhibitions", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editProhibition({ dispatch }, prohib) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/caprohibs/editprohibition/" + prohib.id,
          prohib.vdata
        )
        .then((res) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchProhibitions");
          } else {
            dispatch("fetchdealerProhibitions", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteProhibition({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/caprohibs/deleteprohibition/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchProhibitions");
          } else {
            dispatch("fetchdealerProhibitions", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchProhibitions({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/caprohibs/getallprohibitions")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_PROHIBITIONS", response.data);
          } else {
            commit("FETCH_PROHIBITIONS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerProhibitions({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caprohibs/getdealerprohibitions?dealerName=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_PROHIBITIONS", response.data);
          } else {
            commit("FETCH_PROHIBITIONS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchuniqueProhibition({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caprohibs/getuniqueprohibition?regId=" + id
        )
        .then((response) => {
          commit("FETCH_UNIQUEPROHIBITION", response.data[0]);
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchclientProhibitions({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caprohibs/getclientprohibitions?clientId=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_CLIENTPROHIBITIONS", response.data);
          } else {
            commit("FETCH_CLIENTPROHIBITIONS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //calibrations
    createCalibration({ dispatch }, calib) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/cacalibration/addoreditcalibration",
          calib
        )
        .then(() => {
          dispatch("alert/success", "Success Changing Calibration Date").then(
            () => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            }
          );
          if (this.state.user.role == "Admin") {
            dispatch("fetchCalibrations");
          } else {
            dispatch("fetchdealerCalibrations", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchCalibrations({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/cacalibration/getallcalibs")
        .then((response) => {
          if (response.data != null) {
            var data = response.data;
            var rep = [];
            data.map((element) => {
              var nextdate = moment(
                element.calibrationDate,
                "MM/DD/YYYY HH:mm:ss"
              ).add(element.calibrationperiod, "days");
              var nxtDate = moment(nextdate).format("MM/DD/YYYY HH:mm:ss");
              element["nextcalibrationdate"] = nxtDate;
              rep.push(element);
            });
            commit("FETCH_CALIBRATIONS", rep);
          } else {
            commit("FETCH_CALIBRATIONS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerCalibrations({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cacalibration/getdealercalibs?dealerName=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            var data = response.data;
            var rep = [];
            data.map((element) => {
              var nextdate = moment(element.calibrationDate, "YYYY-MM-DD").add(
                element.calibrationperiod,
                "days"
              );
              var nxtDate = moment(nextdate).format("YYYY-MM-DD");
              element["nextcalibrationdate"] = nxtDate;
              rep.push(element);
            });
            commit("FETCH_CALIBRATIONS", rep);
          } else {
            commit("FETCH_CALIBRATIONS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchclientCalibrations({ dispatch, commit }, item) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cacalibration/getclientcalibs?clientId=" +
            item.client
        )
        .then((response) => {
          if (response.data != null) {
            var data = response.data;
            var rep = [];
            data.map((element) => {
              var nextdate = moment(element.calibrationDate, "YYYY-MM-DD").add(
                element.calibrationperiod,
                "days"
              );
              var nxtDate = moment(nextdate).format("YYYY-MM-DD");
              element["nextcalibrationdate"] = nxtDate;
              rep.push(element);
            });
            commit("FETCH_CALIBRATIONS", rep);
          } else {
            commit("FETCH_CALIBRATIONS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //invoices
    createInvoice({ dispatch }, invoice) {
      Vue.axios
        .post("https://robopower.xyz/us/cainvoices/addinvoice", invoice)
        .then(() => {
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchInvoices");
          } else {
            dispatch("fetchdealerInvoices", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editInvoice({ dispatch }, invoice) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/cainvoices/editinvoice/" + invoice.id,
          invoice.data
        )
        .then(() => {
          dispatch("alert/success", "Edit Invoice").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchInvoices");
          } else {
            dispatch("fetchdealerInvoices", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteInvoice({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/cainvoices/deleteinvoice/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchInvoices");
          } else {
            dispatch("fetchdealerInvoices", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteInvoice2({ dispatch }, invoice) {
      Vue.axios
        .get("https://robopower.xyz/us/cainvoices/deleteinvoice/" + invoice.invoiceId)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
            dispatch("fetchuniquerInvoice",invoice.regnum);
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchInvoices({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/cainvoices/getallinvoices")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_INVOICES", response.data);
          } else {
            commit("FETCH_INVOICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchclientInvoices({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cainvoices/cagetclientinvoices?clientId=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_INVOICES", response.data);
          } else {
            commit("FETCH_INVOICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchuniquerInvoice({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cainvoices/getuniquerinvoice?regnum=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_UNIQUE_INVOICE", response.data);
          } else {
            commit("FETCH_UNIQUE_INVOICE", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchuniquerInvoice2({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cainvoices/getuniquerinvoice?regnum=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_UNIQUE_INVOICE2", response.data);
          } else {
            commit("FETCH_UNIQUE_INVOICE2", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerInvoices({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cainvoices/getdealerinvoices?dealerName=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_INVOICES", response.data);
          } else {
            commit("FETCH_INVOICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchofficeInvoices({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cainvoices/getofficeinvoices?officeId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            var data = response.data;
            commit("FETCH_OFFICEINVOICES", response.data);
            var total = 0;
            var last30days = 0;
            if (data != null)
              data.map((element) => {
                var today = moment();
                let revenue = parseFloat(element.amountpaid);
                total += revenue;
                //last 30days revenue
                var nextdate = moment(element.date, "YYYY-MM-DD");
                var existdate = moment(nextdate).format("YYYY-MM-DD");
                var gap = today.diff(existdate, "days");
                if (gap <= 30) {
                  let lastrevenue = parseFloat(element.amountpaid);
                  last30days += lastrevenue;
                }
              });
            var sentdata = {
              totalrev: total,
              lastrev: last30days,
            };
            commit("FETCH_TOTALREVENUE", sentdata);
          } else {
            commit(
              "FETCH_TOTALREVENUE",
              (sentdata = {
                totalrev: 0,
                lastrev: 0,
              })
            );
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },

    fetchInvoiceservices({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caservices/getofficeservices?officeId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_INVOICESERVICES", response.data);
          } else {
            commit("FETCH_INVOICESERVICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //installations
    createInstallation({ dispatch }, install) {
      Vue.axios
        .post("https://robopower.xyz/us/cainstall/addinstallation", install)
        .then(() => {
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchInstallations");
          } else {
            dispatch("fetchdealerInstallations", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editInstallation({ dispatch }, install) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/cainstall/editinstaller/" + install.id,
          install.idata
        )
        .then((res) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchInstallations");
          } else {
            dispatch("fetchdealerInstallations", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteInstallation({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/cainstall/deleteinstaller/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchInstallations");
          } else {
            dispatch("fetchdealerInstallations", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchInstallations({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/cainstall/getallinstallers")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_INSTALLATIONS", response.data);
          } else {
            commit("FETCH_INSTALLATIONS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerInstallations({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cainstall/getdealerinstallers?dealerName=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_INSTALLATIONS", response.data);
          } else {
            commit("FETCH_INSTALLATIONS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdeviceOperations({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/app/v2data/getdeviceoperations?devid=" + id)
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_OPERATIONS", response.data);
          } else {
            commit("FETCH_OPERATIONS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //Firmware
    createFirmware({ dispatch }, firmware) {
      Vue.axios
        .post("https://robopower.xyz/v2/fw/oledfwUpload", firmware, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            if (firmware.file != undefined)
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
          }.bind(this),
        })
        .then(() => {
          dispatch("alert/success", "Set Sampler Firmware Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchFirmwares");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchFirmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v2/fw/oledgetallfirmwares")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_FIRMWARES", arr);
          } else {
            commit("FETCH_FIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchForm920({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/acceptance/getacceptances")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_920", arr);
          } else {
            commit("FETCH_920", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchForm922({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/acceptance/getremovals")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_922", arr);
          } else {
            commit("FETCH_922", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    uploadAcceptance({ dispatch }, acceptance) {
      Vue.axios
        .post("https://robopower.xyz/us/acceptance", acceptance, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // onUploadProgress: function(progressEvent) {
          //   if (acceptance.file != undefined)
          //     this.uploadPercentage = parseInt(
          //       Math.round((progressEvent.loaded / progressEvent.total) * 100)
          //     );
          // }.bind(this)
        })
        .then(() => {
          dispatch("alert/success", "Set Acceptance Success");
          // dispatch("fetchCbfirmwares");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    uploadRemovals({ dispatch }, acceptance) {
      Vue.axios
        .post("https://robopower.xyz/us/acceptance/addremoval", acceptance, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // onUploadProgress: function(progressEvent) {
          //   if (acceptance.file != undefined)
          //     this.uploadPercentage = parseInt(
          //       Math.round((progressEvent.loaded / progressEvent.total) * 100)
          //     );
          // }.bind(this)
        })
        .then(() => {
          dispatch("alert/success", "Set Removals Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          // dispatch("fetchCbfirmwares");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createCbfirmware({ dispatch }, firmware) {
      Vue.axios
        .post("https://robopower.xyz/v2/cbfw/cbfwUpload", firmware, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            if (firmware.file != undefined)
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
          }.bind(this),
        })
        .then(() => {
          dispatch("alert/success", "Set CB Firmware Success");
          dispatch("fetchCbfirmwares");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createCbv2firmware({ dispatch }, firmware) {
      Vue.axios
        .post("https://robopower.xyz/v2/cbfwv2/cbfwUpload", firmware, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            if (firmware.file != undefined)
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
          }.bind(this),
        })
        .then(() => {
          dispatch("alert/success", "Set CB Firmware v2 Success");
          dispatch("fetchCbv2firmwares");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createCazigfirmware({ dispatch }, firmware) {
      Vue.axios
        .post("https://robopower.xyz/v2/cazigfw/cazigfwUpload", firmware, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            if (firmware.file != undefined)
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
          }.bind(this),
        })
        .then(() => {
          dispatch("alert/success", "Set Cazig Firmware Success");
          dispatch("fetchCazigfirmwares");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createTftfirmware({ dispatch }, firmware) {
      Vue.axios
        .post("https://robopower.xyz/v2/fw/tftfwUpload", firmware, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            if (firmware.file != undefined)
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
          }.bind(this),
        })
        .then(() => {
          dispatch("alert/success", "Set TFT Firmware Success");
          dispatch("fetchTftfirmwares");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createPressfirmware({ dispatch }, firmware) {
      Vue.axios
        .post("https://robopower.xyz/v2/fw/oled_mpxv_pressfwUpload", firmware, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            if (firmware.file != undefined)
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
          }.bind(this),
        })
        .then(() => {
          dispatch("alert/success", "Set TFT Firmware Success");
          dispatch("fetchPressfirmwares");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createGuifirmware({ dispatch }, firmware) {
      dispatch("alert/loading", true);
      Vue.axios
        .post("https://robopower.xyz/v2/guifw/fwUpload", firmware, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            if (firmware.file != undefined)
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
          }.bind(this),
        })
        .then((res) => {
          dispatch("alert/clear");
          dispatch("alert/loading", false);
          dispatch("alert/success", "Set GUI Success");
          dispatch("fetchGuifirmwares");
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchCbv2firmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v2/cbfwv2/cbgetallfirmwares")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_CBV2FIRMWARES", arr);
          } else {
            commit("FETCH_CBV2FIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchCbfirmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v2/cbfw/cbgetallfirmwares")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_CBFIRMWARES", arr);
          } else {
            commit("FETCH_CBFIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchCatftfirmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v2/catftfw/catftgetallfirmwares")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_CATFTFIRMWARES", arr);
          } else {
            commit("FETCH_CATFTFIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchS3firmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v2/s3fw/getallfirmwaress3")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_S3FIRMWARES", arr);
          } else {
            commit("FETCH_S3FIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchcbindiafirmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v1/cbota/cbgetallfirmwares")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_CBINDIAFIRMWARES", arr);
          } else {
            commit("FETCH_CBINDIAFIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchsampindiafirmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v1/sampota/getallfirmwares")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_SAMPINDIAFIRMWARES", arr);
          } else {
            commit("FETCH_SAMPINDIAFIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchneowayn58firmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v3/neowayfw/getallfirmwaresneo")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_NEOWAYFIRMWARES", arr);
          } else {
            commit("FETCH_NEOWAYFIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchCazigfirmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v2/cazigfw/caziggetallfirmwares")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_CAZIGFIRMWARES", arr);
          } else {
            commit("FETCH_CAZIGFIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchTftfirmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v2/fw/tftgetallfirmwares")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_TFTFIRMWARES", arr);
          } else {
            commit("FETCH_TFTFIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchPressfirmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v2/fw/oled_mpxv_pressgetallfirmwares")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_PRESSFIRMWARES", arr);
          } else {
            commit("FETCH_PRESSFIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchGuifirmwares({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/v2/guifw/getallfirmwares")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = arr.sort(
              (a, b) =>
                moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
            );
            arr = arr.reverse();
            commit("FETCH_GUIFIRMWARES", arr);
          } else {
            commit("FETCH_GUIFIRMWARES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //LogsfetchremovalLogs
    fetchremovalLogs({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/getalllogs?deviceId=" + id)
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            var arr2 = arr.sort(
              (a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
            );
            var logg = {
              total: 0,
              pass: 0,
              fail: 0,
              enginerunstate: 0,
              refused: 0,
              IncorrectBreathSamples: 0,
              Bypass: 0,
              othertampering: 0,
              permanantlockout: 0,
              PowerDisconnections: 0,
              HandsetDisconnections: 0,
              HardwareErrors: 0,
            };
            if (arr2 != null) {
              var repdata = arr2.reverse();
              logg.total = repdata.length;
              repdata.map((element) => {
                if (element.typeofevent == 254) {
                  if (element.passfailstatus == 1) {
                    logg.pass += 1;
                  } else if (element.passfailstatus == 0) {
                    logg.fail += 1;
                  }
                } else if (element.typeofevent == 15) {
                  if (element.enginerunstate == 1) {
                    logg.enginerunstate += 1;
                  }
                } else if (element.typeofevent == 65) {
                  logg.IncorrectBreathSamples += 1;
                } else if (element.typeofevent == 21) {
                  logg.PowerDisconnections += 1;
                } else if (element.typeofevent == 4) {
                  logg.Bypass += 1;
                } else if (element.typeofevent == 18) {
                  logg.refused += 1;
                } else if (
                  element.typeofevent == 27 ||
                  element.typeofevent == 25
                ) {
                  logg.othertampering = +1;
                } else if (element.typeofevent == 220) {
                  logg.permanantlockout += 1;
                } else if (element.typeofevent == 62) {
                  logg.HandsetDisconnections += 1;
                }
              });
              commit("FETCH_REMLOGS", logg);
            } else {
              commit("FETCH_REMLOGS", {});
            }
          } else {
            commit("FETCH_LOGS", []);
            dispatch("alert/error", "No Logs found");
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //services
    fetchLogssensorfail({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + id.cbid)
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = response.data.filter((o) => o.typeofevent !== "0");
            var arr2 = arr.sort(
              (a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
            );
            var newArr = [];
            if (arr2 != null) {
              var repdata = arr2.reverse();
              repdata.map((element) => {
                if (element.typeofevent != "101") {
                  if (element.typeofevent != "29") {
                    if (element.typeofevent != "56") {
                      var eventType, Result, Bacvalue;
                      if (element.typeofevent == 254) {
                        //initialtest
                        if (
                          element.randominitial == 2 ||
                          element.randominitial == 0
                        ) {
                          eventType = "INITIAL TEST";
                        } else if (
                          element.randominitial == 3 ||
                          element.randominitial == 1
                        ) {
                          eventType = "RANDOM TEST";
                        } else {
                          eventType = "NA";
                        }
                        if (element.passfailstatus == 1) {
                          Result = "PASS";
                        } else if (element.passfailstatus == 0) {
                          Result = "FAIL";
                        } else {
                          Result = " ";
                        }
                      } else if (element.typeofevent == 15) {
                        if (element.enginerunstate == 0) {
                          eventType = "ENGINE OFF";
                        } else if (element.enginerunstate == 1) {
                          eventType = "ENGINE ON";
                        } else {
                          eventType = "NA";
                        }
                      } else if (element.typeofevent == 65) {
                        eventType = "INSUFFICIENTVOLUME";
                      } else if (element.typeofevent == 66) {
                        eventType = "HARD_BLOW";
                      } else if (element.typeofevent == 65) {
                        eventType = "INSUFFICIENTVOLUME";
                      } else if (element.typeofevent == 21) {
                        eventType = "CAR_BATTERY_TAMPERING";
                      } else if (element.typeofevent == 19) {
                        eventType = "VIOLATION_LOCKOUT";
                      } else if (element.typeofevent == 4) {
                        eventType = "BYPASS";
                      } else if (element.typeofevent == 18) {
                        eventType = "TEST_REFUSED_RR_TEST";
                      } else if (element.typeofevent == 28) {
                        eventType = "TAMPERED";
                      } else if (element.typeofevent == 22) {
                        eventType = "CALIBRATION DONE";
                      } else if (element.typeofevent == 29) {
                        eventType = "START_TEST_ATTEMPT";
                      } else if (element.typeofevent == 34) {
                        eventType = "ENGINE_NOT_STARTED";
                      } else if (element.typeofevent == 27) {
                        eventType = "COOL_SAMPLE";
                      } else if (element.typeofevent == 25) {
                        eventType = "HOT_SAMPLE";
                      } else if (element.typeofevent == 8) {
                        eventType = "ONE_TIME_PASS_CODE";
                      } else if (element.typeofevent == 6) {
                        eventType = "SERVICE_PERIOD_SET";
                      } else if (element.typeofevent == 7) {
                        eventType = "VIOLATION_RESET";
                      } else if (element.typeofevent == 11) {
                        eventType = "LOG_READ";
                      } else if (element.typeofevent == 220) {
                        eventType = "LOCKOUT 0";
                      } else if (element.typeofevent == 221) {
                        eventType = "LOCKOUT 1";
                      } else if (element.typeofevent == 222) {
                        eventType = "LOCKOUT 2";
                      } else if (element.typeofevent == 223) {
                        eventType = "LOCKOUT 3";
                      } else if (element.typeofevent == 224) {
                        eventType = "LOCKOUT 4";
                      } else if (element.typeofevent == 225) {
                        eventType = "LOCKOUT 5";
                      } else if (element.typeofevent == 226) {
                        eventType = "LOCKOUT 6";
                      } else if (element.typeofevent == 227) {
                        eventType = "LOCKOUT 7";
                      } else if (element.typeofevent == 56) {
                        eventType = "TAKE_A_PICTURE";
                      } else if (element.typeofevent == 101) {
                        eventType = "PICTURE_CAPTURED";
                      } else if (element.typeofevent == 60) {
                        eventType = "BYPASS_START";
                      } else if (element.typeofevent == 61) {
                        eventType = "BYPASS_STOP";
                      } else if (element.typeofevent == 14) {
                        eventType = "SERVICE_ALERT";
                      } else if (element.typeofevent == 34) {
                        eventType = "ENGINE_NOT_STARTED";
                      } else if (element.typeofevent == 62) {
                        eventType = "SAMPLER_DISCONNECTED";
                      } else if (element.typeofevent == 10) {
                        eventType = "CAR_BATTERY_OFF";
                      } else if (element.typeofevent == 20) {
                        eventType = "CAR_BATTERY_ON";
                      } else if (element.typeofevent == 217) {
                        eventType = "MEMSET";
                      } else if (element.typeofevent == 219) {
                        eventType = "TIME_SET";
                      } else if (element.typeofevent == 218) {
                        eventType = "DEVICE RESTART";
                      } else if (element.typeofevent == 151) {
                        eventType = "ALCOHOL_SENSOR_FAIL";
                      } else if (element.typeofevent == 152) {
                        eventType = "BREATH_PRESSURE_SEN_FAIL";
                      } else if (element.typeofevent == 153) {
                        eventType = "BREATH_TEMP_SEN_FAIL";
                      } else if (element.typeofevent == 154) {
                        eventType = "BMP180_ALTITUDE_FAIL";
                      } else if (element.typeofevent == 155) {
                        eventType = "PROXIMITY_SENSOR_FAIL";
                      } else if (element.typeofevent == 156) {
                        eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                      } else if (element.typeofevent == 157) {
                        eventType = "RTC_FAIL";
                      }
                      if (element.bacvalue != 255) {
                        Bacvalue = parseFloat(element.bacvalue) / 1000;
                      } else {
                        Bacvalue = "";
                      }
                      var obj = {
                        id: element.id,
                        bacvalue: Bacvalue,
                        rtime: element.rtime,
                        devid: element.devid,
                        controlId: element.controlId,
                        passfailstatus: Result,
                        typeofevent: eventType,
                      };

                      newArr.push(obj);
                    }
                  }
                }
              });

              if (newArr != []) {
                commit("FETCH_LOGSSENSOR", newArr);
              } else {
                console.log("loading");
              }
            } else {
              commit("FETCH_LOGSSENSOR", []);
            }
          } else {
            Vue.axios
              .get(
                "https://robopower.xyz/v2/eeprom/getalllogs?deviceId=" +
                  id.devId
              )
              .then((response) => {
                if (response.data != null) {
                  var arr = response.data;
                  arr = response.data.filter((o) => o.typeofevent !== "0");
                  var arr2 = arr.sort(
                    (a, b) =>
                      moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
                  );
                  var newArr = [];
                  if (arr2 != null) {
                    var repdata = arr2.reverse();
                    repdata.map((element) => {
                      if (element.typeofevent != "101") {
                        if (element.typeofevent != "29") {
                          if (element.typeofevent != "56") {
                            var eventType, Result, Bacvalue;
                            if (element.typeofevent == 254) {
                              //initialtest
                              if (
                                element.randominitial == 2 ||
                                element.randominitial == 0
                              ) {
                                eventType = "INITIAL TEST";
                              } else if (
                                element.randominitial == 3 ||
                                element.randominitial == 1
                              ) {
                                eventType = "RANDOM TEST";
                              } else {
                                eventType = "NA";
                              }
                              if (element.passfailstatus == 1) {
                                Result = "PASS";
                              } else if (element.passfailstatus == 0) {
                                Result = "FAIL";
                              } else {
                                Result = " ";
                              }
                            } else if (element.typeofevent == 15) {
                              if (element.enginerunstate == 0) {
                                eventType = "ENGINE OFF";
                              } else if (element.enginerunstate == 1) {
                                eventType = "ENGINE ON";
                              } else {
                                eventType = "NA";
                              }
                            } else if (element.typeofevent == 65) {
                              eventType = "INSUFFICIENTVOLUME";
                            } else if (element.typeofevent == 66) {
                              eventType = "HARD_BLOW";
                            } else if (element.typeofevent == 65) {
                              eventType = "INSUFFICIENTVOLUME";
                            } else if (element.typeofevent == 21) {
                              eventType = "CAR_BATTERY_TAMPERING";
                            } else if (element.typeofevent == 19) {
                              eventType = "VIOLATION_LOCKOUT";
                            } else if (element.typeofevent == 4) {
                              eventType = "BYPASS";
                            } else if (element.typeofevent == 18) {
                              eventType = "TEST_REFUSED_RR_TEST";
                            } else if (element.typeofevent == 28) {
                              eventType = "TAMPERED";
                            } else if (element.typeofevent == 22) {
                              eventType = "CALIBRATION DONE";
                            } else if (element.typeofevent == 29) {
                              eventType = "START_TEST_ATTEMPT";
                            } else if (element.typeofevent == 34) {
                              eventType = "ENGINE_NOT_STARTED";
                            } else if (element.typeofevent == 27) {
                              eventType = "COOL_SAMPLE";
                            } else if (element.typeofevent == 25) {
                              eventType = "HOT_SAMPLE";
                            } else if (element.typeofevent == 8) {
                              eventType = "ONE_TIME_PASS_CODE";
                            } else if (element.typeofevent == 6) {
                              eventType = "SERVICE_PERIOD_SET";
                            } else if (element.typeofevent == 7) {
                              eventType = "VIOLATION_RESET";
                            } else if (element.typeofevent == 11) {
                              eventType = "LOG_READ";
                            } else if (element.typeofevent == 220) {
                              eventType = "LOCKOUT 0";
                            } else if (element.typeofevent == 221) {
                              eventType = "LOCKOUT 1";
                            } else if (element.typeofevent == 222) {
                              eventType = "LOCKOUT 2";
                            } else if (element.typeofevent == 223) {
                              eventType = "LOCKOUT 3";
                            } else if (element.typeofevent == 224) {
                              eventType = "LOCKOUT 4";
                            } else if (element.typeofevent == 225) {
                              eventType = "LOCKOUT 5";
                            } else if (element.typeofevent == 226) {
                              eventType = "LOCKOUT 6";
                            } else if (element.typeofevent == 227) {
                              eventType = "LOCKOUT 7";
                            } else if (element.typeofevent == 56) {
                              eventType = "TAKE_A_PICTURE";
                            } else if (element.typeofevent == 101) {
                              eventType = "PICTURE_CAPTURED";
                            } else if (element.typeofevent == 60) {
                              eventType = "BYPASS_START";
                            } else if (element.typeofevent == 61) {
                              eventType = "BYPASS_STOP";
                            } else if (element.typeofevent == 14) {
                              eventType = "SERVICE_ALERT";
                            } else if (element.typeofevent == 34) {
                              eventType = "ENGINE_NOT_STARTED";
                            } else if (element.typeofevent == 62) {
                              eventType = "SAMPLER_DISCONNECTED";
                            } else if (element.typeofevent == 10) {
                              eventType = "CAR_BATTERY_OFF";
                            } else if (element.typeofevent == 20) {
                              eventType = "CAR_BATTERY_ON";
                            } else if (element.typeofevent == 217) {
                              eventType = "MEMSET";
                            } else if (element.typeofevent == 219) {
                              eventType = "TIME_SET";
                            } else if (element.typeofevent == 218) {
                              eventType = "DEVICE RESTART";
                            } else if (element.typeofevent == 151) {
                              eventType = "ALCOHOL_SENSOR_FAIL";
                            } else if (element.typeofevent == 152) {
                              eventType = "BREATH_PRESSURE_SEN_FAIL";
                            } else if (element.typeofevent == 153) {
                              eventType = "BREATH_TEMP_SEN_FAIL";
                            } else if (element.typeofevent == 154) {
                              eventType = "BMP180_ALTITUDE_FAIL";
                            } else if (element.typeofevent == 155) {
                              eventType = "PROXIMITY_SENSOR_FAIL";
                            } else if (element.typeofevent == 156) {
                              eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                            } else if (element.typeofevent == 157) {
                              eventType = "RTC_FAIL";
                            }
                            if (element.bacvalue != 255) {
                              Bacvalue = parseFloat(element.bacvalue) / 1000;
                            } else {
                              Bacvalue = "";
                            }
                            var obj = {
                              id: element.id,
                              bacvalue: Bacvalue,
                              rtime: element.rtime,
                              devid: element.devid,
                              controlId: element.controlId,
                              passfailstatus: Result,
                              typeofevent: eventType,
                            };

                            newArr.push(obj);
                          }
                        }
                      }
                    });

                    if (newArr != []) {
                      commit("FETCH_LOGSSENSOR", newArr);
                    } else {
                      console.log("loading");
                    }
                  } else {
                    commit("FETCH_LOGSSENSOR", []);
                  }
                } else {
                  commit("FETCH_LOGSSENSOR", []);
                  dispatch("alert/error", "No Logs found").then(() => {
                    setTimeout(() => {
                      dispatch("alert/clear");
                    }, 2000);
                  });
                }
              })
              .catch((err) => {
                dispatch("alert/error", err).then(() => {
                  setTimeout(() => {
                    dispatch("alert/clear");
                  }, 2000);
                });
              });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    controlidfetchsplitLogs({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + id.item.cbid)
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            if (id.role == "superadmin") {
              arr = response.data.filter((o) => o.typeofevent !== "0");
            } else {
              arr = response.data.filter(
                (o) =>
                  o.typeofevent !== "0" &&
                  o.typeofevent !== "151" &&
                  o.typeofevent !== "152" &&
                  o.typeofevent !== "153" &&
                  o.typeofevent !== "154" &&
                  o.typeofevent !== "155" &&
                  o.typeofevent !== "156" &&
                  o.typeofevent !== "157" &&
                  o.typeofevent !== "218" &&
                  o.typeofevent !== "219"
              );
            }
            var arr2 =
              id.sorted == true
                ? arr.sort((a, b) => a.id - b.id)
                : arr.sort(
                    (a, b) =>
                      moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
                  );
            var newArr = [];
            if (arr2 != null) {
              var repdata = arr2.reverse();
              repdata.map((element) => {
                // if(element.typeofevent !="101"){
                if (element.typeofevent != "29") {
                  if (element.typeofevent != "56") {
                    var eventType, Result, Bacvalue;
                    if (element.typeofevent == 254) {
                      //initialtest
                      if (
                        element.randominitial == 2 ||
                        element.randominitial == 0
                      ) {
                        eventType = "INITIAL TEST";
                      } else if (
                        element.randominitial == 3 ||
                        element.randominitial == 1
                      ) {
                        eventType = "RANDOM TEST";
                      } else {
                        eventType = "NA";
                      }
                      if (element.passfailstatus == 1) {
                        Result = "PASS";
                      } else if (element.passfailstatus == 0) {
                        Result = "FAIL";
                      } else {
                        Result = " ";
                      }
                    } else if (element.typeofevent == 15) {
                      if (element.enginerunstate == 0) {
                        eventType = "ENGINE OFF";
                      } else if (element.enginerunstate == 1) {
                        eventType = "ENGINE ON";
                      } else {
                        eventType = "NA";
                      }
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 66) {
                      eventType = "HARD_BLOW";
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 21) {
                      eventType = "CAR_BATTERY_TAMPERING";
                    } else if (element.typeofevent == 19) {
                      eventType = "VIOLATION_LOCKOUT";
                    } else if (element.typeofevent == 4) {
                      eventType = "BYPASS";
                    } else if (element.typeofevent == 18) {
                      eventType = "TEST_REFUSED_RR_TEST";
                    } else if (element.typeofevent == 28) {
                      eventType = "TAMPERED";
                    } else if (element.typeofevent == 22) {
                      eventType = "CALIBRATION DONE";
                    } else if (element.typeofevent == 29) {
                      eventType = "START_TEST_ATTEMPT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 27) {
                      eventType = "COOL_SAMPLE";
                    } else if (element.typeofevent == 25) {
                      eventType = "HOT_SAMPLE";
                    } else if (element.typeofevent == 8) {
                      eventType = "ONE_TIME_PASS_CODE";
                    } else if (element.typeofevent == 6) {
                      eventType = "SERVICE_PERIOD_SET";
                    } else if (element.typeofevent == 7) {
                      eventType = "VIOLATION_RESET";
                    } else if (element.typeofevent == 11) {
                      eventType = "LOG_READ";
                    } else if (element.typeofevent == 220) {
                      eventType = "LOCKOUT 0";
                    } else if (element.typeofevent == 221) {
                      eventType = "LOCKOUT 1";
                    } else if (element.typeofevent == 222) {
                      eventType = "LOCKOUT 2";
                    } else if (element.typeofevent == 223) {
                      eventType = "LOCKOUT 3";
                    } else if (element.typeofevent == 224) {
                      eventType = "LOCKOUT 4";
                    } else if (element.typeofevent == 225) {
                      eventType = "LOCKOUT 5";
                    } else if (element.typeofevent == 226) {
                      eventType = "LOCKOUT 6";
                    } else if (element.typeofevent == 227) {
                      eventType = "LOCKOUT 7";
                    } else if (element.typeofevent == 56) {
                      eventType = "TAKE_A_PICTURE";
                    } else if (element.typeofevent == 101) {
                      eventType = "PICTURE_CAPTURED";
                    } else if (element.typeofevent == 60) {
                      eventType = "BYPASS_START";
                    } else if (element.typeofevent == 61) {
                      eventType = "BYPASS_STOP";
                    } else if (element.typeofevent == 14) {
                      eventType = "SERVICE_ALERT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 62) {
                      eventType = "SAMPLER_DISCONNECTED";
                    } else if (element.typeofevent == 10) {
                      eventType = "CAR_BATTERY_OFF";
                    } else if (element.typeofevent == 20) {
                      eventType = "CAR_BATTERY_ON";
                    } else if (element.typeofevent == 217) {
                      eventType = "MEMSET";
                    } else if (element.typeofevent == 219) {
                      eventType = "TIME_SET";
                    } else if (element.typeofevent == 218) {
                      eventType = "DEVICE RESTART";
                    } else if (element.typeofevent == 151) {
                      eventType = "ALCOHOL_SENSOR_FAIL";
                    } else if (element.typeofevent == 152) {
                      eventType = "BREATH_PRESSURE_SEN_FAIL";
                    } else if (element.typeofevent == 153) {
                      eventType = "BREATH_TEMP_SEN_FAIL";
                    } else if (element.typeofevent == 154) {
                      eventType = "BMP180_ALTITUDE_FAIL";
                    } else if (element.typeofevent == 155) {
                      eventType = "PROXIMITY_SENSOR_FAIL";
                    } else if (element.typeofevent == 156) {
                      eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                    } else if (element.typeofevent == 157) {
                      eventType = "RTC_FAIL";
                    }
                    if (element.bacvalue != 255) {
                      Bacvalue = parseFloat(element.bacvalue) / 1000;
                    } else {
                      Bacvalue = "";
                    }
                    var obj = {
                      id: element.id,
                      bacvalue: Bacvalue,
                      rtime: element.rtime,
                      devid: element.devid,
                      controlId: element.controlId,
                      passfailstatus: Result,
                      typeofevent: eventType,
                      vbat:
                        element.vbat == "NA" ? " " : element.vbat.split(","),
                    };
                    const exists = newArr.some(item =>
                      item.controlId === obj.controlId &&
                      item.rtime === obj.rtime &&
                      item.typeofevent === obj.typeofevent
                    );
                    if(!exists){
                    newArr.push(obj);
                    }
                  }
                }
                // }
              });

              if (newArr != []) {
                commit("CBIDFETCHSPLIT_LOGS", newArr);
              } else {
                console.log("loading");
              }
            } else {
              commit("CBIDFETCHSPLIT_LOGS", []);
            }
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    controlidfetchLogs({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + id.item.cbid)
        .then((response) => {
          if (response.data != null) {
            var newProm = new Promise((resolve,reject)=>{
              if(id.item.sno){
                var devicetimezone
                Vue.axios
            .get("https://robopower.xyz/us/cadevices/getuniquedevice?sno="+ id.item.sno)
            .then((resp) => {
              if(resp.data){
                // console.log(resp.data)
                if(resp.data[0].timezone == "GMT"){
                  if(resp.data[0].tagstate == "INDIA"){
                    devicetimezone = "Asia/Kolkata"
                  } else if(resp.data[0].tagstate == "CA"){
                    devicetimezone = "America/Los_Angeles"
                  } else if(resp.data[0].tagstate == "TX"){
                    devicetimezone = "America/Chicago"
                  } else if(resp.data[0].tagstate == "TX"){
                    devicetimezone = "America/Chicago"
                  } else if(resp.data[0].tagstate == "WI"){
                    devicetimezone = "America/Chicago"
                  } else if(resp.data[0].tagstate == "AL"){
                    devicetimezone = "America/Chicago"
                  } else{
                    devicetimezone = "GMT"
                  }
                  var obj = {
                    "tz":devicetimezone,
                    "ischange":1
                  }
                  resolve(obj)
                  // })
                } else if(resp.data[0].timezone == ""){
                  var obj1 = {
                    "tz":"America/Los_Angeles",
                    "ischange":0
                  }
                  resolve(obj1)
                } else{
                  var obj2 = {
                    "tz":resp.data[0].timezone,
                    "ischange":0
                  }
                  resolve(obj2)
                }
              } else{
                reject("Device Not Added in the List")
              }
    
            })
              }
            })
            newProm.then((result)=>{
              // console.log(result)
              var arr = response.data;
            if (id.role == "superadmin") {
              arr = response.data.filter((o) => o.typeofevent !== "0");
            } else {
              arr = response.data.filter(
                (o) =>
                  o.typeofevent !== "0" &&
                  o.typeofevent !== "151" &&
                  o.typeofevent !== "152" &&
                  o.typeofevent !== "153" &&
                  o.typeofevent !== "154" &&
                  o.typeofevent !== "155" &&
                  o.typeofevent !== "156" &&
                  o.typeofevent !== "157" &&
                  o.typeofevent !== "218" &&
                  o.typeofevent !== "219"
              );
            }
            var arr2 =
              id.sorted == true
                ? arr.sort((a, b) => a.id - b.id)
                : arr.sort(
                    (a, b) =>
                      moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
                  );
            var newArr = [];
            if (arr2 != null) {
              var repdata = arr2.reverse();
              repdata.map((element) => {
                // if(element.typeofevent !="101"){
                if (element.typeofevent != "29") {
                  if (element.typeofevent != "56") {
                    var eventType, Result, Bacvalue;
                    if (element.typeofevent == 254) {
                      //initialtest
                      if (
                        element.randominitial == 2 ||
                        element.randominitial == 0
                      ) {
                        eventType = "INITIAL TEST";
                      } else if (
                        element.randominitial == 3 ||
                        element.randominitial == 1
                      ) {
                        eventType = "RANDOM TEST";
                      } else {
                        eventType = "NA";
                      }
                      if (element.passfailstatus == 1) {
                        Result = "PASS";
                      } else if (element.passfailstatus == 0) {
                        Result = "FAIL";
                      } else {
                        Result = " ";
                      }
                    } else if (element.typeofevent == 15) {
                      if (element.enginerunstate == 0) {
                        eventType = "ENGINE OFF";
                      } else if (element.enginerunstate == 1) {
                        eventType = "ENGINE ON";
                      } else {
                        eventType = "NA";
                      }
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 66) {
                      eventType = "HARD_BLOW";
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 21) {
                      eventType = "CAR_BATTERY_TAMPERING";
                    } else if (element.typeofevent == 19) {
                      eventType = "VIOLATION_LOCKOUT";
                    } else if (element.typeofevent == 4) {
                      eventType = "BYPASS";
                    } else if (element.typeofevent == 18) {
                      eventType = "TEST_REFUSED_RR_TEST";
                    } else if (element.typeofevent == 28) {
                      eventType = "TAMPERED";
                    } else if (element.typeofevent == 22) {
                      eventType = "CALIBRATION DONE";
                    } else if (element.typeofevent == 29) {
                      eventType = "START_TEST_ATTEMPT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 27) {
                      eventType = "COOL_SAMPLE";
                    } else if (element.typeofevent == 25) {
                      eventType = "HOT_SAMPLE";
                    } else if (element.typeofevent == 8) {
                      eventType = "ONE_TIME_PASS_CODE";
                    } else if (element.typeofevent == 6) {
                      eventType = "SERVICE_PERIOD_SET";
                    } else if (element.typeofevent == 7) {
                      eventType = "VIOLATION_RESET";
                    } else if (element.typeofevent == 11) {
                      eventType = "LOG_READ";
                    } else if (element.typeofevent == 220) {
                      eventType = "LOCKOUT 0";
                    } else if (element.typeofevent == 221) {
                      eventType = "LOCKOUT 1";
                    } else if (element.typeofevent == 222) {
                      eventType = "LOCKOUT 2";
                    } else if (element.typeofevent == 223) {
                      eventType = "LOCKOUT 3";
                    } else if (element.typeofevent == 224) {
                      eventType = "LOCKOUT 4";
                    } else if (element.typeofevent == 225) {
                      eventType = "LOCKOUT 5";
                    } else if (element.typeofevent == 226) {
                      eventType = "LOCKOUT 6";
                    } else if (element.typeofevent == 227) {
                      eventType = "LOCKOUT 7";
                    } else if (element.typeofevent == 56) {
                      eventType = "TAKE_A_PICTURE";
                    } else if (element.typeofevent == 101) {
                      eventType = "PICTURE_CAPTURED";
                    } else if (element.typeofevent == 60) {
                      eventType = "BYPASS_START";
                    } else if (element.typeofevent == 61) {
                      eventType = "BYPASS_STOP";
                    } else if (element.typeofevent == 14) {
                      eventType = "SERVICE_ALERT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 62) {
                      eventType = "SAMPLER_DISCONNECTED";
                    } else if (element.typeofevent == 10) {
                      eventType = "CAR_BATTERY_OFF";
                    } else if (element.typeofevent == 20) {
                      eventType = "CAR_BATTERY_ON";
                    } else if (element.typeofevent == 217) {
                      eventType = "MEMSET";
                    } else if (element.typeofevent == 219) {
                      eventType = "TIME_SET";
                    } else if (element.typeofevent == 218) {
                      eventType = "DEVICE RESTART";
                    } else if (element.typeofevent == 151) {
                      eventType = "ALCOHOL_SENSOR_FAIL";
                    } else if (element.typeofevent == 152) {
                      eventType = "BREATH_PRESSURE_SEN_FAIL";
                    } else if (element.typeofevent == 153) {
                      eventType = "BREATH_TEMP_SEN_FAIL";
                    } else if (element.typeofevent == 154) {
                      eventType = "BMP180_ALTITUDE_FAIL";
                    } else if (element.typeofevent == 155) {
                      eventType = "PROXIMITY_SENSOR_FAIL";
                    } else if (element.typeofevent == 156) {
                      eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                    } else if (element.typeofevent == 157) {
                      eventType = "RTC_FAIL";
                    }
                    if (element.bacvalue != 255) {
                      Bacvalue = parseFloat(element.bacvalue) / 1000;
                    } else {
                      Bacvalue = "";
                    }
                    var convertedDateTime 
                    // console.log("bef"+result.ischange)
                    if(element.controlId == "ABC_c4_dd_57_8c_a4_9c"){  //controlid 48 and sampler 165 was connected so getting 1970 always
                      var distTime = moment(element.created_at).format('MM/DD/YYYY HH:mm:ss');
                      convertedDateTime = moment.utc(distTime, 'MM/DD/YYYY HH:mm:ss').tz("America/Los_Angeles").format('MM/DD/YYYY HH:mm:ss');
                    } else{
                    if(result.ischange == 1){
                    convertedDateTime = moment.utc(element.rtime, 'MM/DD/YYYY HH:mm:ss').tz(result.tz).format('MM/DD/YYYY HH:mm:ss'); //For GMT
                    } else{
                      convertedDateTime = element.rtime; // For non-GMT
                    }
                  }
                    // console.log("orig"+element.rtime);
                    // console.log("conv"+convertedDateTime)
                    var obj = {
                      id: element.id,
                      bacvalue: Bacvalue,
                      rtime:convertedDateTime,
                      devid: element.devid,
                      controlId: element.controlId,
                      passfailstatus: Result,
                      typeofevent: eventType,
                      vbat:
                        element.vbat == "NA" ? " " : element.vbat.split(","),
                        imgtime:element.rtime
                    };
                    const exists = newArr.some(item =>
                      item.controlId === obj.controlId &&
                      item.rtime === obj.rtime &&
                      item.typeofevent === obj.typeofevent
                    );
                    if(!exists){
                    newArr.push(obj);
                    }
                  }
                }
                // }
              });

              if (newArr != []) {
                if(id.item.sno =="612CA00165"){
                  var newArry = newArr.sort(
                    (a, b) =>
                      moment(b.rtime).valueOf() - moment(a.rtime).valueOf()
                  );
                  commit("CBIDFETCH_LOGS", newArry);
                } else{
                commit("CBIDFETCH_LOGS", newArr);
                }
              } else {
                console.log("loading");
              }
            } else {
              commit("CBIDFETCH_LOGS", []);
            }

            })
          
            
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    samplefetchLogs({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/v2/eeprom/getalllogs?deviceId=" + id.item.devId
        )
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            if (id.role == "superadmin") {
              arr = response.data.filter((o) => o.typeofevent !== "0");
            } else {
              arr = response.data.filter(
                (o) =>
                  o.typeofevent !== "0" &&
                  o.typeofevent !== "151" &&
                  o.typeofevent !== "152" &&
                  o.typeofevent !== "153" &&
                  o.typeofevent !== "154" &&
                  o.typeofevent !== "155" &&
                  o.typeofevent !== "156" &&
                  o.typeofevent !== "157" &&
                  o.typeofevent !== "218" &&
                  o.typeofevent !== "219"
              );
            }
            var arr2 =
              id.sorted == true
                ? arr.sort((a, b) => a.id - b.id)
                : arr.sort(
                    (a, b) =>
                      moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
                  );
            var newArr = [];
            if (arr2 != null) {
              var repdata = arr2.reverse();
              repdata.map((element) => {
                if (element.typeofevent != "101") {
                  if (element.typeofevent != "29") {
                    if (element.typeofevent != "56") {
                      var eventType, Result, Bacvalue;
                      if (element.typeofevent == 254) {
                        //initialtest
                        if (
                          element.randominitial == 2 ||
                          element.randominitial == 0
                        ) {
                          eventType = "INITIAL TEST";
                        } else if (
                          element.randominitial == 3 ||
                          element.randominitial == 1
                        ) {
                          eventType = "RANDOM TEST";
                        } else {
                          eventType = "NA";
                        }
                        if (element.passfailstatus == 1) {
                          Result = "PASS";
                        } else if (element.passfailstatus == 0) {
                          Result = "FAIL";
                        } else {
                          Result = " ";
                        }
                      } else if (element.typeofevent == 15) {
                        if (element.enginerunstate == 0) {
                          eventType = "ENGINE OFF";
                        } else if (element.enginerunstate == 1) {
                          eventType = "ENGINE ON";
                        } else {
                          eventType = "NA";
                        }
                      } else if (element.typeofevent == 65) {
                        eventType = "INSUFFICIENTVOLUME";
                      } else if (element.typeofevent == 66) {
                        eventType = "HARD_BLOW";
                      } else if (element.typeofevent == 65) {
                        eventType = "INSUFFICIENTVOLUME";
                      } else if (element.typeofevent == 21) {
                        eventType = "CAR_BATTERY_TAMPERING";
                      } else if (element.typeofevent == 19) {
                        eventType = "VIOLATION_LOCKOUT";
                      } else if (element.typeofevent == 4) {
                        eventType = "BYPASS";
                      } else if (element.typeofevent == 18) {
                        eventType = "TEST_REFUSED_RR_TEST";
                      } else if (element.typeofevent == 28) {
                        eventType = "TAMPERED";
                      } else if (element.typeofevent == 22) {
                        eventType = "CALIBRATION DONE";
                      } else if (element.typeofevent == 29) {
                        eventType = "START_TEST_ATTEMPT";
                      } else if (element.typeofevent == 34) {
                        eventType = "ENGINE_NOT_STARTED";
                      } else if (element.typeofevent == 27) {
                        eventType = "COOL_SAMPLE";
                      } else if (element.typeofevent == 25) {
                        eventType = "HOT_SAMPLE";
                      } else if (element.typeofevent == 8) {
                        eventType = "ONE_TIME_PASS_CODE";
                      } else if (element.typeofevent == 6) {
                        eventType = "SERVICE_PERIOD_SET";
                      } else if (element.typeofevent == 7) {
                        eventType = "VIOLATION_RESET";
                      } else if (element.typeofevent == 11) {
                        eventType = "LOG_READ";
                      } else if (element.typeofevent == 220) {
                        eventType = "LOCKOUT 0";
                      } else if (element.typeofevent == 221) {
                        eventType = "LOCKOUT 1";
                      } else if (element.typeofevent == 222) {
                        eventType = "LOCKOUT 2";
                      } else if (element.typeofevent == 223) {
                        eventType = "LOCKOUT 3";
                      } else if (element.typeofevent == 224) {
                        eventType = "LOCKOUT 4";
                      } else if (element.typeofevent == 225) {
                        eventType = "LOCKOUT 5";
                      } else if (element.typeofevent == 226) {
                        eventType = "LOCKOUT 6";
                      } else if (element.typeofevent == 227) {
                        eventType = "LOCKOUT 7";
                      } else if (element.typeofevent == 56) {
                        eventType = "TAKE_A_PICTURE";
                      } else if (element.typeofevent == 101) {
                        eventType = "PICTURE_CAPTURED";
                      } else if (element.typeofevent == 60) {
                        eventType = "BYPASS_START";
                      } else if (element.typeofevent == 61) {
                        eventType = "BYPASS_STOP";
                      } else if (element.typeofevent == 14) {
                        eventType = "SERVICE_ALERT";
                      } else if (element.typeofevent == 34) {
                        eventType = "ENGINE_NOT_STARTED";
                      } else if (element.typeofevent == 62) {
                        eventType = "SAMPLER_DISCONNECTED";
                      } else if (element.typeofevent == 10) {
                        eventType = "CAR_BATTERY_OFF";
                      } else if (element.typeofevent == 20) {
                        eventType = "CAR_BATTERY_ON";
                      } else if (element.typeofevent == 217) {
                        eventType = "MEMSET";
                      } else if (element.typeofevent == 219) {
                        eventType = "TIME_SET";
                      } else if (element.typeofevent == 218) {
                        eventType = "DEVICE RESTART";
                      } else if (element.typeofevent == 151) {
                        eventType = "ALCOHOL_SENSOR_FAIL";
                      } else if (element.typeofevent == 152) {
                        eventType = "BREATH_PRESSURE_SEN_FAIL";
                      } else if (element.typeofevent == 153) {
                        eventType = "BREATH_TEMP_SEN_FAIL";
                      } else if (element.typeofevent == 154) {
                        eventType = "BMP180_ALTITUDE_FAIL";
                      } else if (element.typeofevent == 155) {
                        eventType = "PROXIMITY_SENSOR_FAIL";
                      } else if (element.typeofevent == 156) {
                        eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                      } else if (element.typeofevent == 157) {
                        eventType = "RTC_FAIL";
                      }
                      if (element.bacvalue != 255) {
                        Bacvalue = parseFloat(element.bacvalue) / 1000;
                      } else {
                        Bacvalue = "";
                      }
                      var obj = {
                        id: element.id,
                        bacvalue: Bacvalue,
                        rtime: element.rtime,
                        devid: element.devid,
                        controlId: element.controlId,
                        passfailstatus: Result,
                        typeofevent: eventType,
                        vbat:
                          element.vbat == "NA" ? " " : element.vbat.split(","),
                      };

                      const exists = newArr.some(item =>
                        item.devid === obj.devid &&
                        item.rtime === obj.rtime &&
                        item.typeofevent === obj.typeofevent
                      );
                      if(!exists){
                      newArr.push(obj);
                      }
                    }
                  }
                }
              });

              if (newArr != []) {
                commit("SAMPLERFETCH_LOGS", newArr);
              } else {
                console.log("loading");
              }
            } else {
              commit("SAMPLERFETCH_LOGS", []);
            }
          } else {
            commit("SAMPLERFETCH_LOGS", []);
            dispatch("alert/error", "No Logs found").then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    samplefetchsplitLogs({ dispatch, commit }, id) {
      // console.log(id);
      Vue.axios
        .get(
          "https://robopower.xyz/v2/eeprom/getalllogs?deviceId=" + id.item.devId
        )
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            if (id.role == "superadmin") {
              arr = response.data.filter((o) => o.typeofevent !== "0");
            } else {
              arr = response.data.filter(
                (o) =>
                  o.typeofevent !== "0" &&
                  o.typeofevent !== "151" &&
                  o.typeofevent !== "152" &&
                  o.typeofevent !== "153" &&
                  o.typeofevent !== "154" &&
                  o.typeofevent !== "155" &&
                  o.typeofevent !== "156" &&
                  o.typeofevent !== "157" &&
                  o.typeofevent !== "218" &&
                  o.typeofevent !== "219"
              );
            }
            var arr2 =
              id.sorted == true
                ? arr.sort((a, b) => a.id - b.id)
                : arr.sort(
                    (a, b) =>
                      moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
                  );
            if (arr2 != null) {
              var repdata = arr2.reverse();

              if (repdata != []) {
                commit("SAMPLERFETCHSPLIT_LOGS", repdata);
              } else {
                console.log("loading");
              }
            } else {
              commit("SAMPLERFETCHSPLIT_LOGS", []);
            }
          } else {
            commit("SAMPLERFETCHSPLIT_LOGS", []);
            dispatch("alert/error", "No Logs found").then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchLogs({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + id.cbid)
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr = response.data.filter(
              (o) =>
                o.typeofevent !== "0" &&
                o.typeofevent !== "151" &&
                o.typeofevent !== "152" &&
                o.typeofevent !== "153" &&
                o.typeofevent !== "154" &&
                o.typeofevent !== "155" &&
                o.typeofevent !== "156" &&
                o.typeofevent !== "157"
            );
            var arr2 = arr.sort(
              (a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
            );
            var newArr = [];
            if (arr2 != null) {
              var repdata = arr2.reverse();
              repdata.map((element) => {
                // if(element.typeofevent !="101"){
                if (element.typeofevent != "29") {
                  if (element.typeofevent != "56") {
                    var eventType, Result, Bacvalue;
                    if (element.typeofevent == 254) {
                      //initialtest
                      if (
                        element.randominitial == 2 ||
                        element.randominitial == 0
                      ) {
                        eventType = "INITIAL TEST";
                      } else if (
                        element.randominitial == 3 ||
                        element.randominitial == 1
                      ) {
                        eventType = "RANDOM TEST";
                      } else {
                        eventType = "NA";
                      }
                      if (element.passfailstatus == 1) {
                        Result = "PASS";
                      } else if (element.passfailstatus == 0) {
                        Result = "FAIL";
                      } else {
                        Result = " ";
                      }
                    } else if (element.typeofevent == 15) {
                      if (element.enginerunstate == 0) {
                        eventType = "ENGINE OFF";
                      } else if (element.enginerunstate == 1) {
                        eventType = "ENGINE ON";
                      } else {
                        eventType = "NA";
                      }
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 66) {
                      eventType = "HARD_BLOW";
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 21) {
                      eventType = "CAR_BATTERY_TAMPERING";
                    } else if (element.typeofevent == 19) {
                      eventType = "VIOLATION_LOCKOUT";
                    } else if (element.typeofevent == 4) {
                      eventType = "BYPASS";
                    } else if (element.typeofevent == 18) {
                      eventType = "TEST_REFUSED_RR_TEST";
                    } else if (element.typeofevent == 28) {
                      eventType = "TAMPERED";
                    } else if (element.typeofevent == 22) {
                      eventType = "CALIBRATION DONE";
                    } else if (element.typeofevent == 29) {
                      eventType = "START_TEST_ATTEMPT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 27) {
                      eventType = "COOL_SAMPLE";
                    } else if (element.typeofevent == 25) {
                      eventType = "HOT_SAMPLE";
                    } else if (element.typeofevent == 8) {
                      eventType = "ONE_TIME_PASS_CODE";
                    } else if (element.typeofevent == 6) {
                      eventType = "SERVICE_PERIOD_SET";
                    } else if (element.typeofevent == 7) {
                      eventType = "VIOLATION_RESET";
                    } else if (element.typeofevent == 11) {
                      eventType = "LOG_READ";
                    } else if (element.typeofevent == 220) {
                      eventType = "LOCKOUT 0";
                    } else if (element.typeofevent == 221) {
                      eventType = "LOCKOUT 1";
                    } else if (element.typeofevent == 222) {
                      eventType = "LOCKOUT 2";
                    } else if (element.typeofevent == 223) {
                      eventType = "LOCKOUT 3";
                    } else if (element.typeofevent == 224) {
                      eventType = "LOCKOUT 4";
                    } else if (element.typeofevent == 225) {
                      eventType = "LOCKOUT 5";
                    } else if (element.typeofevent == 226) {
                      eventType = "LOCKOUT 6";
                    } else if (element.typeofevent == 227) {
                      eventType = "LOCKOUT 7";
                    } else if (element.typeofevent == 56) {
                      eventType = "TAKE_A_PICTURE";
                    } else if (element.typeofevent == 101) {
                      eventType = "PICTURE_CAPTURED";
                    } else if (element.typeofevent == 60) {
                      eventType = "BYPASS_START";
                    } else if (element.typeofevent == 61) {
                      eventType = "BYPASS_STOP";
                    } else if (element.typeofevent == 14) {
                      eventType = "SERVICE_ALERT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 62) {
                      eventType = "SAMPLER_DISCONNECTED";
                    } else if (element.typeofevent == 10) {
                      eventType = "CAR_BATTERY_OFF";
                    } else if (element.typeofevent == 20) {
                      eventType = "CAR_BATTERY_ON";
                    } else if (element.typeofevent == 217) {
                      eventType = "MEMSET";
                    } else if (element.typeofevent == 219) {
                      eventType = "TIME_SET";
                    } else if (element.typeofevent == 218) {
                      eventType = "DEVICE RESTART";
                    }
                    //   else if (element.typeofevent == 151) {
                    // eventType = "ALCOHOL_SENSOR_FAIL";
                    // }   else if (element.typeofevent == 152) {
                    // eventType = "BREATH_PRESSURE_SEN_FAIL";
                    // }   else if (element.typeofevent == 153) {
                    // eventType = "BREATH_TEMP_SEN_FAIL";
                    // }   else if (element.typeofevent == 154) {
                    // eventType = "BMP180_ALTITUDE_FAIL";
                    // }   else if (element.typeofevent == 155) {
                    // eventType = "PROXIMITY_SENSOR_FAIL";
                    // }   else if (element.typeofevent == 156) {
                    // eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                    // } else if (element.typeofevent == 157) {
                    // eventType = "RTC_FAIL";
                    // }
                    if (element.bacvalue != 255) {
                      Bacvalue = parseFloat(element.bacvalue) / 1000;
                    } else {
                      Bacvalue = "";
                    }
                    var obj = {
                      id: element.id,
                      bacvalue: Bacvalue,
                      rtime: element.rtime,
                      devid: element.devid,
                      controlId: element.controlId,
                      passfailstatus: Result,
                      typeofevent: eventType,
                    };

                    newArr.push(obj);
                  }
                }
                // }
              });

              if (newArr != []) {
                // console.log(newArr)
                commit("FETCH_LOGS", newArr);
              } else {
                console.log("loading");
              }
            } else {
              commit("FETCH_LOGS", []);
            }
          } else {
            Vue.axios
              .get(
                "https://robopower.xyz/v2/eeprom/getalllogs?deviceId=" +
                  id.devId
              )
              .then((response) => {
                if (response.data != null) {
                  // console.log(response.data)
                  var arr = response.data;
                  arr = response.data.filter((o) => o.typeofevent !== "0");
                  // console.log(arr)
                  var arr2 = arr.sort(
                    (a, b) =>
                      moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
                  );
                  var newArr = [];
                  if (arr2 != null) {
                    var repdata = arr2.reverse();
                    repdata.map((element) => {
                      // if(element.typeofevent !="101"){
                      if (element.typeofevent != "29") {
                        if (element.typeofevent != "56") {
                          var eventType, Result, Bacvalue;
                          if (element.typeofevent == 254) {
                            //initialtest
                            if (
                              element.randominitial == 2 ||
                              element.randominitial == 0
                            ) {
                              eventType = "INITIAL TEST";
                            } else if (
                              element.randominitial == 3 ||
                              element.randominitial == 1
                            ) {
                              eventType = "RANDOM TEST";
                            } else {
                              eventType = "NA";
                            }
                            if (element.passfailstatus == 1) {
                              Result = "PASS";
                            } else if (element.passfailstatus == 0) {
                              Result = "FAIL";
                            } else {
                              Result = " ";
                            }
                          } else if (element.typeofevent == 15) {
                            if (element.enginerunstate == 0) {
                              eventType = "ENGINE OFF";
                            } else if (element.enginerunstate == 1) {
                              eventType = "ENGINE ON";
                            } else {
                              eventType = "NA";
                            }
                          } else if (element.typeofevent == 65) {
                            eventType = "INSUFFICIENTVOLUME";
                          } else if (element.typeofevent == 66) {
                            eventType = "HARD_BLOW";
                          } else if (element.typeofevent == 65) {
                            eventType = "INSUFFICIENTVOLUME";
                          } else if (element.typeofevent == 21) {
                            eventType = "CAR_BATTERY_TAMPERING";
                          } else if (element.typeofevent == 19) {
                            eventType = "VIOLATION_LOCKOUT";
                          } else if (element.typeofevent == 4) {
                            eventType = "BYPASS";
                          } else if (element.typeofevent == 18) {
                            eventType = "TEST_REFUSED_RR_TEST";
                          } else if (element.typeofevent == 28) {
                            eventType = "TAMPERED";
                          } else if (element.typeofevent == 22) {
                            eventType = "CALIBRATION DONE";
                          } else if (element.typeofevent == 29) {
                            eventType = "START_TEST_ATTEMPT";
                          } else if (element.typeofevent == 34) {
                            eventType = "ENGINE_NOT_STARTED";
                          } else if (element.typeofevent == 27) {
                            eventType = "COOL_SAMPLE";
                          } else if (element.typeofevent == 25) {
                            eventType = "HOT_SAMPLE";
                          } else if (element.typeofevent == 8) {
                            eventType = "ONE_TIME_PASS_CODE";
                          } else if (element.typeofevent == 6) {
                            eventType = "SERVICE_PERIOD_SET";
                          } else if (element.typeofevent == 7) {
                            eventType = "VIOLATION_RESET";
                          } else if (element.typeofevent == 11) {
                            eventType = "LOG_READ";
                          } else if (element.typeofevent == 220) {
                            eventType = "LOCKOUT 0";
                          } else if (element.typeofevent == 221) {
                            eventType = "LOCKOUT 1";
                          } else if (element.typeofevent == 222) {
                            eventType = "LOCKOUT 2";
                          } else if (element.typeofevent == 223) {
                            eventType = "LOCKOUT 3";
                          } else if (element.typeofevent == 224) {
                            eventType = "LOCKOUT 4";
                          } else if (element.typeofevent == 225) {
                            eventType = "LOCKOUT 5";
                          } else if (element.typeofevent == 226) {
                            eventType = "LOCKOUT 6";
                          } else if (element.typeofevent == 227) {
                            eventType = "LOCKOUT 7";
                          } else if (element.typeofevent == 56) {
                            eventType = "TAKE_A_PICTURE";
                          } else if (element.typeofevent == 101) {
                            eventType = "PICTURE_CAPTURED";
                          } else if (element.typeofevent == 60) {
                            eventType = "BYPASS_START";
                          } else if (element.typeofevent == 61) {
                            eventType = "BYPASS_STOP";
                          } else if (element.typeofevent == 14) {
                            eventType = "SERVICE_ALERT";
                          } else if (element.typeofevent == 34) {
                            eventType = "ENGINE_NOT_STARTED";
                          } else if (element.typeofevent == 62) {
                            eventType = "SAMPLER_DISCONNECTED";
                          } else if (element.typeofevent == 10) {
                            eventType = "CAR_BATTERY_OFF";
                          } else if (element.typeofevent == 20) {
                            eventType = "CAR_BATTERY_ON";
                          } else if (element.typeofevent == 217) {
                            eventType = "MEMSET";
                          } else if (element.typeofevent == 219) {
                            eventType = "TIME_SET";
                          } else if (element.typeofevent == 218) {
                            eventType = "DEVICE RESTART";
                          }
                          //   else if (element.typeofevent == 151) {
                          // eventType = "ALCOHOL_SENSOR_FAIL";
                          // }   else if (element.typeofevent == 152) {
                          // eventType = "BREATH_PRESSURE_SEN_FAIL";
                          // }   else if (element.typeofevent == 153) {
                          // eventType = "BREATH_TEMP_SEN_FAIL";
                          // }   else if (element.typeofevent == 154) {
                          // eventType = "BMP180_ALTITUDE_FAIL";
                          // }   else if (element.typeofevent == 155) {
                          // eventType = "PROXIMITY_SENSOR_FAIL";
                          // }   else if (element.typeofevent == 156) {
                          // eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                          // } else if (element.typeofevent == 157) {
                          // eventType = "RTC_FAIL";
                          // }
                          if (element.bacvalue != 255) {
                            Bacvalue = parseFloat(element.bacvalue) / 1000;
                          } else {
                            Bacvalue = "";
                          }
                          var obj = {
                            id: element.id,
                            bacvalue: Bacvalue,
                            rtime: element.rtime,
                            devid: element.devid,
                            controlId: element.controlId,
                            passfailstatus: Result,
                            typeofevent: eventType,
                          };

                          newArr.push(obj);
                        }
                      }
                      // }
                    });

                    if (newArr != []) {
                      // console.log(newArr)
                      commit("FETCH_LOGS", newArr);
                    } else {
                      console.log("loading");
                    }
                  } else {
                    commit("FETCH_LOGS", []);
                  }
                } else {
                  commit("FETCH_LOGS", []);
                  dispatch("alert/error", "No Logs found").then(() => {
                    setTimeout(() => {
                      dispatch("alert/clear");
                    }, 2000);
                  });
                }
              })
              .catch((err) => {
                dispatch("alert/error", err).then(() => {
                  setTimeout(() => {
                    dispatch("alert/clear");
                  }, 2000);
                });
              });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchtempLogs({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/getwifilogs?deviceId=" + id)
        .then((response) => {
          if (response.data != null) {
            // console.log(response.data)
            var arr = response.data;
            arr = response.data.filter((o) => o.typeofevent !== "0");
            // console.log(arr)
            var arr2 = arr.sort(
              (a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
            );
            var newArr = [];
            if (arr2 != null) {
              var repdata = arr2.reverse();
              repdata.map((element) => {
                // if(element.typeofevent !="101"){
                // if(element.typeofevent !="29"){
                // if(element.typeofevent != "56") {
                var eventType, Result, Bacvalue;
                if (element.typeofevent == 254) {
                  //initialtest
                  if (
                    element.randominitial == 2 ||
                    element.randominitial == 0
                  ) {
                    eventType = "INITIAL TEST";
                  } else if (
                    element.randominitial == 3 ||
                    element.randominitial == 1
                  ) {
                    eventType = "RANDOM TEST";
                  } else {
                    eventType = "NA";
                  }
                  if (element.passfailstatus == 1) {
                    Result = "PASS";
                  } else if (element.passfailstatus == 0) {
                    Result = "FAIL";
                  } else {
                    Result = " ";
                  }
                } else if (element.typeofevent == 15) {
                  if (element.enginerunstate == 0) {
                    eventType = "ENGINE OFF";
                  } else if (element.enginerunstate == 1) {
                    eventType = "ENGINE ON";
                  } else {
                    eventType = "NA";
                  }
                } else if (element.typeofevent == 65) {
                  eventType = "INSUFFICIENTVOLUME";
                } else if (element.typeofevent == 101) {
                  eventType = "PICTURE_CAPTURED";
                } else if (element.typeofevent == 29) {
                  eventType = "START_TEST_ATTEMPT";
                } else if (element.typeofevent == 56) {
                  eventType = "TAKE_A_PICTURE";
                } else if (element.typeofevent == 66) {
                  eventType = "HARD_BLOW";
                } else if (element.typeofevent == 65) {
                  eventType = "INSUFFICIENTVOLUME";
                } else if (element.typeofevent == 21) {
                  eventType = "CAR_BATTERY_TAMPERING";
                } else if (element.typeofevent == 19) {
                  eventType = "VIOLATION_LOCKOUT";
                } else if (element.typeofevent == 4) {
                  eventType = "BYPASS";
                } else if (element.typeofevent == 18) {
                  eventType = "TEST_REFUSED_RR_TEST";
                } else if (element.typeofevent == 28) {
                  eventType = "TAMPERED";
                } else if (element.typeofevent == 22) {
                  eventType = "CALIBRATION DONE";
                } else if (element.typeofevent == 29) {
                  eventType = "START_TEST_ATTEMPT";
                } else if (element.typeofevent == 34) {
                  eventType = "ENGINE_NOT_STARTED";
                } else if (element.typeofevent == 27) {
                  eventType = "COOL_SAMPLE";
                } else if (element.typeofevent == 25) {
                  eventType = "HOT_SAMPLE";
                } else if (element.typeofevent == 8) {
                  eventType = "ONE_TIME_PASS_CODE";
                } else if (element.typeofevent == 6) {
                  eventType = "SERVICE_PERIOD_SET";
                } else if (element.typeofevent == 7) {
                  eventType = "VIOLATION_RESET";
                } else if (element.typeofevent == 11) {
                  eventType = "LOG_READ";
                } else if (element.typeofevent == 220) {
                  eventType = "LOCKOUT 0";
                } else if (element.typeofevent == 221) {
                  eventType = "LOCKOUT 1";
                } else if (element.typeofevent == 222) {
                  eventType = "LOCKOUT 2";
                } else if (element.typeofevent == 223) {
                  eventType = "LOCKOUT 3";
                } else if (element.typeofevent == 224) {
                  eventType = "LOCKOUT 4";
                } else if (element.typeofevent == 225) {
                  eventType = "LOCKOUT 5";
                } else if (element.typeofevent == 226) {
                  eventType = "LOCKOUT 6";
                } else if (element.typeofevent == 227) {
                  eventType = "LOCKOUT 7";
                } else if (element.typeofevent == 56) {
                  eventType = "TAKE_A_PICTURE";
                } else if (element.typeofevent == 101) {
                  eventType = "PICTURE_CAPTURED";
                } else if (element.typeofevent == 60) {
                  eventType = "BYPASS_START";
                } else if (element.typeofevent == 61) {
                  eventType = "BYPASS_STOP";
                } else if (element.typeofevent == 14) {
                  eventType = "SERVICE_ALERT";
                } else if (element.typeofevent == 34) {
                  eventType = "ENGINE_NOT_STARTED";
                } else if (element.typeofevent == 62) {
                  eventType = "SAMPLER_DISCONNECTED";
                } else if (element.typeofevent == 10) {
                  eventType = "CAR_BATTERY_OFF";
                } else if (element.typeofevent == 20) {
                  eventType = "CAR_BATTERY_ON";
                } else if (element.typeofevent == 217) {
                  eventType = "MEMSET";
                } else if (element.typeofevent == 219) {
                  eventType = "TIME_SET";
                } else if (element.typeofevent == 218) {
                  eventType = "DEVICE RESTART";
                }
                if (element.bacvalue != 255) {
                  Bacvalue = parseFloat(element.bacvalue) / 1000;
                } else {
                  Bacvalue = "";
                }
                var obj = {
                  id: element.id,
                  controlId: element.controlId,
                  bacvalue: Bacvalue,
                  rtime: element.rtime,
                  devid: element.devid,
                  passfailstatus: Result,
                  typeofevent: eventType,
                };

                newArr.push(obj);
                //     }
                //   }
                // }
              });

              if (newArr != []) {
                // console.log(newArr)
                commit("FETCH_TEMPLOGS", newArr);
              } else {
                console.log("empty");
              }
            } else {
              commit("FETCH_TEMPLOGS", []);
            }
          } else {
            commit("FETCH_TEMPLOGS", []);
            dispatch("alert/error", "NO WIFI LOGS FOUND").then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchLogsbeforesensor({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + id.cbid)
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr.sort(function (a, b) {
              return a.id - b.id;
            });
            var newArray = [];
            if (arr != null) {
              var sortarr = arr.reverse();
              sortarr.map((element) => {
                // if(element.typeofevent !="101"){
                if (element.typeofevent != "29") {
                  if (element.typeofevent != "56") {
                    var eventType, Result, Bacvalue;
                    if (element.typeofevent == 254) {
                      //initialtest
                      if (
                        element.randominitial == 2 ||
                        element.randominitial == 0
                      ) {
                        eventType = "INITIAL TEST";
                      } else if (
                        element.randominitial == 3 ||
                        element.randominitial == 1
                      ) {
                        eventType = "RANDOM TEST";
                      } else {
                        eventType = "NA";
                      }
                      if (element.passfailstatus == 1) {
                        Result = "PASS";
                      } else if (element.passfailstatus == 0) {
                        Result = "FAIL";
                      } else {
                        Result = " ";
                      }
                    } else if (element.typeofevent == 15) {
                      if (element.enginerunstate == 0) {
                        eventType = "ENGINE OFF";
                      } else if (element.enginerunstate == 1) {
                        eventType = "ENGINE ON";
                      } else {
                        eventType = "NA";
                      }
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 66) {
                      eventType = "HARD_BLOW";
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 21) {
                      eventType = "CAR_BATTERY_TAMPERING";
                    } else if (element.typeofevent == 19) {
                      eventType = "VIOLATION_LOCKOUT";
                    } else if (element.typeofevent == 4) {
                      eventType = "BYPASS";
                    } else if (element.typeofevent == 18) {
                      eventType = "TEST_REFUSED_RR_TEST";
                    } else if (element.typeofevent == 28) {
                      eventType = "TAMPERED";
                    } else if (element.typeofevent == 22) {
                      eventType = "CALIBRATION DONE";
                    } else if (element.typeofevent == 29) {
                      eventType = "START_TEST_ATTEMPT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 27) {
                      eventType = "COOL_SAMPLE";
                    } else if (element.typeofevent == 25) {
                      eventType = "HOT_SAMPLE";
                    } else if (element.typeofevent == 8) {
                      eventType = "ONE_TIME_PASS_CODE";
                    } else if (element.typeofevent == 6) {
                      eventType = "SERVICE_PERIOD_SET";
                    } else if (element.typeofevent == 7) {
                      eventType = "VIOLATION_RESET";
                    } else if (element.typeofevent == 11) {
                      eventType = "LOG_READ";
                    } else if (element.typeofevent == 220) {
                      eventType = "LOCKOUT 0";
                    } else if (element.typeofevent == 221) {
                      eventType = "LOCKOUT 1";
                    } else if (element.typeofevent == 222) {
                      eventType = "LOCKOUT 2";
                    } else if (element.typeofevent == 223) {
                      eventType = "LOCKOUT 3";
                    } else if (element.typeofevent == 224) {
                      eventType = "LOCKOUT 4";
                    } else if (element.typeofevent == 225) {
                      eventType = "LOCKOUT 5";
                    } else if (element.typeofevent == 226) {
                      eventType = "LOCKOUT 6";
                    } else if (element.typeofevent == 227) {
                      eventType = "LOCKOUT 7";
                    } else if (element.typeofevent == 56) {
                      eventType = "TAKE_A_PICTURE";
                    } else if (element.typeofevent == 101) {
                      eventType = "PICTURE_CAPTURED";
                    } else if (element.typeofevent == 60) {
                      eventType = "BYPASS_START";
                    } else if (element.typeofevent == 61) {
                      eventType = "BYPASS_STOP";
                    } else if (element.typeofevent == 14) {
                      eventType = "SERVICE_ALERT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 62) {
                      eventType = "SAMPLER_DISCONNECTED";
                    } else if (element.typeofevent == 10) {
                      eventType = "CAR_BATTERY_OFF";
                    } else if (element.typeofevent == 20) {
                      eventType = "CAR_BATTERY_ON";
                    } else if (element.typeofevent == 217) {
                      eventType = "EVENT_MEMSET";
                    } else if (element.typeofevent == 219) {
                      eventType = "EVENT_TIME_SET";
                    } else if (element.typeofevent == 218) {
                      eventType = "EVENT_RESET";
                    } else if (element.typeofevent == 151) {
                      eventType = "ALCOHOL_SENSOR_FAIL";
                    } else if (element.typeofevent == 152) {
                      eventType = "BREATH_PRESSURE_SEN_FAIL";
                    } else if (element.typeofevent == 153) {
                      eventType = "BREATH_TEMP_SEN_FAIL";
                    } else if (element.typeofevent == 154) {
                      eventType = "BMP180_ALTITUDE_FAIL";
                    } else if (element.typeofevent == 155) {
                      eventType = "PROXIMITY_SENSOR_FAIL";
                    } else if (element.typeofevent == 156) {
                      eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                    } else if (element.typeofevent == 157) {
                      eventType = "RTC_FAIL";
                    }
                    if (element.bacvalue != 255) {
                      Bacvalue = parseFloat(element.bacvalue) / 1000;
                    } else {
                      Bacvalue = "";
                    }
                    var obj = {
                      id: element.id,
                      bacvalue: Bacvalue,
                      rtime: element.rtime,
                      controlId: element.controlId,
                      devid: element.devid,
                      passfailstatus: Result,
                      typeofevent: eventType,
                    };

                    newArray.push(obj);
                  }
                }
                // }
              });

              if (newArray != []) {
                // console.log(newArray)
                commit("FETCH_LOGSBEFORESENSOR", newArray);
              } else {
                console.log("loading");
              }
            } else {
              commit("FETCH_LOGSBEFORESENSOR", []);
            }
          } else {
            Vue.axios
              .get(
                "https://robopower.xyz/v2/eeprom/getalllogs?deviceId=" +
                  id.devId
              )
              .then((response) => {
                if (response.data != null) {
                  var arr = response.data;
                  arr.sort(function (a, b) {
                    return a.id - b.id;
                  });
                  var newArray = [];
                  if (arr != null) {
                    var sortarr = arr.reverse();
                    sortarr.map((element) => {
                      // if(element.typeofevent !="101"){
                      if (element.typeofevent != "29") {
                        if (element.typeofevent != "56") {
                          var eventType, Result, Bacvalue;
                          if (element.typeofevent == 254) {
                            //initialtest
                            if (
                              element.randominitial == 2 ||
                              element.randominitial == 0
                            ) {
                              eventType = "INITIAL TEST";
                            } else if (
                              element.randominitial == 3 ||
                              element.randominitial == 1
                            ) {
                              eventType = "RANDOM TEST";
                            } else {
                              eventType = "NA";
                            }
                            if (element.passfailstatus == 1) {
                              Result = "PASS";
                            } else if (element.passfailstatus == 0) {
                              Result = "FAIL";
                            } else {
                              Result = " ";
                            }
                          } else if (element.typeofevent == 15) {
                            if (element.enginerunstate == 0) {
                              eventType = "ENGINE OFF";
                            } else if (element.enginerunstate == 1) {
                              eventType = "ENGINE ON";
                            } else {
                              eventType = "NA";
                            }
                          } else if (element.typeofevent == 65) {
                            eventType = "INSUFFICIENTVOLUME";
                          } else if (element.typeofevent == 66) {
                            eventType = "HARD_BLOW";
                          } else if (element.typeofevent == 65) {
                            eventType = "INSUFFICIENTVOLUME";
                          } else if (element.typeofevent == 21) {
                            eventType = "CAR_BATTERY_TAMPERING";
                          } else if (element.typeofevent == 19) {
                            eventType = "VIOLATION_LOCKOUT";
                          } else if (element.typeofevent == 4) {
                            eventType = "BYPASS";
                          } else if (element.typeofevent == 18) {
                            eventType = "TEST_REFUSED_RR_TEST";
                          } else if (element.typeofevent == 28) {
                            eventType = "TAMPERED";
                          } else if (element.typeofevent == 22) {
                            eventType = "CALIBRATION DONE";
                          } else if (element.typeofevent == 29) {
                            eventType = "START_TEST_ATTEMPT";
                          } else if (element.typeofevent == 34) {
                            eventType = "ENGINE_NOT_STARTED";
                          } else if (element.typeofevent == 27) {
                            eventType = "COOL_SAMPLE";
                          } else if (element.typeofevent == 25) {
                            eventType = "HOT_SAMPLE";
                          } else if (element.typeofevent == 8) {
                            eventType = "ONE_TIME_PASS_CODE";
                          } else if (element.typeofevent == 6) {
                            eventType = "SERVICE_PERIOD_SET";
                          } else if (element.typeofevent == 7) {
                            eventType = "VIOLATION_RESET";
                          } else if (element.typeofevent == 11) {
                            eventType = "LOG_READ";
                          } else if (element.typeofevent == 220) {
                            eventType = "LOCKOUT 0";
                          } else if (element.typeofevent == 221) {
                            eventType = "LOCKOUT 1";
                          } else if (element.typeofevent == 222) {
                            eventType = "LOCKOUT 2";
                          } else if (element.typeofevent == 223) {
                            eventType = "LOCKOUT 3";
                          } else if (element.typeofevent == 224) {
                            eventType = "LOCKOUT 4";
                          } else if (element.typeofevent == 225) {
                            eventType = "LOCKOUT 5";
                          } else if (element.typeofevent == 226) {
                            eventType = "LOCKOUT 6";
                          } else if (element.typeofevent == 227) {
                            eventType = "LOCKOUT 7";
                          } else if (element.typeofevent == 56) {
                            eventType = "TAKE_A_PICTURE";
                          } else if (element.typeofevent == 101) {
                            eventType = "PICTURE_CAPTURED";
                          } else if (element.typeofevent == 60) {
                            eventType = "BYPASS_START";
                          } else if (element.typeofevent == 61) {
                            eventType = "BYPASS_STOP";
                          } else if (element.typeofevent == 14) {
                            eventType = "SERVICE_ALERT";
                          } else if (element.typeofevent == 34) {
                            eventType = "ENGINE_NOT_STARTED";
                          } else if (element.typeofevent == 62) {
                            eventType = "SAMPLER_DISCONNECTED";
                          } else if (element.typeofevent == 10) {
                            eventType = "CAR_BATTERY_OFF";
                          } else if (element.typeofevent == 20) {
                            eventType = "CAR_BATTERY_ON";
                          } else if (element.typeofevent == 217) {
                            eventType = "EVENT_MEMSET";
                          } else if (element.typeofevent == 219) {
                            eventType = "EVENT_TIME_SET";
                          } else if (element.typeofevent == 218) {
                            eventType = "EVENT_RESET";
                          } else if (element.typeofevent == 151) {
                            eventType = "ALCOHOL_SENSOR_FAIL";
                          } else if (element.typeofevent == 152) {
                            eventType = "BREATH_PRESSURE_SEN_FAIL";
                          } else if (element.typeofevent == 153) {
                            eventType = "BREATH_TEMP_SEN_FAIL";
                          } else if (element.typeofevent == 154) {
                            eventType = "BMP180_ALTITUDE_FAIL";
                          } else if (element.typeofevent == 155) {
                            eventType = "PROXIMITY_SENSOR_FAIL";
                          } else if (element.typeofevent == 156) {
                            eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                          } else if (element.typeofevent == 157) {
                            eventType = "RTC_FAIL";
                          }
                          if (element.bacvalue != 255) {
                            Bacvalue = parseFloat(element.bacvalue) / 1000;
                          } else {
                            Bacvalue = "";
                          }
                          var obj = {
                            id: element.id,
                            bacvalue: Bacvalue,
                            rtime: element.rtime,
                            controlId: element.controlId,
                            devid: element.devid,
                            passfailstatus: Result,
                            typeofevent: eventType,
                          };

                          newArray.push(obj);
                        }
                      }
                      // }
                    });

                    if (newArray != []) {
                      commit("FETCH_LOGSBEFORESENSOR", newArray);
                    } else {
                      console.log("loading");
                    }
                  } else {
                    commit("FETCH_LOGSBEFORESENSOR", []);
                  }
                } else {
                  commit("FETCH_LOGSBEFORESENSOR", []);
                  dispatch("alert/error", "No Logs found").then(() => {
                    setTimeout(() => {
                      dispatch("alert/clear");
                    }, 2000);
                  });
                }
              })
              .catch((err) => {
                dispatch("alert/error", err).then(() => {
                  setTimeout(() => {
                    dispatch("alert/clear");
                  }, 2000);
                });
              });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchLogsbefore({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + id.cbid)
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            arr.sort(function (a, b) {
              return a.id - b.id;
            });
            arr = response.data.filter(
              (o) =>
                o.typeofevent !== "0" &&
                o.typeofevent !== "151" &&
                o.typeofevent !== "152" &&
                o.typeofevent !== "153" &&
                o.typeofevent !== "154" &&
                o.typeofevent !== "155" &&
                o.typeofevent !== "156" &&
                o.typeofevent !== "157"
            );
            var newArray = [];
            if (arr != null) {
              var sortarr = arr.reverse();
              sortarr.map((element) => {
                // if(element.typeofevent !="101"){
                if (element.typeofevent != "29") {
                  if (element.typeofevent != "56") {
                    var eventType, Result, Bacvalue;
                    if (element.typeofevent == 254) {
                      //initialtest
                      if (
                        element.randominitial == 2 ||
                        element.randominitial == 0
                      ) {
                        eventType = "INITIAL TEST";
                      } else if (
                        element.randominitial == 3 ||
                        element.randominitial == 1
                      ) {
                        eventType = "RANDOM TEST";
                      } else {
                        eventType = "NA";
                      }
                      if (element.passfailstatus == 1) {
                        Result = "PASS";
                      } else if (element.passfailstatus == 0) {
                        Result = "FAIL";
                      } else {
                        Result = " ";
                      }
                    } else if (element.typeofevent == 15) {
                      if (element.enginerunstate == 0) {
                        eventType = "ENGINE OFF";
                      } else if (element.enginerunstate == 1) {
                        eventType = "ENGINE ON";
                      } else {
                        eventType = "NA";
                      }
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 66) {
                      eventType = "HARD_BLOW";
                    } else if (element.typeofevent == 65) {
                      eventType = "INSUFFICIENTVOLUME";
                    } else if (element.typeofevent == 21) {
                      eventType = "CAR_BATTERY_TAMPERING";
                    } else if (element.typeofevent == 19) {
                      eventType = "VIOLATION_LOCKOUT";
                    } else if (element.typeofevent == 4) {
                      eventType = "BYPASS";
                    } else if (element.typeofevent == 18) {
                      eventType = "TEST_REFUSED_RR_TEST";
                    } else if (element.typeofevent == 28) {
                      eventType = "TAMPERED";
                    } else if (element.typeofevent == 22) {
                      eventType = "CALIBRATION DONE";
                    } else if (element.typeofevent == 29) {
                      eventType = "START_TEST_ATTEMPT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 27) {
                      eventType = "COOL_SAMPLE";
                    } else if (element.typeofevent == 25) {
                      eventType = "HOT_SAMPLE";
                    } else if (element.typeofevent == 8) {
                      eventType = "ONE_TIME_PASS_CODE";
                    } else if (element.typeofevent == 6) {
                      eventType = "SERVICE_PERIOD_SET";
                    } else if (element.typeofevent == 7) {
                      eventType = "VIOLATION_RESET";
                    } else if (element.typeofevent == 11) {
                      eventType = "LOG_READ";
                    } else if (element.typeofevent == 220) {
                      eventType = "LOCKOUT 0";
                    } else if (element.typeofevent == 221) {
                      eventType = "LOCKOUT 1";
                    } else if (element.typeofevent == 222) {
                      eventType = "LOCKOUT 2";
                    } else if (element.typeofevent == 223) {
                      eventType = "LOCKOUT 3";
                    } else if (element.typeofevent == 224) {
                      eventType = "LOCKOUT 4";
                    } else if (element.typeofevent == 225) {
                      eventType = "LOCKOUT 5";
                    } else if (element.typeofevent == 226) {
                      eventType = "LOCKOUT 6";
                    } else if (element.typeofevent == 227) {
                      eventType = "LOCKOUT 7";
                    } else if (element.typeofevent == 56) {
                      eventType = "TAKE_A_PICTURE";
                    } else if (element.typeofevent == 101) {
                      eventType = "PICTURE_CAPTURED";
                    } else if (element.typeofevent == 60) {
                      eventType = "BYPASS_START";
                    } else if (element.typeofevent == 61) {
                      eventType = "BYPASS_STOP";
                    } else if (element.typeofevent == 14) {
                      eventType = "SERVICE_ALERT";
                    } else if (element.typeofevent == 34) {
                      eventType = "ENGINE_NOT_STARTED";
                    } else if (element.typeofevent == 62) {
                      eventType = "SAMPLER_DISCONNECTED";
                    } else if (element.typeofevent == 10) {
                      eventType = "CAR_BATTERY_OFF";
                    } else if (element.typeofevent == 20) {
                      eventType = "CAR_BATTERY_ON";
                    } else if (element.typeofevent == 217) {
                      eventType = "EVENT_MEMSET";
                    } else if (element.typeofevent == 219) {
                      eventType = "EVENT_TIME_SET";
                    } else if (element.typeofevent == 218) {
                      eventType = "EVENT_RESET";
                    }
                    if (element.bacvalue != 255) {
                      Bacvalue = parseFloat(element.bacvalue) / 1000;
                    } else {
                      Bacvalue = "";
                    }
                    var obj = {
                      id: element.id,
                      bacvalue: Bacvalue,
                      rtime: element.rtime,
                      controlId: element.controlId,
                      devid: element.devid,
                      passfailstatus: Result,
                      typeofevent: eventType,
                    };

                    newArray.push(obj);
                  }
                }
                // }
              });

              if (newArray != []) {
                // console.log(newArray)
                commit("FETCH_LOGSBEFORE", newArray);
              } else {
                console.log("loading");
              }
            } else {
              commit("FETCH_LOGSBEFORE", []);
            }
          } else {
            Vue.axios
              .get(
                "https://robopower.xyz/v2/eeprom/getalllogs?deviceId=" +
                  id.devId
              )
              .then((response) => {
                if (response.data != null) {
                  var arr = response.data;
                  arr.sort(function (a, b) {
                    return a.id - b.id;
                  });
                  arr = response.data.filter(
                    (o) =>
                      o.typeofevent !== "0" &&
                      o.typeofevent !== "151" &&
                      o.typeofevent !== "152" &&
                      o.typeofevent !== "153" &&
                      o.typeofevent !== "154" &&
                      o.typeofevent !== "155" &&
                      o.typeofevent !== "156" &&
                      o.typeofevent !== "157"
                  );
                  var newArray = [];
                  if (arr != null) {
                    var sortarr = arr.reverse();
                    sortarr.map((element) => {
                      // if(element.typeofevent !="101"){
                      if (element.typeofevent != "29") {
                        if (element.typeofevent != "56") {
                          var eventType, Result, Bacvalue;
                          if (element.typeofevent == 254) {
                            //initialtest
                            if (
                              element.randominitial == 2 ||
                              element.randominitial == 0
                            ) {
                              eventType = "INITIAL TEST";
                            } else if (
                              element.randominitial == 3 ||
                              element.randominitial == 1
                            ) {
                              eventType = "RANDOM TEST";
                            } else {
                              eventType = "NA";
                            }
                            if (element.passfailstatus == 1) {
                              Result = "PASS";
                            } else if (element.passfailstatus == 0) {
                              Result = "FAIL";
                            } else {
                              Result = " ";
                            }
                          } else if (element.typeofevent == 15) {
                            if (element.enginerunstate == 0) {
                              eventType = "ENGINE OFF";
                            } else if (element.enginerunstate == 1) {
                              eventType = "ENGINE ON";
                            } else {
                              eventType = "NA";
                            }
                          } else if (element.typeofevent == 65) {
                            eventType = "INSUFFICIENTVOLUME";
                          } else if (element.typeofevent == 66) {
                            eventType = "HARD_BLOW";
                          } else if (element.typeofevent == 65) {
                            eventType = "INSUFFICIENTVOLUME";
                          } else if (element.typeofevent == 21) {
                            eventType = "CAR_BATTERY_TAMPERING";
                          } else if (element.typeofevent == 19) {
                            eventType = "VIOLATION_LOCKOUT";
                          } else if (element.typeofevent == 4) {
                            eventType = "BYPASS";
                          } else if (element.typeofevent == 18) {
                            eventType = "TEST_REFUSED_RR_TEST";
                          } else if (element.typeofevent == 28) {
                            eventType = "TAMPERED";
                          } else if (element.typeofevent == 22) {
                            eventType = "CALIBRATION DONE";
                          } else if (element.typeofevent == 29) {
                            eventType = "START_TEST_ATTEMPT";
                          } else if (element.typeofevent == 34) {
                            eventType = "ENGINE_NOT_STARTED";
                          } else if (element.typeofevent == 27) {
                            eventType = "COOL_SAMPLE";
                          } else if (element.typeofevent == 25) {
                            eventType = "HOT_SAMPLE";
                          } else if (element.typeofevent == 8) {
                            eventType = "ONE_TIME_PASS_CODE";
                          } else if (element.typeofevent == 6) {
                            eventType = "SERVICE_PERIOD_SET";
                          } else if (element.typeofevent == 7) {
                            eventType = "VIOLATION_RESET";
                          } else if (element.typeofevent == 11) {
                            eventType = "LOG_READ";
                          } else if (element.typeofevent == 220) {
                            eventType = "LOCKOUT 0";
                          } else if (element.typeofevent == 221) {
                            eventType = "LOCKOUT 1";
                          } else if (element.typeofevent == 222) {
                            eventType = "LOCKOUT 2";
                          } else if (element.typeofevent == 223) {
                            eventType = "LOCKOUT 3";
                          } else if (element.typeofevent == 224) {
                            eventType = "LOCKOUT 4";
                          } else if (element.typeofevent == 225) {
                            eventType = "LOCKOUT 5";
                          } else if (element.typeofevent == 226) {
                            eventType = "LOCKOUT 6";
                          } else if (element.typeofevent == 227) {
                            eventType = "LOCKOUT 7";
                          } else if (element.typeofevent == 56) {
                            eventType = "TAKE_A_PICTURE";
                          } else if (element.typeofevent == 101) {
                            eventType = "PICTURE_CAPTURED";
                          } else if (element.typeofevent == 60) {
                            eventType = "BYPASS_START";
                          } else if (element.typeofevent == 61) {
                            eventType = "BYPASS_STOP";
                          } else if (element.typeofevent == 14) {
                            eventType = "SERVICE_ALERT";
                          } else if (element.typeofevent == 34) {
                            eventType = "ENGINE_NOT_STARTED";
                          } else if (element.typeofevent == 62) {
                            eventType = "SAMPLER_DISCONNECTED";
                          } else if (element.typeofevent == 10) {
                            eventType = "CAR_BATTERY_OFF";
                          } else if (element.typeofevent == 20) {
                            eventType = "CAR_BATTERY_ON";
                          } else if (element.typeofevent == 217) {
                            eventType = "EVENT_MEMSET";
                          } else if (element.typeofevent == 219) {
                            eventType = "EVENT_TIME_SET";
                          } else if (element.typeofevent == 218) {
                            eventType = "EVENT_RESET";
                          }
                          if (element.bacvalue != 255) {
                            Bacvalue = parseFloat(element.bacvalue) / 1000;
                          } else {
                            Bacvalue = "";
                          }
                          var obj = {
                            id: element.id,
                            bacvalue: Bacvalue,
                            rtime: element.rtime,
                            controlId: element.controlId,
                            devid: element.devid,
                            passfailstatus: Result,
                            typeofevent: eventType,
                          };

                          newArray.push(obj);
                        }
                      }
                      // }
                    });

                    if (newArray != []) {
                      // console.log(newArray)
                      commit("FETCH_LOGSBEFORE", newArray);
                    } else {
                      console.log("loading");
                    }
                  } else {
                    commit("FETCH_LOGSBEFORE", []);
                  }
                } else {
                  commit("FETCH_LOGSBEFORE", []);
                  dispatch("alert/error", "No Logs found").then(() => {
                    setTimeout(() => {
                      dispatch("alert/clear");
                    }, 2000);
                  });
                }
              })
              .catch((err) => {
                dispatch("alert/error", err).then(() => {
                  setTimeout(() => {
                    dispatch("alert/clear");
                  }, 2000);
                });
              });
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    //routes
    createRoute({ dispatch }, rdata) {
      Vue.axios
        .post("https://robopower.xyz/app/routes/addroute", rdata)
        .then(() => {
          var cd = this.state.user;
          dispatch("alert/success", "Set Route Success");

          dispatch("fetchallschoolRoutes", cd.dealerId);
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    editRoute({ dispatch }, rdata) {
      Vue.axios
        .post(
          "https://robopower.xyz/app/routes/editroute/" + rdata.id,
          rdata.vdata
        )
        .then(() => {
          var cd = this.state.user;
          dispatch("alert/success", "SUCCESS UPDATING ROUTE");
          if (cd.role == "ADMIN") {
            dispatch("fetchversionRoutes", cd);
          } else {
            dispatch("fetchclientRoutes", cd.clientId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    deleteRoute({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/app/routes/deleteroute/" + id)
        .then((response) => {
          dispatch("alert/success", response.data);
          var cd = this.state.user;
          dispatch("alert/success", "success deleting route" + id);
          if (cd.role == "ADMIN") {
            dispatch("fetchversionRoutes", cd);
          } else {
            dispatch("fetchclientRoutes", cd.clientId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchallRoutes({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/app/routes/getallroutes")
        .then((response) => {
          if (response.data != null) commit("FETCH_ROUTES", response.data);
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchrouteStops({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/app/routes/getroutestops?routeId=" + id)
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_STOPS", response.data);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchclientRoutes({ dispatch, commit }, user) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/routes/getclientallroutes?clientId=" + user
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_ROUTES", response.data);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchallclientRoutes({ dispatch, commit }, user) {
      Vue.axios
        .get("https://robopower.xyz/app/school/getdashroutes?clientId=" + user)
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_ROUTES", response.data);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchuniqueRoute({ dispatch, commit }, user) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/routes/getuniqueroutes?routeId=" +
            user.routeId
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_ROUTES", response.data);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    //services
    createService({ dispatch }, service) {
      Vue.axios
        .post("https://robopower.xyz/us/caservices/addservice", service)
        .then(() => {
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchServices");
          } else {
            dispatch("fetchdealerServices", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editService({ dispatch }, service) {
      Vue.axios
        .post(
          "https://robopower.xyz/us/caservices/editservice/" + service.id,
          service.sdata
        )
        .then(() => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchServices");
          } else {
            dispatch("fetchdealerServices", this.state.user.dealerId);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteService({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/caservices/deleteservice/" + id)
        .then(() => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          if (this.state.user.role == "Admin") {
            dispatch("fetchServices");
          } else {
            dispatch("fetchdealerServices", this.state.user.dealerId);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchServices({ dispatch, commit }) {
      Vue.axios
        .get("https://robopower.xyz/us/caservices/getallservices")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_SERVICES", response.data);
          } else {
            commit("FETCH_SERVICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerServices({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caservices/getdealerservices?dealerName=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_SERVICES", response.data);
          } else {
            commit("FETCH_SERVICES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchDashboard({ dispatch, commit }) {
      var mydb = {
        totaldevices: "loading",
        availabledevices: "loading",
        currentrevenue: "loading",
        totalrevenue: "loading",
        newclients: "loading",
        totalclients: "loading",
        newinstallations: "loading",
        removalthismonth: "loading",
        calibrations: [],
        saleslabels: "loading",
        salescount: "loading",
        officeId: "loading",
        officeName: "loading",
      };
      this.state.dashboard = mydb;
      Vue.axios
        .get("https://robopower.xyz/us/cadbdata/getdata")
        .then((response) => {
          var crevenue = 0;
          var trevenue = 0;
          var today = moment();
          var data = response.data;
          var lastmonths = [[], [], []];
          var monthslist = moment.monthsShort();
          var currentDate = moment();
          var cals = [];
          if (data.calibrations != null) {
            data.calibrations.forEach((element) => {
              // var caldatee = moment(JSON.parse(element).calibrationDate)
              // var gapd = today.diff(caldatee,"days");
              // var caldayss= parseInt(JSON.parse(element).calibrationperiod);
              // if(gapd > caldayss-7){
              cals.push(JSON.parse(element));
              // }
            });
          }
          if (data.sales != null) {
            data.sales.forEach((le) => {
              var str2 = JSON.parse(le);
              trevenue += parseFloat(str2.amountpaid);
              var invoicedate = moment(str2.date).month();
              var curmonth = today.month();
              if (curmonth == invoicedate) {
                crevenue += parseFloat(str2.amountpaid);
              }
            });
          }
          for (var i = 0; i < 6; i++) {
            var count = 0;
            var futureMonth = moment(currentDate).subtract(i, "M");
            lastmonths[0].push(
              monthslist[futureMonth.month()] + "-" + futureMonth.year()
            );
            lastmonths[1].push(0);
            if (data.sales != null) {
              data.sales.forEach((element) => {
                var str = JSON.parse(element);
                var currdate =
                  monthslist[futureMonth.month()] + "-" + futureMonth.year();
                var pastdate =
                  monthslist[moment(str.date).month()] +
                  "-" +
                  moment(str.date).year();

                if (currdate == pastdate) {
                  count += parseFloat(str.amountpaid);
                }
              });
              lastmonths[1][lastmonths[1].length - 1] = count;
              lastmonths[2].push(
                monthslist[futureMonth.month()] + " $" + count
              );
            }
          }
          var res = {
            totaldevices: data.totaldevices,
            availabledevices: data.availabledevices,
            currentrevenue: crevenue,
            totalrevenue: trevenue,
            newclients: data.newclients,
            totalclients: data.totalclients,
            newinstallations: data.newinstallations,
            removalthismonth: data.removalthismonth,
            calibrations: cals,
            saleslabels: lastmonths[2],
            salescount: lastmonths[1],
            officeId: data.officeId,
            officeName: data.officeName,
          };
          commit("FETCH_DASHBOARD", res);
        })
        .catch((err) => {
          dispatch("alert/error").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchalllockoutdevicesDashboard({ dispatch, commit }, id) {
      Vue.axios
        .get("https://robopower.xyz/us/cadevices/getalllockoutdevices")
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            var cdate = moment(new Date()).format("MM/DD/YY");
            var ifnodata = arr.filter(
              (res) => res.rtime.split(" ")[0] == cdate
            );
            var newArr = [];
            if (ifnodata != null) {
              var repdata = ifnodata;
              repdata.map((element) => {
                if (element.typeofevent == 220) {
                  element["typeofevent"] = "LOCKOUT 0";
                  newArr.push(element);
                } else if (element.typeofevent == 221) {
                  element["typeofevent"] = "LOCKOUT 1";
                  newArr.push(element);
                } else if (element.typeofevent == 222) {
                  element["typeofevent"] = "LOCKOUT 2";
                  newArr.push(element);
                } else if (element.typeofevent == 223) {
                  element["typeofevent"] = "LOCKOUT 3";
                  newArr.push(element);
                } else if (element.typeofevent == 224) {
                  element["typeofevent"] = "LOCKOUT 4";
                  newArr.push(element);
                } else if (element.typeofevent == 225) {
                  element["typeofevent"] = "LOCKOUT 5";
                  newArr.push(element);
                } else if (element.typeofevent == 226) {
                  element["typeofevent"] = "LOCKOUT 6";
                  newArr.push(element);
                } else if (element.typeofevent == 227) {
                  element["typeofevent"] = "LOCKOUT 7";
                  newArr.push(element);
                } else {
                  element["typeofevent"] = "NA";
                  newArr.push(element);
                }
              });
              commit("FETCH_LOCKOUT", newArr);
            } else {
              commit("FETCH_LOCKOUT", []);
            }
          } else {
            dispatch("alert/error", "No Lockout Events found").then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err);
        });
    },
    fetchlockoutdevicesDashboard({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/cadevices/getlockoutdevices?dealerName=" +
            id
        )
        .then((response) => {
          if (response.data != null) {
            var arr = response.data;
            var cdate = moment(new Date()).format("MM/DD/YY");
            var ifnodata = arr.filter(
              (res) => res.rtime.split(" ")[0] == cdate
            );
            var newArr = [];
            if (ifnodata != null) {
              var repdata = ifnodata;
              repdata.map((element) => {
                if (element.typeofevent == 220) {
                  element["typeofevent"] = "LOCKOUT 0";
                  newArr.push(element);
                } else if (element.typeofevent == 221) {
                  element["typeofevent"] = "LOCKOUT 1";
                  newArr.push(element);
                } else if (element.typeofevent == 222) {
                  element["typeofevent"] = "LOCKOUT 2";
                  newArr.push(element);
                } else if (element.typeofevent == 223) {
                  element["typeofevent"] = "LOCKOUT 3";
                  newArr.push(element);
                } else if (element.typeofevent == 224) {
                  element["typeofevent"] = "LOCKOUT 4";
                  newArr.push(element);
                } else if (element.typeofevent == 225) {
                  element["typeofevent"] = "LOCKOUT 5";
                  newArr.push(element);
                } else if (element.typeofevent == 226) {
                  element["typeofevent"] = "LOCKOUT 6";
                  newArr.push(element);
                } else if (element.typeofevent == 227) {
                  element["typeofevent"] = "LOCKOUT 7";
                  newArr.push(element);
                } else {
                  element["typeofevent"] = "NA";
                  newArr.push(element);
                }
              });
              commit("FETCH_LOCKOUT", newArr);
            } else {
              commit("FETCH_LOCKOUT", []);
            }
          } else {
            dispatch("alert/error", "No Lockout Events found").then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err);
        });
    },
    fetchofficeDashboard({ dispatch, commit }, id) {
      var mydb = {
        totaldevices: "loading",
        availabledevices: "loading",
        currentrevenue: "loading",
        totalrevenue: "loading",
        newclients: "loading",
        totalclients: "loading",
        newinstallations: "loading",
        removalthismonth: "loading",
        calibrations: "loading",
        saleslabels: "loading",
        salescount: "loading",
        officeId: "loading",
        officeName: "loading",
      };
      this.state.dashboard = mydb;
      Vue.axios
        .get("https://robopower.xyz/us/cadbdata/getofficedata?officeId=" + id)
        .then((response) => {
          var crevenue = 0;
          var trevenue = 0;
          var today = moment();
          var data = response.data;
          var lastmonths = [[], [], []];
          var monthslist = moment.monthsShort();
          var currentDate = moment();
          var cals = [];
          if (data.calibrations != null) {
            data.calibrations.forEach((element) => {
              var caldatee = moment(JSON.parse(element).calibrationDate);
              var gapd = today.diff(caldatee, "days");
              var caldayss = parseInt(JSON.parse(element).calibrationperiod);
              if (gapd > caldayss - 7) {
                cals.push(JSON.parse(element));
              }
            });
          }
          if (data.sales != null) {
            data.sales.forEach((le) => {
              var str2 = JSON.parse(le);
              trevenue += parseFloat(str2.amountpaid);
              var invoicedate = moment(str2.date).month();
              var curmonth = today.month();
              if (curmonth == invoicedate) {
                crevenue += parseFloat(str2.amountpaid);
              }
            });
          }
          for (var i = 0; i < 6; i++) {
            var count = 0;
            var futureMonth = moment(currentDate).subtract(i, "M");
            lastmonths[0].push(
              monthslist[futureMonth.month()] + "-" + futureMonth.year()
            );
            lastmonths[1].push(0);
            if (data.sales != null) {
              data.sales.forEach((element) => {
                var str = JSON.parse(element);
                var currdate =
                  monthslist[futureMonth.month()] + "-" + futureMonth.year();
                var pastdate =
                  monthslist[moment(str.date).month()] +
                  "-" +
                  moment(str.date).year();

                if (currdate == pastdate) {
                  count += parseFloat(str.amountpaid);
                }
              });
              lastmonths[1][lastmonths[1].length - 1] = count;
              lastmonths[2].push(
                monthslist[futureMonth.month()] + " $" + count
              );
            }
          }
          var res = {
            totaldevices: data.totaldevices,
            availabledevices: data.availabledevices,
            currentrevenue: crevenue,
            totalrevenue: trevenue,
            newclients: data.newclients,
            totalclients: data.totalclients,
            newinstallations: data.newinstallations,
            removalthismonth: data.removalthismonth,
            calibrations: cals,
            saleslabels: lastmonths[2],
            salescount: lastmonths[1],
            officeId: data.officeId,
            officeName: data.officeName,
          };
          commit("FETCH_DASHBOARD", res);
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchdealerDashboard({ dispatch, commit }, id) {
      var myProm = new Promise((resolve, reject) => {
        Vue.axios
          .get(
            "https://robopower.xyz/us/cadbdata/getdealerdata?dealerName=" + id
          )
          .then((response) => {
            if (response.data) {
              var crevenue = 0;
              var trevenue = 0;
              var today = moment();
              var data = response.data;
              var lastmonths = [[], [], []];
              var monthslist = moment.monthsShort();
              var currentDate = moment();
              var cals = [];
              if (data.calibrations.length > 0) {
                // console.log(data.calibrations)
                const sortedData = data.calibrations.sort((a, b) => new Date(a.time) - new Date(b.time));

                // Get the last 5 objects
                const lastFive = sortedData.slice(-5);
                lastFive.forEach((element) => {
                  cals.push(JSON.parse(element));
                  // }
                });
              }
              if (data.sales.length > 0) {
                data.sales.forEach((le) => {
                  var str2 = JSON.parse(le);
                  trevenue += parseFloat(str2.amountpaid);
                  var invoicedate = moment(str2.date).month();
                  var curmonth = today.month();
                  if (curmonth == invoicedate) {
                    crevenue += parseFloat(str2.amountpaid);
                  }
                });
              }
              for (var i = 0; i < 6; i++) {
                var count = 0;
                var futureMonth = moment(currentDate).subtract(i, "M");
                lastmonths[0].push(
                  monthslist[futureMonth.month()] + "-" + futureMonth.year()
                );
                lastmonths[1].push(0);
                if (data.sales != null) {
                  data.sales.forEach((element) => {
                    var str = JSON.parse(element);
                    var currdate =
                      monthslist[futureMonth.month()] +
                      "-" +
                      futureMonth.year();
                    var pastdate =
                      monthslist[moment(str.date).month()] +
                      "-" +
                      moment(str.date).year();

                    if (currdate == pastdate) {
                      count += parseFloat(str.amountpaid);
                    }
                  });
                  lastmonths[1][lastmonths[1].length - 1] = count;
                  lastmonths[2].push(
                    monthslist[futureMonth.month()] + " $" + count
                  );
                }
              }
              var res = {
                totaldevices: data.totaldevices,
                availabledevices: data.availabledevices,
                currentrevenue: crevenue,
                totalrevenue: trevenue,
                newclients: data.newclients,
                totalclients: data.totalclients,
                newinstallations: data.newinstallations,
                removalthismonth: data.removalthismonth,
                calibrations: cals,
                saleslabels: lastmonths[2],
                salescount: lastmonths[1],
                officeId: data.officeId,
                officeName: data.officeName,
              };
              resolve({ data: res });
            }
          })
          .catch((err) => {
            dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                dispatch("alert/clear");
              }, 2000);
            });
          });
      });
      myProm.then((res) => {
        commit("FETCH_DASHBOARD", res.data);
      });
    },
    //school
    fetchschoolteachers({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/teachers/getallteachers?dealerId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_SCHOOLTEACHERS",response.data);
          } else {
            commit("FETCH_SCHOOLTEACHERS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchschoolsubjects({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/schoolsubjects/getschoolsubjects?dealerId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_SCHOOLSUBJECTS",JSON.parse(response.data.subjects));
          } else {
            commit("FETCH_SCHOOLSUBJECTS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchstudentClasses({ dispatch, commit }) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/students/getallclasses")
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_STUDENTSCLASSES", response.data);
          } else {
            commit("FETCH_STUDENTSCLASSES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchRoutestops({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/vehicleroutes/getroutestops2?routeId=" +
          id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_ROUTESTOPS", response.data);
          } else {
            commit("FETCH_ROUTESTOPS", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchRoutestopVehicles({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/us/fullclients/getrouteidfullclient?routeId=" +
          id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_ROUTESTOPSVEHICLES", response.data);
          } else {
            commit("FETCH_ROUTESTOPSVEHICLES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchStudentsActivites({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/schoolactivity/getschoolactivities?dealerId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            const allschoolactivaties = response.data.sort((a, b) => a.class - b.class)
            commit("FETCH_STUDENTSACTIVITES", allschoolactivaties);
          } else {
            commit("FETCH_STUDENTSACTIVITES", []);
          }
        })
        .catch((error) => {
          dispatch("alert/error", error);
        });
    },
    fetchschoolallclasses({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/schoolexam/getschoolexams?dealerId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_SCHOOLALLCLASSES",response.data);
          } else {
            commit("FETCH_SCHOOLALLCLASSES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchschoolStudents({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/students/getallstudents?dealerId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_SCHOOLSTUDENTS", response.data);
          } else {
            commit("FETCH_SCHOOLSTUDENTS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchschoolholidays({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/schoolholidays/getschoolholidays?dealerId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_SCHOOLHOLIDAYS", JSON.parse(response.data.holidays));
          } else {
            commit("FETCH_SCHOOLHOLIDAYS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchschoolExams({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/schoolexam/getschoolexams?dealerId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_SCHOOLEXAMS",response.data);
          } else {
            commit("FETCH_SCHOOLEXAMS", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchschoolFees({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/schoolfee/getschoolfees?dealerId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            commit("FETCH_SCHOOLFEES",response.data);
          } else {
            commit("FETCH_SCHOOLFEES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchschoolClasses({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/students/getallclasses?dealerId=" + id
        )
        .then((response) => {
          if (response.data != null) {
            const sortedClasses = response.data.sort((a, b) => a.id - b.id);
            commit("FETCH_SCHOOLCLASSES", sortedClasses);
          } else {
            commit("FETCH_SCHOOLCLASSES", []);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createTeacher({ dispatch, commit }, teacher) {
      Vue.axios
        .post("https://robopower.xyz/app/teachers/addteacher", teacher)
        .then((res) => {
          commit("CREATE_TEACHER", res.data[0]);
            dispatch("fetchschoolteachers", this.state.user.dealerId);
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editTeacher({ dispatch }, teacher) {
      Vue.axios
        .post(
          "https://robopower.xyz/app/teachers/editteacher/" + teacher.id,
          teacher.udata
        )
        .then((res) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
            dispatch("fetchschoolteachers", this.state.user.dealerId);
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    deleteTeacher({ dispatch }, id) {
      Vue.axios
        .get("https://robopower.xyz/app/teachers/deleteteacher/" + id)
        .then((response) => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
            dispatch("fetchschoolteachers", this.state.user.dealerId);
        })
        .catch((error) => {
          dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createStudents({ dispatch, commit }, client) {
      Vue.axios
        .post("https://robopower.xyz/app/students/addstudent", client)
        .then(() => {
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchallschoolStudents", this.state.user.dealerId);
        });
    },
    editStudents({ dispatch, commit }, student) {
      Vue.axios
        .post(
          "https://robopower.xyz/app/students/editstudent/" + student.id,
          student.devdata
        )
        .then(() => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchallschoolStudents", this.state.user.dealerId);
        });
    },
    DeleteStudent({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/app/students/deletestudent/" + id
        )
        .then(() => {
          dispatch("alert/success", "Delete Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
          dispatch("fetchallschoolStudents", this.state.user.dealerId);
        });
    },

    //paymentgateways

    fetchpaymentgateway({ dispatch, commit }, id) {
      Vue.axios
        .get(
          "https://robopower.xyz/v2/gateway/getdealergateways?dealerid=" + id
        ).then((response) => {
          if (response.data != null) {
            const obj = {
              "stripekeys":JSON.parse(response.data[0].stripe_details),
              "isLiveMode": response.data[0].mode == "TEST"?false:true,
              "gatewayid": response.data[0].id,
            }
            commit("FETCH_PAYMENTGATEWAY",obj);

          } else {
            const obj = {
              "stripekeys":"",
              "isLiveMode": "",
              "gatewayid": "",
            }
            commit("FETCH_PAYMENTGATEWAY", obj);
          }
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    createpaymentgateway({ dispatch, commit }, formdata ) {
      Vue.axios
        .post("https://robopower.xyz/v2/gateway/addgateway", formdata)
        .then((res) => {
          commit("CREATE_PAYMENTGATEWAY", res.data);
            dispatch("fetchpaymentgateway", this.state.user.dealerId);
          dispatch("alert/success", "Add Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    editpaymentgateway({ dispatch }, data) {
      Vue.axios
        .post(
          "https://robopower.xyz/v2/gateway/editgateway/" + data.id, data.formdata
          
        )
        .then((res) => {
          dispatch("alert/success", "Edit Success").then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
            dispatch("fetchpaymentgateway", this.state.user.dealerId);
        })
        .catch((err) => {
          dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              dispatch("alert/clear");
            }, 2000);
          });
        });
    },
  },
  modules: {
    alert,
    authentication,
  },
});