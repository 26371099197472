
<script>
import moment from "moment";
import Vue from "vue";
export default {
  data: () => ({
     
  }),

  computed: {

  },

  watch: {
    
  },

  created() {
  },
  mounted() {
    var stime = moment(new Date()).toISOString();
const parsedDate = moment(this.$route.query.createdDate, 'YYYY-MM-DD HH:mm:ss A [UTC]Z', 'Asia/Kolkata');
const cdate = parsedDate.toISOString();
    var obj={"fields":{}};
    obj.fields.amount = {"integerValue":parseFloat(this.$route.query.amount)};
    obj.fields.appointment = {"referenceValue":'projects/alcobrakeuser/databases/(default)/documents/appointments/'+this.$route.query.appointment};
    obj.fields.createdDate = {"timestampValue":cdate};
    obj.fields.customer = {"referenceValue":'projects/alcobrakeuser/databases/(default)/documents/customers/'+this.$route.query.customer};
    obj.fields.status = {"stringValue":"success"};
    obj.fields.transactionDate={"timestampValue":stime};
    obj.fields.user = {"referenceValue":'projects/alcobrakeuser/databases/(default)/documents/users/'+this.$route.query.user};
    Vue.axios.patch("https://firestore.googleapis.com/v1/projects/alcobrakeuser/databases/(default)/documents/payments/"+this.$route.query.docid,obj).then((res2)=>{
        console.log(res2)
    })      
  },

  methods: {
  }

};
</script>
<template>
  <v-container fluid>
  <v-card
    class="mx-auto"
    max-width="380"
    outlined
    style="margin-top:240px;background-color:white"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          <v-img  src="../../src/assets/successicon.png" width="100" height="100"/>
          <b>Success</b>
        </div>
        <v-list-item-subtitle><b>Your Last Transaction Success</b></v-list-item-subtitle>
        <v-list-item-subtitle>Payment ID :<b>{{this.$route.query.payment_intent}}</b></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
  </v-container>
</template>
