<script>
import {mapGetters} from "vuex";
import Vue from "vue";
import axios from "axios";
  export default {
    data() {
      return {
        user: JSON.parse(localStorage.getItem("user")),
        //dealersearch:null,
        search:null,
            gpsHeaders: [
        { text: 'Registration Number', value: 'regnum' },
        { text: 'Client', value: 'client' },
        { text: 'No GPS Updates From', value: 'No Gps Updates From days' },
      ],
      eventsHeaders: [
        { text: 'Registration Number', value: 'regnum' },
        { text: 'Client', value: 'client' },
        { text: 'No Events Updates From', value: 'No Events Updates From days' },
      ],
      selecteddealer:null,
      gpsVehicles: [],
      eventVehicles: [],
        logg:{
          testattempts:0,
          passevents:0,
          failevents:0,
          insufficientvolumes:0
        },
        tab: null,
        officeInfo: null,
        officeInfo2: null,
        officeInfo3: null,
        vehicleInfo:null,
        // devices: [],
        total_clients: '',
        totalDevices: '',
        availableDevices: '', 
        devicesWentToService: '',
        installedDevices: '',
        active_tab:0,
        items: [
          '', '', 'DEVICE REPORTS'
        ],
      officeRules: [
      v => !!v || 'Select office',
      ],
      }
    },
    watch:{
      active_tab(val){
        /* eslint-disable no-console */
        if(val ==0){
          this.$store.state.clientcount={}
         this.$store.state.devicecount={}     
        this.officeInfo2= null;
        this.officeInfo3=null;
        } else if(val ==1){
          this.$store.state.totalrevenue={}
         this.$store.state.devicecount={}  
         this.officeInfo= null;
        this.officeInfo3=null;
        } else if(val==2){
          this.$store.state.totalrevenue={}
          this.$store.state.clientcount={}
          this.officeInfo= null;
        this.officeInfo2= null;
        } else{
          this.$store.state.totalrevenue={}
          this.$store.state.clientcount={}
          this.$store.state.devicecount={}        
        }
      }
    },
    computed: {
       GpsResultQuery() {
      if (this.selecteddealer) {
        return this.gpsVehiclesConverted.filter((item) => {
          return item.dealer == this.selecteddealer;
        });
      } else {
        return this.gpsVehiclesConverted;
      }
    
    },
    EventsResultQuery() {
//      console.log(this.eventVehiclesConverted)
      if (this.selecteddealer) {
//        console.log(this.eventVehiclesConverted)
        return this.eventVehiclesConverted.filter((item) => {
          return item.dealer == this.selecteddealer;
        });
      } else {
        return this.eventVehiclesConverted;
      }
    
    },
      gpsVehiclesConverted() {
      return this.gpsVehicles.map(vehicle => ({
        ...vehicle,
        'No Gps Updates From days': this.convertToDaysAndHours(vehicle['regnum'],vehicle['No Gps Updates From days']),
      }));
    },
    eventVehiclesConverted() {
      return this.eventVehicles.map(vehicle => ({
        ...vehicle,
        'No Events Updates From days': this.convertToDaysAndHours(vehicle['regnum'],vehicle['No Events Updates From days']),
      }));
    },
      ...mapGetters(['installations']),
    ...mapGetters(['clients']),
    ...mapGetters(['fullclients']),
    ...mapGetters(['clientdevices']),
    ...mapGetters(['devices']),
    ...mapGetters(['offices']),
    ...mapGetters(['officeinvoices']),
    ...mapGetters(['totalrevenue']),
    ...mapGetters(['clientcount']),
    ...mapGetters(['devicecount']),
    ...mapGetters(['dealers']),
    },
    beforeDestroy(){
    this.$store.state.totalrevenue={}
    this.$store.state.clientcount={}
    this.$store.state.devicecount={}  
    },
    mounted() {
      this.mounteddata();
      this.fetchData();
    },
    methods: {
      Querydata(){
        console.log(this.selecteddealer)
      },
         async fetchData() {
      try {
        const response = await axios.get('https://robopower.xyz/app/v2data/checkupdates');
        const data = response.data;
        this.gpsVehicles = data['No Gps Vehicles'];
        this.eventVehicles = data['No Events Vehicles'];
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    convertToDaysAndHours(regnum,hours) {
      
      const totalHours = parseFloat(hours);
      const days = Math.floor(totalHours / 24);
      const remainingHours = Math.floor(totalHours % 24);
      return `${days} day(s) and ${remainingHours} hour(s)`;
    },
      mounteddata(){
        
        if(this.user.role=="Admin"|| this.user.role == 'superadmin'){
          this.$store.dispatch('fetchDealers');
     this.$store.dispatch('fetchfullClients')
    this.$store.dispatch('fetchOffices')
    this.$store.dispatch('fetchDevices')   
      } else{
     this.$store.dispatch('fetchdealerfullClients',this.user.dealerId)
    this.$store.dispatch('fetchdealerOffices',this.user.dealerId)
    this.$store.dispatch('fetchdealerDevices',this.user.dealerId)
      }
      },
      getSales() {
        /* eslint-disable no-console */
        if(this.officeInfo != null){
        this.$store.dispatch('fetchofficeInvoices',this.officeInfo)
        }
      },
      getEvents(){
// console.log(this.vehicleInfo);
this.logg={
          testattempts:"Loading...",
          passevents:"Loading...",
          failevents:"Loading...",
          insufficientvolumes:"Loading..."
        },
Vue.axios
        .get(
          "https://robopower.xyz/us/cadevices/getuniquedevice?sno=" +
            this.vehicleInfo
        )
        .then((res) => {
          // console.log(res.data)
          if(res.data != null){
            var devInf = res.data[0];
            Vue.axios
        .get(
          "https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + devInf.cbid
        )
        .then((response) => {
          if (response.data != null) {
            // console.log("cbid based")
            var remData = response.data;
            // console.log(remData);
            this.logg.passevents = remData.filter(
        (item) => item.passfailstatus === "1" && item.typeofevent=="254"
      ).length;
      this.logg.failevents = remData.filter(
        (item) => item.passfailstatus === "0" && item.typeofevent=="254"
      ).length;
      this.logg.testattempts = remData.filter(
        (item) => item.typeofevent === "65" || item.typeofevent=="254"
      ).length;
      this.logg.insufficientvolumes = remData.filter(
        (item) => item.typeofevent === "65"
      ).length;


          } else{
            this.logg={
          testattempts:0,
          passevents:0,
          failevents:0,
          insufficientvolumes:0
        }
      }
        })

          }

        })
      },
    getDevicesInfo() {
      if(this.officeInfo3 != null){
      this.$store.dispatch('fetchofficeDevices',this.officeInfo3)
      }
    },
    getClients() {
      if(this.officeInfo2 != null){
      this.$store.dispatch('fetchfullofficeClients',this.officeInfo2)
      }
    }
     
    }
  }
</script>

<template>
<v-row class="justify-center mt-10">
    <v-col class="col-md-6">
      
      <v-tabs 
       background-color="#00A3A3" color="white" 
      v-model="active_tab" 
      dark centered style="border-radius:20px">
      <v-tabs-slider color="black"></v-tabs-slider>
      <v-tab style="font-weight:bold">
        <v-icon left>mdi-sale</v-icon>
        SALES REPORT
      </v-tab>
      <v-tab style="font-weight:bold">
        <v-icon left>mdi-account-group</v-icon>
        CLIENTS REPORT
      </v-tab>
      <v-tab style="font-weight:bold">
        <v-icon left>mdi-cellphone-link</v-icon>
        DEVICE REPORT
      </v-tab>
      <v-tab style="font-weight:bold">
        <v-icon left>mdi-cellphone-link</v-icon>
        EVENTS REPORT
      </v-tab>
      <v-tab v-if="this.user.role=='Admin'|| this.user.role == 'superadmin'" style="font-weight:bold">
        <v-icon left>mdi-cellphone-link</v-icon>
        BUG REPORT
      </v-tab>

      <v-tab-item>
        <v-row class="justify-center mt-5">
          <v-col class="col-md-10">
            <v-card flat color="#334c3e" style="border-radius:20px">
          <v-card-text>
            <v-col cols="12" sm="12" md="12">
              <div style="background:white;padding:25px 8px 8px 5px;border-radius:10px;border:2px solid #00A3A3">
                 <v-autocomplete
                            dense outlined
                            required
                            :rules="officeRules"
                            v-model="officeInfo"
                            @change="getSales"
                            :items="offices"
                            label="Select Office*"
                            item-text="officeName"
                            item-value="officeId" 
                          ></v-autocomplete>
              </div>
                         
                        </v-col>
            <p class="p-text">
              Total Revenue <span class="p-number">${{totalrevenue.totalrev}}</span>
            </p>

            <p class="p-text">Revenue from past 30days <span class="p-number">${{totalrevenue.lastrev}}</span>
            </p>
          </v-card-text>
          </v-card>
          </v-col>
        </v-row>
        
      </v-tab-item>
      <v-tab-item>
        <v-row class="justify-center mt-5">
          <v-col class="col-md-10">
             <v-card flat color="#334c3e" style="border-radius:20px">
          <v-card-text>
            <v-col cols="12" sm="12" md="12">
              <div style="background:white;padding:25px 8px 8px 5px;border-radius:10px;border:2px solid #00A3A3">
                 <v-autocomplete
                            dense outlined
                            required
                            :rules="officeRules"
                            v-model="officeInfo2"
                            :items="offices"
                            @change="getClients"
                            label="Select Office*"
                            item-text="officeName"
                            item-value="officeId"
                          ></v-autocomplete>
              </div>
                         
                        </v-col>
            <p class="p-text">Total Clients <span class="p-number">{{clientcount.allclients}}</span> 
            </p>

            <p class="p-text">New clients from past 30days <span class="p-number">{{clientcount.latestclients}}</span> 
            </p>
          </v-card-text>
        </v-card>
          </v-col>
        </v-row>
       
      </v-tab-item>
      <v-tab-item>
        <v-row class="justify-center mt-5">
          <v-col class="col-md-10">
                 <v-card flat color="#334c3e" style="border-radius:20px">
          <v-card-text>
            <v-col cols="12" sm="12" md="12">
              <div style="background:white;padding:25px 8px 8px 5px;border-radius:10px;border:2px solid #00A3A3">
                <v-autocomplete
                            dense outlined
                            required
                            :rules="officeRules"
                            v-model="officeInfo3"
                            :items="offices"
                            @change="getDevicesInfo"
                            label="Select Office*"
                            item-text="officeName"
                            item-value="officeId"
                          ></v-autocomplete>
              </div>
                          
                        </v-col>
             <p class="p-text">Total devices <span class="p-number">{{devicecount.alldevices}}</span>
            </p>

            <p class="p-text">Available devices <span class="p-number">{{devicecount.availabledevices}}</span>
            </p>

            <p class="p-text">Devices went to service <span class="p-number">{{devicecount.servicedevices}}</span> 
            </p>

            <p class="p-text">Installed devices <span class="p-number">{{devicecount.installeddevices}}</span>
            </p>
          </v-card-text>
        </v-card>
          </v-col>
        </v-row>
   
      </v-tab-item>
      <v-tab-item>
        <v-row class="justify-center mt-5">
          <v-col class="col-md-10">
            <v-card flat color="#334c3e" style="border-radius:20px">
          <v-card-text>
            <v-col cols="12" sm="12" md="12">
              <div style="background:white;padding:25px 8px 8px 5px;border-radius:10px;border:2px solid #00A3A3">
                 <v-autocomplete
                            dense outlined
                            required
                            :rules="officeRules"
                            v-model="vehicleInfo"
                            @change="getEvents"
                            :items="fullclients"
                            label="Select Vehicle*"
                            item-text="sno"
                            item-value="sno" 
                          ></v-autocomplete>
              </div>
                         
                        </v-col>
                        <p class="p-text">
              TOTAL TESTS <span class="p-number">${{logg.testattempts}}</span>
            </p>
            <p class="p-text">
              PASS TESTS <span class="p-number">${{logg.passevents}}</span>
            </p>

            <p class="p-text">FAIL TESTS<span class="p-number">${{logg.failevents}}</span>
            </p>
            <p class="p-text">INSUFFICIENT VOLUMES<span class="p-number">${{logg.insufficientvolumes}}</span>
            </p>
          </v-card-text>
          </v-card>
          </v-col>
        </v-row>
        
      </v-tab-item>
      <v-tab-item>
       
<v-toolbar flat color="#00A3A3" dark dense class="hidden-sm-and-down mb-4" style="border-radius:20px">
      <v-toolbar-title>DEVICE UPDATES</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-autocomplete
                            dense outlined
                            required
                            v-model="selecteddealer"
                            :items="dealers"
                            label="Select Dealer*"
                            item-text="dealerName"
                            item-value="dealerId" 
                          ></v-autocomplete>
      <template v-slot:extension>
        <v-tabs v-model="tab"  centered>
          <v-tabs-slider color="black"></v-tabs-slider>

          <v-tab style="font-weight:bold">
           NO GPS UPDATES
          </v-tab>
           <v-tab style="font-weight:bold">
           NO EVENT UPDATES
          </v-tab>                 
        </v-tabs>
      </template>
    </v-toolbar>

    <!-- Toolbar for smaller screens -->
    <v-toolbar flat color="#00A3A3" dark dense class="hidden-md-and-up mb-4" style="border-radius:20px">
      <v-toolbar-title style="white-space: nowrap; width: auto;">DEVICE UPDATES</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field 
        v-model="search" 
        append-icon="mdi-magnify" 
        label="Search" 
        single-line 
        hide-details 
        style="max-width: 150px;">
      </v-text-field>
      <v-spacer></v-spacer>
      <v-tabs v-model="tab" centered>
        <v-tabs-slider color="black"></v-tabs-slider>
        <v-tab style="font-weight:bold">NO GPS UPDATES</v-tab>
        <v-tab style="font-weight:bold">NO EVENT UPDATES</v-tab>
      </v-tabs>
    </v-toolbar>

    <!-- Tab content -->
    <v-tabs-items v-model="tab">
      <!-- Form 920: No GPS Vehicles Table -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-data-table :headers="gpsHeaders" :items="GpsResultQuery" class="elevation-1">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.regnum }}</td>
                  <td>{{ item.client }}</td>
                  <td>{{ item['No Gps Updates From days'] }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Form 922: No Events Vehicles Table -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-data-table :headers="eventsHeaders" :items="EventsResultQuery" class="elevation-1">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.regnum }}</td>
                  <td>{{ item.client }}</td>
                  <td>{{ item['No Events Updates From days'] }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
      </v-tab-item>
    </v-tabs>

    </v-col>
</v-row>
 
</template>

<style scoped>
.p-text{
  color:white;
  text-align: center;
  margin-top:10px;
}
.p-number
{
  background:#00A3A3;padding:5px;border-radius:20px;margin-left:10px
}
template
{
  background: white !important;
}
</style>