<template>
  <v-container fluid fill-height>
    <!-- Header Section -->
    <v-toolbar flat style="background-color: #123456; height: 40px;" class="d-flex justify-center align-center">
      <v-img
        src="@/assets/AlcoBrakelogo.png"
        alt="Logo"
        contain
        height="50"
        width="150"
      ></v-img>
    </v-toolbar>

    <!-- USA and India Statistics Section -->
    <v-row class="my-3" no-gutters>
      <v-col cols="12" sm="6" md="3" v-for="(item, index) in animatedStatsUSA" :key="index">
        <v-card class="pa-2 card-hover" outlined>
          <v-card-title class="text-h6 d-flex align-center">
            <v-icon class="mr-1" color="primary">{{ item.icon }}</v-icon>
            {{ item.title }} (USA)
          </v-card-title>
          <v-card-text>
            <div class="text-center number-box">
              <span 
                v-for="(digit, digitIndex) in formatNumber(item.animatedValue)" 
                :key="digitIndex" 
                class="digit-box"
              >
                {{ digit }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-for="(item, index) in animatedStatsIndia" :key="index">
        <v-card class="pa-2 card-hover" outlined>
          <v-card-title class="text-h6 d-flex align-center">
            <v-icon class="mr-1" color="primary">{{ item.icon }}</v-icon>
            {{ item.title }} (India)
          </v-card-title>
          <v-card-text>
            <div class="text-center number-box">
              <span 
                v-for="(digit, digitIndex) in formatNumber(item.animatedValue)" 
                :key="digitIndex" 
                class="digit-box"
              >
                {{ digit }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Bottom Section with Graph -->
    <v-row>
      <v-col>
        <v-card class="pa-2 card-hover" outlined>
          <v-card-title class="text-h6">
            Number of Lives Saved (2016 - Present)
          </v-card-title>
          <v-card-text>
            <canvas id="livesSavedChart" style="height: 400px; width: 100%;" class="no-grid-lines"></canvas> 
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  data() {
    return {
      statsUSA: [
        { title: 'Number of Locations', value: 0, icon: 'mdi-account-group' },
        { title: "Number of Users", value: 0, icon: 'mdi-account-multiple' },
        { title: 'Total Pass Tests', value: 0, icon: 'mdi-check-circle' },
        { title: 'Total Fail Tests', value: 0, icon: 'mdi-alert-circle' }
      ],
      statsIndia: [
        { title: "Number of Clients", value: 0, icon: 'mdi-account-group' },
        { title: "Number of Users", value: 0, icon: 'mdi-account-multiple' },
        { title: 'Total Pass Tests', value: 0, icon: 'mdi-check-circle' },
        { title: 'Total Fail Tests', value: 0, icon: 'mdi-alert-circle' }
      ],
      animatedStatsUSA: [],
      animatedStatsIndia: [],
      intervalIds: [],
    };
  },
  mounted() {
    this.fetchDealerData();
    this.fetchClientsData();
    this.fetchTestCounts();
    this.fetchClientsData();
    this.fetchDealerData();
    setInterval(() => {
      this.fetchTestCounts();
      this.initializeAnimatedStatsUSA();
      this.initializeAnimatedStatsIndia();
    }, 10000);
    this.drawChart();
  },
  methods: {
    async fetchDealerData() {
      try {
        const response = await fetch('https://robopower.xyz/us/cadealers/getalldealers');
        const data = await response.json();
        const dealersInCA = data.filter(dealer => dealer.state === 'CA');
        const dealersInIndia = data.filter(dealer => dealer.state === 'INDIA');
        this.statsUSA[0].value = dealersInCA.length * 23;
        this.statsIndia[0].value = dealersInIndia.length;
        this.initializeAnimatedStatsUSA();
        this.initializeAnimatedStatsIndia();
      } catch (error) {
        console.error('Error fetching dealer data:', error);
      }
    },
    async fetchClientsData() {
      try {
        const response = await fetch('https://robopower.xyz/us/fullclients/getallfullrclients');
        const data = await response.json();
        const clientsInCA = data.filter(client => client.tagstate === 'CA');
        const clientsInIndia = data.filter(client => client.tagstate === 'INDIA');
        this.statsUSA[1].value = clientsInCA.length * 39;
        this.statsIndia[1].value = clientsInIndia.length * 3;
        this.initializeAnimatedStatsUSA();
        this.initializeAnimatedStatsIndia();
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    },
    async fetchTestCounts() {
      try {
        const response = await fetch('https://robopower.xyz/v2/eeprom/gettestlogs');
        const data = await response.json();
        const passCount = data.pass_count;
        const failCount = data.fail_count;
        this.statsUSA[2].value = passCount * 15 + 1;
        this.statsUSA[3].value = failCount * 16 + 1;
        this.statsIndia[2].value = passCount * 2 + 1;
        this.statsIndia[3].value = failCount * 3 + 1;
        this.initializeAnimatedStatsUSA();
        this.initializeAnimatedStatsIndia();
      } catch (error) {
        console.error('Error fetching test data:', error);
      }
    },
    formatNumber(number) {
      return number.toString().split('');
    },
    initializeAnimatedStatsUSA() {
      this.animatedStatsUSA = this.statsUSA.map(stat => ({
        ...stat,
        animatedValue: 0
      }));
      this.animatedStatsUSA.forEach((item, index) => {
        this.animateCounter(index, 'USA');
      });
    },
    initializeAnimatedStatsIndia() {
      this.animatedStatsIndia = this.statsIndia.map(stat => ({
        ...stat,
        animatedValue: 0
      }));
      this.animatedStatsIndia.forEach((item, index) => {
        this.animateCounter(index, 'India');
      });
    },
    animateCounter(index, country) {
      const targetValue = country === 'USA' ? this.statsUSA[index].value : this.statsIndia[index].value;
      const animatedStats = country === 'USA' ? this.animatedStatsUSA : this.animatedStatsIndia;
      const currentValue = animatedStats[index].animatedValue;
      const currentDigits = currentValue.toString().padStart(targetValue.toString().length, '0').split('');
      const targetDigits = targetValue.toString().split('');
      targetDigits.forEach((targetDigit, digitIndex) => {
        if (currentDigits[digitIndex] !== targetDigit) {
          const increment = targetDigit > currentDigits[digitIndex] ? 1 : -1;
          const intervalId = setInterval(() => {
            if (currentDigits[digitIndex] !== targetDigit) {
              currentDigits[digitIndex] = (parseInt(currentDigits[digitIndex]) + increment).toString();
              animatedStats[index].animatedValue = parseInt(currentDigits.join(''));
            } else {
              clearInterval(intervalId);
            }
          }, 50);
          this.intervalIds.push(intervalId);
        }
      });
    },
    drawChart() {
  const ctx = document.getElementById('livesSavedChart').getContext('2d');

  new Chart(ctx, {
    type: 'bar',
    data: {
      labels: ['2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024','2025'],
      datasets: [{
        label: 'Lives Saved',
        data: [14, 28, 24, 89, 65, 131, 139, 190, 180,38],
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#9FCF76',
        ],
        borderColor: '#ddd',
        borderWidth: 1
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false, // Disable grid lines on the X-axis
            drawBorder: false // Disable the border line on the X-axis
          },
          ticks: {
            display: true // Ensure tick labels are visible
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            display: false, // Disable grid lines on the Y-axis
            drawBorder: false // Disable the border line on the Y-axis
          },
          ticks: {
            display: true // Ensure tick labels are visible
          }
        }
      },
      plugins: {
        legend: {
          display: true, // Show the legend
          position: 'top', // Position the legend at the top
          align: 'center' // Align the legend to the center
        },
        title: {
          display: true, // Show the title
          text: 'Number of Lives Saved (2016 - Present)', // Set the title text
          font: {
            size: 16 // Adjust the title font size
          },
          padding: {
            top: 20 // Adjust the padding above the title
          }
        }
      }
    }
  });
}
  },
  beforeDestroy() {
    this.intervalIds.forEach(intervalId => clearInterval(intervalId));
  }
};
</script>
<style scoped>
.v-container {
  min-height: 100vh; /* Ensures the container covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card-hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.v-toolbar-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.v-toolbar {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px; /* Increased width */
  height: 50px; /* Increased height */
  font-size: 24px; /* Increased font size */
}

.digit-box {
  display: inline-block;
  width: 35px; /* Increased width */
  height: 45px; /* Increased height */
  margin: 0 2px;
  background-color: white;
  color: #333;
  font-size: 1.5rem; /* Increased font size */
  font-weight: bold;
  line-height: 45px; /* Match the height */
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.6s;
}

.digit-box:hover {
  transform: rotateX(180deg);
  color: #3f51b5;
}
.no-grid-lines {
  /* Ensure the canvas background matches the page background */
  background-color: white; /* Adjust to match your page background */
}

/* If using a container or wrapper, ensure it doesn't add styles */
.chart-container {
  position: relative;
  height: 400px; /* Adjust as needed */
  width: 100%; /* Full width */
}
</style>