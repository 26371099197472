<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    show: false,
    password: "Password",
    access: [{ text: "Manager" }, { text: "Technician" }],
    headers: [
      {
        text: "Name",
        align: "left",
        value: "TeacherName",
      },
      { text: "Mobile", value: "Mobile" },
      { text: "Email", value: "Email" },
      { text: "Password", value: "password" },
      { text: "Class", value: "class" },
      { text: "Grade", value: "Grade" },
      { text: "Subjects", value: "teach_subjects" },
      { text: "Actions", value: "action", sortable: false },
      { text: "Delete", value: "delete", sortable: false },
      { text: "More..", value: "data-table-expand" },
    ],
    editedIndex: -1,
    countrycode: "us",
    grade: [
      { id: "1", grade: "A" },
      { id: "2", grade: "B" },
      { id: "3", grade: "C" },
    ],
    editedItem: {
      id: "",
      teachername: "",
      mobile: "",
      email: "",
      password: "",
      class: "",
      grade: "",
      sdealer: "",
      teach_subjects: [],
      userhome: "",
      selectedSubjects: [],
      is_active: true,
    },
    defaultItem: {
      id: "",
      teachername: "",
      mobile: "",
      email: "",
      password: "",
      class: "",
      grade: "",
      sdealer: "",
      teach_subjects: [],
      userhome: "",
      selectedSubjects: [],
    },
    valid: true,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Name must contain 3 or more characters",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
    ecodeRules: [(v) => !!v || "Select Country Code"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
        "E-mail must be valid",
    ],
    dealerRules: [(v) => !!v || "Select Dealer"],
    accessRules: [(v) => !!v || "Select access level"],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length >= 8) || "Password must contain 8 or more charactors",
    ],
    phone2Rules: [
      (v) => !!v || "Phone2 is required",
      (v) =>
        (v && v.length > 10 && v.length < 15) || "Enter valid phone number",
    ],
    phoneRules: [
      (v) => !!v || "Phone is required",
      (v) =>
        (v && v.length > 10 && v.length < 15) || "Enter valid phone number",
    ],
    phoneRuless: [
      (v) => !!v || "Phone is required",
      (v) => (v && v.length > 9 && v.length < 11) || "Enter valid phone number",
    ],
    phone2Ruless: [
      (v) => !!v || "Phone2 is required",
      (v) => (v && v.length > 9 && v.length < 11) || "Enter valid phone number",
    ],
  }),

  computed: {
    Reminder() {
      if (this.expanded.length > 0) {
        return this.expanded;
      } else {
        return this.schoolteachers;
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Teachers" : "Edit Teachers";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(["schoolteachers"]),
    ...mapGetters(["schoolsubjects"]),
    ...mapGetters(["studentsClasses"]),
    ...mapGetters(["dealers"]),
  },

  watch: {
    countrycode(val) {
      this.countrycode = val;
      setTimeout(() => {
        this.countrycode = "us";
      }, 500);
    },
    dialog(val) {
      val || this.close();
    },
    selectedSubjects(val) {
      this.selectedSubjects = val;
    },
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.$store.dispatch("fetchschoolteachers", this.user.dealerId);
    this.$store.dispatch("fetchschoolsubjects", this.user.dealerId);
    this.$store.dispatch("fetchstudentClasses");
  },

  methods: {
    getTeacherSubjects(teach_subjects) {
      return this.schoolsubjects
        .filter((subject) => teach_subjects.includes(subject.subject_code))
        .map((subject) => subject.subject_name)
        .join(", ");
    },
    isNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      else e.preventDefault();
    },
    deleteTeacher(teach) {
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to delete Teacher - " + teach.TeacherName,
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes Delete it!",
          cancelButtonText: "No, Keep it!",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        })
          .then((result) => {
            if (result.value) {
              this.$store.dispatch('deleteTeacher', teach.id)
              this.$store
                .dispatch("alert/success", "You successfully deleted this file")
                .then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
            } else {
              this.$store
                .dispatch("alert/error", "Your file is still intact")
                .then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    loadFormData() {
      const formdata = new FormData();
      formdata.append("teachername", this.editedItem.teachername);
      formdata.append("mobile", this.editedItem.mobile);
      formdata.append("email", this.editedItem.email);
      formdata.append("password", this.editedItem.password);
      formdata.append("class", this.editedItem.class.class);
      formdata.append("grade", this.editedItem.grade);
      formdata.append("dealer", this.user.dealerId);
      formdata.append("teach_subjects",JSON.stringify(this.editedItem.selectedSubjects));
      return formdata;
    },
    editloadFormData() {
         const formdata = new FormData();
      formdata.append("teachername", this.editedItem.teachername);
      formdata.append("mobile", this.editedItem.mobile);
      formdata.append("email", this.editedItem.email);
      formdata.append("password", this.editedItem.password);
      formdata.append("class", this.editedItem.class.class);
      formdata.append("grade", this.editedItem.grade);
      formdata.append("dealer", this.user.dealerId);
      formdata.append(
        "teach_subjects",
        JSON.stringify(this.editedItem.selectedSubjects)
      );
      return formdata;
    },
    initialize() {},
    onSelect({ dialCode }) {
      // console.log(dialCode)
      this.editedItem.userhome = dialCode;
    },
    editItem(item) {
      if (this.user.emailId != "Justin@alcobrake.com") {
        this.editedIndex = 1;
        this.editedItem.id = item.id;
        this.editedItem.teachername = item.TeacherName;
        this.editedItem.mobile = item.Mobile;
        this.editedItem.email = item.Email;
        this.editedItem.password = item.password;
        this.editedItem.class = item.class;
        this.editedItem.grade = item.Grade;
        this.editedItem.selectedSubjects = JSON.parse(item.teach_subjects);
        this.editedItem.sdealer = item.dealer;
        this.editedItem.is_active = item;
        this.dialog = true;
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },

    deleteItem() {},

    close() {
      this.dialog = false;
      this.countrycode = "US";
      this.$refs.form.reset();
      this.editedIndex = -1;
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.editedItem.mobile == undefined) this.editedItem.mobile = "";
      const fd = this.loadFormData();
      if (this.editedIndex == -1) {
        this.$store.dispatch("createTeacher", fd);
      } else {
        const editfd = this.editloadFormData();
        var postdata = {
          udata: editfd,
          id: this.editedItem.id,
        };
        this.$store.dispatch("editTeacher", postdata);
      }
      this.close();
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius: 20px">
      <v-data-table
        dense
        :headers="headers"
        :items="Reminder"
        item-key="id"
        sort-by="TeacherName"
        :search="search"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-card color="grey lighten-4" text style="border-radius: 20px">
            <v-toolbar
              text
              color="#00A3A3"
              dark
              dense
              style="border-radius: 20px"
            >
              <span v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                <v-toolbar-title style="font-size: 22px"
                  >TEACHERS</v-toolbar-title
                >
              </span>
              <span v-else>
                <v-toolbar-title style="font-size: 15px"
                  >TEACHERS</v-toolbar-title
                >
              </span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-row>
                <v-col cols="12" lg="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-dialog v-model="dialog" max-width="600px">
                <template v-slot:activator="{ on }">
                  <v-row>
                    <v-col cols="12">
                      <v-layout justify-end>
                        <v-btn
                          v-if="user.emailId != 'Justin@alcobrake.com'"
                          v-on="on"
                          style="border-radius: 20px; background: black"
                        >
                          <h2>+</h2>
                          <v-icon size="20px" color="dark"
                            >mdi-teach</v-icon
                          ></v-btn
                        >
                      </v-layout>
                    </v-col>
                  </v-row>
                </template>

                <v-card style="border-radius: 20px">
                  <v-toolbar
                    text
                    color="#00A3A3"
                    class=""
                    dark
                    dense
                    style="border-radius: 20px"
                  >
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-form
                    autocomplete="off"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              outlined
                              required
                              v-on:keypress="isLetter($event)"
                              :rules="nameRules"
                              v-model="editedItem.teachername"
                              label="Name*"
                            ></v-text-field>
                          </v-col>
                          <div class="left-btn" v-if="editedIndex == -1">
                            <vue-country-code
                              @onSelect="onSelect"
                              :enabledCountryCode="true"
                              :defaultCountry="countrycode"
                              style="border: none"
                              :preferredCountries="['in', 'us', 'gb']"
                            >
                              <input
                                type="number"
                                v-model="editedItem.userhome"
                                name="phone"
                                id="phone"
                                placeholder="phone number"
                              />
                            </vue-country-code>
                          </div>

                          <div class="left-btn" v-if="editedIndex == -1">
                            <v-text-field
                              dense
                              outlined
                              required
                              :rules="phone2Ruless"
                              v-on:keypress="isNumber($event)"
                              v-model="editedItem.mobile"
                              label="Phone"
                              class="textfeild"
                            ></v-text-field>
                          </div>
                          <v-col cols="12" sm="12" md="6" v-else>
                            <v-text-field
                              dense
                              outlined
                              required
                              :rules="phone2Rules"
                              v-on:keypress="isNumber($event)"
                              v-model="editedItem.mobile"
                              label="Phone 2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              outlined
                              required
                              :rules="emailRules"
                              v-model="editedItem.email"
                              label="Email*"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              outlined
                              v-model="editedItem.password"
                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              required
                              :rules="passwordRules"
                              :type="show ? 'text' : 'password'"
                              name="password"
                              label="Password*"
                              counter
                              @click:append="show = !show"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              label="Select Grade*"
                              :rules="dealerRules"
                              required
                              outlined
                              class="purple-input"
                              v-on:keypress="isLetter($event)"
                              v-model="editedItem.grade"
                              :items="grade"
                              item-text="grade"
                              item-value="grade"
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.grade }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              label="Select Class*"
                              :rules="dealerRules"
                              required
                              outlined
                              class="purple-input"
                              v-on:keypress="isLetter($event)"
                              v-model="editedItem.class"
                              :items="studentsClasses"
                              item-text="class"
                              item-value="class"
                              return-object
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.class }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <!-- <div class="left-btn"> -->
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              label="Select Subjects*"
                              :rules="dealerRules"
                              required
                              outlined
                              multiple
                              class="purple-input"
                              v-on:keypress="isLetter($event)"
                              v-model="editedItem.selectedSubjects"
                              :items="schoolsubjects"
                              item-text="subject_name"
                              item-value="subject_code"
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.subject_name }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <!-- </div> -->
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="close"
                      style="background: #ff3d41; color: white"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!valid"
                      @click="save"
                      >{{ buttonTitle }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </v-card>
        </template>
        <template slot="item.teach_subjects" slot-scope="{ item }">
          <p>{{ getTeacherSubjects(item.teach_subjects) }}</p>
        </template>
        <template slot="item.action" slot-scope="{ item }">
          <v-icon class="mr-2 mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
          <!-- <v-icon color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
        <template slot="item.delete" slot-scope="{ item }">
          <v-icon
            class="mr-2 mt-2"
            larger
            color="red"
            @click="deleteTeacher(item)"
          >
            mdi-delete-circle
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="ma-0 pa-0" align="center">
            <v-col class="col-md-6">
              <v-card
                class="mt-2 mb-2"
                color="#334c3e"
                style="border-radius: 20px; padding: 10px"
              >
                <v-list dense class="ma-0 pa-0" style="background: #334c3e">
                  <v-list-item>
                    <v-icon class="off-icon">mdi-clipboard-account</v-icon>
                    <v-list-item-content class="content1"
                      >Name:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.TeacherName
                    }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-cellphone-iphone</v-icon>
                    <v-list-item-content class="content1"
                      >Mobile:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.Mobile
                    }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-email-outline</v-icon>
                    <v-list-item-content class="content1"
                      >Email:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.Email
                    }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-webcam</v-icon>
                    <v-list-item-content class="content1"
                      >Grade:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.Grade
                    }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-office-building</v-icon>
                    <v-list-item-content class="content1"
                      >Class:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.class
                    }}</v-list-item-content> </v-list-item
                  ><v-list-item>
                    <v-icon class="off-icon">mdi-account-edit</v-icon>
                    <v-list-item-content class="content1"
                      >Teach_Subjects:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      getTeacherSubjects(item.teach_subjects)
                    }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2 {
  color: #0ae2e2 !important;
  border-bottom: 1px dashed white;
}
.content1 {
  color: white !important;
  margin-left: 8px;
}
.off-icon {
  color: white;
  background: #00a3a3;
  padding: 2px;
  border-radius: 10px;
}
</style>