<template>
  <div style="height: auto;">
    <v-dialog v-model="imgdialog" width="500px" @click:outside="imgclose">
      <v-toolbar text color="#f3e5f5" class="" dark dense>
        <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"> <span
            style="font-size:15px;color:#8e24aa">TIME:{{ rtime }}</span>&nbsp;&nbsp;
          <span style="font-size:15px;color:#8e24aa">BAC:
            {{ bacval }}</span></v-toolbar-title>
        <v-toolbar-title v-else> <span style="font-size:11px;color: #8e24aa;">TIME:{{ rtime }}</span>&nbsp;<span
            style="font-size:11px;color: #8e24aa;">BAC: {{ bacval }}</span></v-toolbar-title>
        <v-layout justify-end><v-btn color="#8e24aa" small @click="imgclose" dark style="border-radius:20px;"><v-icon
              color="white">
              mdi-close
            </v-icon></v-btn>
        </v-layout>
      </v-toolbar>
      <v-img id="my-video" :style="'max-width: 100%;' + (result == 'FAIL' ? 'border: 4px solid red;' : 'border: 3px solid green;') + (isMobile ? 'margin-left: 0px;' : 'margin-left: 0px;')"
        v-bind:src="'data:image/jpeg;base64,' + imageBytes" v-if="imageBytes != '' && imageBytes != 'noimagefound'" />
      <v-card-text v-else class=text-center>
        <h2 style="background:#8e24aa;color:white;margin-bottom: 2%;;border-radius:20px">{{ imageloadingtext }}</h2>
      </v-card-text>
      <v-toolbar text color="#ba68c8" class="" dark dense style="margin-top:-2%">
        <v-toolbar-title>
          <span style="font-size:16px;">RESULT: {{ result }}</span>
        </v-toolbar-title>
      </v-toolbar>
    </v-dialog>
    <!-- <v-card
            class="mt-6 mx-auto" style="margin-top:10%;border-radius:20px;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);"> -->
    <!-- <v-sheet
              class="v-sheet--offset mx-auto"
              color="#00A3A3"
              elevation="12"
              max-width="calc(100% - 32px)" style="top: -30px;
            position: relative;"
            > -->
            <canvas v-if="bacs.length > 0" ref="barChart" height="60px" width="600px"></canvas>
            <!-- <div v-else-if="bacs.length==0" class="text-center" style="color:  #EA3C53;font-weight: bold;font-size: 25px;margin-bottom: 10%;">No Test Records Found</div> -->
            <div v-else class="text-center" style="color:  #EA3C53;font-weight: bold;font-size: 25px;margin-bottom: 10%;">{{barstext}}</div>
    <!-- </v-sheet> -->
    <!-- </v-card> -->

  </div>
</template>

<script>
import Chart from 'chart.js';
import Vue from "vue";
import moment from 'moment';

export default {
  name: "alcoholInfo",
  props: ["devid"],
  data() {
    return {

      bacval: null,
      imageBytes: '',
      imgdialog: false,
      rtime: null,
      result: 'NA',
      isSet: true,
      bacs: [],
      imgtimes:[],
      imageloadingtext: 'Loading...',
      barstext: 'Loading...',
      dialogOpened: false,
      chart: null
    };
  },
  beforeMount() {
    
  },
  mounted() {
    this.renderChart();
    // setTimeout(() => {
      setInterval(() => {
        if (this.chart) {
      this.chart.destroy(); // Destroy the old chart instance
      this.renderChart(); // Render the chart again
    }
      }, 8000)
    // }, 10000)
  },
  watch: {
    charts(val){
      this.charts = val;
    },
    imgtimes(val){
      this.imgtimes = val;
    },
    bacs(val){
      this.bacs = val;
    },
    rtime(val){
      this.rtime = val;
    },
    bacval(val){
      this.bacval = val;
    },
    devid: {
      deep: true,
      handler() {
        if (this.chart) {
          this.chart.destroy(); // Destroy the old chart instance
        }
        this.renderChart(); // Render the chart again with updated data
      }
    }
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.mobileview = true;
        return true
      } else {
        this.mobileview = false;
        return false
      }
    },
    imgclose() {
      this.dialogOpened = false;
      this.bacval = null;
      this.imageBytes = '';
      this.imageloadingtext = 'Loading....'
      this.imgdialog = false;
      this.rtime = null;
      this.result = 'NA';
    },
    onOpenimage(event, chartElements) {
      console.log("OPNED")
      if(chartElements[0]._chart.chart.chart.chart.data.labels[chartElements[0]._index]){
      this.rtime = chartElements[0]._chart.chart.chart.chart.data.labels[chartElements[0]._index];

      // console.log(event,'gggggggggggggggggggggggg');
      if (!this.dialogOpened && chartElements.length > 0 ) {
        this.dialogOpened = true;
        this.imgdialog = true;
        const clickedBarIndexval = chartElements[0]._index;
        // console.log(event,'eeeeeeeeeeeeeeeeeee')
        this.result = parseInt(this.bacs[clickedBarIndexval]) < 20 ? "PASS" : "FAIL";
        this.bacval = this.bacs[clickedBarIndexval];
        var imgtime = this.imgtimes[clickedBarIndexval];
        Vue.axios
          .get(
            "https://robopower.xyz/app/getImage/getb64v4?deviceId=" + this.devid.Vehicle.cbid +
            "&Image=" +
            imgtime
          )
          .then((res) => {
            // console.log(res.data)
            // check Image at exact time.
            if (res.data) {
              // console.log(res.data)
              if (res.data != 'noimagefound') {
                this.imageloadingtext = "Loading..."
              } else {
                this.imageloadingtext = "No Image Found"
              }
              // console.log(res.data)
              this.imageBytes = res.data;
            } else {
              this.imageloadingtext = "No Image Found"
              this.$store.dispatch("alert/error", "No Image Found").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })

              // End second check
            }
          })
          .catch((error) => {
            this.$store.dispatch("alert/error", error).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          });
      }
    }
    },
    renderChart() {
      // console.log(this.devid.Vehicle.sno)
      var myPromise = new Promise(async (res, rej) => {
        this.barstext = "Loading..."
        var labels = [];
        var datas = [];
        var bgcolors = [];
        var bacs = [];
        var imgtimes =[];
        if (this.isSet) {
          var newProm = new Promise((resolve, reject) => {
      if (this.devid.Vehicle.sno) {
        var devicetimezone;
        Vue.axios
          .get("https://robopower.xyz/us/cadevices/getuniquedevice?sno=" + this.devid.Vehicle.sno)
          .then((resp) => {
            if (resp.data) {
              if (resp.data[0].timezone === "GMT") {
                if (resp.data[0].tagstate === "INDIA") {
                  devicetimezone = "Asia/Kolkata";
                } else if (resp.data[0].tagstate === "CA") {
                  devicetimezone = "America/Los_Angeles";
                } else if (resp.data[0].tagstate === "TX") {
                  devicetimezone = "America/Chicago";
                } else if (resp.data[0].tagstate === "WI") {
                  devicetimezone = "America/Chicago";
                } else if (resp.data[0].tagstate === "AL") {
                  devicetimezone = "America/Chicago";
                } else {
                  devicetimezone = "GMT";
                }
                resolve(devicetimezone);
              } else {
                devicetimezone = resp.data[0].timezone;
                resolve(devicetimezone);
              }
            } else {
              reject("Device Not Added in the List");
            }
          });
      }
    });

    newProm.then(async (result) => {
      await Vue.axios
        .get("https://robopower.xyz/v2/eeprom/gettenlogs?cbid=" + this.devid.Vehicle.cbid)
        .then((response) => {
          if (response.data != null) {
            var eventsdata = response.data;
            eventsdata.map((res) => {
              var convertedDateTime;  
              if (result != "GMT") {
                convertedDateTime = moment.utc(res.rtime, 'MM/DD/YYYY HH:mm:ss').tz(result).format('MM/DD/YYYY HH:mm:ss');
              } else {
                convertedDateTime = res.rtime;
              }
              if (res.typeofevent == "254") {
                // console.log(res.typeofevent,'typeogevent')
                labels.push(convertedDateTime);
                datas.push(10);
                bacs.push(res.bacvalue);
                // console.log("time",res.rtime[0])
                imgtimes.push(res.rtime);  // Update imgtimes array here

                if (parseFloat(res.bacvalue) >= 20) {
                  bgcolors.push('#ff5252');
                } else if (parseFloat(res.bacvalue) < 20 && parseFloat(res.bacvalue) > 0) {
                  bgcolors.push('#ffcc80');
                } else {
                  bgcolors.push('#c5e1a5');
                }
              }
            });
          } else{
            this.barstext = "No Records Found"
          }
          // Update data properties
          this.bacs = bacs;
          this.imgtimes = imgtimes; // Ensure reactivity by setting it directly
          // console.log(imgtimes,'uvakbwiyfbgwchnx')
        });
      res({ "status": "ok", "labels": labels, "datas": datas, "bgcolors": bgcolors, "bacs": bacs });
    })
        } else {
          rej("Device Not Found")
        }
      })
      myPromise.then((res) => {
         //console.log(res,'ressssssssssssssss')
         //console.log(res)
         if(res){
        if(this.$refs.barChart){
        var ctx = this.$refs.barChart.getContext('2d');
        
        this.charts = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: res.labels,
            datasets: [
              {
                barPercentage: 0.6,
                // categoryPercentage: 0.5,
                backgroundColor: res.bgcolors,
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                data: res.datas,
              },
            ],
          },
          barThickness: 50,
          options: {
          barThickness: 50 ,
            tooltips: {
              callbacks: { 
                label: function (tooltipItem, res) {
                  return false;
                }
              },
              enabled: true,
              xAlign: 'center',
              yAlign: 'top',
              footerMarginTop: 6,
              bodyAlign: 'center',
            },
            cutoutPercentage: 150,
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                // stacked: true,

                gridLines: {
                  display: false
                },
                ticks: {
                  display: false,
                  maxRotation: 45,
                  minRotation: 45,
                }
              },
              ],
              yAxes: [{
                stacked: true,
                display: false,
                gridLines: {
                  display: false
                }
              }]

            },
            responsive: true,
            maintainAspectRatio: false,
            onClick: this.onOpenimage,
          }
        });
      }
         }
      })

    },
  },
};
</script>

<style scoped>
</style>
