<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
Vue.use(Vuex);
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    grades: [
      { id: "1", grade: "A" },
      { id: "2", grade: "B" },
      { id: "3", grade: "C" },
    ],
    gradeA: 0,
    gradeB: 0,
    gradeC: 0,
    selectedGrades: {},
    semesterList: [],
    classid: "",
    gradeStudents: [],
    sundays: [],
    nostudents: false,
    show: false,
    isSem: false,
    academicYear: ["04/01/2024", "03/31/2025"],
    selectedDate: new Date().toISOString().substr(0, 10),
    holidays: [],
    markedDates: [],
    markedDatesList: {},
    holidaysThisMonth: [],
    studentPresentDays: [],
    presentMonthSundays: 0,
    presentmonth: 0,
    totalworkingdays: 0,
    presentdays: 0,
    absentCount: 0,
    studentName: "",
    attendanceStatus: {},
    selectedClass: {},
    selectedGrade: "",
    headers: [
      {
        text: "Id",
        align: "left",
        value: "id",
      },
      { text: "Class", value: "class" },
      { text: "Description", value: "description" },
      // { text: "Actions", value: "action", sortable: false },
      { text: "More..", value: "data-table-expand" },
    ],
  }),

  computed: {
    Reminder() {
      if (this.expanded.length > 0) {
        return this.expanded;
      } else {
        return this.classes;
      }
    },
    formattedDate() {
      return moment(this.selectedDate).format("MMMM Do YYYY");
    },
    ...mapGetters(["classes"]),
    ...mapGetters(["schoolstudents"]),
  },

  watch: {
    selectedGrades(val) {
      this.selectedGrades = val;
    },
    expanded: {
      handler: function () {
        this.updateGrades();
      },
    },
    schoolholidays(val) {
      this.schoolholidays = val;
    },
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.$store.dispatch("fetchschoolClasses", this.user.dealerId);
    this.$store.dispatch("fetchschoolStudents", this.user.dealerId);
  },

  methods: {
    initialize() {},
    updateGrades() {
      if (this.expanded.length > 0) {
        this.gradeA = this.schoolstudents.filter((student) => {
          return (
            student.grade === "A" && student.standard === this.expanded[0].class
          );
        });
        this.gradeB = this.schoolstudents.filter((student) => {
          return (
            student.grade === "B" && student.standard === this.expanded[0].class
          );
        });
        this.gradeC = this.schoolstudents.filter((student) => {
          return (
            student.grade === "C" && student.standard === this.expanded[0].class
          );
        });
      } else {
        this.selectedGrades = {};
        this.nostudents = false;
        this.isSem = false;
      }
    },
    SelectGrade(item, grade) {
      this.selectedClass = item;
      this.selectedGrade = grade;
      this.selectedGrades = {};
      // Store the selected grade per class
      this.$set(this.selectedGrades, item.class, grade);
      this.classid = item.id;
      Vue.axios
        .get(
          "https://robopower.xyz/app/students/getschoolstudentsbyclass?dealerId=" +
            this.user.dealerId +
            "&class=" +
            item.class +
            "&grade=" +
            grade
        )
        .then((res) => {
          if (res.data != null) {
            this.gradeStudents = res.data;
            this.nostudents = true;
            res.data.map((student) => {
              Vue.axios
                .get(
                  "https://robopower.xyz/app/schoolattendance/getstudentattendence?studentid=" +
                    student.studentId
                )
                .then((response) => {
                  if (response.data) {
                    const today = moment().format("MM/DD/YYYY");
                    const isTodayInArray = response.data.some((item) => {
                      return item.accessdate == today;
                    });
                    this.$set(
                      this.attendanceStatus,
                      student.studentId,
                      isTodayInArray
                    );
                  }
                });
            });
          } else {
            this.nostudents = false;
          }
        });
    },
    getStudentsByGradeAndClass(item) {
      const selectedGrade = this.selectedGrades[item.class];
      return this.schoolstudents.filter(
        (student) =>
          student.grade === selectedGrade && student.standard === item.class
      );
    },
    isGradeSelected(item, grade) {
      return this.selectedGrades[item.class] === grade;
    },
    SelectedStudent(item) {
      // console.log(this.selectedClass, "class");
      // console.log(this.selectedGrade, "grade");
      Vue.axios
        .get(
          "https://robopower.xyz/app/examresults/getstudentresults?dealerId=" +
            this.user.dealerId +
            "&classId=" +
            this.classid +
            "&studentId=" +
            item.studentId
        )
        .then((responce) => {
          this.isSem = true;
          this.semesterList = responce.data;
        });
    },

    getEventColor(event) {
      return event.color;
    },
    closeCard() {
      this.selectedGrades = {};
      this.nostudents = false;
      this.isSem = false;
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius: 20px">
      <v-data-table
        dense
        :headers="headers"
        :items="Reminder"
        item-key="class"
        sort-by="id"
        :search="search"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-col class="col-md-12">
              <v-card
                class="mt-2 mb-2"
                color="#334c3e"
                style="border-radius: 10px; padding: 10px"
                align="center"
                v-if="isSem == false"
              >
                <v-row justify="center" align="center">
                  <v-col cols="auto">
                    <v-btn
                      :color="
                        isGradeSelected(item, 'A') ? 'primary' : 'default'
                      "
                      size="x-small"
                      @click="SelectGrade(item, 'A')"
                    >
                      GRADE A
                      <h3>( {{ gradeA.length }} )</h3>
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      :color="
                        isGradeSelected(item, 'B') ? 'primary' : 'default'
                      "
                      size="x-small"
                      @click="SelectGrade(item, 'B')"
                    >
                      GRADE B
                      <h3>( {{ gradeB.length }} )</h3>
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      :color="
                        isGradeSelected(item, 'C') ? 'primary' : 'default'
                      "
                      size="x-small"
                      @click="SelectGrade(item, 'C')"
                    >
                      GRADE C
                      <h3>( {{ gradeC.length }} )</h3>
                    </v-btn>
                  </v-col> </v-row
                >&nbsp;

                <v-list
                  dense
                  class="ma-0 pa-0"
                  style="
                    background: #334c3e;
                    overflow-x: auto;
                    white-space: nowrap;
                  "
                  v-if="nostudents == true"
                >
                  <v-row no-gutters>
                    <v-col
                      v-for="(student, index) in gradeStudents"
                      :key="index"
                      cols="auto"
                      class="align-center"
                      style="display: inline-block"
                    >
                      <v-card
                        style="background-color: transparent"
                        @click="SelectedStudent(student)"
                      >
                        <v-list-item
                          class="align-center"
                          style="display: flex; flex-direction: column"
                        >
                          <v-row no-gutters>
                            <v-icon class="off-icon"
                              >mdi-clipboard-account</v-icon
                            >
                          </v-row>
                          <v-list-item-content
                            class="content1"
                            style="text-align: center"
                          >
                            Student: {{ student.studentName }} <br />
                            ( Parent: {{ student.parentName }} )<br />
                            ( RollNumber: {{ student.rollnumber }} )<br />
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-list>
              </v-card>

              <v-card color="#334c3e" align="center" v-else>
                <v-card-title class="d-flex justify-end">
                  <v-icon color="white" @click="closeCard()">mdi-close</v-icon>
                </v-card-title>
                <v-list dense color="#334c3e" v-if="semesterList.length > 0">
                  <v-row>
                    <v-col v-for="(Sems, index) in semesterList" :key="index">
                      <v-list-item>
                        <v-list-item-content class="content1">
                          <span style="font-size: 20px">{{
                            Sems.semester
                          }}</span>
                          <br /><br />

                          <div>
                            Class:&nbsp;
                            <span>{{ selectedClass.class }}</span> | &nbsp;
                            Grade : <span>{{ selectedGrade }}</span> | &nbsp;
                            Dealer : &nbsp;<span>{{ user.userName }}</span
                            ><br /><br />
                            Total Marks : &nbsp;<span>{{
                              Sems.exams.reduce(
                                (sum, exam) => sum + (exam.marks || 0),
                                0
                              )
                            }}</span
                            ><br /><br />
                            Percentage : &nbsp;<span
                              >{{
                                (
                                  (Sems.exams.reduce(
                                    (sum, exam) => sum + (exam.marks || 0),
                                    0
                                  ) /
                                    (Sems.exams.length * 100)) *
                                  100
                                ).toFixed(2)
                              }}%</span
                            ><br />
                          </div>
                          <v-col v-if="Sems.exams.length > 0">
                            <v-row
                              v-for="sem in Sems.exams"
                              :key="sem.subject"
                              cols="12"
                            >
                              <v-card
                                class="d-flex flex-column justify-space-between"
                                style="height: 80px; width: 250px"
                              >
                                <v-card-title class="text-h6"
                                  >{{ sem.date }} - {{ sem.day }}</v-card-title
                                >
                                <v-card-subtitle
                                  >Marks:
                                  {{
                                    sem.marks !== undefined ? sem.marks : "0"
                                  }}</v-card-subtitle
                                >
                              </v-card>
                            </v-row>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-list>
                <div v-else>
                  <p style="font-size: 20px;font-weight: bold;color: aliceblue">No ProgressCard</p>
                </div>
              </v-card>
            </v-col>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2 {
  color: #0ae2e2 !important;
  border-bottom: 1px dashed white;
}
.content1 {
  color: white !important;
  margin-left: 8px;
}
.off-icon {
  color: white;
  /* background: #00a3a3; */
  /* padding: 2px; */
  /* border-radius: 10px; */
}
</style>