<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height mainbody" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="5">
            <v-card class="elevation-12">
              <v-toolbar class="multi-color-toolbar">
                <v-row>
                  <v-toolbar-title><img src='../../public/old_logo.png' width="30%" /></v-toolbar-title>
                </v-row>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form class="text-xs-center" dark="dark" ref="form" lazy-validation>
                  <v-container id="dropdown-example-3">
                    <v-text-field dense outlined
                      @keyup.enter="onTrack" 
                      id="selectedemail" 
                      class="custom-text-field" 
                      required 
                      :color="textFieldColor" 
                      @click="changeColor" 
                      v-model="selectedEmail" 
                      label="Enter Email ID" 
                      persistent-placeholder 
                      name="email" 
                      prepend-icon="mdi-account" 
                      type="text">
                    </v-text-field>

                    <v-text-field dense outlined
                      @keyup.enter="onTrack" 
                      id="selectedpassword" 
                      class="custom-text-field" 
                      required 
                      :color="textFieldColor" 
                      @click="changeColor" 
                      v-model="selectedPassword" 
                      label="Enter Password" 
                      name="password" 
                      prepend-icon="mdi-lock" 
                      persistent-placeholder 
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                      :type="showPassword ? 'text' : 'password'" 
                      @click:append="showPassword = !showPassword">
                    </v-text-field>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="onTrack" class="white--text" color="#00897b">Track</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      textFieldColor: '#009688',
      selectedEmail: null,
      selectedPassword: null
    };
  },
  created() {
    // Ensure the label behaves correctly on autofill
    if (this.selectedEmail || this.selectedPassword) {
      this.$nextTick(() => {
        this.selectedEmail = this.selectedEmail || '';
        this.selectedPassword = this.selectedPassword || '';
      });
    }
  },
  methods: {
    changeColor() {
      this.textFieldColor = '#009688';
    },
    onTrack() {
      if (this.selectedEmail != '' && this.selectedPassword != '') {
        var email = this.selectedEmail;
        var password = this.selectedPassword;
        this.$store.dispatch("authentication/fleetlogin", {
          email,
          password
        });
      }
    }
  }
};
</script>

<style>
.mainbody {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right, #26a69a, #b2dfdb, #009688);
  overflow: hidden;
}

.multi-color-toolbar {
  background: linear-gradient(to right, #e4e9ec, #80cbc4);
  color: white;
}

.custom-text-field .v-input__control {
  margin-top: 0;
}
</style>
