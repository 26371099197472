<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
Vue.use(Vuex);
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    classId:"",
    grades: [
      { id: "1", grade: "A" },
      { id: "2", grade: "B" },
      { id: "3", grade: "C" },
    ],
    gradeA: 0,
    gradeB: 0,
    gradeC: 0,
    selectedGrades: {},
    gradeStudents: [],
    sundays: [],
    nostudents: false,
    show: false,
    studentDetails: false,
    studentName: "",
    parentName:"",
    rollnumber:'',
    studentAddress:"",
    attendanceStatus: {},
    actual_fee: {},
    totalFee:'',
    headers: [
      {
        text: "Id",
        align: "left",
        value: "id",
      },
      { text: "Class", value: "class" },
      { text: "Description", value: "description" },
      // { text: "Actions", value: "action", sortable: false },
      { text: "More..", value: "data-table-expand" },
    ],
  }),

  computed: {
    Reminder() {
      if (this.expanded.length > 0) {
        return this.expanded;
      } else {
        return this.classes;
      }
    },
    ...mapGetters(["classes"]),
    ...mapGetters(["schoolstudents"]),
  },

  watch: {
    selectedGrades(val) {
      this.selectedGrades = val;
    },
    expanded: {
      handler: function () {
        this.updateGrades();
      },
    },
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.$store.dispatch("fetchschoolClasses", this.user.dealerId);
    this.$store.dispatch("fetchschoolStudents", this.user.dealerId);
  },

  methods: {
    initialize() {},
    updateGrades() {
      if (this.expanded.length > 0) {
        this.gradeA = this.schoolstudents.filter((student) => {
          return (
            student.grade === "A" && student.standard === this.expanded[0].class
          );
        });
        this.gradeB = this.schoolstudents.filter((student) => {
          return (
            student.grade === "B" && student.standard === this.expanded[0].class
          );
        });
        this.gradeC = this.schoolstudents.filter((student) => {
          return (
            student.grade === "C" && student.standard === this.expanded[0].class
          );
        });
      } else {
        this.selectedGrades = {};
        this.nostudents = false;
        this.studentDetails = false;
      }
    },
    SelectGrade(item, grade) {
      this.selectedGrades = {};
      // Store the selected grade per class
      this.classId = item.id
      this.$set(this.selectedGrades, item.class, grade);
      Vue.axios
        .get(
          "https://robopower.xyz/app/students/getschoolstudentsbyclass?dealerId=" +
            this.user.dealerId +
            "&class=" +
            item.class +
            "&grade=" +
            grade
        )
        .then((res) => {
          if (res.data != null) {
            this.gradeStudents = res.data;
            this.nostudents = true;
           res.data.map((student)=>{
              Vue.axios
        .get(
          "https://robopower.xyz/app/schoolattendance/getstudentattendence?studentid=" + student.studentId
        ).then((response)=>{
          if(response.data){
              const today = moment().format('MM/DD/YYYY');
              const isTodayInArray = response.data.some(item => {
          return item.accessdate == today;
        });
        this.$set(this.attendanceStatus, student.studentId, isTodayInArray);
          }
        })
            })
           
          } else {
            this.nostudents = false;
          }
        });
    },
    getStudentsByGradeAndClass(item) {
      const selectedGrade = this.selectedGrades[item.class];
      return this.schoolstudents.filter(
        (student) =>
          student.grade === selectedGrade && student.standard === item.class
      );
    },
    isGradeSelected(item, grade) {
      return this.selectedGrades[item.class] === grade;
    },
    SelectedStudent(item) {
      this.studentDetails = true;
      this.studentName = item.studentName;
      this.parentName = item.parentName;
      this.rollnumber = item.rollnumber;
      this.studentAddress = item.studentAddress;
      Vue.axios.get("https://robopower.xyz/app/schoolfee/getclassfee?dealerId="+this.user.dealerId+"&classId="+this.classId).then((response) => {
        const feeData = response.data[0];
        const totalFee = parseFloat(feeData.tuition_fee) + parseFloat(feeData.admission_fee )+ parseFloat(feeData.registration_fee) + parseFloat(feeData.other_fees);
        this.actual_fee = response.data[0];
        this.totalFee = totalFee
      })
      
    },

    closeCard() {
      this.selectedGrades = {};
      this.nostudents = false;
      this.studentDetails = false;
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius: 20px">
      <v-data-table
        dense
        :headers="headers"
        :items="Reminder"
        item-key="class"
        sort-by="id"
        :search="search"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >

        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-col class="col-md-12">
              <v-card
                class="mt-2 mb-2"
                color="#334c3e"
                style="border-radius: 10px; padding: 10px"
                align="center"
                v-if="studentDetails == false"
              >
                <v-row justify="center" align="center">
                  <v-col cols="auto">
                    <v-btn
                      :color="
                        isGradeSelected(item, 'A') ? 'primary' : 'default'
                      "
                      size="x-small"
                      @click="SelectGrade(item, 'A')"
                    >
                      GRADE A
                      <h3>( {{ gradeA.length }} )</h3>
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      :color="
                        isGradeSelected(item, 'B') ? 'primary' : 'default'
                      "
                      size="x-small"
                      @click="SelectGrade(item, 'B')"
                    >
                      GRADE B
                      <h3>( {{ gradeB.length }} )</h3>
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      :color="
                        isGradeSelected(item, 'C') ? 'primary' : 'default'
                      "
                      size="x-small"
                      @click="SelectGrade(item, 'C')"
                    >
                      GRADE C
                      <h3>( {{ gradeC.length }} )</h3>
                    </v-btn>
                  </v-col> 
                  </v-row>&nbsp;

                <v-list
                  dense
                  class="ma-0 pa-0"
                  style="
                    background: #334c3e;
                    overflow-x: auto;
                    white-space: nowrap;
                  "
                  v-if="nostudents == true"
                >
                  <v-row no-gutters>
                    <v-col
                      v-for="(student, index) in gradeStudents"
                      :key="index"
                      cols="auto"
                      class="align-center"
                      style="display: inline-block"
                    >
                    <v-card style="background-color: transparent;" @click="SelectedStudent(student)">
                      <v-list-item
                        class="align-center"
                        style="display: flex; flex-direction: column"
                      >
                      <v-row no-gutters>
                        <v-icon
                          class="off-icon"
                          
                          >mdi-clipboard-account</v-icon>
                        </v-row>
                        <v-list-item-content
                          class="content1"
                          style="text-align: center"
                        >
                          Student: {{ student.studentName }} <br />
                          ( Parent: {{ student.parentName }} )<br />
                          ( RollNumber: {{ student.rollnumber }} )
                        </v-list-item-content>
                   
                      </v-list-item>
                    </v-card>
                    </v-col>
                  </v-row>
                </v-list>
              </v-card>

              <v-card color="#334c3e" class="d-flex justify-center" v-else>
                <v-card-title class="d-flex justify-end">
                  <v-icon color="white" @click="closeCard()">mdi-close</v-icon>
                </v-card-title>
                <v-card class="d-flex justify-center" color="white" max-width="700px">
                    <v-row>
                    <v-col>
               <h2 style="color:black">{{studentName}}</h2>
               <h5 style="color:black">ParentName : {{parentName}}</h5>
            <h5 style="color:black">RollNumber : {{rollnumber}}</h5>
            <h5 style="color:black">StudentAddress : {{studentAddress}}</h5>
        </v-col>
        <v-col>
            <h2 style="color:black">Fee Breakdown</h2>
               <h4 style="color:black">{{actual_fee.term}}</h4>
               <h5 style="color:black">Tuition_Fee : {{actual_fee.tuition_fee}}</h5>
            <h5 style="color:black">Admission_Fee : {{actual_fee.admission_fee}}</h5>
            <h5 style="color:black">Registration_Fee : {{actual_fee.registration_fee}}</h5>
            <h5 style="color:black">Other_Fees : {{actual_fee.other_fees}}</h5>
        </v-col>
    </v-row>
   
                </v-card>
             <v-row>
    <v-col>
    <h2 style="color:black">Installament Payments</h2>
</v-col>
</v-row>
                
              </v-card>
            </v-col>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2 {
  color: #0ae2e2 !important;
  border-bottom: 1px dashed white;
}
.content1 {
  color: white !important;
  margin-left: 8px;
}
.off-icon {
  color: white;
  /* background: #00a3a3; */
  /* padding: 2px; */
  /* border-radius: 10px; */
}
</style>