<script>
import {mapGetters} from 'vuex';
import Feestruture from './Feestruture.vue';
import StudentFees from './studentfees.vue'
export default {
    components: {
        Feestruture,
        StudentFees
  },
  data: () => ({
    user  : JSON.parse(localStorage.getItem("user")),
    activeTab: 0,
  }),

  computed: {
     
  },

  watch: {
    activeTab(val){
        this.activeTab = val
    }
  },

  created() {
    
  },
  mounted() {

  }
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius:20px">
    <v-tabs v-model="activeTab" background-color="#00A3A3" dark dense style="border-radius:20px">
      <v-tab>Fee Struture</v-tab>
      <v-tab>Student Fees</v-tab>
    </v-tabs>

            <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <!-- Fee Struture Content -->
            <Feestruture />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <!-- Student Fees Content -->
            <StudentFees/>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs-items>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2
{
  color:#0ae2e2 !important;
  border-bottom:1px dashed white
}
.content1
{
  color: white !important;
  margin-left:8px;
}
.off-icon
{
  color:white;
  background:#00A3A3;
  padding:2px;
  border-radius:10px;
}

</style>