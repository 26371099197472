<template>
  <v-container class="pa-2 overflow-auto" style="max-height: 80vh; margin-top: 10px;">
    <template>
      <v-card>
        <!-- Device Selection -->
        <div v-if="!selectedDevice">
          <v-select
            v-model="selectedDevice"
            :items="deviceList"
            label="Select Device"
            outlined
            dense
            placeholder="Select a device"
          ></v-select>
        </div>

        <div v-else>
          <!-- IP Address Input -->
          <div v-if="!isStreaming">
            <v-text-field
              v-model="ipAddress"
              label="Enter IP Address"
              outlined
              dense
              placeholder="e.g., 192.168.231.133"
            ></v-text-field>
            <v-btn color="primary" @click="startStreaming">Go</v-btn>
          </div>

          <div v-else>
            <!-- Embed the stream with iframe -->
            <iframe
              :src="`http://${ipAddress}:8000`"
              style="width: 100%; height: 300px; border: none;"
              allowfullscreen>
            </iframe>
            <v-btn color="red" @click="stopStreaming" class="mt-2">Stop Streaming</v-btn>
          </div>

          <!-- Tabs -->
          <v-tabs v-model="tab" next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            center-active>
            <v-tab>Drowsiness</v-tab>
            <v-tab>No Driver</v-tab>
            <v-tab>Mobile Driving</v-tab>
            <v-tab>Others</v-tab>
          </v-tabs>

          <!-- Tab Items -->
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-card-text>Drowsiness Alerts.</v-card-text>
                <v-row>
                  <v-col
                    v-for="(image, index) in getimageuri"
                    :key="index"
                    cols="12"
                    sm="4"
                  >
                    <v-img
                      :src="'https://robopower.xyz/' + image"
                      max-width="180" max-heigth="256"
                      style="margin-left: 10px;"
                      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    ></v-img>
                  </v-col>
                </v-row>
                &nbsp;
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>No Driver Alerts.</v-card-text>
                <v-row>
                  <v-col
                    v-for="(image, index) in getimageuri"
                    :key="index"
                    cols="12"
                    sm="4"
                  >
                    <v-img
                      :src="'https://robopower.xyz/' + image"
                      max-width="180" max-heigth="256"
                      style="margin-left: 10px;"
                      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    ></v-img>
                  </v-col>
                </v-row>
                &nbsp;
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>Mobile Driving Alerts.</v-card-text>
                <v-row>
                  <v-col
                    v-for="(image, index) in getimageuri"
                    :key="index"
                    cols="12"
                    sm="4"
                  >
                    <v-img
                      :src="'https://robopower.xyz/' + image"
                      max-width="180" max-heigth="256"
                      style="margin-left: 10px;"
                      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    ></v-img>
                  </v-col>
                </v-row>
                &nbsp;
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>Unknown Alerts.</v-card-text>
                <v-row>
                  <v-col
                    v-for="(image, index) in getimageuri"
                    :key="index"
                    cols="12"
                    sm="4"
                  >
                    <v-img
                      :src="'https://robopower.xyz/' + image"
                      max-width="180" max-heigth="256"
                      style="margin-left: 10px;"
                      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    ></v-img>
                  </v-col>
                </v-row>
                &nbsp;
              </v-card>
            </v-tab-item>
            <!-- Repeat tab content for other tabs -->
          </v-tabs-items>
        </div>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      deviceList: ["c6:31:8c:e3:38:ce", "95:65:d9:b6:2d:cb"],
      selectedDevice: null,
      tab: 0,
      getimageuri: null,
      ipAddress: "",
      isStreaming: false,
    };
  },
  watch: {
    tab(newIndex) {
      console.log("Tab changed to index:", newIndex);
      this.onLoad(newIndex);
    },
    selectedDevice(newDevice) {
      console.log("Device selected:", newDevice);
      this.onLoad(this.tab);
    },
  },
  methods: {
    startStreaming() {
      if (this.ipAddress) {
        this.isStreaming = true;
      } else {
        alert("Please enter a valid IP address.");
      }
    },
    stopStreaming() {
      this.isStreaming = false;
      this.ipAddress = "";
    },
    onLoad(tabvalue) {
  if (!this.selectedDevice) return;

  console.log(`Loading data for device: ${this.selectedDevice}, tab: ${tabvalue}`);

  // Clear the existing images
  this.getimageuri = null;

  let data = { devid: this.selectedDevice, type: tabvalue + 1 };
  Vue.axios
    .post(
      "https://robopower.xyz/ai/facedetection/getdistractedimages",
      JSON.stringify(data)
    )
    .then((resp) => {
      console.log(resp.data);
      this.getimageuri = resp.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
},

  },
};
</script>

<style scoped>
.v-btn-toggle {
  margin-top: 10px;
}
</style>
