<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
Vue.use(Vuex);
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    grades: [
      { id: "1", grade: "A" },
      { id: "2", grade: "B" },
      { id: "3", grade: "C" },
    ],
    gradeA: 0,
    gradeB: 0,
    gradeC: 0,
    selectedGrades: {},
    gradeStudents: [],
    sundays: [],
    nostudents: false,
    show: false,
    calander: false,
    academicYear: ["04/01/2024", "03/31/2025"],
    selectedDate: new Date().toISOString().substr(0, 10),
    holidays: [],
    markedDates: [],
    markedDatesList: {},
    holidaysThisMonth: [],
    studentPresentDays: [],
    presentMonthSundays: 0,
    presentmonth: 0,
    totalworkingdays: 0,
    presentdays: 0,
    absentCount: 0,
    studentName: "",
    attendanceStatus: {},
    headers: [
      {
        text: "Id",
        align: "left",
        value: "id",
      },
      { text: "Class", value: "class" },
      { text: "Description", value: "description" },
      // { text: "Actions", value: "action", sortable: false },
      { text: "More..", value: "data-table-expand" },
    ],
  }),

  computed: {
    Reminder() {
      if (this.expanded.length > 0) {
        return this.expanded;
      } else {
        return this.classes;
      }
    },
    formattedDate() {
      return moment(this.selectedDate).format("MMMM Do YYYY");
    },
    ...mapGetters(["classes"]),
    ...mapGetters(["schoolstudents"]),
    ...mapGetters(["schoolholidays"]),
    ...mapGetters(["studentattendances"]),
  },

  watch: {
    selectedGrades(val) {
      this.selectedGrades = val;
    },
    expanded: {
      handler: function () {
        this.updateGrades();
      },
    },
    schoolholidays(val){
    this.schoolholidays = val
    }
  },

  created() {
    this.initialize();
    this.markSundaysInAcademicYear();
    setTimeout(() => {
      this.addHolidaysToMarkedDates();
    }, 1000);
  },
  mounted() {
    this.$store.dispatch("fetchschoolClasses", this.user.dealerId);
    this.$store.dispatch("fetchschoolStudents", this.user.dealerId);
    this.$store.dispatch("fetchschoolholidays", this.user.dealerId);
  },

  methods: {
    initialize() {},
    markSundaysInAcademicYear() {
      const startDate = moment(this.academicYear[0], "MM/DD/YYYY");
      const endDate = moment(this.academicYear[1], "MM/DD/YYYY");
      let current = startDate.clone();
      while (current.isBefore(endDate) || current.isSame(endDate)) {
        if (current.day() === 0) {
          this.markedDates.push({
            name: "Sunday",
            start: current.format("YYYY-MM-DD"),
            color: "red",
          });
        }
        current.add(1, "days");
      }
    },
    addHolidaysToMarkedDates() {
      setTimeout(()=>{
      this.schoolholidays.forEach((holiday) => {
        const isSunday = moment(holiday.Date, "MM/DD/YYYY").day() === 0;
        if (!isSunday) {
          this.markedDates.push({
            name: holiday.Holiday,
            start: moment(holiday.Date, "MM/DD/YYYY").format("YYYY-MM-DD"),
            color: "red",
          });
        }
      });
    },500)
    },
    studentPresentDaysToMarkedDates() {
      this.studentPresentDays.forEach((entry) => {
        this.markedDates.push({
          name: "Present",
          start: moment(entry.accessdate, "MM/DD/YYYY").format("YYYY-MM-DD"),
          color: "green",
        });
      });
    },
    onDayClick({ date }) {
      this.selectedDate = moment(date).format("YYYY-MM-DD");
      console.log(`Selected date: ${this.selectedDate}`);
    },
    updateGrades() {
      if (this.expanded.length > 0) {
        this.gradeA = this.schoolstudents.filter((student) => {
          return (
            student.grade === "A" && student.standard === this.expanded[0].class
          );
        });
        this.gradeB = this.schoolstudents.filter((student) => {
          return (
            student.grade === "B" && student.standard === this.expanded[0].class
          );
        });
        this.gradeC = this.schoolstudents.filter((student) => {
          return (
            student.grade === "C" && student.standard === this.expanded[0].class
          );
        });
      } else {
        this.selectedGrades = {};
        this.nostudents = false;
        this.calander = false;
        this.absentCount = 0;
        this.holidaysThisMonth = null
      }
    },
    SelectGrade(item, grade) {
      this.selectedGrades = {};
      // Store the selected grade per class
      this.$set(this.selectedGrades, item.class, grade);
      Vue.axios
        .get(
          "https://robopower.xyz/app/students/getschoolstudentsbyclass?dealerId=" +
            this.user.dealerId +
            "&class=" +
            item.class +
            "&grade=" +
            grade
        )
        .then((res) => {
          if (res.data != null) {
            this.gradeStudents = res.data;
            this.nostudents = true;
           res.data.map((student)=>{
              Vue.axios
        .get(
          "https://robopower.xyz/app/schoolattendance/getstudentattendence?studentid=" + student.studentId
        ).then((response)=>{
          if(response.data){
              const today = moment().format('MM/DD/YYYY');
              const isTodayInArray = response.data.some(item => {
          return item.accessdate == today;
        });
        this.$set(this.attendanceStatus, student.studentId, isTodayInArray);
          }
        })
            })
           
          } else {
            this.nostudents = false;
          }
        });
    },
    getStudentsByGradeAndClass(item) {
      const selectedGrade = this.selectedGrades[item.class];
      return this.schoolstudents.filter(
        (student) =>
          student.grade === selectedGrade && student.standard === item.class
      );
    },
    isGradeSelected(item, grade) {
      return this.selectedGrades[item.class] === grade;
    },
    SelectedStudent(item) {
      this.calander = true;
      this.studentName = item.studentName;
      this.markedDates = this.markedDates.filter(
        (date) => date.name !== "Present"
      );
      this.studentPresentDays = [];
      const currentDate = moment.utc();
      const currentMonth = currentDate.format("M");
      const startOfMonth = currentDate.clone().startOf("month");
      Vue.axios
        .get(
          "https://robopower.xyz/app/schoolattendance/getstudentattendence?studentid=" +
            item.studentId
        )
        .then((response) => {
          const attendancedata = response.data;
          if (attendancedata != null) {
            const presentdays = response.data.filter((day) => {
              const accessdateFormatted = moment
                .utc(day.accessdate, "MM/DD/YYYY")
                .format("MM/DD/YYYY");
              const accessdateMonth = moment
                .utc(accessdateFormatted, "MM/DD/YYYY")
                .format("M");
              return accessdateMonth === currentMonth;
            });
            this.studentPresentDays = presentdays;
            this.studentPresentDaysToMarkedDates();
          } else {
            this.studentPresentDays = null;
          }
          Vue.axios
            .get(
              "https://robopower.xyz/app/schoolholidays/getschoolholidays?dealerId=" +
                this.user.dealerId
            )
            .then((response) => {
              if (response.data) {
                const allholidays = JSON.parse(response.data.holidays);
                this.holidaysThisMonth = allholidays.filter((holiday) => {
                  const holidayMonth = moment
                    .utc(holiday.Date, "MM/DD/YYYY")
                    .format("MM/DD/YYYY");
                  const month = moment
                    .utc(holidayMonth, "MM/DD/YYYY")
                    .format("M");
                  return month === currentMonth;
                });
              } else {
                this.presentMonthSundays = 0;
                this.holidaysThisMonth = null
              }
            });
        });
        setTimeout(()=>{
      const sundays = this.getPresentMonthSundays();
      const holidaysAndSundaysCount = sundays - this.holidaysThisMonth.length;
      const totalDaysUpToNow = currentDate.diff(startOfMonth, "days") + 1;
      this.absentCount = totalDaysUpToNow - holidaysAndSundaysCount - this.studentPresentDays.length;
    },2000)
    },
    getPresentMonthSundays() {
      const startOfMonth = moment().startOf("month");
      const endOfMonth = moment().endOf("month");
      let current = startOfMonth.clone();
      let sundayCount = 0;

      this.sundays = [];

      while (current.isBefore(endOfMonth) || current.isSame(endOfMonth)) {
        if (current.day() === 0) {
          this.sundays.push(current.format("YYYY-MM-DD"));
          sundayCount++;
        }
        current.add(1, "days");
      }
      return sundayCount;
    },

    getEventColor(event) {
      return event.color;
    },
    closeCard() {
      this.selectedGrades = {};
      this.nostudents = false;
      this.calander = false;
      this.absentCount = 0;
      this.holidaysThisMonth = null
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius: 20px">
      <v-data-table
        dense
        :headers="headers"
        :items="Reminder"
        item-key="class"
        sort-by="id"
        :search="search"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-col class="col-md-12">
              <v-card
                class="mt-2 mb-2"
                color="#334c3e"
                style="border-radius: 10px; padding: 10px"
                align="center"
                v-if="calander == false"
              >
                <v-row justify="center" align="center">
                  <v-col cols="auto">
                    <v-btn
                      :color="
                        isGradeSelected(item, 'A') ? 'primary' : 'default'
                      "
                      size="x-small"
                      @click="SelectGrade(item, 'A')"
                    >
                      GRADE A
                      <h3>( {{ gradeA.length }} )</h3>
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      :color="
                        isGradeSelected(item, 'B') ? 'primary' : 'default'
                      "
                      size="x-small"
                      @click="SelectGrade(item, 'B')"
                    >
                      GRADE B
                      <h3>( {{ gradeB.length }} )</h3>
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      :color="
                        isGradeSelected(item, 'C') ? 'primary' : 'default'
                      "
                      size="x-small"
                      @click="SelectGrade(item, 'C')"
                    >
                      GRADE C
                      <h3>( {{ gradeC.length }} )</h3>
                    </v-btn>
                  </v-col> 
                  </v-row>&nbsp;

                <v-list
                  dense
                  class="ma-0 pa-0"
                  style="
                    background: #334c3e;
                    overflow-x: auto;
                    white-space: nowrap;
                  "
                  v-if="nostudents == true"
                >
                  <v-row no-gutters>
                    <v-col
                      v-for="(student, index) in gradeStudents"
                      :key="index"
                      cols="auto"
                      class="align-center"
                      style="display: inline-block"
                    >
                    <v-card style="background-color: transparent;" @click="SelectedStudent(student)">
                      <v-list-item
                        class="align-center"
                        style="display: flex; flex-direction: column"
                      >
                      <v-row no-gutters>
                        <v-icon
                          class="off-icon"
                          
                          >mdi-clipboard-account</v-icon>
                          <v-icon size="30" v-if="attendanceStatus[student.studentId]" color="green">mdi-checkbox-multiple-marked-circle</v-icon>
                          <v-icon size="28" v-else color="red">mdi-close-circle</v-icon>
                        </v-row>
                        <v-list-item-content
                          class="content1"
                          style="text-align: center"
                        >
                          Student: {{ student.studentName }} <br />
                          ( Parent: {{ student.parentName }} )
                        </v-list-item-content>
                   
                      </v-list-item>
                    </v-card>
                    </v-col>
                  </v-row>
                </v-list>
              </v-card>

              <v-card color="#334c3e" align="center" v-else>
                <v-card-title class="d-flex justify-end">
                  <v-icon color="white" @click="closeCard()">mdi-close</v-icon>
                </v-card-title>
                <v-row>
                  <v-col cols="3">
                    <h4 style="color: aliceblue">
                      StudentName : {{ studentName }}
                    </h4>
                  </v-col>
                  <v-col cols="3">
                    <h4 style="color: aliceblue">
                      Present Month Holidays : {{ holidaysThisMonth.length }}
                    </h4>
                  </v-col>
                  <v-col cols="3">
                    <h4 style="color: aliceblue">
                      Presents :
                      {{ studentPresentDays.length }}
                    </h4>
                  </v-col>
                  <v-col cols="3">
                    <h4 style="color: aliceblue">
                      Absent :
                      {{ absentCount }}
                    </h4>
                  </v-col>
                  <v-col cols="3">
                    <h4 style="color: aliceblue">
                      Current Month Sundays : {{ sundays.length }}
                    </h4>
                  </v-col>
                </v-row>
                <v-card-text>
                  <v-col>
                    <v-calendar
                      v-model="selectedDate"
                      ref="calendar"
                      color="primary"
                      :events="markedDates"
                      :event-color="getEventColor"
                      @click:day="onDayClick"
                    />
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2 {
  color: #0ae2e2 !important;
  border-bottom: 1px dashed white;
}
.content1 {
  color: white !important;
  margin-left: 8px;
}
.off-icon {
  color: white;
  /* background: #00a3a3; */
  /* padding: 2px; */
  /* border-radius: 10px; */
}
</style>