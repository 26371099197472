<template>
  <v-container class="pa-5 overflow-auto" style="max-height: 80vh">
    <div v-if="caltrackexists">
      <v-row>
        <v-col cols="12" md="8">
          <v-card-text>Device version: V.{{ config.sampversion }}</v-card-text>
        </v-col>
        <v-col cols="12" md="4">
          <v-card-text style="font-size: 20px; font-weight: bold">{{
            regnum
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-card-text>
        <v-card>
          <div style="padding: 10px;overflow:hidden">
          <v-row>
            <v-card-title style="margin-left: 20px">SMS Settings</v-card-title>
          </v-row>
          <v-col cols="12" md="4">
            <v-switch
              v-model="settings.smsService"
              label="SMS Service Enable/Disable"
            >
            </v-switch>
          </v-col>
          <!-- Additional Toggle Switches -->

            <div v-if="settings.smsService" class="additional-switches">
              <v-row>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="smsSettings.fail"
                    :true-value="true"
                    :false-value="false"
                    @change="handleToggleChange(smsSettings.fail,'fail')"
                    label="FailTest Enable/Disable"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="smsSettings.refused"
                    :true-value="true"
                    :false-value="false"
                    @change="handleToggleChange(smsSettings.refused,'refused')"
                    label="TestRefused Enable/Disable"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="smsSettings.bypass"
                    :true-value="true"
                    :false-value="false"
                    @change="handleToggleChange(smsSettings.bypass,'bypass')"
                    label="Bypass Enable/Disable"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="smsSettings.paymentsdue"
                    :true-value="true"
                    :false-value="false"
                    @change="handleToggleChange(smsSettings.paymentsdue,'paymentdue')"
                    label="PaymentDue Enable/Disable"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="smsSettings.noupdates"
                    :true-value="true"
                    :false-value="false"
                    @change="handleToggleChange(smsSettings.noupdates,'noupdates')"
                    label="NoUpdates Enable/Disable"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="smsSettings.overspeed"
                    :true-value="true"
                    :false-value="false"
                    @change="handleToggleChange(smsSettings.overspeed,'overspeed')"
                    label="Overspeed Enable/Disable"
                  ></v-switch>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-if="smsSettings.overspeed">
            <div style="flex: 1; margin-left: 10px">Set Speed Limit</div>
            <v-slider
              v-model="smsSettings.overspeed_limit"
              :thumb-size="26"
              :max="100"
              :min="10"
               @change="handleToggleChange(smsSettings.overspeed_limit,'overspeed_limit')"
              thumb-label="always"
            ></v-slider>
          </div>
        </v-card>
        &nbsp;
        <v-card v-if="this.data.Vehicle.facerecognition === '1'">
          <!-- <v-card > -->
            <div style="padding: 15px;">
          <v-row>
            <v-card-title style="font-weight: bold"
              >DMS Settings</v-card-title
            >
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-switch
                v-model="smsSettings.facerecognition"
                :true-value="true"
                :false-value="false"
                @change="handleToggleChange(smsSettings.facerecognition,'facerecognition')"
                label="FaceRecognition Enable/Disable"
              >
              </v-switch>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch
                v-model="smsSettings.mobiledriving"
                :true-value="true"
                :false-value="false"
                @change="handleToggleChange(smsSettings.mobiledriving,'mobiledriving')"
                label="MobileDriving Enable/Disable"
              >
              </v-switch>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch
                v-model="smsSettings.drowsiness"
                :true-value="true"
                :false-value="false"
                @change="handleToggleChange(smsSettings.drowsiness,'drowsiness')"
                label="Drowsiness Enable/Disable"
              >
              </v-switch>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch
                v-model="smsSettings.nodriver"
                :true-value="true"
                :false-value="false"
                @change="handleToggleChange(smsSettings.nodriver,'nodriver')"
                label="NoDriver Enable/Disable"
              >
              </v-switch>
            </v-col>
            </v-row>
          </div>
        </v-card>
        &nbsp;
        <v-card style="padding: 10px">
          <v-row>
            <v-card-title style="font-weight: bold"
              >Device Settings</v-card-title
            >
          </v-row>
          <!-- Input Fields -->
          <v-row>
            <v-col cols="12" md="6">
              <v-switch
                v-model="config.random_test_enable"
                :true-value="true"
                :false-value="false"
                label="Random Test Enable/Disable"
              >
              </v-switch>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="config.eng_of_tim"
                label="Engine Off Timer (in minutes)"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="config.bac_val"
                label="BAC Cutoff Level"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <!-- <h1>{{config.random_test_enable}}</h1> -->
              <v-text-field
                :disabled="!config.random_test_enable"
                v-model="config.rolling_test_tim"
                label="Random Test Interval (in minutes)"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                disabled
                v-model="config.blow_vol"
                label="Blow Volume"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                disabled
                v-model="config.blow_time_out"
                label="Blow Time"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Blow Type Buttons -->
          <v-row>
            <v-col cols="12">
              <p>Blow Type:</p>
              <v-btn-toggle v-model="settings.blowType" mandatory>
                <v-btn @click="setVolume('low')" value="low">Low</v-btn>
                <v-btn @click="setVolume('med')" value="medium">Medium</v-btn>
                <v-btn @click="setVolume('high')" value="high">High</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>

      <v-card-actions>
        <!-- <h1>{{config.sampversion}}</h1> -->
        <v-btn
          :disabled="config.sampversion < 52"
          color="primary"
          @click="confirmSave"
          >Save</v-btn
        >

        <v-btn
          :disabled="config.sampversion < 52"
          color="secondary"
          @click="resetSettings"
          >Reset</v-btn
        >
      </v-card-actions>
    </div>
    <!-- <v-card > -->
    <h1 v-else-if="caltrackloading" style="text-align: center">Loading....</h1>
    <!-- </v-card> -->
    <!-- <v-card > -->
    <h1 v-else>No Records Found Please Update Your Device</h1>
    <!-- </v-card> -->
    <!-- Confirmation Dialog -->
    <v-dialog v-model="showDialog" max-width="400px">
      <v-card>
        <v-card-title>Confirm Changes</v-card-title>
        <v-card-text>
          Make sure the device is online while updating the changes. Once
          updated, the device will restart. Do you want to proceed?
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="proceedSave">OK</v-btn>
          <v-btn color="secondary" text @click="showDialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Full-page Loader -->
    <v-overlay :value="loading" z-index="10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
  
  <script>
import Vue from "vue";
export default {
  props: ["data"],
  data() {
    return {
      fleetuser: JSON.parse(localStorage.getItem("fleetuser")),
      loading: false,
      caltracking: null,
      showDialog: false, // For confirmation dialog
      caltrackexists: false,
      caltrackloading: false,
      regnum: "",
      overspeedslider: 10,
      config: {
        sampid: null,
        sampversion: null,
        currentdate: null, //Dont compare it with current Time
        caldate: null,
        nextcaldate: null,
        cbversion: null,
        batadc: null,
        cbid: null,
        offset_pre: null,
        alco_sen_offset: null,
        tmp75: null,
        bt: null,
        alt: null,
        Brac_peak_factor30: null,
        calibration_set_flag: null,
        eng_of_tim: null,
        bac_val: null,
        random_test_enable: false,
        rolling_test_tim: null,
        blow_vol: null,
        blow_time_out: null,
      },
      toggleSwitch: false,
      settings: {
        smsService: 0, // Changed to 1/0
        randomTest: 0, // Changed to 1/0
        engineOffTimer: 0,
        bacCutoff: 0,
        randomTestInterval: 0,
        blowType: "medium", // Default to medium
      },
      smsSettings: {
        bypass: false,
        fail: false,
        refused: false,
        overspeed: false,
        facerecognition: false,
        mobiledriving: false,
        drowsiness: false,
        nodriver: false,
        paymentsdue: false,
        noupdates: false,
        overspeed_limit: 10,
        alertId:0,
      },
    };
  },
  mounted() {
    console.log("Mounted data:", this.data);
    this.settings.smsService = this.data.Vehicle.issms === 1 ? 1 : 0;
    this.regnum = this.data.Vehicle.regnum;
    //   this.settings.randomTest = this.data.Vehicle.isRandomTest === 1 ? 1 : 0;
    //   this.settings.overspeedAlert = this.data.Vehicle.isOverspeedAlert === 1 ? 1 : 0;
    console.log(this.data.Vehicle.cbid);
    this.onLoad();
  },
  methods: {
    async handleToggleChange(newValue,type) {
        // Show SweetAlert confirmation when the toggle is set to true
        const toggle = newValue === true ? "enable" : "disable" 
         const Swal = require("sweetalert2");
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: type === "overspeed_limit" ? `Do you want to Set a ${newValue} SpeedLimit` : `Do you want to ${toggle} the toggle?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true, // Makes the 'No' button on the left
        });

        if (result.isConfirmed) {
          // If user clicks 'Yes', log the action and keep the toggle as true
          const togglevalue = newValue === true ? 1 : 0
          var data;
          if(type === "overspeed_limit"){
          data = {
            "type": type,
            action: newValue
          }
        }else{
          data = {
            "type": type,
            action: togglevalue
          }
        }
        const smsSettingData = JSON.stringify(data)
          // console.log(data,"toggled value")
          Vue.axios.put('https://robopower.xyz/v2/alerts/editalert/'+ this.smsSettings.alertId,smsSettingData).then((response)=>{
            if(response.status === 200){
            this.$store.dispatch("alert/success", "ALERT SUCCESSFULLY DONE").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
          })
        } else {
          // If user clicks 'No', revert the toggle to false
          this.toggleState = false;
          this.onLoad()
        }
    },
    onLoad() {
      this.caltrackloading = true;
      Vue.axios
        .get(
          "https://robopower.xyz/v2/caltrack/getlatestcbidcaltrack?cbid=" +
            this.data.Vehicle.cbid
        )
        .then((response) => {
          console.log(response.data, "llllllllllll");
          if (response.data != null) {
            this.caltrackexists = true;
            this.caltrackloading = false;
            var list = response.data[0].fullresp.String.split(",");
            if (list.length == 21) {
              this.config.sampid = list[0];
              this.config.sampversion = list[1];
              this.config.currentdate = list[2];
              this.config.caldate = list[3];
              this.config.nextcaldate = list[4];
              this.config.cbversion = list[5];
              this.config.batadc = list[6];
              this.config.cbid = list[7];
              this.config.offset_pre = list[8];
              this.config.alco_sen_offset = list[9];
              this.config.tmp75 = list[10];
              this.config.bt = list[11];
              this.config.alt = list[12];
              this.config.Brac_peak_factor30 = list[13];
              this.config.calibration_set_flag = list[14];
              // newly Added Params
              this.config.eng_of_tim = list[15] / 60;
              this.config.bac_val = list[16];
              console.log("dfff" + list[17]);
              this.config.random_test_enable = list[17] == 0 ? true : false;
              this.config.rolling_test_tim = list[18];
              this.config.blow_vol = list[19];
              this.config.blow_time_out = list[20];
              // Set Blow Type based on conditions
              if (
                this.config.blow_vol === "35000" &&
                this.config.blow_time_out === "800"
              ) {
                this.settings.blowType = "low";
              } else if (
                this.config.blow_vol === "55000" &&
                this.config.blow_time_out === "1000"
              ) {
                this.settings.blowType = "medium";
              } else if (
                this.config.blow_vol === "60000" &&
                this.config.blow_time_out === "1100"
              ) {
                this.settings.blowType = "high";
              }
            } else {
              this.config.sampid = list[0];
              this.config.sampversion = list[1];
              this.config.currentdate = list[2];
              this.config.caldate = list[3];
              this.config.nextcaldate = list[4];
              this.config.cbversion = list[5];
              this.config.batadc = list[6];
              this.config.cbid = list[7];
              this.config.offset_pre = list[8];
              this.config.alco_sen_offset = list[9];
              this.config.tmp75 = list[10];
              this.config.bt = list[11];
              this.config.alt = list[12];
              this.config.Brac_peak_factor30 = list[13];
              this.config.calibration_set_flag = list[14];
              // newly Added Params
              this.config.eng_of_tim = "30"; //30 mins
              this.config.bac_val = "20"; //
              this.config.random_test_enable = false;
              this.config.rolling_test_tim = "180";
              this.config.blow_vol = "35000";
              this.config.blow_time_out = "800";
            }
          } else {
            this.caltrackexists = false;
            this.caltrackloading = false;
          }
        });
      Vue.axios(
        "https://robopower.xyz/v2/alerts/getalertconfig?clientId=" +
          this.data.Vehicle.id
      ).then((resp) => {
        console.log(resp.data, "xxxxxxxxxxxxx");
        if (resp.data) {
          this.smsSettings.bypass = resp.data.bypass;
          this.smsSettings.drowsiness = resp.data.drowsiness;
          this.smsSettings.facerecognition = resp.data.facerecognition;
          this.smsSettings.fail = resp.data.fail;
          this.smsSettings.mobiledriving = resp.data.mobiledriving;
          this.smsSettings.noupdates = resp.data.noupdates;
          this.smsSettings.overspeed = resp.data.overspeed;
          this.smsSettings.overspeed_limit = resp.data.overspeed_limit;
          this.smsSettings.paymentsdue = resp.data.paymentsdue;
          this.smsSettings.refused = resp.data.refused;
          this.smsSettings.alertId = resp.data.alertId
        } else {
          console.log("No Records Found");
        }
      });
    },
    confirmSave() {
      this.showDialog = true;
    },
    proceedSave() {
      this.loading = true;
      this.showDialog = false;
      this.saveSettings();
    },
    setVolume(type) {
      if (type == "low") {
        this.config.blow_vol = "35000";
        this.config.blow_time_out = "800";
      } else if (type == "med") {
        this.config.blow_vol = "55000";
        this.config.blow_time_out = "1000";
      } else {
        this.config.blow_vol = "60000";
        this.config.blow_time_out = "1100";
      }
    },
    saveSettings() {
      var rtEnable = this.config.random_test_enable ? "0" : "1";
      var configval =
        this.config.eng_of_tim +
        "," +
        this.config.bac_val +
        "," +
        rtEnable +
        "," +
        this.config.rolling_test_tim +
        "," +
        this.config.blow_vol / 1000 +
        "," +
        this.config.blow_time_out;
      console.log(configval);
      var cmdData = {
        devId: this.data.Vehicle.cbid,
        command: "79",
        resp: configval,
        refby: this.fleetuser.dealerId,
      };
      console.log(cmdData);
      var data = JSON.stringify(cmdData);
      Vue.axios
        .post("https://robopower.xyz/app/commands", data)
        .then((res) => {
          if (res.data == "OPERATION DONE") {
            this.text = "Configuration sent successfully";
            this.alerts = true;
            this.blowtype = "low";
            this.$store.dispatch("alert/success", "OPERATION DONE").then(() => {
              var fetchData = {
                devId: this.data.Vehicle.cbid,
                command: "82",
                resp: "",
                refby: this.fleetuser.dealerId,
              };
              var infodata = JSON.stringify(fetchData);
              Vue.axios
                .post("https://robopower.xyz/app/commands", infodata)
                .then((res) => {
                  if (res.data == "OPERATION DONE") {
                    setTimeout(() => {
                      this.loading = false;
                      this.$store.dispatch("alert/success");
                    }, 2000);
                  } else {
                    this.$store
                      .dispatch("alert/error", "Fetching details Failed")
                      .then(() => {
                        setTimeout(() => {
                          this.loading = false; // Hide loader after operation
                          this.$store.dispatch("alert/clear");
                        }, 2000);
                      });
                  }
                });
            });
          } else {
            this.blowtype = "low";
            this.$store
              .dispatch("alert/error", "OPERATION NOT DONE")
              .then(() => {
                setTimeout(() => {
                  this.loading = false; // Hide loader after operation
                  console.log("Settings saved successfully!");
                }, 2000);
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        })
        .catch((error) => {
          this.$store.dispatch("alert/error", error).then(() => {
            // this.ismemloading = false
            setTimeout(() => {
              this.loading = false; // Hide loader after operation
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    resetSettings() {
      this.onLoad();
      this.settings = {
        smsService: 0,
        randomTest: 0,
        engineOffTimer: 0,
        bacCutoff: 0,
        randomTestInterval: 0,
        blowType: "medium",
      };
    },
  },
};
</script>
  
  <style scoped>
.v-btn-toggle {
  margin-top: 10px;
}
.additional-switches {
  display: flex;
  justify-content: space-between; /* Distribute switches evenly */
  gap: 16px; /* Add space between switches */
  margin-top: 16px;
}
</style>
  