<script>
import Vue from  "vue";
import axios from "axios";
import {mapGetters} from 'vuex';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import CA from '../stateforms/CA.vue'
import TX from '../stateforms/TX.vue'
import moment from "moment";
import vueConfig from "../../vue.config";
import * as XLSX from "xlsx";

Vue.use(axios);
export default {
  components: {
  CA,
  TX 
  },
  data: () => ({
    mytype:"uninstall",
    user:JSON.parse(localStorage.getItem("user")),
    search:"",
    dialog12:false,
    pdfdialog:false,
    signdialog: false,
    accepdialog: false,
    interlockdialog: false,
    removelslogdialog:false,
    sign: "",
    content: [],
    parsed: false,
    myclient: null,
    isLoadlogs: true,
    uploadgifdialog: false,
    valid: true,
    files:[],
    acceptancedata: [],
     percent: 90,
      uploadPercentage:0,
    filename: "unknown",
    versionRules: [v => !!v || "Select Version"],
      fileRules: [v => !!v || "Select File"],
    commentRules: [v => !!v || "Select Comment"],
    version: "",
    comment: "",
    viewlogs: "",
    fileBytes: null,
    textdialog: false,
    editedItem: {
        id: "",
        manufacturer: "Alcobrake",
        isinstalledandworking: "1",
        driverisincompliance: "1",
        officeId: "",
        dob: "",
        client: "",
        clientId: "",
        isunavailablevehicle: "0",
        dealer: "",
        clientphone1: "",
        isresidsame: "1",
        isbar: "",
        officelicensenumber: "",
        installer: "",
        imobile: "",
        clientphone2: "",
        suffix: "",
        email: "",
        clientAddress: "",
        space: "",
        city: "",
        state: "",
        zipcode: "",
        rclientAddress: "",
        rspace: "",
        rcity: "",
        rstate: "",
        rzipcode: "",
        regdate: "",
        regnum: "",
        uregnum: "",
        device: "",
        installDate: "",
        calibrationDate: "",
        nextcmonitordate: "",
        make: "",
        umake: "",
        model: "",
        year: "",
        uyear: "",
        color: "",
        vin: "",
        uvin: "",
        tag: "",
        tagstate: "",
        dlnum: "",
        prohibitionnumber: "",
        prohibstart: "",
        prohibend: "",
        conviction: "",
        calibrationperiod: "",
        timestamp:"",
      },
       accepheaders: [
        {
          text: "id",
          align: "left",
          value: "id",
        },
        { text: "FileName", value: "fileName" },
        { text: "Timestamp", value: "timestamp" },
        { text: "Client", value: "client" },
        { text: "Device", value: "device" },
      ],
    headers: [
      { text: "SNO", value: "sno" },
      {
        text: "Client Name",
        align: "left",
        value: "client"
      },
      { text: "RegNum", value: "regnum" },
      { text: "DLnum", value: "DLnum" },
      { text: "State", value: "state" },
      { text: "Primary Mobile", value: "clientphone1" },
      { text: "Email", value: "email" },
      { text: "Address", value: "clientAddress" },
      { text: "RegDate", value: "regdate"},
      { text: "UnInstallation Form", value: "sign" },
      { text: "Logs", value: "logs"},
      { text: "Restore", value: "restore", sortable: false }
    ],
    csvdialog10:false
     }),
  computed: {
    ...mapGetters(['removals']),
    ...mapGetters(["remlogs"]),
  },
  beforeMount() {
    this.mounteddata();
  },
    watch: {
    uploadPercentage:function(val){
      if (val == 100 || val == undefined || val == 0) {
        // console.log(val)
        this.uploadgifdialog=false;
      } else{
        this.uploadgifdialog = true;
        this.count = val;
      }
    },
    currentfile: function(val) {
      if (val == null) {
        // console.log(this.uploadPercentage)
        // this.uploadPercentage = val;
        this.currentfile = val;
      }
    }
  },
  methods: {
    csvclose() {
      this.textdialog = false
    },
  // Viewcsv() {
  //     this.textdialog = true
  //     Vue.axios.get('https://robopower.xyz/app/getImage/getcsvlog?file=' + this.viewlogs.device + '&client=' + this.viewlogs.client).then((res) => {
  //       var datass = res.data
  //       this.$papa.parse(datass, {
  //       header: true,
  //       skipEmptyLines: true,
  //       complete: function( results ){
  //           this.content = results.data;
  //           this.parsed = true;
  //       }.bind(this)
  //   });
  //       // this.fileBytes = header;
  //       console.log(this.content)
  //            })
  //     this.removelslogdialog = false
  //   },
    Viewcsv() {
      window.open('https://robopower.xyz/app/getImage/getcsvlog?file=' + this.viewlogs.device + '&client=' + this.viewlogs.client)
      this.removelslogdialog = false
    },
  // Viewtext() {
  //     // console.log(this.viewlogs)
  //     this.textdialog = true
  //     Vue.axios.get('https://robopower.xyz/app/getImage/gettextlog?file=' + this.viewlogs.device + '&client=' + this.viewlogs.client).then((res) => {
  //        console.log(res.data)
  //       this.fileBytes = res.data
  //     })
  //     this.removelslogdialog = false
  //   },
   Viewtext() {
     window.open('https://robopower.xyz/app/getImage/gettextlog?file=' + this.viewlogs.device + '&client=' + this.viewlogs.client)
      this.removelslogdialog = false
    },
     close() {
      this.removelslogdialog = false;
    },
      interlockDownloadform() {
      var that = this;
      html2canvas(document.getElementById("interlockpdfcontent")).then(function (
        canvas
      ) {
        var doc = new jsPDF("p", "mm", "a4");
        var imgData = canvas.toDataURL("image/png");
        doc.setFontSize(800);
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        doc.addImage(imgData, "JPEG", 0, 0, width, height);
        doc.save(that.filename);
        // var blob = new Blob([canvas.toDataURL("image/png")], {
        //   type: "text/plain;charset=utf-8",
        // });
      });
      // this.accepdialog = false
      this.interlockdialog = false;
    },
     interlockformclose(){
        this.interlockdialog = false;
            this.remlogs.pass = 0
     this.remlogs.enginerunstate = 0
      this.remlogs.refused= 0
      this.remlogs.fail= 0
      this.remlogs.IncorrectBreathSamples= 0    
      this.remlogs.Bypass= 0           
      this.remlogs.PowerDisconnections= 0            
      this.remlogs.HandsetDisconnections= 0           
      this.remlogs.othertampering= 0
      this.remlogs.permanantlockout = 0
    },
    Downloadform() {
      var that = this;
     html2canvas(document.getElementById("pdfcontent")).then(function (canvas) {
                var doc = new jsPDF('p','mm','a4');
                 var imgData = canvas.toDataURL('image/png');   
                    doc.setFontSize(800);
                var width = doc.internal.pageSize.getWidth();
                var height = doc.internal.pageSize.getHeight();
                doc.addImage(imgData, 'JPEG',0,0,width, height);
                doc.save(that.filename);
                var blob = new Blob([canvas.toDataURL('image/png')], {type: "text/plain;charset=utf-8"});
       const formData = new FormData();
      formData.append("file",blob);
      formData.append("filename", that.editedItem.client+"_"+that.editedItem.regnum);
      formData.append("client", that.editedItem.client);
      formData.append("device", that.editedItem.device);
    that.$store.dispatch("uploadRemovals", formData);
		});
     this.pdfdialog = false
    },
    Acceptance(item) {
      this.editedItem.id = item.id;
      this.editedItem.officeId = item;
      this.editedItem.client = item.client;
      if (item.clientphone1 != "") {
        var mdb1 = item.clientphone1.split("");
        var mb1 =
          mdb1[0] +
          " " +
          mdb1[1] +
          mdb1[2] +
          mdb1[3] +
          " " +
          mdb1[4] +
          mdb1[5] +
          mdb1[6] +
          "-" +
          mdb1[7] +
          mdb1[8] +
          mdb1[9] +
          mdb1[10];
        this.editedItem.clientphone1 = mb1;
      }
      if (item.clientphone2 != "") {
        var mdb2 = item.clientphone2.split("");
        var mb2 =
          mdb2[0] +
          " " +
          mdb2[1] +
          mdb2[2] +
          mdb2[3] +
          " " +
          mdb2[4] +
          mdb2[5] +
          mdb2[6] +
          "-" +
          mdb2[7] +
          mdb2[8] +
          mdb2[9] +
          mdb2[10];
        this.editedItem.clientphone2 = mb2;
      }
      this.editedItem.email = item.email;
      // var db = item.dob.match(/.{1,2}/g);
      // var stb = db[0] + "/" + db[1] + "/" + db[2]+db[3];
      // var mdy = moment(item.dob).format("MM/DD/YYYY");
      // this.editedItem.dob = mdy;
      var std = item.dob.split("-");
      this.editedItem.dob = std[2]+","+std[1]+","+std[0];
      this.editedItem.dlnum = item.DLnum;
      this.editedItem.clientAddress = item.clientAddress;
      this.editedItem.space = item.space;
      this.editedItem.state = item.state;
      this.editedItem.city = item.city;
      this.editedItem.sno = item.sno
      this.editedItem.zipcode = item.zipcode;
      this.editedItem.regdate = item.regdate;
      this.editedItem.regnum = item.regnum;
      this.editedItem.device = item.device;
      this.editedItem.installDate = item.installDate;
      this.editedItem.calibrationDate = item.calibrationDate;
      this.editedItem.nextcmonitordate = item.nextcmonitordate;
      this.editedItem.make = item.make;
      this.editedItem.model = item.model;
      this.editedItem.year = item.year;
      this.editedItem.color = item.color;
      this.editedItem.vin = item.vin;
      this.editedItem.tag = item.tag;
      this.editedItem.tagstate = item.tagstate;
      // this.editedItem.dlnum = item.dlnum;
      this.editedItem.prohibitionnumber = item.prohibitionnumber;
      this.editedItem.prohibstart = item.prohibstart;
      this.editedItem.prohibend = item.prohibend;
      this.editedItem.conviction = item.conviction;
      this.editedItem.calibrationperiod = item.calibrationperiod;
      this.editedItem.suffix = item.suffix;
      this.editedItem.rclientAddress = item.rclientaddress;
      this.editedItem.rspace = item.rspace;
      this.editedItem.rstate = item.rstate;
      this.editedItem.rcity = item.rcity;
      this.editedItem.rzipcode = item.rzipcode;
      this.editedItem.isresidsame = item.isresidsame;
      this.editedItem.isinstalledandworking = item.isinstalledandworking;
      this.editedItem.driverisincompliance = item.driverisincompliance;
      this.editedItem.isunavailablevehicle = item.isunavailablevehicle;
      this.editedItem.uregnum = item.uregnum;
      this.editedItem.umake = item.umake;
      this.editedItem.uyear = item.uyear;
      this.editedItem.uvin = item.uvin;
      this.fetchInstaller(item.installer);
      this.fetchOfficedetails();
    },
    fetchInstaller(id) {
      Vue.axios
        .get("https://robopower.xyz/us/causers/getuniqueuser?userId=" + id)
        .then((response) => {
          var ssm = response.data[0];
          this.editedItem.installer = ssm;
          var mdb1 = ssm.userMobile1.split("");
      var mbb =
        mdb1[0] +
        mdb1[1] +
        mdb1[2] +
        " " +
        mdb1[3] +
        mdb1[4] +
        mdb1[5] +
        "-" +
        mdb1[6] +
        mdb1[7] +
        mdb1[8] +
        mdb1[9];
        this.editedItem.imobile = mbb;
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
    fetchOfficedetails() {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caoffices/getuniqueoffice?officeId=" +
            this.editedItem.officeId.officeId
        )
        .then((response) => {
          if (response.data != null) {
            var x = response.data[0];
            this.editedItem.officeId = x;
            this.editedItem.officeName = x.officeName;
            this.editedItem.isbar = x.isbar;
            this.editedItem.faddress = x.officeaddress;
            this.editedItem.fcity = x.city;
            this.editedItem.fstate = x.state;
            this.editedItem.fzipcode = x.zipcode;
            this.editedItem.officelicensenumber = x.officelicense;
            this.editedItem.dealer = x.dealerName;
            this.$store.dispatch("fetchdealerUsers", x.dealerId);
            // if(this.user.role == "Admin"){
            this.$store.dispatch("fetchdealerDevices", x.dealerId);
            // }
            if (this.editedIndex == -10) {
              this.dialog = true;
            }
          } else {
            console.log("no data");
          }
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
     close12() {
      this.dialog12 = false;
      this.undo();
    },
    removelsLogsdialog(item) {
      this.viewlogs = item
     this.removelslogdialog = true
    },
    Client(item){
      // console.log(item)
      if (item.state == "CA" || item.state == "INDIA") {
        this.filename = item.client + "_" + item.regnum;
        this.myclient = item
        this.Acceptance(item);
        this.pdfdialog = true
      }else{
        this.filename = item.client + "_" + item.regnum;
        this.myclient = item
        this.$store.dispatch("fetchremovalLogs", item.device);
        this.Acceptance(item);
        // console.log(this.remlogs)
        this.interlockdialog = true;
      }
    },
    print(){
 this.$htmlToPaper("printme");
    },
    formclose : function(){
this.pdfdialog =false
    },
    closeacc(){
      this.signdialog = false
    },
      accclose(){
        this.accepdialog =  false
        this.signdialog = false
  },
     newacceptance(myclient){
      this.dialog12 = true;
      this.Acceptance(myclient);
      this.pdfdialog =true
      this.signdialog = false
    },
    acceptance(id){
      this.myclient = id;
      this.accepdialog = true

    },
     undo() {
      var _this = this;
      _this.$refs.signaturePad.undoSignature();
    },
     onBegin() {
      console.log("=== Begin ===");
    },
    onEnd() {
      // console.log("=== End ===");
      setTimeout(() => {
        this.saveSign();
      }, 500);
    },
     saveSign() {
      var _this = this;
      this.$refs.signaturePad.resizeCanvas();
      var png = _this.$refs.signaturePad.saveSignature();
      this.sign = png.data;
    },
    restoreClient(item){
const Swal = require("sweetalert2");
       Swal.fire({
          title: 'Are you sure? You want to Restore '+item.regnum+ 'Vehicle',
          text: 'You can\'t revert your action',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          showCloseButton: true,
          showLoaderOnConfirm: true
        }).then((result) => {
          if(result.value) {         
             this.$store.dispatch('alert/success','Successfully Restored to Clients').then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
             })
             this.$store.dispatch('restoreFullclientprohib', item)  
            this.$store.dispatch("fetchRemovals") 
          } else {
            // this.$swal('Cancelled', 'Your file is still intact', 'info')
            this.$store.dispatch('alert/error',"Your file is still intact").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
          }
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },     
    mounteddata() {
if(this.user.role=="Admin"|| this.user.role == 'superadmin'){
    this.$store.dispatch('fetchRemovals')
    } else{
      this.$store.dispatch('fetchdealerRemovals',this.user.dealerId);
    }
    },
    Export() {
      if (this.removals.length < 0) {
        this.$store
          .dispatch("alert/error", "Data Loading Please Wait")
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      } else {
        this.csvdialog10 = true;
      }
    },
    csvclose10() {
      this.csvdialog10 = false;
    },

    removelsFiledownload(type){
      
  const worksheetData = [
    [
      "DLnum","officeId","sno","suffix","client", "devId", "cbid", "clientAddress",
      "clientphone1", "clientphone2", "dob","email", "autorenewal", "autorenewal_id",
      "calibrationDate", "calibrationperiod","installDate","nextcmonitordate","state", "city","zipcode","year","tag","space","vin",
      "status", "color","installer","isinstalledandworking",
      "conviction", "driverisincompliance", "facedetection",
      "isresidsame", "issms", "isunavailablevehicle", "make", "model",
      "prohibend", "prohibitionnumber", "prohibstart","customer_id","manufacturer","rcity","rclientaddress","regdate",
      "regnum","routeid","umake",
      "tagstate","rspace","rstate","rzipcode","uregnum","uvin","uyear"
    ],
    ...this.removals.map(row => {
          // First office: include all dealer fields
          return [
            row.DLnum || '',
            row.officeId || '',
            row.sno || '',
            row.suffix || '',
            row.client || '',
            row.devId || '',
            row.cbid || '',
            row.clientAddress || '',
            row.clientphone1 || '',
            row.clientphone2 || '',
            row.dob || '',
            row.email || '',
            row.autorenewal_id || '',
            row.autorenewal || '',
            row.calibrationDate || '',
            row.calibrationperiod || '',
            row.installDate || '',
            row.nextcmonitordate || '',
            row.state || '',
            row.city || '',
            row.zipcode || '',
            row.year || '',
            row.tag || '',
            row.space || '',
            row.vin || '',
            row.status || '',
            row.color || '',
            row.installer || '',
            row.isinstalledandworking || '',
            row.conviction || '',
            row.driverisincompliance || '',
            row.facedetection || '',
            row.isresidsame || '',
            row.issms || '',
            row.isunavailablevehicle || '',
            row.make || '',
            row.model || '',
            row.prohibend || '',
            row.prohibitionnumber || '',
            row.prohibstart || '',
            row.customer_id || '',
            row.manufacturer || '',
            row.rcity || '',
            row.rclientaddress || '',
            row.regdate || '',
            row.regnum || '',
            row.routeid || '',
            row.umake || '',
            row.tagstate || '',
            row.rspace || '',
            row.rstate || '',
            row.rzipcode || '',
            row.uregnum || '',
            row.uvin || '',
            row.uyear || '',
          ];
    })
  ];

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Set column widths
  worksheet["!cols"] = [
    { wch: 12 }, // DLnum
    { wch: 7 }, // officeId
    { wch: 20 }, // sno
    { wch: 6 }, // suffix
    { wch: 28 }, // client
    { wch: 26 }, // devId
    { wch: 26 }, // cbid
    { wch: 28 }, // clientAddress
    { wch: 15 }, // clientphone1
    { wch: 15 }, // clientphone2
    { wch: 15 }, // dob
    { wch: 25 }, // email
    { wch: 14 }, // autorenewal_id
    { wch: 18 }, // autorenewal
    { wch: 19 }, // calibrationDate
    { wch: 20 }, // calibrationperiod
    { wch: 18 }, // installDate
    { wch: 20 }, // nextcmonitordate
    { wch: 10 }, // state
    { wch: 15 },  // city
    { wch: 15 }, // zipcode
    { wch: 10 }, // year
    { wch: 14 }, // tag
    { wch: 10 }, // space
    { wch: 22 }, // vin
    { wch: 15 }, // status
    { wch: 10 }, // color
    { wch: 10 }, // installer
    { wch: 19 }, // isinstalledandworking
    { wch: 14 }, // conviction
    { wch: 20 }, // driverisincompliance
    { wch: 15 }, // facedetection
    { wch: 13 }, // isresidsame
    { wch: 13 }, // issms
    { wch: 17 }, // make
    { wch: 14 }, // model
    { wch: 16 }, // prohibend
    { wch: 16 }, // prohibitionnumber
    { wch: 16 }, // prohibstart
    { wch: 10 }, // customer_id
    { wch: 20 }, // manufacturer
    { wch: 15 }, // rcity
    { wch: 28 }, // rclientaddress
    { wch: 25 },  // regdate
    { wch: 25 }, // regnum
    { wch: 10 }, // routeid
    { wch: 14 }, // umake
    { wch: 8 }, // tagstate
    { wch: 15 }, // rspace
    { wch: 15 }, // rstate
    { wch: 10 }, // rzipcode
    { wch: 10 }, // uregnum
    { wch: 28 },  // uvin
    { wch: 8 }, // uyear
  ];
  if(type === "csv"){
    console.log('csv')
  // Create a workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Removels");

  // Write workbook to file
  const blob = new Blob([XLSX.write(workbook, { bookType: "xlsx", type: "array" })], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  // Download the file
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "removels.xlsx";
  link.click();
  this.csvdialog10 = false;
}else{
  console.log("txt")
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Removels");

  // Write workbook to file
  const blob = new Blob([XLSX.write(workbook, { bookType: "txt", type: "array" })], {
    type: "text/plain;charset=utf-8;",
  });
  // text/plain;charset=utf-8; and bookType: txt

  // Download the file
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "removels.txt";
  link.click();
  this.csvdialog10 = false;
}
},
  }
};
</script>
<template>
  <v-container fluid>
     <!-- <v-dialog v-model="signdialog" max-width="400px">
                <v-toolbar text color="orange">
              <v-toolbar-title>Do You Want to Add?</v-toolbar-title>
                  <v-layout justify-end>
                      <v-icon color="primary" size="30px" @click="closeacc"> mdi-close </v-icon>
                </v-layout>
                </v-toolbar>
                <v-row>
                  <v-btn color="blue" @click="acceptance(myclient)" style="margin-left:30px">
                    Acceptance
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="newacceptance(myclient)" style="margin-right:10px">
                    922 FORM
                  </v-btn>
                </v-row>
              </v-dialog> -->

            <v-dialog  v-model="removelslogdialog" max-width="550px" style="border-radius:20px !important">
          
                <v-card color="" text style="border-radius:20px" >
                
                   <v-toolbar class="mb-4" flat color="#00A3A3" dark dense style="border-radius:20px">
                    <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">Please Choose The Format</v-toolbar-title>
                    <v-toolbar-title v-else style="font-size:12px">Please Choose The Format</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-layout justify-end>
                      <v-btn color="black" @click="close"   class="sm-0" style="border-radius:20px"><v-icon>mdi-close</v-icon>
                      </v-btn>
                  
                    </v-layout>
                   </v-toolbar>
                   <div align="center">
                     
                       <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mb-2"
                        tile color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="Viewcsv"
                        style="border-radius:10px;color:white"
                      >
                        CSV
                        <v-icon right>
                          mdi-texture
                        </v-icon>
                          </v-btn>
                        </template>

                        <span>split data with delimiter semi-colon(;)</span>
                      </v-tooltip>
                  </div>
                   <div align="center" >
                      <v-btn class="mb-2" color="#334c3e"
                        tile
                      
                        @click="Viewtext" style="border-radius:10px;color:white"
                      >
                        TEXT Document
                        <v-icon right>
                          mdi-comment-text-outline
                        </v-icon>
                      </v-btn>
                                
                   </div>
                  
              
              </v-card>
            
              
            </v-dialog>






              <!-- <v-dialog v-model="accepdialog" max-width="800px">
          <v-toolbar text color="#26BB44" class="" dark dense>
              <v-row>
                    <v-toolbar-title>Acceptance</v-toolbar-title>
                    <v-layout justify-end>
                 <v-btn color="primary"  @click="accclose" dark v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                      >Close</v-btn>
                      <v-icon v-else color="primary" size="40px" @click="accclose"> mdi-close </v-icon>
                </v-layout>
              </v-row>
                  </v-toolbar>
              <v-data-table 
               dense
        :headers="accepheaders"
        :items="acceptancedata"
        :search="search"
        class="elevation-1">
              </v-data-table>
              </v-dialog> -->

               <v-dialog v-model="interlockdialog"  max-width="955px" >
               <v-card style="text-align:center;" v-if="!remlogs.total"><h1>Loading...</h1></v-card>
         <TX v-else :editedItem='editedItem' :type="mytype" :mylogdata="remlogs"></TX>
      <v-card v-if="remlogs.total">
        <v-card-text>
          &nbsp;
            <v-row justify="end">
            <v-btn color="red" left @click="interlockformclose" style="background:#ff3d41;color:white;">Cancel</v-btn>
            &nbsp;
            <v-btn color="primary" @click="interlockDownloadform">Download</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pdfdialog" max-width="955px" class="form-dialog">
    <CA :editedItem="editedItem" :type="mytype"></CA>
                     <v-card>
        <v-card-text>
                   <v-row>
                     <v-col class="col-md-12" align="Right">
                       <v-btn color="" @click="formclose"   style="background:#ff3d41;color:white;margin-right:10px">Cancel</v-btn>
                       <!-- &nbsp;
                    <v-btn color="primary" @click="print">print</v-btn>
                  &nbsp; -->
                      <v-btn color="primary" @click="Downloadform">Download</v-btn>
                   
                     </v-col>
                   </v-row>
                   </v-card-text>
                   </v-card>                                
                           </v-dialog>

         <v-dialog v-model="textdialog">
          <v-card color="#26BB44" text>
        <v-card-title class="white--text" >
          LOGS
          <v-spacer></v-spacer>          
           <v-layout justify-end>
                <v-btn color="primary" @click="csvclose"   class="sm-0" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">Close
                </v-btn>
                 <v-icon  v-else @click="csvclose"  size="40px"  color="primary" >
                  mdi-close</v-icon>
           </v-layout>
        </v-card-title>
      </v-card>
      <v-card v-if="content">
        <v-card-text>
          {{content}}
        </v-card-text>
      </v-card>                
    </v-dialog>

    <!-- print form -->
    <div id="printme"  style="display:none;">        
          <div style="margin-top:240px">
      <p style="font-size:20px;margin-left:850px;font-weight:bold;">{{editedItem.dlnum}}</p>
      <br/>
      <v-row style="margin-top:22px;">
        <v-col cols="9">
<p style="font-size:20px;margin-left:10px;font-weight:bold;">{{editedItem.client}}</p>
        </v-col>
        <v-col cols="3">
        <p style="font-size:20px;margin-left:10px;font-weight:bold;">{{editedItem.suffix}}</p>
        </v-col>
      </v-row>
      
      <v-row style="margin-top:4px;">
        <v-col cols="9">
        <p style="font-size:20px;margin-left:10px;font-weight:bold;">{{editedItem.clientAddress}}</p>
        </v-col>
        <v-col cols="3">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;"></p>
        <!-- <p style="font-size:20px;margin-left:10PX;font-weight:bold;">{{editedItem.space}}</p> -->
        </v-col>
      </v-row>
      <v-row style="margin-top:4px;">
        <v-col cols="5">
        <p style="font-size:20px;margin-left:10px;font-weight:bold;">{{editedItem.city}}</p>
        </v-col>
        <v-col cols="4">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;">{{editedItem.state}}</p>
        </v-col>
        <v-col cols="3">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;">{{editedItem.zipcode}}</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:4px;">
        <v-col cols="9">
        <p style="font-size:20px;margin-left:10px;font-weight:bold;">{{editedItem.rclientAddress}}</p>
        </v-col>
        <v-col cols="3">
        <!-- <p style="font-size:20px;margin-left:10PX;font-weight:bold;">{{editedItem.rspace}}</p> -->
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
        <p style="font-size:20px;margin-left:10px;font-weight:bold;">{{editedItem.rcity}}</p>
        </v-col>
        <v-col cols="4">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;">{{editedItem.rstate}}</p>
        </v-col>
        <v-col cols="3">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;">{{editedItem.rzipcode}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3.5">
        <p style="font-size:20px;margin-left:10px;font-weight:bold;margin-top:5px;">{{editedItem.dob.replaceAll("/",'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')}}</p>
        </v-col>
        <v-col cols="3.5">
        <p style="font-size:20px;font-weight:bold;margin-top:5px;">{{editedItem.clientphone1.replaceAll(" ",'&nbsp;&nbsp;&nbsp;&nbsp;')}}</p>
        </v-col>
        <v-col cols="5">
        <p style="font-size:20px;font-weight:bold;margin-left:70px;margin-top:5px;">{{editedItem.clientphone2.replaceAll(" ",'&nbsp;&nbsp;&nbsp;&nbsp;')}}</p>
        </v-col>
      </v-row>

    <br/>
      <v-row style="margin-top:15px;">
        <v-col cols="12">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">ALCOBRAKE PRIVATE LIMITED</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:20px;">
        <v-col cols="5">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">{{editedItem.officeName}}</p>
        </v-col>
        <v-col cols="7">
          <v-row>
          <v-col style="margin-left:17px;margin-bottom:32px" cols="3">
          <v-row >
            <b-icon v-if="editedItem.isbar == '1'" style="width:25px" icon="check"/>
          <!-- <v-checkbox v-model="isbar" input-value="1" ></v-checkbox> -->
          <p v-else></p>
          </v-row>
          <v-row>
          <b-icon v-if="editedItem.isbar != '1'" style="width:25px" icon="check"/>
          <p v-else></p>
          </v-row>
          </v-col>
          <v-col cols="4">
            <p style="font-size:20px;margin-left:30PX;font-weight:bold;">{{editedItem.officelicensenumber}}</p>
          </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
        <p style="font-size:20px;margin-left:18px;font-weight:bold;">{{editedItem.faddress}}</p>
        </v-col>
        <v-col cols="4">
        <p style="font-size:20px;font-weight:bold;">{{editedItem.fcity}}</p>
        </v-col>
        <v-col cols="1.5">
        <p style="font-size:20px;margin-left:8PX;font-weight:bold;">{{editedItem.fstate}}</p>
        </v-col>
        <v-col cols="1.5">
                <p style="font-size:20px;font-weight:bold;margin-left:10px;">{{editedItem.fzipcode}}</p>
        </v-col>
      </v-row>
           <v-row style="margin-top:40px;">
        <v-col cols="8">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">{{editedItem.installDate}}</p>
        </v-col>
        <v-col cols="4">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">{{editedItem.nextcmonitordate}}</p>
        </v-col>
      </v-row>
       <v-row style="margin-top:50px;">
        <v-col cols="2.5">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">{{editedItem.make}}</p>
        </v-col>
        <v-col cols="1">
          <p style="font-size:20px;font-weight:bold;">{{editedItem.year}}</p>
        </v-col>
        <v-col cols="2.5">
          <p style="font-size:20px;margin-left:10PX;font-weight:bold;">{{editedItem.regnum}}</p>
        </v-col><v-col cols="6">
          <p style="font-size:20px;font-weight:bold;">{{editedItem.vin}}</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:35px;">
        <v-col cols="6">
          <p style="font-size:20px;margin-left:4PX;font-weight:bold;">{{editedItem.prohibstart}}</p>
        </v-col>
        <v-col cols="6">
          <p style="font-size:20px;margin-left:4PX;font-weight:bold;">{{editedItem.prohibend}}</p>
        </v-col>
      </v-row>
       <v-row style="margin-top:10px;">
        <b-icon style="width:25px" icon="check"/>
      </v-row>
      <v-row style="margin-top:5px;">
        <b-icon style="width:25px" icon="check"/>
      </v-row>
      <v-row style="margin-top:45px;">
        <v-col cols="3">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">{{editedItem.umake}}</p>
        </v-col>
        <v-col cols="1">
          <p style="font-size:20px;font-weight:bold;">{{editedItem.uyear}}</p>
        </v-col>
        <v-col cols="2">
          <!-- <p style="font-size:20px;margin-left:10PX;font-weight:bold;"></p> -->
          <p style="font-size:20px;margin-left:10PX;font-weight:bold;">{{editedItem.uregnum}}</p>
        </v-col><v-col cols="6">
          <p style="font-size:20px;font-weight:bold;">{{editedItem.uvin}}</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:120px;">
        <v-col cols="8">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">{{editedItem.installer.userName}}</p>
        </v-col>
        <v-col cols="4">
          <p style="font-size:20px;font-weight:bold;margin-left:-15px">{{editedItem.imobile.replaceAll(" ",'&nbsp;&nbsp;&nbsp;&nbsp;')}}</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:10px;">
        <v-col cols="8">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;"><img :src="sign" v-if="sign" height="70" width="250" /></p>
        </v-col>
        <v-col cols="4">
          <p style="font-size:20px;font-weight:bold;">{{editedItem.installDate}}</p>
        </v-col>
      </v-row>
  </div>
</div>

<!-- 
    <v-dialog width="600" v-model="dialog12" >
                <v-toolbar text color="#26BB44" dark dense>
              <v-toolbar-title>SIGNATURE</v-toolbar-title>
                </v-toolbar>
                <v-card color="grey lighten-4" text>
                      <VueSignaturePad
                          id="signature"
                          width="100%"
                          height="400px"
                          ref="signaturePad"
                          :options="{ onBegin, onEnd }"
                        />
                         <v-card-actions>
                           <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close12">
                      Cancel
                    </v-btn>
                  
                        </v-card-actions>
                
                </v-card>
              </v-dialog> -->
             <v-card color="grey lighten-4" flat style="border-radius:20px">
               
               
      <v-data-table
        dense
        :headers="headers"
        :items="removals"
        sort-by="id"
        item-key="id"
        :search="search"
        class="elevation-1" 
      >
      <template v-slot:top>
                  <v-toolbar class="mb-4" flat color="#00A3A3" dark dense style="border-radius:20px">
              
              <v-toolbar-title>REMOVALS</v-toolbar-title>
              <!-- <v-toolbar-title v-else style="font-size:13px">REMOVALS</v-toolbar-title> -->
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-row>
         <v-col class="col-md-6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
                 </v-col>
               </v-row>
               <v-dialog width="400px" v-model="csvdialog10">
                <template v-slot:activator="{ on }">
                  <v-row>
                    <v-col v-if="user.role == 'Admin' || user.role == 'superadmin'">
                      <v-layout justify-end>
                        <v-btn v-on="on" style="border-radius:20px;background:black" @click="Export">
                          <h5>Export</h5>
                        </v-btn>

                      </v-layout>
                    </v-col>
                  </v-row>

                </template>
              <v-card text style="border-radius: 20px">
                <v-toolbar
                  color="#00A3A3"
                  style="border-radius: 20px; color: white"
                >
                  <v-toolbar-title
                    v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                  >
                    Please Choose The Format
                  </v-toolbar-title>
                  <v-toolbar-title v-else style="font-size: 15px">
                    Please Choose The Format
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-layout justify-end>
                    <v-btn
                      color="black"
                      @click="csvclose10"
                      class="sm-0"
                      style="border-radius: 20px"
                      ><v-icon color="white"> mdi-close</v-icon>
                    </v-btn>
                  </v-layout>
                </v-toolbar>
                <div align="center">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-4"
                        tile
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="removelsFiledownload('csv')"
                        style="border-radius: 10px; color: white"
                      >
                        CSV Format
                        <v-icon right> mdi-download </v-icon>
                      </v-btn>
                    </template>

                    <span>split data with delimiter semi-colon(;)</span>
                  </v-tooltip>
                </div>
                &nbsp;
                <!-- <div align="center">
                  <v-btn
                    class="mt-2 mb-2"
                    tile
                    color="#334c3e"
                    @click="removelsFiledownload('txt')"
                    style="border-radius: 10px; color: white"
                  >
                    TEXT Document
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </div> -->
              </v-card>
            </v-dialog>
                </v-toolbar>
              
               </template>
      <template slot=item.restore slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="red" @click="restoreClient(item)" alt="" v-bind="attrs"
          v-on="on">
            mdi-refresh
          </v-icon>
      </template>
      <span>Restore to Clients</span>
    </v-tooltip>
        </template>
  
        <template slot=item.sign slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="blue" @click="Client(item)" alt="" v-bind="attrs"
          v-on="on">
           mdi-note-text
          </v-icon>
      </template>
      <span>Uninstallation Form</span>
    </v-tooltip>
        </template>
    
        <template slot=item.logs slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <img src='../assets/logs.png' width='25' height='25' @click="removelsLogsdialog(item)" alt="" v-bind="attrs"
          v-on="on">
      </template>
      <span>LOGS</span>
    </v-tooltip>
        </template>
              </v-data-table>
             </v-card>   
  </v-container>
</template>
<style scoped>
body {
  background: #ddd;
}

.container {
  background: white;
  padding: 0 0.7rem 1rem;
}

.top-heading {
  background: rgb(74, 118, 175);
  color: white;
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
@media print {
  .hide-print {
    display: none;
    visibility: hidden;
  }

  .input-field input,
  table input {
    border-bottom: none;
  }
}


</style>