<script>
import {mapGetters} from 'vuex';
export default {
  data: () => ({
    user  : JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    headers: [
      {text: "Term",value: "term"},
      { text: "Registration Fee", value: "registration_fee" },
      { text: "Admission Fee", value: "admission_fee" },
      { text: "Tuition Fee", value: "tuition_fee" },
      { text: "Exam Fee", value: "exam_fee" },
    ],
  }),

  computed: {
     Reminder() {
      return this.schoolfees
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Technician" : "Edit Technician";
    },
     buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(['schoolfees']),

  },

  watch: {

  },

  created() {
    this.initialize();
  },
  mounted() {
     this.$store.dispatch('fetchschoolFees',this.user.dealerId)
  },

  methods: {
    initialize() { },
  }
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius:20px">
      <v-data-table
        dense
        :headers="headers"
        :items="Reminder"
        item-key="fee_id"
        sort-by="term"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2
{
  color:#0ae2e2 !important;
  border-bottom:1px dashed white
}
.content1
{
  color: white !important;
  margin-left:8px;
}
.off-icon
{
  color:white;
  background:#00A3A3;
  padding:2px;
  border-radius:10px;
}

</style>