<script>
//import getInfo from "../scripts/getInfo";
import { mapGetters } from "vuex";
import * as XLSX from "xlsx";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    show: false,
    states: [
      "AL",
      "AK",
      "AS",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FM",
      "FL",
      "GA",
      "GU",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MH",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "MP",
      "OH",
      "OK",
      "OR",
      "PW",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VI",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
      "INDIA",
    ],
    // access: [{ text: "Admin" }, { text: "Manager" }, { text: "Technician" }],
    headers: [
      // { text: "ID", value: "officeId" },
      {
        text: "Name",
        align: "left",
        value: "officeName",
      },
      { text: "Phone", value: "officeMobile1" },
      // { text: "Phone 2", value: "officeMobile2" },
      { text: "Dealer", value: "dealer" },
      { text: "Actions", value: "action", sortable: false },
      { text: "Delete", value: "delete", sortable: false },
      { text: "More..", value: "data-table-expand" },
    ],
    editedIndex: -1,
    defcountry: "us",
    editedItem: {
      form: "",
      officeName: "",
      officeId: "",
      officeMobile1: "",
      officeMobile2: "",
      officeemail: "",
      officehome: "",
      officework: "",
      officepassword: "",
      officeaddress: "",
      city: "",
      state: "",
      zipcode: "",
      isbar: "1",
      officelicense: "",
      dealerName: "",
    },
    defaultItem: {
      officeName: "",
      officeId: "",
      officeMobile1: "",
      officeMobile2: "",
      officeemail: "",
      officehome: "",
      officework: "",
      officepassword: "",
      officeaddress: "",
      city: "",
      state: "",
      zipcode: "",
      isbar: "1",
      officelicense: "",
      dealerName: "",
    },
    valid: true,
    cityRules: [
      (v) => !!v || "City is required",
      (v) => (v && v.length >= 3) || "City must contain 3 or more characters",
    ],
    stateRules: [(v) => !!v || "Select State"],
    zipRules: [
      (v) => !!v || "Zip is required",
      (v) => (v && v.length == 5) || "Enter valid zip code",
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Name must contain 3 or more characters",
      (v) => (v && v.length <= 50) || "Name must be less than 50 characters",
    ],
    idRules: [(v) => !!v || "Id is required"],
    //  phoneRules: [
    //     v => !!v || "Phone is Required",
    //     v => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
    //     v => v != 0 || "Phone Number can't be zeoro!"
    // ],
    phoneRules: [
      (v) => !!v || "Phone is required",
      (v) =>
        (v && v.length >= 10 && v.length <= 14) || "Enter valid phone number",
    ],
    phoneRulesa: [
      (v) => !!v || "Phone is required",
      (v) =>
        (v && v.length >= 10 && v.length <= 11) || "Enter valid phone number",
    ],
    phone2Rulesa: [
      (v) => !!v || "Phone is required",
      (v) =>
        (v && v.length >= 10 && v.length <= 11) || "Enter valid phone number",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length >= 8) || "Password must contain 8 or more charactors",
    ],
    addressRules: [(v) => !!v || "Address is required"],
    licenseRules: [(v) => !!v || "Bar License is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
        "E-mail must be valid",
    ],
    phone2Rules: [
      (v) => !!v || "Phone2 is Required",
      (v) => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
      (v) => v != 0 || "Phone Number can't be zeoro!",
    ],
    //   phoneRules: [
    //   v => !!v || 'Phone is required',
    //   v => v && v.length > 10 && v.length < 15 || 'Enter valid phone number',
    // ],

    dealerRules: [(v) => !!v || "Select Dealer"],
    csvdialog10: false,
  }),

  computed: {
    resultQuery() {
      if (this.expanded.length > 0) {
        var mylist = this.expanded;
        return mylist;
      } else {
        return this.offices;
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Office" : "Edit Office";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(["offices"]),
    ...mapGetters(["dealers"]),
  },

  watch: {
    defcountry(val) {
      this.defcountry = val;
      setTimeout(() => {
        this.defcountry = "us";
      }, 500);
    },
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  mounted() {
    if (this.user.role == "dealer") {
      this.editedItem.dealerName = this.user.dealerId;
    }
    this.mountdata();
  },

  methods: {
    onSelect({ dialCode }) {
      this.editedItem.officehome = dialCode;
    },
    onSelect2({ dialCode }) {
      this.editedItem.officework = dialCode;
    },
    isNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    mountdata() {
      this.$store.dispatch("fetchDealers");
      if (this.user.role == "Admin" || this.user.role == "superadmin") {
        this.$store.dispatch("fetchOffices");
      } else {
        this.$store.dispatch("fetchdealerOffices", this.user.dealerId);
      }
    },
    deleteOffice(office) {
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to delete Office - " + office.officeName,
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes Delete it!",
          cancelButtonText: "No, Keep it!",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        })
          .then((result) => {
            if (result.value) {
              this.$store
                .dispatch("deleteOffice", office.officeId)
                .then(() => {
                  this.mounteddata();
                })
                .catch((err) => {
                  console.log("first");
                  this.$store.dispatch("alert/error", err).then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/clear");
                    }, 2000);
                  });
                });
              // this.$swal('Deleted', 'You successfully deleted this file', 'success')
              console.log("second");
              this.$store
                .dispatch("alert/success", "You successfully deleted this file")
                .then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
            } else {
              // this.$swal('Cancelled', 'Your file is still intact', 'info')
              console.log("third");
              this.$store
                .dispatch("alert/error", "Your file is still intact")
                .then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    loadFormData() {
      const formData = new FormData();
      formData.append("officeName", this.editedItem.officeName);
      formData.append(
        "officeMobile1",
        this.editedItem.officehome + this.editedItem.officeMobile1
      );
      formData.append(
        "officeMobile2",
        this.editedItem.officework + this.editedItem.officeMobile2
      );
      formData.append("officeemail", this.editedItem.officeemail);
      formData.append("officepassword", this.editedItem.officepassword);
      formData.append("officeaddress", this.editedItem.officeaddress);
      formData.append("city", this.editedItem.city);
      formData.append("state", this.editedItem.state);
      formData.append("zipcode", this.editedItem.zipcode);
      formData.append("isbar", this.editedItem.isbar);
      formData.append("officelicense", this.editedItem.officelicense);
      formData.append("dealer", this.user.dealerId);
      return formData;
    },
    editloadFormData() {
      const formData = new FormData();
      formData.append("officeName", this.editedItem.officeName);
      formData.append("officeMobile1", this.editedItem.officeMobile1);
      formData.append("officeMobile2", this.editedItem.officeMobile2);
      formData.append("officeemail", this.editedItem.officeemail);
      formData.append("officepassword", this.editedItem.officepassword);
      formData.append("officeaddress", this.editedItem.officeaddress);
      formData.append("city", this.editedItem.city);
      formData.append("state", this.editedItem.state);
      formData.append("zipcode", this.editedItem.zipcode);
      formData.append("isbar", this.editedItem.isbar);
      formData.append("officelicense", this.editedItem.officelicense);
      formData.append("dealer", this.user.dealerId);
      return formData;
    },
    initialize() {},

    editItem(item) {
      if (this.user.emailId != "Justin@alcobrake.com") {
        // console.log(item)
        this.editedIndex = this.offices.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },

    close() {
      this.dialog = false;
      this.defcountry = "US";
      this.$refs.form.reset();
      this.editedIndex = -1;
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.editedItem.officeMobile2 == undefined)
        this.editedItem.officeMobile2 = "";

      const fd = this.loadFormData();
      if (this.editedIndex == -1) {
        this.$store
          .dispatch("createOffice", fd)
          .then(() => {
            this.mountdata();
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      } else {
        const editfd = this.editloadFormData();
        var postdata = {
          odata: editfd,
          id: this.editedItem.officeId,
        };
        this.$store
          .dispatch("editOffice", postdata)
          .then(() => {
            this.mountdata();
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      }
      this.close();
    },
    Export() {
      if (this.dealers.length < 0) {
        this.$store
          .dispatch("alert/error", "Data Loading Please Wait")
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      } else {
        console.log(this.dealers);
        this.csvdialog10 = true;
      }
    },
    csvclose10() {
      this.csvdialog10 = false;
    },

    officesFiledownload(type) {
      const worksheetData = [
        [
          "Dealer ID",
          "Dealer",
          "Office ID",
          "Office Name",
          "Office Mobile 1",
          "Office Mobile 2",
          "Office Email",
          "Office Password",
          "Office Address",
          "City",
          "State",
          "Zipcode",
          "Is Bar",
          "Office License",
        ],
        ...this.offices.map((office) => {
          return [
            office.dealerId || "",
            office.dealer || "",
            office.officeId || "",
            office.officeName || "",
            office.officeMobile1 || "",
            office.officeMobile2 || "",
            office.officeemail || "",
            office.officepassword || "",
            office.officeaddress || "",
            office.city || "",
            office.state || "",
            office.zipcode || "",
            office.isbar || "",
            office.officelicense || "",
          ];
        }),
      ];

      // Create a worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Set column widths
      worksheet["!cols"] = [
        { wch: 6 }, // Dealer ID
        { wch: 20 }, // Dealer
        { wch: 7 }, // Office ID
        { wch: 28 }, // Office Name
        { wch: 15 }, // Office Mobile 1
        { wch: 15 }, // Office Mobile 2
        { wch: 27 }, // Office Email
        { wch: 17 }, // Office Password
        { wch: 35 }, // Office Address
        { wch: 15 }, // City
        { wch: 10 }, // State
        { wch: 10 }, // Zipcode
        { wch: 5 }, // Is Bar
        { wch: 17 }, // Office License
      ];
      if (type === "csv") {
        console.log("csv");
        // Create a workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Offices");

        // Write workbook to file
        const blob = new Blob(
          [XLSX.write(workbook, { bookType: "xlsx", type: "array" })],
          {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }
        );
        // Download the file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "offices.xlsx";
        link.click();
        this.csvdialog10 = false;
      } else {
        console.log("txt");
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Offices");

        // Write workbook to file
        const blob = new Blob(
          [XLSX.write(workbook, { bookType: "txt", type: "array" })],
          {
            type: "text/plain;charset=utf-8;",
          }
        );
        // text/plain;charset=utf-8; and bookType: txt

        // Download the file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "offices.txt";
        link.click();
        this.csvdialog10 = false;
      }
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius: 20px !important">
      <v-data-table
        dense
        :headers="headers"
        :items="resultQuery"
        item-key="officeId"
        sort-by="office_name"
        :search="search"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-form autocomplete="off" ref="form" v-model="valid" lazy-validation>
            <v-toolbar
              flat
              color="#00A3A3"
              dark
              dense
              class="mb-2"
              style="border-radius: 20px"
            >
              <span style="font-size: 40%">
                <v-toolbar-title>OFFICES</v-toolbar-title>
              </span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-row align="center" justify="space-between">
                <template v-if="user.role == 'Admin' || user.role == 'superadmin'">
                <v-col cols="4" lg="7">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              
            </v-col>
          </template>
          <template v-else>
                <v-col cols="5">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              
            </v-col>
          </template>
              <v-spacer></v-spacer>
              
              <v-col cols="7" 
              lg="5" 
    class="d-flex justify-end" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
    <template v-if="user.role == 'Admin' || user.role == 'superadmin'">
                <v-btn style="border-radius: 20px; background: black"
                          @click="Export"
                        >
                          <h5>Export</h5>
                        </v-btn>
                      </template>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="user.emailId != 'Justin@alcobrake.com'"
                          style="border-radius: 20px; background: black"
                          @click="dialog = true"
                        >
                          <h2>+</h2>
                          <v-icon size="20px" color="dark"
                            >mdi-home-modern</v-icon
                          ></v-btn
                        >
                      </v-col>
                      <v-col cols="7" 
              lg="5" class="d-flex justify-end" v-else>
              <template v-if="user.role == 'Admin' || user.role == 'superadmin'">
                <v-btn style="border-radius: 20px; background: black"
                          @click="Export" small
                        >
                          <h5>Export</h5>
                        </v-btn>
                      </template>
                        <v-spacer></v-spacer>
                        <v-btn small
                          v-if="user.emailId != 'Justin@alcobrake.com'"
                          style="border-radius: 20px; background: black"
                          @click="dialog = true"
                        >
                          <h2>+</h2>
                          <v-icon size="20px" color="dark"
                            >mdi-home-modern</v-icon
                          ></v-btn
                        >
                      </v-col>
              </v-row>
             
              <v-dialog width="400px" v-model="csvdialog10">
                       
                <v-card text style="border-radius: 20px">
                  <v-toolbar
                    color="#00A3A3"
                    style="border-radius: 20px; color: white"
                  >
                    <v-toolbar-title
                      v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                    >
                      Please Choose The Format
                    </v-toolbar-title>
                    <v-toolbar-title v-else style="font-size: 15px">
                      Please Choose The Format
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-layout justify-end>
                      <v-btn
                        color="black"
                        @click="csvclose10"
                        class="sm-0"
                        style="border-radius: 20px"
                        ><v-icon color="white"> mdi-close</v-icon>
                      </v-btn>
                    </v-layout>
                  </v-toolbar>
                  <div align="center">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mt-4"
                          tile
                          color="green"
                          v-bind="attrs"
                          v-on="on"
                          @click="officesFiledownload('csv')"
                          style="border-radius: 10px; color: white"
                        >
                          CSV Format
                          <v-icon right> mdi-download </v-icon>
                        </v-btn>
                      </template>

                      <span>split data with delimiter semi-colon(;)</span>
                    </v-tooltip>
                  </div>
                  &nbsp;
                  <!-- <div align="center">
                  <v-btn
                    class="mt-2 mb-2"
                    tile
                    color="#334c3e"
                    @click="officesFiledownload('txt')"
                    style="border-radius: 10px; color: white"
                  >
                    TEXT Document
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </div> -->
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialog" max-width="720px">
                <v-card style="border-radius: 20px">
                  <v-toolbar
                    flat
                    color="#00A3A3"
                    class=""
                    dark
                    dense
                    style="border-radius: 20px"
                  >
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
  <v-row>
    <!-- Name Field -->
    <v-col cols="12">
      <v-text-field
        dense outlined required
        :rules="nameRules"
        v-on:keypress="isLetter($event)"
        v-model="editedItem.officeName"
        label="Name*"
      ></v-text-field>
    </v-col>

    <!-- Phone 1 -->
    <v-col cols="12">
      <v-row dense align="center">
        <!-- Country Code Selector -->
        <v-col cols="2">
          <vue-country-code
            v-model="editedItem.officehome"
            @onSelect="onSelect"
            :enabledCountryCode="true"
            :defaultCountry="defcountry"
            :preferredCountries="['in', 'us', 'gb']"
            style="border: none"
          ></vue-country-code>
        </v-col>
        <!-- Phone Number Input -->
        <v-col cols="10">
          <v-text-field
            dense outlined required
            :rules="phoneRulesa"
            v-on:keypress="isNumber($event)"
            v-model="editedItem.officeMobile1"
            label="Phone*"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <!-- Phone 2 -->
    <v-col cols="12">
      <v-row dense align="center">
        <!-- Country Code Selector -->
        <v-col cols="2">
          <vue-country-code
            v-model="editedItem.officework"
            @onSelect="onSelect2"
            :enabledCountryCode="true"
            :defaultCountry="defcountry"
            :preferredCountries="['in', 'us', 'gb']"
            style="border: none"
          ></vue-country-code>
        </v-col>
        <!-- Phone Number Input -->
        <v-col cols="10">
          <v-text-field
            dense outlined required
            :rules="phone2Rulesa"
            v-on:keypress="isNumber($event)"
            v-model="editedItem.officeMobile2"
            label="Phone 2"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <!-- Email -->
    <v-col cols="6">
      <v-text-field
        dense outlined required
        :rules="emailRules"
        v-model="editedItem.officeemail"
        label="Email*"
      ></v-text-field>
    </v-col>

    <!-- Password -->
    <v-col cols="6">
      <v-text-field
        dense outlined required
        :rules="passwordRules"
        v-model="editedItem.officepassword"
        label="Password*"
      ></v-text-field>
    </v-col>

    <!-- Address -->
    <v-col cols="6">
      <v-text-field
        dense outlined required
        :rules="addressRules"
        v-model="editedItem.officeaddress"
        label="Address*"
      ></v-text-field>
    </v-col>

    <!-- City -->
    <v-col cols="6">
      <v-text-field
        dense outlined required
        :rules="cityRules"
        v-model="editedItem.city"
        label="City*"
      ></v-text-field>
    </v-col>

    <!-- State -->
    <v-col cols="6">
      <v-autocomplete
        dense outlined required
        :rules="stateRules"
        v-model="editedItem.state"
        :items="states"
        label="Select State*"
      ></v-autocomplete>
    </v-col>

    <!-- Zipcode -->
    <v-col cols="6">
      <v-text-field
        dense outlined required
        :rules="zipRules"
        v-model="editedItem.zipcode"
        label="Zipcode*"
      ></v-text-field>
    </v-col>

    <!-- BAR License -->
    <v-col cols="6">
      <v-checkbox
        false-value="0"
        true-value="1"
        v-model="editedItem.isbar"
        :label="`isBAR License`"
      ></v-checkbox>
    </v-col>
    <v-col cols="6">
      <v-text-field
        dense outlined required
        :rules="licenseRules"
        v-model="editedItem.officelicense"
        :label="
          editedItem.isbar == '1'
            ? 'BAR License'
            : 'BEARHFTI License'
        "
      ></v-text-field>
    </v-col>
  </v-row>
</v-container>

                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="close"
                      style="background: #ff3d41; color: white"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      :disabled="!valid"
                      text
                      @click="save"
                      >{{ buttonTitle }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </v-form>
        </template>
        <template slot="item.action" slot-scope="{ item }">
          <v-icon class="mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
        </template>
        <template slot="item.delete" slot-scope="{ item }">
          <v-icon
            class="mr-2 mt-2"
            larger
            color="red"
            @click="deleteOffice(item)"
          >
            mdi-delete-circle
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="ma-0 pa-0" align="center">
            <v-col class="col-md-6">
              <v-card
                class="mt-2 mb-2"
                color="#334c3e"
                style="border-radius: 20px; padding: 10px"
              >
                <v-list dense class="ma-0 pa-0" style="background: #334c3e">
                  <v-list-item>
                    <v-icon class="off-icon">mdi-clipboard-account</v-icon>
                    <v-list-item-content class="content1"
                      >ID:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.officeId
                    }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-account</v-icon>
                    <v-list-item-content class="content1"
                      >Name:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.officeName
                    }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-cellphone-iphone</v-icon>
                    <v-list-item-content class="content1"
                      >Phone:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.officeMobile1
                    }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-cellphone-basic</v-icon>
                    <v-list-item-content class="content1"
                      >Phone 2:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.officeMobile2
                    }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-icon class="off-icon">mdi-email-outline</v-icon>
                    <v-list-item-content class="content1"
                      >Email:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.officeemail
                    }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-icon class="off-icon">mdi-home-outline</v-icon>
                    <v-list-item-content class="content1"
                      >Address:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.officeaddress
                    }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-book-open</v-icon>
                    <v-list-item-content class="content1"
                      >Bar License 2:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.officelicense
                    }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-account-tie</v-icon>
                    <v-list-item-content class="content1"
                      >Dealer:</v-list-item-content
                    >
                    <v-list-item-content class="align-end content2">{{
                      item.dealer
                    }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<style scoped>
.content2 {
  color: #14c7c7 !important;
  border-bottom: 1px dashed white;
}
.content1 {
  color: white !important;
  margin-left: 8px;
}
.off-icon {
  color: white;
  background: #00a3a3;
  padding: 2px;
  border-radius: 10px;
}
</style>
