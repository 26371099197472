<template>
  <div v-if="!isMobile()">
    <div id="map2" :style="{ height: screenHeight + 'px', width: '114%', marginLeft: '-2%', top: '0', bottom: '0' }"></div>

    <div class="zoom-control">
      <button @click="zoomIn" style="text-align: center; font-size: 20px; font-weight: bold; cursor: pointer;">+</button>
      <v-divider style="border-color: black !important; margin-top: 10%;"></v-divider>
      <button @click="zoomOut" style="text-align: center; font-size: 30px; font-weight: bold; cursor: pointer;">-</button>
    </div>
  </div>
  <div v-else>
    <div id="map2" :style="{ height: screenHeight + 'px', width: '114%', marginLeft: '-2%', top: '0', bottom: '0' }"></div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import engrun from "../assets/engrun.png";
import engoff from "../assets/engoff.png";
import engon from "../assets/engon.png";
import Vue from "vue";
export default {
  name: "Checkfullmap",
  props: ["vehdata"],
  data() {
    return {
      // location: [],
      selectedVehicle: null,
      onloaded: false,
      futureArray: [],
      map2: null,
      zoomval: 5,
      onsingle: false,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight
    };
  },

  watch: {
    vehdata(val) {
      this.vehdata = val;
      if (this.map2 == null) {
        this.onInit();
      }
    },
    futureArray(val) {
      this.futureArray = val;
    },
    map2(val) {
      this.map2 = val
    },
    // location(val) {
    //   this.location = val;
    // },
  },

  methods: {
    updateScreenSize() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
//    mapUpdate(data) {
//     this.futureArray = [];
//      data.map((ele) => {
//        this.futureArray.push({
//          "coordinates": [parseFloat(ele.Gps.lon), parseFloat(ele.Gps.lat)],
//          "icon": ele.Engine.enginerunstate == 1 && parseFloat(ele.Gps.speed) > 0 ? "engrun" : ele.Engine.enginerunstate == 1 && parseFloat(ele.Gps.speed) == 0.0 ? "engon" : "engoff",
//          "regnum": ele.Vehicle.regnum
 //       })
//      })

//    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    onSingleview(id) {
      this.onsingle = true;
      this.selectedVehicle = id.Vehicle.regnum;
      this.map2.panTo([id.Gps.lon, id.Gps.lat], { zoom: 14 });
    },
    onAllview() {
      this.onsingle = false;
      this.map2.setZoom(this.zoomval);
    },
    zoomIn() {
      this.map2.zoomIn()
    },
    zoomOut() {
      this.map2.zoomOut();
    },
    onInit() {
      var nogpsdevs =this.vehdata.filter(item => item.Gps.lat === '' || item.Gps.lon === '');
      var yesgpsdevs =this.vehdata.filter(item => item.Gps.lat != '' || item.Gps.lon != '');
console.log(this.vehdata.length +""+nogpsdevs.length)
      if (this.vehdata.length != nogpsdevs.length) {
        var lati = parseFloat(yesgpsdevs[0].Gps.lat);
        var long = parseFloat(yesgpsdevs[0].Gps.lon);
        // this.location = [long, lati];
        // if(lati != '' & long != ''){
        mapboxgl.accessToken =
          "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
        this.map2 = new mapboxgl.Map({
          container: "map2",
          style: "mapbox://styles/mapbox/streets-v11",
          // center: [-96, 37.8],
          center: [long, lati],
          zoom: this.zoomval,
          attributionControl: false,
        })
        if (this.map2) {
          this.map2.on("load", () => {
            this.futureArray.map((marker, index) => {
              this.map2.loadImage(marker.icon == "engrun" ? engrun : marker.icon == "engoff" ? engoff : engon, (error, image) => {
                if (error) throw error;
                if (this.map2.hasImage(marker.icon)) {
                  this.map2.removeImage(marker.icon);
                }
                this.map2.addImage(marker.icon, image);
                this.map2.addSource(marker.regnum, {
                  type: "geojson",
                  data: {
                    type: "FeatureCollection",
                    features: [
                      {
                        // feature for Mapbox DC
                        type: "Feature",
                        geometry: {
                          type: "Point",
                          coordinates: marker.coordinates,
                        },
                      },
                    ],
                  },
                });
                // Add a symbol layer
                this.map2.addLayer({
                  'id': `marker-${marker.regnum}`,
                  'type': 'symbol',
                  'source': marker.regnum,
                  'layout': {
                    'icon-image': marker.icon,
                    'icon-size': 0.20,
                    'text-field': ' ',
                    'text-size': 12,
                  }
                });

                // this.map2.on('click', `marker-${marker.regnum}`, (e) => {
                  // console.log(e.features[0].geometry.coordinates.slice())
                  // console.log(marker.regnum)
                  // const coordinates = e.features[0].geometry.coordinates.slice();
                  // console.log(coordinates)
                  const popup = new mapboxgl.Popup({ closeOnClick: false })
                    .setLngLat(marker.coordinates)
                    .addTo(this.map2)
                    .setOffset([0, -15])
                    .setHTML('<div style="text-align:center;font-size:10px; color: black; width:80px;height:10px">' + marker.regnum + '</div>')
                    // .addTo(this.map2);
                    // console.log(popup)
                // });
                // // Close button click event
                // this.map.on('click', 'close-popup', () => {
                //   // Close the popup
                //   this.map.getPopup().remove();
                // });
              });
            });
            // Simulate updating markers every 5 seconds
            setInterval(() => {
              this.onloaded = true;
              // var that = this;
              // Update marker data
              // const updatedMarkers = that.futureArray;
              // Update the marker data source
              this.futureArray.map((marker, index) => {
                this.map2.getSource(marker.regnum).setData({
                  type: "FeatureCollection",
                  features: [
                    {
                      // feature for Mapbox DC
                      type: "Feature",
                      geometry: {
                        type: "Point",
                        coordinates: marker.coordinates,
                      },
                    },
                  ],
                })
                //update layer
                var existingLayer = this.map2.getLayer(`marker-${marker.regnum}`);
                if (existingLayer) {
                  // If the layer already exists, update its layout properties
                  this.map2.setLayoutProperty(`marker-${marker.regnum}`, 'icon-image', marker.icon);
                  //addcenter
                  if (this.onsingle && (this.selectedVehicle == marker.regnum)) {
                    this.map2.panTo(marker.coordinates, { zoom: 15 });
                    this.map2.setCenter(marker.coordinates)
                  }
                  //             this.map2.popup.setLngLat(marker.coordinates).setHTML(marker.regnum)
                  // .addTo(this.map2);
                  // Add more layout property updates as needed
                } else {
                  // Add a symbol layer
                  this.map2.addLayer({
                    'id': `marker-${marker.regnum}`,
                    'type': 'symbol',
                    'source': marker.regnum,
                    'layout': {
                      'icon-image': marker.icon,
                      'icon-size': 0.25,
                      'text-field': ' ',
                      'text-size': 12,
                    }
                  });
                  //addcenter
                  if (this.onsingle && (this.selectedVehicle == marker.regnum)) {
                    this.map2.panTo(marker.coordinates, { zoom: 15 });
                    this.map2.setCenter(marker.coordinates)
                  }
                }
                // var popup = this.map2.getFeatureState({ source: `marker-${marker.regnum}`, id: index }).popup;
                // popup.setHTML(marker.regnum);
              });
            }, 8000);
          });
        }
      } else {

        mapboxgl.accessToken =
          "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
        this.map2 = new mapboxgl.Map({
          container: "map2",
          style: "mapbox://styles/mapbox/streets-v11",
          // center: [-96, 37.8],
          center: [0.0, 0.0],
          zoom: 1,
          attributionControl: false,
        });

        if (this.map2) {
          this.map2.on("load", () => {
            this.map2.addSource("checknulllocation", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    // feature for Mapbox DC
                    type: "Feature",
                    geometry: {
                      type: "Point",
                      coordinates: [0.0, 0.0],
                    },
                    properties: {
                      description: 'LOCATION NOT FOUND'
                    },
                  },
                ],
              },
            });
          });
          this.map2.panTo([0.0, 0.0]);
        }
      }
    },
  },

  mounted() {
    // setInterval(()=>{
    if (this.vehdata.length > 0) {
      this.vehdata.map((ele) => {
        this.futureArray.push({
          "coordinates": [parseFloat(ele.Gps.lon), parseFloat(ele.Gps.lat)],
          "icon": ele.Engine.enginerunstate == 1 && parseFloat(ele.Gps.speed) > 0 ? "engrun" : ele.Engine.enginerunstate == 1 && parseFloat(ele.Gps.speed) == 0.0 ? "engon" : "engoff",
          "regnum": ele.Vehicle.regnum
        })
      })
      setTimeout(() => {
        this.onInit();
      }, 1000)
    }
    // },5000)
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize);
  }

};
</script>
<style scoped>
#map2 {
  height: 600px;
  width: 114%;
  margin-left: -2%;
  /* position: absolute; */
  top: 0;
  bottom: 0;
}

.mapboxgl-Popup-content {
  color: #F3F3DD;
  background-color: #91785D;
  border-color: #91785D;
  max-width: 250px;
  box-shadow: 3px 3px 2px #8B5D33;
  font-family: 'Oswald';
}

.zoom-control {
  position: absolute;
  bottom: 80%;
  left: 96%;
  border: 1px solid black;
  padding: 5px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.8);
}
</style>
  