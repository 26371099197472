<template>
  <div class="payment-keys">
    <h1>Payment Gateway Keys</h1>
    <form @submit.prevent="updateKeys">
        <v-container>
          <h3 style="margin-top: 30px">Stripe Gateway</h3>
          <div class="toggle-container">
            <span
              class="mode-label"
              :class="{ active: isLiveMode }"
              id="test-mode"
              :style="{ color: isLiveMode ? 'gray' : 'green',fontWeight: isLiveMode ? 'normal' : 'bold', }"
              >Test Mode</span
            >
            <v-switch
              v-model="isLiveMode"
              inset
              class="switch"
              @change="switchMode"
            ></v-switch>
            <span
              class="mode-label"
              :class="{ active: isLiveMode }"
              id="live-mode"
              :style="{
                color: isLiveMode ? 'green' : 'gray',
                fontWeight: isLiveMode ? 'bold' : 'normal',
              }"
              >Live Mode</span
            >
          </div>
        </v-container>
        <template>
        <v-row class="token">
          <v-col >
            <v-text-field
              outlined
              dense
              v-model="stripe.test_key"
              label="Enter Stripe test key"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-model="stripe.live_key"
              label="Enter Stripe live key"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="token">
          <v-col>
            <v-text-field
              outlined
              dense
              v-model="stripe.test_token"
              label="Enter Stripe test token"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-model="stripe.live_token"
              label="Enter Stripe live token"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <button class="button"  type="submit">{{ formTitle }}</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isEdit: true,

      user: JSON.parse(localStorage.getItem("user")),
      dealerid: "257",
      gatewayid: null,
      isLiveMode: false,
      stripe: {
        test_key: "",
        live_key: "",
        test_token: "",
        live_token: "",
      },
      message: "",
    };
  },

  computed: {
    ...mapGetters(["paymentgateway"]),
    ...mapGetters(["createpaymentgateway"]),
    formTitle() {
      return this.paymentgateway.gatewayid === "" ? "Add Keys" : "Update Keys";
    },
    formValid() {
    return (
      this.stripe.test_key &&
      this.stripe.live_key &&
      this.stripe.test_token &&
      this.stripe.live_token
    );
  },
  },
  mounted() {
    this.$store.dispatch("fetchpaymentgateway", this.user.dealerId);
    setTimeout(() => {
      this.fetchdetails();
    }, 1000);
  },
  methods: {
    async fetchdetails() {
      try {
        this.stripe.test_key = this.paymentgateway.stripekeys.test_key;
        this.stripe.live_key = this.paymentgateway.stripekeys.live_key;
        this.stripe.test_token = this.paymentgateway.stripekeys.test_token;
        this.stripe.live_token = this.paymentgateway.stripekeys.live_token;
        this.isLiveMode = this.paymentgateway.isLiveMode;
      } catch (err) {
        console.log(err);
      }
    },
    editloadformdata() {
      const formdata = new FormData();
      const datetime = moment().format("MM/DD/YYYY HH:mm:ss");
      formdata.append("dealer_id", this.user.dealerId);
      formdata.append(
        "stripe_details",
        JSON.stringify({
          test_key: this.stripe.test_key,
          live_key: this.stripe.live_key,
          test_token: this.stripe.test_token,
          live_token: this.stripe.live_token,
        })
      );
      formdata.append("updated_at", datetime);
      return formdata;
    },
    loadformdata() {
      const formdata = new FormData();
      const datetime = moment().format("MM/DD/YYYY HH:mm:ss");
      formdata.append("dealer_id", this.user.dealerId);
      formdata.append(
        "stripe_details",
        JSON.stringify({
          test_key: this.stripe.test_key,
          live_key: this.stripe.live_key,
          test_token: this.stripe.test_token,
          live_token: this.stripe.live_token,
        })
      );
      formdata.append("created_at", datetime);
      return formdata;
    },
    async updateKeys() {
      try {
        if (this.paymentgateway.gatewayid != "") {
          // console.log("edit")
          const formdata = this.editloadformdata();
          const data = {
            id: this.paymentgateway.gatewayid,
            formdata: formdata,
          };
          this.$store.dispatch("editpaymentgateway", data);
        } else {
          // console.log("add")
          if(this.formValid){
          const fd = this.loadformdata();
          this.$store.dispatch("createpaymentgateway", fd);
          }else{
            this.$store.dispatch("alert/success", "Please Fill Required Fields.").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
          }
        }
      } catch (error) {
        console.error("Error updating keys:", error);
        this.message = "Failed to update keys.";
      }
    },
    async switchMode() {
      try {
        const datetime = moment().format("MM/DD/YYYY HH:mm:ss");
        const mode = this.isLiveMode ? "LIVE" : "TEST";
        const formdata = new FormData();
        formdata.append("dealer_id", this.user.dealerId);
        formdata.append("mode", mode);
        formdata.append("updated_at", datetime);
        await axios({
          method: "POST",
          url: "https://robopower.xyz/v2/gateway/switchmode",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
.payment-keys {
  max-width: 1000px;
  margin: 0 auto;
}
form {
  display: flex;
  flex-direction: column;
}
div {
  margin-bottom: 15px;
}
label {
  font-weight: bold;
}
input {
  padding: 8px;
  font-size: 16px;
}
.button {
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #4caf50;
  border: none;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  transition: all 0.3s ease;
  width: 200px;
  align-self: center;
}
.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  pointer-events: none;
}
.button:hover {
  background-color: #45a049;
}
.token {
  margin-top: -50px
}
.toggle-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.mode-label {
  margin: 0 10px;
  font-size: 16px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
</style>
