<template>
  <div class="container-fluid" style="background-color: #C9EFFC;">
    <v-toolbar style="background-color: #2596be">
      <v-img src="../assets/volvo_logo.png" style="margin-top:1px" max-width="50px" height="50px"/>

      <v-col >
        <span style="font-size: 30px; color: white; margin-left: 10px;font-weight: bold;">Volvo Travles</span>
      </v-col>

      <!-- Spacer to push the search bar to the center -->
      <v-spacer></v-spacer>

      <!-- Search bar with rounded corners -->

      <!-- <v-text-field
        flat
        solo
        rounded
        placeholder="Search..."
        prepend-inner-icon="mdi-magnify"
        class="search-bar"
        hide-details

      ></v-text-field> -->

      <!-- Spacer to push the logout button and profile icon to the right -->
      <v-spacer></v-spacer>
      <!-- <v-col>
    <button class='button'>Total Vehicle</button>
</v-col>
<v-col style="margin-left: -100px;">
    <button class='button'>Active Vehicle</button>
</v-col>
<v-col style="margin-left: -80px;">
    <button class='button'>Inactive Vehicle</button>
</v-col>
<v-col style="margin-left: -80px;">
    <button class='button'>Idle Vehicle</button>
</v-col> -->


      <!-- Profile icon -->
     
      <v-btn icon>
        <v-icon style="margin-top: 3px;" size="40px" color="white">mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon size="40px" color="white">mdi-bell-outline</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon size="40px" color="white">mdi-account-circle</v-icon>
      </v-btn>

      <!-- Logout button -->
      <v-btn  @click="onLogout" color="red">
        <v-icon color="white" >mdi-power-standby</v-icon>
      </v-btn>
    </v-toolbar>

   <!-- <v-col style="text-align: center;">
    <v-text-filed style="font-size: 50PX; font-weight: bold;">
      TOTAL VEHICLE &#58; 200
        </v-text-filed>
   </v-col> -->

    <!-- Content of the Dashboard -->
    <v-row class="my-3" no-gutters>
      <v-col cols="12" sm="6" md="3" v-for="(item, index) in StatsList" :key="index">
        <v-card class="pa-2 card-hover" outlined>
          <v-card-title class="text-h6 d-flex align-center">
            <v-icon class="mr-1" color="primary">{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-card-title>
          <v-card-text>
            <div class="text-center number-box">
              <span 
                v-for="(digit, digitIndex) in formatNumber(item.value)" 
                :key="digitIndex" 
                class="digit-box"
              >
                {{ digit }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="dashboard">
      <VehicleStatus :vehicles="fullclients" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { onBeforeMount } from 'vue';
import VehicleStatus from './VehicleStatus.vue';
import { onMounted } from 'vue';


export default {
  name: 'Dashboard',
  components: {
    VehicleStatus
  },
  data() {
    return {
      volvouser:JSON.parse(localStorage.getItem("volvouser")),
      lastUpdated: new Date().toLocaleString(),
      fullclients:[],
      StatsList: [
        { title: 'Total Vehicles', value: 0, icon: 'mdi-account-group' },
        { title: "Active Vehicles", value: 0, icon: 'mdi-account-multiple' },
        { title: 'Idle Vehicles', value: 0, icon: 'mdi-check-circle' },
        { title: 'Inactive Vehicles', value: 0, icon: 'mdi-alert-circle' },

      ],
      total:0,
      moving :0,
      offline :0,
      online :0,
      fails :0,
      passes:0,
      bypass :0,
      Idle :0,
      overspeed :0
    };
  },
  
  methods: {
    
    formatNumber(number) {
      return number.toString().split('');
    },
    onLogout() {
      this.$store.dispatch("authentication/volvologout");
    },
    calCount(data) {
      var countPromise = new Promise((res, rej) => {
        var moving = 0;
        var offline = 0;
        var online = 0;
        var fails = 0;
        var passes = 0;
        var bypass = 0;
        var overspeed = 0;
        var Idle = 0;
        if (data.length > 0) {
          this.total = data.length;
          this.StatsList[0].value = data.length; 

          data.map((res) => {
            if (parseFloat(res.Gps.speed) > 80.0) {
              overspeed = overspeed + 1
            }
            if (res.Engine.enginerunstate == 1 && parseFloat(res.Gps.speed) == 0.0) {
              Idle = Idle + 1
            }
            if (res.Engine.enginerunstate == "0") {
              offline = offline + 1
            } else if (res.Engine.enginerunstate == "1" && parseFloat(res.Gps.speed) > 3.0) {
              moving = moving + 1;
            } else {
              online = online + 1
            }
            if (res.Event.typeofevent == "4") {
              bypass = bypass + 1;
            } else {
              if (res.Event.passfailstatus == "1") {
                passes = passes + 1
              } else {
                fails = fails + 1
              }
            }
          })
          res({ "status": "ok", "moving": moving, "offline": offline, "online": online, "fails": fails, "passes": passes, "bypass": bypass, "Idle": Idle, "overspeed": overspeed })
        }
      })
      countPromise.then((res) => {
        this.StatsList[1].value = res.moving;
        this.StatsList[2].value = res.Idle;
        this.StatsList[3].value = res.offline;
        this.fails = res.fails;
        this.passes = res.passes;
        this.bypass = res.bypass;
        this.overspeed = res.overspeed;

      })

    },
  },
  beforeMount(){
    console.log("uuu")
    console.log(this.volvouser)
    Vue.axios
      .get("https://robopower.xyz/app/v2data/getdealerlatestupdates2?dealerId=" + this.volvouser.dealerId)
      .then(response => {
        if (response.data != null) {
          this.fullclients = response.data;
          this.calCount(response.data);
          console.log(this.fullclients)
        }
      });
  }
};
</script>

<style>
.dashboard {
  display: flex;
  flex-direction: column;
}

.search-bar {
  max-width: 400px;
  visibility: visible; /* Ensure the search bar is visible */
}

.button {
      font-size: 25px;
      color: white;
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-decoration: none;
      margin-left: -30px;
  }
  .button:hover {
      color: black;
      text-decoration: underline;
      text-decoration-color: white;
      text-underline-offset: 10px;   
      /* text-decoration-thickness: 1px; */
   

  }
</style>
