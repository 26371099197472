import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import Removals from "./components/Removals";
import login from "../src/login";
import Dealers from "./components/Dealers";
import Offices from "./components/Offices";
import Technicians from "./components/Technicians";
import Devices from "./components/Devices";
import Clients from "./components/Clients";
import Prohibitions from "./components/Prohibitions";
import Firmwares from "./components/firmwares";
import Gpsdb from "./fleet/gpsdashboard";
import Fleetreports from "./fleet/Reports.vue";
import Geofence from "./fleet/geofence";
import Successtx from "./components/successtx";
import Successpayment from "./components/successpayment";
import Successdealerpayment from "./components/successdealerpayment";
import Gdrivepaymentsuccess from "./components/gdrivesuccesspayment"
import Failtx from "./components/failtx";
import Services from "./components/Services";
import Calibrations from "./components/Calibrations";
import LogFiles from "./components/logs";
import StudentFiles from "./components/students";
import RouteFiles from "./components/routes";
import Invoices from "./components/Invoices";
import Installations from "./components/Installations";
import Reports from "./components/Reports";
import Acceptance from "./components/Acceptance";
import Flutterflow from "./components/fluttterflowpayment"; 
import Flutterflowsuccesstxn from "./components/fluttersuccess";
import Backups from "./components/backups";
import Payments from "./components/payments";
import Success from "./components/success";
import Errors from "./components/error"
import Components from './components/components';
import Filetranslator from './components/filetranslator';
import ParentLogin from "./components/parent/parentlogin";
import FleetLogin from "./fleet/login";
import ParentDashboard from "./components/parent/pdashboard";
import Paymentlink from "./components/paymentlink"
import OledDevices from "./components/oleddevices";
import GeoFencing from "./components/geofencing";
import Calibrationkit from "./components/calibrationkit"
import Dealership from "./components/Dealership.vue";
import Successdealershiptx from "./components/successdealershiptx.vue";
import reactpayments from "./components/reactpayments.vue";
import controlroom from "./components/controlroom.vue";
import gateway from "./components/gateway.vue";
import livestream from "./components/livestream.vue";
import volvologin from "./volvo/login.vue";
import dashboard_volvo from "./volvo/volvodashboard.vue"
import Teachers from "./components/school/teachers.vue";
import StudentServices from "./components/school/services.vue";
import Fees from "./components/school/fees.vue"
import StudentFeeStruture from "./components/school/Feestruture.vue"
import StudentFees from "./components/school/studentfees.vue"
import StudentExams from "./components/school/exams.vue"
import HomeWorks from './components/school/homeworks.vue'
import Activites from './components/school/activites.vue'
import Subjects from './components/school/subjects.vue'
import ProgressCards from "./components/school/progresscards.vue";
import PaymentMode from "./components/paymentMode.vue"

Vue.use(Router);

const router = new Router({

  // name: "Dealers",
  mode: "history",
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash, offset: { x: 0, y: 70 }
      }
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
  routes: [
    {
      path: "/",
      meta: {
        reqAuth: true
      },
      redirect: {  name: localStorage.getItem('user')?"Dashboard":localStorage.getItem('fleetuser')?"GpsDashboard":localStorage.getItem("alcotrackerparentuser")?"ParentDashboard":'Dashboard' }
      // redirect: {  name: localStorage.getItem('user')?"Dashboard":"ParentDashboard" }
    },
    {
      path: "/flutterflow",
      name: "Flutterflow",
      component: Flutterflow,
      meta: {
        title:"Flutterflow",
        // reqAuth: false,
        breadCrumb: 'Flutterflow'
      }
    },
   
    {
      path: "/livestream",
      name: "livestream",
      component: livestream,
      meta: {
        title:"livestream",
        // reqAuth: false,
        breadCrumb: 'livestream'
      }
    },
    {
      path: "/reactpayments",
      name: "reactpayments",
      component: reactpayments,
      meta: {
        title:"error",
        breadCrumb: "error",
        // reqAuth: false,
      },
    },
    {
      path: "/fluttersuccesstransaction",
      name: "transactionsuccess",
      component: Flutterflowsuccesstxn,
      meta: {
        title:"Flutterflow",
        // reqAuth: false,
        breadCrumb: 'Flutterflow'
      }
    },
    
    {
      path: "/login",
      name: "login",
      component: login
    },
    {
      path:"/paymentlink",
      name:"Paymentlink",
      component:Paymentlink,
      meta: {
        title:"Paymentlink",
        reqAuth: false,
        // breadCrumb: "Dashboard"
      }

    },
    {
      path: "/parent/login",
      name: "parentlogin",
      component: ParentLogin
    },
    {
      path: "/fleet/login",
      name: "fleetlogin",
      component: FleetLogin
    },
    {
      path: "/volvo/login",
      name: "volvologin",
      component: volvologin
    },
    {
      path: "/parent/pdashboard",
      name: "ParentDashboard",
      component: ParentDashboard,
    },
    {
      path: "/fleet/gpsdashboard",
      name: "GpsDashboard",
      component: Gpsdb,
      meta: {
        title:"GpsDashboard",
        reqAuth: true,
        // breadCrumb: "Dashboard"
      },
      // children: [
      //   {
      //     path: "/fleetreports",
      //     name: "Fleetreports",
      //     component: Fleetreports,
      //     meta: {
      //       title:"Fleetreports",
      //       reqAuth: true,
      //       breadCrumb: "Reports"
      //     }
      //   },
      // ]
    },
    {
      path: "/volvo/dashboard_volvo",
      name: "dashboard_volvo",
      component: dashboard_volvo,
      meta: {
        title:"dashboard_volvo",
        reqAuth: true,
      },
    },
    {
      path: "/fleet/fleetreports",
      name: "Fleetreports",
      component: Fleetreports,
      meta: {
        title:"Fleetreports",
        reqAuth: true,
        breadCrumb: "Reports"
      }
    },
    {
      path: "/fleet/geofence",
      name: "GeoFence",
      component: Geofence,
      meta: {
        title:"GeoFence",
        reqAuth: true,
        // breadCrumb: "Dashboard"
      }
    },
    {
      path: "/successtransaction",
      name: "Success",
      component: Successtx,
      meta: {
        title:"Success",
        reqAuth: true,
        breadCrumb: "Success"
      }
    },
    {
      path: "/successdealershiptransaction",
      name: "Success",
      component: Successdealershiptx,
      meta: {
        title:"Successdealershiptx",
        reqAuth: true,
        breadCrumb: "Successdealershiptx"
      }
    },
    {
      path: "/successpayment",
      name: "Successpayment",
      component: Successpayment,
      meta: {
        title:"Successpayment",
        reqAuth: true,
        breadCrumb: "Success"
      }
    },
    {
      path: "/successdealertransaction",
      name: "Successdealerpayment",
      component: Successdealerpayment,
      meta: {
        title:"Successpayment",
        reqAuth: true,
        breadCrumb: "Success"
      }
    },
    {
      path: "failedtransaction/:id",
      name: "Failed",
      component: Failtx,
      meta: {
        title:"Fail",
        reqAuth: true,
        breadCrumb: "Fail"
      }
    },
    {
      path: "/filetranslator",
      name: "FILE-TRANSLATOR",
      component: Filetranslator,
      meta: {
        title:"FILE-TRANSLATOR",
        reqAuth: true,
        breadCrumb: "FILE-TRANSLATOR"
      }
      },
      {
        path: "/gdrivesuccesstxn",
        name: "GDRIVE-SUCCESS-TRANSACTION",
        component: Gdrivepaymentsuccess,
        meta: {
          title:"GDRIVE-SUCCESS-TRANSACTION",
          reqAuth: true,
          breadCrumb: "GDRIVE-SUCCESS-TRANSACTION"
        }
        },
        {
          path: "/controlroom",
          name: "CONTROLROOM",
          component: controlroom,
          meta: {
            title:"CONTROLROOM",
            reqAuth: true,
            breadCrumb: "CONTROLROOM"
          }
          },
    {
      path: "/dashboard/",
      name: "Home",
      component: Home,
      //beforeEnter: checkAuth,
      meta: {
        reqAuth: true,
        breadCrumb: "Dashboard"
      },
      children: [
        localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).role !="uploader"? {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          title:"Dashboard",
          reqAuth: true,
          // breadCrumb: "Dashboard"
        }
      }:{
        path: "",
        name: "Dashboard",
        component: Filetranslator,
        meta: {
          title:"Filetranslator",
          reqAuth: true,
          // breadCrumb: "Dashboard"
        }
      }:{
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          title:"Dashboard",
          reqAuth: true,
          // breadCrumb: "Dashboard"
        }
      }
      ,
       {
        path: "dealers",
        name: "Dealers",
        component: Dealers,
        meta: {
          title:"Dealers",
          reqAuth: true,
          breadCrumb: "Dealers"
        }
      },

      //school
      {
        path: "teachers",
        name: "Teachers",
        component: Teachers,
        meta: {
          title:"Teachers",
          reqAuth: true,
          breadCrumb: "Teachers"
        }
      },
      {
        path: "services",
        name: "Services",
        component: StudentServices,
        meta: {
          title:"Services",
          reqAuth: true,
          breadCrumb: "Services"
        }        
      },
      {
        path: "fees",
        name: "Fees",
        component: Fees,
        meta: {
          title:"Fees",
          reqAuth: true,
          breadCrumb: "Fees"
        }        
      },
      {
        path: "FeeStruture",
        name: "FeeStruture",
        component: StudentFeeStruture,
        meta: {
          title:"FeeStruture",
          reqAuth: true,
          breadCrumb: "FeeStruture"
        }        
      },
      {
        path: "Studentfees",
        name: "Studentfees",
        component: StudentFees,
        meta: {
          title:"Studentfees",
          reqAuth: true,
          breadCrumb: "Studentfees"
        }        
      },
      {
        path: "StudentExams",
        name: "StudentExams",
        component: StudentExams,
        meta: {
          title:"StudentExams",
          reqAuth: true,
          breadCrumb: "StudentExams"
        }        
      },
      {
        path: "HomeWorks",
        name: "HomeWorks",
        component: HomeWorks,
        meta: {
          title:"HomeWorks",
          reqAuth: true,
          breadCrumb: "HomeWorks"
        }        
      },
      {
        path: "Activites",
        name: "Activites",
        component: Activites,
        meta: {
          title:"Activites",
          reqAuth: true,
          breadCrumb: "Activites"
        }        
      },
      
      {
        path: "subjects",
        name: "Subjects",
        component: Subjects,
        meta: {
          title:"Subjects",
          reqAuth: true,
          breadCrumb: "Subjects"
        }        
      },
      {
        path: "progresscards",
        name: "progressCards",
        component: ProgressCards,
        meta: {
          title:"progressCards",
          reqAuth: true,
          breadCrumb: "progressCards"
        }        
      },

      {
        path: "dealership",
        name: "Dealers",
        component: Dealership,
        meta: {
          title:"Dealership",
          reqAuth: true,
          breadCrumb: "Dealers"
        }
      },
      
       {
        path: "offices",
        name: "Offices",
        component: Offices,
        meta: {
          title:"Offices",
          reqAuth: true,
          breadCrumb: "Offices"
        }
      }, {
        path: "technicians",
        name: "Technicians",
        component: Technicians,
        meta: {
          title:"Technicians",
          reqAuth: true,
          breadCrumb: "User Management"
        }
      }, {
        path: "devices",
        name: "Devices",
        component: Devices,
        meta: {
          reqAuth: true,
          title:"Devices",
          breadCrumb: "Devices"
        }        
      },
      {
        path: "geofencing",
        name: "Geofencing",
        component: GeoFencing,
        meta: {
          reqAuth: true,
          title:"Geofencing",
          breadCrumb: "Geofencing"
        }        
      }, 
      {
        path: "acceptance",
        name: "Acceptance",
        component:Acceptance,
        meta: {
          reqAuth: true,
          title:"Acceptance",
          breadCrumb: "Acceptance"
        }        
      }, 
      {
        path: "clients",
        name: "Clients",
        component: Clients,
        meta: {
          reqAuth: true,
          title:"Clients",
          breadCrumb: "Clients"
        }
      }, 
      {
        path:"oleddevices",
        name:"OledDevices",
        component:OledDevices,
        meta: {
          title:"OledDevices",
          reqAuth: true,
          breadCrumb: "OledDevices"
        }
      },
      {
        path:"calibrationkit",
        name:"Calibrationkit",
        component:Calibrationkit,
        meta: {
          title:"Calibrationkit",
          reqAuth: true,
          breadCrumb: "Calibrationkit"
        }
      },
      {
        path: "prohibitions",
        name: "Prohibitions",
        component: Prohibitions,
        meta: {
          reqAuth: true,
          title:"Prohibitions",
          breadCrumb: "Prohibitions"
        }
      },
      {
        path: "PaymentMode",
        name: "PaymentMode",
        component: PaymentMode,
        meta: {
          reqAuth: true,
          title:"PaymentMode",
          breadCrumb: "PaymentMode"
        }
      },
      //  {
      //   path: "vehicles",
      //   name: "Vehicles",
      //   component: Vehicles,
      //   meta: {
      //     reqAuth: true,
      //     title:"Vehicles",
      //     breadCrumb: 'Vehicles'
      //   }
      // },
      // {
      //   path: "drivers",
      //   name: "Drivers",
      //   component: Drivers,
      //   meta: {
      //     reqAuth: true,
      //     title:"Drivers",
      //     breadCrumb: 'Drivers'
      //   }
      // },
      // {
      //   path: "Notifications",
      //   name: "Notifications",
      //   component: Notifications,
      //   meta: {
      //     reqAuth: true,
      //     title:"Notifications",
      //     breadCrumb: 'Notifications'
      //   }
      // },
       {
        path: "services",
        name: "Services",
        component: Services,
        meta: {
          title:"Services",
          reqAuth: true,
          breadCrumb: "Services"
        }        
      }, {
        path: "calibrations",
        name: "Calibrations",
        component: Calibrations,
        meta: {
          title:"Calibrations",
          reqAuth: true,
          breadCrumb: "Calibrations"
        }        
      }, {
        path: "log_files",
        name: "LogFiles",
        component: LogFiles,
        meta: {
          title:"Logfiles",
          reqAuth: true,
          breadCrumb: "LogFiles"
        }
      },
      {
        path: "students",
        name: "Students",
        component: StudentFiles,
        meta: {
          title:"StudentFiles",
          reqAuth: true,
          breadCrumb: "StudentFiles"
        }
      },
      {
        path: "routes",
        name: "Routes",
        component: RouteFiles,
        meta: {
          title:"Routes",
          reqAuth: true,
          breadCrumb: "Routes"
        }
      },
       {
        path: "invoices",
        name: "Invoices",
        component: Invoices,
        meta: {
          title:"Invoices",
          reqAuth: true,
          breadCrumb: "Invoices"
        }
      }, {
        path: "installations",
        name: "INSTALLATIONS",
        component: Installations,
        meta: {
          title:"Installations",
          reqAuth: true,
          breadCrumb: "installations"
        }
      }, {
        path: "reports",
        name: "Reports",
        component: Reports,
        meta: {
          title:"Reports",
          reqAuth: true,
          breadCrumb: "Reports"
        }
      },{
        path: "removals",
        name: "Removals",
        component: Removals,
        meta: {
          title:"Removals",
          reqAuth: true,
          breadCrumb: "Removals"
        },
      },
        {
          path: "gateway",
          name: "gateway",
          component: gateway,
          meta: {
            title:"gateway",
            reqAuth: true,
            breadCrumb: "gateway"
          }
      },{
        path: "firmwares",
        name: "Firmwares",
        component: Firmwares,
        meta: {
          title:"Firmwares",
          reqAuth: true,
          breadCrumb: "Firmwares"
        }
      },
      // {
      //   path: "profile",
      //   name: "Profile",
      //   component: Profile,
      //   meta: {
      //     title:"Profile",
      //     reqAuth: true,
      //     breadCrumb: 'Profile'
      //   }
        
      // },
      {
        path: "backups",
        name: "Backups",
        component: Backups,
        meta: {
          title:"backups",
          reqAuth: true,
          breadCrumb: "backups"
        }
        },
          {
        path: "filetranslator",
        name: "FILE-TRANSLATOR",
        component: Filetranslator,
        meta: {
          title:"FILE-TRANSLATOR",
          reqAuth: true,
          breadCrumb: "FILE-TRANSLATOR"
        }
        },
      {
        path: "components",
        name: "Components",
        component: Components,
        meta: {
          title:"Components",
          reqAuth: true,
          breadCrumb: "components"
        }
      },
      {
        path: "payments",
        name: "payments",
        component: Payments,
        meta: {
          title:"payments",
          reqAuth: true,
          breadCrumb: "payments"
        }
      },
      {
        path: "dashboard/success",
        name: "success",
        component: Success,
        meta: {
          title:"success",
          reqAuth: true,
          breadCrumb: "success"
        }
      },
      {
        path: "dashboard/error",
        name: "error",
        component: Errors,
        meta: {
          title:"error",
          reqAuth: true,
          breadCrumb: "error"
        }
      }
    ]
    }
  ]
});
router.beforeEach((to, from, next) => {
  / eslint-disable no-console /
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login","/parent/login","/fleet/login","/volvo/login","/fluttersuccesstransaction","/flutterflow","/paymentlink","/reactpayments","/successtransaction"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  const loggedIn2 = localStorage.getItem("alcotrackerparentuser");
  const loggedIn3 = localStorage.getItem("fleetuser");
  const loggedIn4 = localStorage.getItem("volvouser");
  // console.log(authRequired)
  // console.log(to)
  if (authRequired && !loggedIn && !loggedIn2 && !loggedIn3 && !loggedIn4) {
    return next('/login');
  }
  next();
});
const DEFAULT_TITLE = 'Alcobrake Dealer';
router.afterEach((to) => {
  var mytitle = 'Alcobrake'
  if(to.fullPath == '/login' || to.fullPath.includes("/dashboard")){
    mytitle = "Alcobrake Dealer"
  } else if(to.fullPath == '/parent/login' || to.fullPath.includes("/pdashboard")){
    mytitle = "Alcobrake Parent App"
  } else if(to.fullPath == '/fleet/login' || to.fullPath.includes("/gpsdashboard")){
    mytitle = "Alcobrake fleet App"
  }
  else if(to.fullPath == '/volvo/login' || to.fullPath.includes("/dashboard_volvo")){
    mytitle = "Alcobrake volvo App"
  }
else{
    mytitle = "Alcobrake"
  }
    Vue.nextTick(() => {
        document.title = mytitle;
    });
});
  export default router