<template>
  <v-container class="fill-height d-flex justify-center align-center" fluid>
    <!-- Volvo logo placed in the top-left corner -->
     <div>
      <v-row>
        <!-- <v-col> -->
    <v-img 
      src="../assets/volvo_logo.png"
      max-width="120"
      class="logo-top-left position-absolute"
    ></v-img> 
  <!-- </v-col>
  <v-col> -->
    <v-text class="text-top-right position-absolute">Visit our Website:<span><a href="https://volvobuses.com">Volvo Buses</a> </span></v-text>
  <!-- </v-col> -->
  </v-row>
  </div>
    <v-card
      class="pa-5 volvo-card"
      
      max-width="600"
      elevation="15"
    >
      <v-card-title class="justify-center">
        <v-icon color="#FFFFFF" size="50">mdi-car</v-icon>
      </v-card-title>
      <v-card-subtitle class="text-center mb-5">
        <span style="font-size: 24px; font-weight: bold; color: #FFFFFF;">Login</span>
      </v-card-subtitle>

      <v-form>
        <v-text-field
          label="Email"
          v-model="email"
          prepend-icon="mdi-email"
          color="white"
          outlined
          required
          dense
          class="volvo-input"
        ></v-text-field>

        <v-text-field
          label="Password"
          v-model="password"
          prepend-icon="mdi-lock"
          type="password"
          color="white"
          outlined
          required
          dense
          class="volvo-input"
        ></v-text-field>

        <v-btn
          block
          large
          class="volvo-button"
          @click="onTrack" 
        >
          Login
        </v-btn>
      </v-form>

      <v-card-actions class="d-flex justify-center">
        <v-btn text color="#ffffff">Forgot Password?</v-btn>
      </v-card-actions>
    </v-card>

    <!-- "Powered by Alcobrake" placed in the bottom-right corner -->
    <v-text  class="text-bottom-right position-absolute">Powered by Alcobrake</v-text>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    onTrack() {
      if (this.email != '' && this.password != '') {
        var email = this.email;
        var password = this.password;
        this.$store.dispatch("authentication/volvologin", {
          email,
          password
        });
      }
    },
    
  }
};
</script>

<style scoped>
.fill-height {
  min-height: 100vh;
  background: url('../assets/volvobg.png') no-repeat center center fixed; /* Background image */
  background-size: cover; /* Ensure the image covers the whole page */
  position: relative;
}

.logo-top-left {
  top: 20px;
  left: 20px;
}
.text-top-right {
  background-color:rgb(123, 160, 142);
  /* border-radius: 2%; */
  top: 20px;
  right: 20px;
  font-size: 18px;
  color:white;
}
.text-bottom-right {
  bottom: 20px;
  right: 20px;
  font-size: 14px;
  color: #FFFFFF;
}

.volvo-card {
  background-color: rgba(224, 224, 224, 0.55); 
  border-radius: 15px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4); 
}

.volvo-input .v-input__control {
  border-radius: 8px !important;
  box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.2); /* Inner glow effect for shiny look */
}

.volvo-button {
  background: linear-gradient(45deg, #4abae3, #00509e); 
  color: white !important;
  font-weight: bold;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.volvo-button:hover {
  background: linear-gradient(45deg, #00509e, #0074cc); /* Lighter shine on hover */
}

.v-btn:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
}

.position-absolute {
  position: absolute;
}
</style>
