<template>
  <div v-if="!isMobile()" style="background-color:white;">
    <div id="map2"></div>
  </div>
  <div v-else>
    <div id="map2"></div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import Myicon from "../assets/fmarker.png";
import engrun from "../assets/engrun.png";
import engoff from "../assets/engoff.png";
import engon from "../assets/engon.png";
import Vue from "vue";
export default {
  name: "Checkmap",
  props: ["data"],
  data() {
    return {
      // location: [],
      map2: null,
    };
  },

  watch: {
    data(val) {
      if (val) {
        console.log("has data")
        console.log(val);
        this.onUpdate(val);
      }
    }
    // location(val) {
    //   this.location = val;
    // },
  },

  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    zoomIn() {
      this.map2.zoomIn();
    },
    zoomOut() {
      this.map2.zoomOut();
    },
    onUpdate(val) {
      if (val) {
        var lati = parseFloat(val.lat);
        var long = parseFloat(val.lon);
        this.map2.panTo([long, lati]);
        // this.map2.setZoom(15);
        this.map2.getSource("checklocation").setData(
          {
            type: "FeatureCollection",
            features: [
              {
                // feature for Mapbox DC
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [long, lati],
                },
              },
            ],
          },
        );
      } else {
        console.log("no data")
        console.log(val);
        // this.location = [long, lati];
        this.map2.getSource("checklocation").setData(
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  description: "LOCATION NOT FOUND"
                },
                geometry: {
                  type: "Point",
                  coordinates: [0.0, 0.0]
                }
              }
            ]
          }
        );
        // this.map2.setZoom(1)
        this.map2.panTo([0.0, 0.0]);

      }
    },
    onInit() {
      console.log(this.data)
      if (this.data) {
        var lati = parseFloat(this.data.lat);
        var long = parseFloat(this.data.lon);
        // this.location = [long, lati];

        mapboxgl.accessToken =
          "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
        this.map2 = new mapboxgl.Map({
          container: "map2",
          style: "mapbox://styles/mapbox/streets-v11",
          // center: [-96, 37.8],
          center: [long, lati],
          zoom: 15,
          attributionControl: false,
        });
        if (this.map2) {
          this.map2.on("load", () => {
            // Add an image to use as a custom marker
            this.map2.loadImage(engrun, (error, image) => {
              if (error) throw error;
              this.map2.addImage("custom-marker", image);
              // Add a GeoJSON source with 2 points
              this.map2.addSource("checklocation", {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: [
                    {
                      // feature for Mapbox DC
                      type: "Feature",
                      geometry: {
                        type: "Point",
                        coordinates: [long, lati],
                      },
                      // properties: {
                      //   title: vm.data.typeofevent,
                      // },
                    },
                  ],
                },
              });
              console.log("pan");
              this.map2.panTo([long, lati]);
              console.log("to");
              // Add a symbol layer
              this.map2.addLayer({
                'id': "checklocation",
                'type': "symbol",
                'source': "checklocation",
                layout: {
                  "icon-size": 0.25,
                  "icon-image": "custom-marker",
                  'icon-allow-overlap': true,
                  // get the title name from the source's "title" property
                  "text-field": ["get", "title"],
                  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                  "text-offset": [0, 1.25],
                  "text-anchor": "top",
                  'icon-rotate': 0, // Initial rotation angle
                },
              });
              this.map2.resize()
            });
          });
        }
      } else {

        mapboxgl.accessToken =
          "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
        this.map2 = new mapboxgl.Map({
          container: "map2",
          style: "mapbox://styles/mapbox/streets-v11",
          // center: [-96, 37.8],
          center: [0.0, 0.0],
          zoom: 1,
          attributionControl: false,
          maxBounds: [
            [103.6, 1.1704753],
            [104.1, 1.4754753],
          ],
        });
        if (this.map2) {
          this.map2.on("load", () => {
            // Add an image to use as a custom marker
            // this.map2.loadImage('', (error, image) => {
            // if (error) throw error;
            // this.map2.addImage("custom-marker", image);
            // Add a GeoJSON source with 2 points
            this.map2.addSource("checklocation", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    // feature for Mapbox DC
                    type: "Feature",
                    geometry: {
                      type: "Point",
                      coordinates: [0.0, 0.0],
                    },
                    properties: {
                      description: 'LOCATION NOT FOUND'
                    },
                  },
                ],
              },
            });

            // });
          });
          this.map2.panTo([0.0, 0.0]);
        }

      }
    },
  },

  mounted() {
    this.onInit();
  },
};
</script>
<style scoped>
#map2 {
  height: 600px;
  width: 114%;
  margin-left: -2%;
  /* position: absolute; */
  top: 0;
  bottom: 0;
}

.zoom-control {
  position: absolute;
  bottom: 73%;
  left: 96%;
  border: 1px solid black;
  padding: 5px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.8);
}
</style>
  