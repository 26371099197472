<script>
import {mapGetters} from 'vuex';
import Attendance from './attendance.vue'
import Fees from './fees.vue'
import Exams from './exams.vue'
import HomeWorks from './homeworks.vue'
import Activites from './activites.vue'
import Subjects from './subjects.vue'
import ProgressCards from './progresscards.vue'
export default {
    components: {
        Attendance,
        Fees,
        Exams,
        HomeWorks,
        Activites,
        Subjects,
        ProgressCards
  },
  data: () => ({
    user  : JSON.parse(localStorage.getItem("user")),
    activeTab: 0,
  }),

  computed: {
     
  },

  watch: {
    activeTab(val){
        this.activeTab = val
    }
  },

  created() {
    
  },
  mounted() {

  }
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius:20px">
    <v-tabs v-model="activeTab" background-color="#00A3A3" dark dense style="border-radius:20px">
      <v-tab>Attendance</v-tab>
      <v-tab>Fees</v-tab>
      <v-tab>Homework</v-tab>
      <v-tab>ProgressCards</v-tab>
      <v-tab>Exams</v-tab>
      <v-tab>Activites</v-tab>
      <v-tab>Subjects</v-tab>
    </v-tabs>

            <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <!-- Attendance Content -->
            <Attendance />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <!-- Fees Content -->
            <Fees />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <!-- Homework Content -->
             <HomeWorks />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <!-- Homework Content -->
             <ProgressCards />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <!-- Exams Content -->
             <Exams />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <!-- Exams Content -->
             <Activites />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <!-- Exams Content -->
             <Subjects />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2
{
  color:#0ae2e2 !important;
  border-bottom:1px dashed white
}
.content1
{
  color: white !important;
  margin-left:8px;
}
.off-icon
{
  color:white;
  background:#00A3A3;
  padding:2px;
  border-radius:10px;
}

</style>