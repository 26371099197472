<template>
  <div class="speedometer">
    <svg viewBox="0 0 300 300">
      <!-- Outer circle -->
      <circle cx="100" cy="100" r="90" stroke="lightgray" stroke-width="10" fill="none" />

      <!-- Colored arcs (green, yellow, red) -->
      <path
        d="M 40 140 A 80 80 0 0 1 60 40"
        stroke="green"
        stroke-width="12"
        fill="none"
      />
      <path
        d="M 60 40 A 80 80 0 0 1 120 30"
        stroke="orange"
        stroke-width="12"
        fill="none"
      />
      <path
        d="M 120 30 A 80 80 0 0 1 160 70"
        stroke="red"
        stroke-width="12"
        fill="none"
      />
      <path
        d="M 160 70 A 80 80 0 0 1 155 140"
        stroke="blue"
        stroke-width="12"
        fill="none"
      />

      <!-- Needle -->
      <line
        x1="100"
        y1="100"
        :x2="100 + needleLength * Math.cos((speed / 200) * Math.PI - Math.PI)"
        :y2="100 + needleLength * Math.sin((speed / 200) * Math.PI - Math.PI)"
        stroke="red"
        stroke-width="4"
      />
      <!-- Center circle for needle -->
      <circle cx="100" cy="100" r="5" fill="blue" />

      <!-- Speed text -->
      <text x="100" y="150" text-anchor="middle" font-size="16" font-weight="bold" fill="black">
  {{ speed }} km/h
</text>


      <!-- Speed indicator points -->
      <g stroke="black" stroke-width="2">
        <!-- Function to calculate tick marks -->
        <line v-for="i in Array.from({ length: 42 }, (_, index) => index * 10)" :key="i"
          :x1="100 + (80 - 10) * Math.cos((i / 200) * Math.PI - Math.PI)"
          :y1="100 + (80 - 10) * Math.sin((i / 200) * Math.PI - Math.PI)"
          :x2="100 + 80 * Math.cos((i / 200) * Math.PI - Math.PI)"
          :y2="100 + 80 * Math.sin((i / 200) * Math.PI - Math.PI)"
        />
      </g>

      <!-- Tick marks -->
      <g stroke="black" stroke-width="2">
        <!-- Function to calculate tick marks -->
        <line v-for="i in 20" :key="i"
          :x1="100 + (80 - 20) * Math.cos((i / 10) * Math.PI - Math.PI)"
          :y1="100 + (80 - 20) * Math.sin((i / 10) * Math.PI - Math.PI)"
          :x2="100 + 80 * Math.cos((i / 10) * Math.PI - Math.PI)"
          :y2="100 + 80 * Math.sin((i / 10) * Math.PI - Math.PI)"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    speed: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      needleLength: 60, // Length of the needle
    };
  },
};
</script>

<style scoped>
.speedometer {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
