<template>
  <div class="hello">
    <div v-if="!isLoading" id="map" :style="{ height: screenHeight + 'px', width: '114%', marginLeft: '-2%', top: '0', bottom: '0' }"></div>
    <div :style="{ height: screenHeight + 'px'}" id="map" v-else><h1 style="color: rgb(161, 231, 90);text-align: center;padding-top: 30%;font-weight: bold;font-size: xx-large;">Loading....</h1></div>
  </div>
</template>
<script src="https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.js"></script>
  <script src="https://api.tiles.mapbox.com/mapbox-gl-js/v1.6.0/mapbox-gl.js"></script>
  <script>
  import Vue from "vue";
  import mapboxgl from "mapbox-gl";
  import moment from "moment";
  import { mapGetters } from "vuex";
  import Eventemit from "../components/eventemit";
  // import Client  from "../components/Clients.vue";
  
  // import this.oledDevices
    //  from "../components/oled_list.json";
  export default {
    name: "Routemap",
    props: [
      "data",
      "starttime",
      "endtime",
      "isPlay",
    ],
    data() {
      return {
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        isLoading:false,
        marks: null,
        fs: [],
        distancetravelled:0
      };
    },
    computed:{
         ...mapGetters(['oledDevices'])
    },
    methods: {
      updateScreenSize() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
      distance(lat1, lon1, lat2, lon2, unit) {
        if (lat1 == lat2 && lon1 == lon2) {
          return 0;
        } else {
          var radlat1 = (Math.PI * lat1) / 180;
          var radlat2 = (Math.PI * lat2) / 180;
          var theta = lon1 - lon2;
          var radtheta = (Math.PI * theta) / 180;
          var dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
          if (dist > 1) {
            dist = 1;
          }
          dist = Math.acos(dist);
          dist = (dist * 180) / Math.PI;
          dist = dist * 60 * 1.1515;
          if (unit == "K") {
            dist = dist * 1.609344;
          }
          if (unit == "N") {
            dist = dist * 0.8684;
          }
          return dist;
        }
      },
      init(alldata) {
        // this.isLoading = false;
        const size = 150;
        const passDot = {
          width: size,
          height: size,
          data: new Uint8Array(size * size * 4),
  
          // When the layer is added to the map,
          // get the rendering context for the map canvas.
          onAdd: function() {
            const canvas = document.createElement("canvas");
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext("2d");
          },
  
          // Call once before every frame where the icon will be used.
          render: function() {
            const duration = 1000;
            const t = (performance.now() % duration) / duration;
  
            const radius = (size / 2) * 0.3;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const context = this.context;
  
            // Draw the outer circle.
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2
            );
            context.fillStyle = `rgba(41, 241, 195, ${1 - t})`;
            context.fill();
  
            // Draw the inner circle.
            context.beginPath();
            context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
            context.fillStyle = "green";
            context.strokeStyle = "white";
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();
  
            // Update this image's data with data from the canvas.
            this.data = context.getImageData(0, 0, this.width, this.height).data;
  
            // Continuously repaint the map, resulting
            // in the smooth animation of the dot.
            map.triggerRepaint();
  
            // Return `true` to let the map know that the image was updated.
            return true;
          }
        };
        const failDot = {
          width: size,
          height: size,
          data: new Uint8Array(size * size * 4),
  
          onAdd: function() {
            const canvas = document.createElement("canvas");
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext("2d");
          },
  
          // Call once before every frame where the icon will be used.
          render: function() {
            const duration = 1000;
            const t = (performance.now() % duration) / duration;
  
            const radius = (size / 2) * 0.3;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const context = this.context;
  
            // Draw the outer circle.
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2
            );
            context.fillStyle = `rgba(255, 0, 71, ${1 - t})`;
            context.fill();
  
            // Draw the inner circle.
            context.beginPath();
            context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
            context.fillStyle = "red";
            context.strokeStyle = "white";
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            this.data = context.getImageData(0, 0, this.width, this.height).data;
            map.triggerRepaint();
  
            // Return `true` to let the map know that the image was updated.
            return true;
          }
        };
        // const size = 200;
        mapboxgl.accessToken =
          "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
        const map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: alldata[0].geometry.coordinates[0],
          zoom: 16
        });
        map.on('load', () => {
         
  
          map.addSource("route", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: alldata
            }
          });
          const marker = new mapboxgl.Marker({
            color: "#F84C4C"
          });
          map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
              "line-join": "round",
              "line-cap": "round"
            },
            paint: {
              "line-color": ["get", "color"],
              "line-width": 6
            }
          });
             // Add Arrow Layer
      map.addLayer({
        id: "arrows",
        type: "symbol",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: alldata
          }
        },
        layout: {
          "text-field": ">", // Arrow symbol
          "text-size": 16,
          "symbol-placement": "point",
          "text-rotate": ["get", "rotation"], // Dynamic rotation
          "text-rotation-alignment": "map" // Align rotation with map
        },
        paint: {
        "text-color": "white", // Arrow color to white
        "text-halo-color": "black", // Optional: black outline around arrows
        "text-halo-width": 2 // Outline thickness
      }
      });
    // });
  
          alldata.forEach((el, index) => {
            setTimeout(() => {
              map.setCenter(el.geometry.coordinates[0]);
              marker.setLngLat(el.geometry.coordinates[0]);
              marker.addTo(map);
              const popup = new mapboxgl.Popup({ offset: 25 })
                    .setText(`Speed: ${el.properties.speed} km/h`)
                    .addTo(map);
                    marker.setPopup(popup);
                    // popup.open(); 
              this.isLoading = false;
            }, index * 700);
          });
        });
      }
    },
    beforeMount() {
       this.$store.dispatch('fetchOLEDDevices');
    },
    mounted() {

        var allArray = [];
        
        this.isLoading=true;
        if (this.oledDevices
    .filter((value) => value.sno ==this.data[0].Vehicle.sno).length ==0) {
           Vue.axios
          .get(
            "https://robopower.xyz/app/v2data/getallpingsv2?devId=" +
              this.data[0].Vehicle.devId+"&from="+moment(this.starttime).format(
                  "MM/DD/YYYY HH:mm:ss"
                )+"&to="+moment(this.endtime).format("MM/DD/YYYY HH:mm:ss")
          )
          .then(response => {
            if(response.data){
            var datan = response.data;
            datan.map(element => {
              if (datan.length != datan.indexOf(element) + 1) {
                var starttime = moment(this.starttime).format(
                  "MM/DD/YYYY HH:mm:ss"
                );
                var endtime = moment(this.endtime).format("MM/DD/YYYY HH:mm:ss");
                var nextindex = datan.indexOf(element) + 1;
                if (
                  element.gtime > starttime &&
                  element.gtime < endtime &&
                  element.lat != "0.000000" &&
                  element.lon != "0.000000" &&
                  datan[nextindex].lat != "0.000000" &&
                  datan[nextindex].lon != "0.000000" &&
                  element.lat != "NA" &&
                  element.lon != "NA" &&
                  datan[nextindex].lat != "NA" &&
                  datan[nextindex].lon != "NA"
                ) {
                  var distance = this.distance(
                    parseFloat(element.lat),
                    parseFloat(element.lon),
                    parseFloat(datan[nextindex].lat),
                    parseFloat(datan[nextindex].lon),
                    "K"
                  );
                  var coords = [
                    [parseFloat(element.lon), parseFloat(element.lat)],
                    [
                      parseFloat(datan[nextindex].lon),
                      parseFloat(datan[nextindex].lat)
                    ]
                  ];
                  this.distancetravelled += distance;
                 
                  if (distance > 0.5) {
                    
                    allArray.push({
                      type: "Feature",
                      properties: {
                        color: "red", // red
                        speed:element.speed
                      },
                      geometry: {
                        type: "LineString",
                        coordinates: coords
                      }
                    });
                  } else {
                    allArray.push({
                      type: "Feature",
                      properties: {
                        color: "green", // red
                        speed:element.speed
                      },
                      geometry: {
                        type: "LineString",
                        coordinates: coords
                      }
                    });
                  }
                }
              }
            });
            
            if (allArray.length <= 0) {
            
              this.$emit("routeclosed");
              this.isLoading=true;
            } else {
              window.addEventListener('resize', this.updateScreenSize);
              Eventemit.$emit("distancetravelled",{distance: this.distancetravelled, loading: this.isLoading});
              this.init(allArray);
              
            }
            
            } else{
              this.$emit("routeclosed")
            }
            // requestAnimationFrame(this.animateMarker);
          })
          .catch(error => {
            console.log("Error: ", error);
          });
          
  
        } else{
          Vue.axios
  .get(
    `https://robopower.xyz/app/v2data/getallpingsv22?cbid=${this.data[0].Vehicle.cbid}&from=${moment(this.starttime).format(
      "MM/DD/YYYY HH:mm:ss"
    )}&to=${moment(this.endtime).format("MM/DD/YYYY HH:mm:ss")}`
  )
  .then(response => {
    if (response.data) {
      const datan = response.data.filter(
        element =>
          element.lat !== "0.000000" &&
          element.lon !== "0.000000" &&
          element.lat !== "NA" &&
          element.lon !== "NA"
      );

      let allArray = [];
      let arrowArray = [];
      for (let i = 0; i < datan.length - 1; i++) {
        const element = datan[i];
        const next = datan[i + 1];

        const distance = this.distance(
          parseFloat(element.lat),
          parseFloat(element.lon),
          parseFloat(next.lat),
          parseFloat(next.lon),
          "K"
        );

        if (distance > 0.2) {
          const coords = [
            [parseFloat(element.lon), parseFloat(element.lat)],
            [parseFloat(next.lon), parseFloat(next.lat)]
          ];
          const midpoint = [
            (parseFloat(element.lon) + parseFloat(next.lon)) / 2,
            (parseFloat(element.lat) + parseFloat(next.lat)) / 2
          ];

          const dx = parseFloat(next.lon) - parseFloat(element.lon);
          const dy = parseFloat(next.lat) - parseFloat(element.lat);
          const angle = (Math.atan2(dy, dx) * 180) / Math.PI;

          allArray.push({
            type: "Feature",
            properties: {
              color: distance > 0.5 ? "red" : "green", // Choose color based on distance
              speed: element.speed
            },
            geometry: {
              type: "LineString",
              coordinates: coords
            }
          });

          arrowArray.push({
            type: "Feature",
            properties: {
              rotation: angle
            },
            geometry: {
              type: "Point",
              coordinates: midpoint
            }
          });
        }
      }

      if (allArray.length) {
        Eventemit.$emit("distancetravelled", { distance: this.distancetravelled, loading: this.isLoading });
        this.initMap(allArray, arrowArray);
      } else {
        this.$emit("routeclosed");
      }
    } else {
      this.$emit("routeclosed");
    }
  })
  .catch(error => console.error("Error: ", error));

//     Vue.axios
//       .get(
//         `https://robopower.xyz/app/v2data/getallpingsv22?cbid=${this.data[0].Vehicle.cbid}&from=${moment(this.starttime).format(
//           "MM/DD/YYYY HH:mm:ss"
//         )}&to=${moment(this.endtime).format("MM/DD/YYYY HH:mm:ss")}`
//       )
//       .then(response => {
//         if (response.data) {
//           const datan = response.data.filter(
//             element =>
//               element.lat !== "0.000000" &&
//               element.lon !== "0.000000" &&
//               element.lat !== "NA" &&
//               element.lon !== "NA"
//           );

//           let allArray = [];
//           let arrowArray = [];
//           for (let i = 0; i < datan.length - 1; i++) {
//             const element = datan[i];
//             const next = datan[i + 1];

//             const distance = this.distance(
//               parseFloat(element.lat),
//               parseFloat(element.lon),
//               parseFloat(next.lat),
//               parseFloat(next.lon),
//               "K"
//             );

//             if (distance > 0.2) {
//               const coords = [
//                 [parseFloat(element.lon), parseFloat(element.lat)],
//                 [parseFloat(next.lon), parseFloat(next.lat)]
//               ];
//               const midpoint = [
//                 (parseFloat(element.lon) + parseFloat(next.lon)) / 2,
//                 (parseFloat(element.lat) + parseFloat(next.lat)) / 2
//               ];
//               const bearing = this.calculateBearing(
//                 parseFloat(element.lat),
//                 parseFloat(element.lon),
//                 parseFloat(next.lat),
//                 parseFloat(next.lon)
//               );

//               allArray.push({
//                 type: "Feature",
//                 properties: {
//                   color: distance > 0.5 ? "red" : "green", // Choose color based on distance
//                   speed: element.speed
//                 },
//                 geometry: {
//                   type: "LineString",
//                   coordinates: coords
//                 }
//               });
//  // Calculate angle for arrow
//  const dx = nextPoint[0] - currentPoint[0];
//             const dy = nextPoint[1] - currentPoint[1];
//             const angle = (Math.atan2(dy, dx) * 180) / Math.PI;

//             // Add Arrow to arrowArray
//             arrowArray.push({
//               type: "Feature",
//               properties: {
//                 rotation: angle,
//               },
//               geometry: {
//                 type: "Point",
//                 coordinates: currentPoint,
//               },
//             });
//               // arrowArray.push({
//               //   type: "Feature",
//               //   properties: {
//               //     direction: ">", // Arrow symbol
//               //     rotation: bearing // Rotation in degrees
//               //   },
//               //   geometry: {
//               //     type: "Point",
//               //     coordinates: midpoint
//               //   }
//               // });
//             }
//           }

//           if (allArray.length) {
//             Eventemit.$emit("distancetravelled", { distance: this.distancetravelled, loading: this.isLoading });
//             this.initMap(allArray, arrowArray);
//           } else {
//             this.$emit("routeclosed");
//           }
//         } else {
//           this.$emit("routeclosed");
//         }
//       })
//       .catch(error => console.error("Error: ", error));
      
  // },

        // Vue.axios
        //   .get(
        //   "https://robopower.xyz/app/v2data/getallpingsv22?cbid=" +
        //       this.data[0].Vehicle.cbid+"&from="+moment(this.starttime).format(
        //           "MM/DD/YYYY HH:mm:ss"
        //         )+"&to="+moment(this.endtime).format("MM/DD/YYYY HH:mm:ss")
        //   )
        //   .then(response => {
        //     if(response.data){
        //     var datan = response.data;
        //     datan.map(element => {
        //       if (datan.length != datan.indexOf(element) + 1) {
        //         var starttime = moment(this.starttime).format(
        //           "MM/DD/YYYY HH:mm:ss" 
        //         );
        //         var endtime = moment(this.endtime).format("MM/DD/YYYY HH:mm:ss");
        //         var nextindex = datan.indexOf(element) + 1;
        //         if (
        //           element.gtime > starttime &&
        //           element.gtime < endtime &&
        //           element.lat != "0.000000" &&
        //           element.lon != "0.000000" &&
        //           datan[nextindex].lat != "0.000000" &&
        //           datan[nextindex].lon != "0.000000" &&
        //           element.lat != "NA" &&
        //           element.lon != "NA" &&
        //           datan[nextindex].lat != "NA" &&
        //           datan[nextindex].lon != "NA"
        //         ) {
        //           var distance = this.distance(
        //             parseFloat(element.lat),
        //             parseFloat(element.lon),
        //             parseFloat(datan[nextindex].lat),
        //             parseFloat(datan[nextindex].lon),
        //             "K"
        //           );
        //           var coords = [
        //             [parseFloat(element.lon), parseFloat(element.lat)],
        //             [
        //               parseFloat(datan[nextindex].lon),
        //               parseFloat(datan[nextindex].lat)
        //             ]
        //           ];
        //           this.distancetravelled += distance ;
        //           // if(distance>0.2){
        //           if (distance > 0.5) {
                 
        //             allArray.push({
        //               type: "Feature",
        //               properties: {
        //                 color: "red", // red
        //                 speed:element.speed
        //               },
        //               geometry: {
        //                 type: "LineString",
        //                 coordinates: coords
        //               }
        //             });
        //           } else {
        //             allArray.push({
        //               type: "Feature",
        //               properties: {
        //                 color: "green", // red
        //                 speed:element.speed
        //               },
        //               geometry: {
        //                 type: "LineString",
        //                 coordinates: coords
        //               }
        //             });
        //           }
        //         }
        //       }
        //     });
            
        //     if (allArray.length <= 0) {
        //       this.$emit("routeclosed");
        //       this.isLoading=true;
        //     // Clear the contents of the "map" element
        //   const mapElement = document.getElementById("map");
        //   if (mapElement) {
        //     mapElement.innerHTML = "";
        //   }
        //   } else {
        //     window.addEventListener('resize', this.updateScreenSize);
        //     Eventemit.$emit("distancetravelled",{ distance: this.distancetravelled, loading: this.isLoading });
        //     this.init(allArray);
        //   }
        //     // requestAnimationFrame(this.animateMarker);
        //   } else {
        //      this.$emit("routeclosed");
        //   }
        //   })
        //   .catch(error => {
        //     console.log("Error: ", error);
        //   });
        }
        
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize);
  }
  };
  </script>
  
<style scoped>
/* #map {
  display: inline-flex;
  height: 600px;
  width: 118%;
  margin-left: -1%;
  top: 0;
  bottom: 0;
} */

.overlay {
  position: absolute;
  top: 10px;
  left: 10px;
}

.overlay button {
  font: 600 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #3386c0;
  color: #fff;
  display: inline-block;
  margin: 0;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.overlay button:hover {
  background-color: #4ea0da;
}
</style>