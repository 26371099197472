<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
Vue.use(Vuex);
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    subjects: [],
    mergedExamsSubjects: [],
    selectedGrades: {},
    showhomework: false,
    allHomeworks: [],
    nohomeworktext: "",
    headers: [
      {
        text: "Id",
        align: "left",
        value: "id",
      },
      { text: "Class", value: "class" },
      { text: "Description", value: "description" },
      { text: "subjects", value: "subjectslist" },
      { text: "More..", value: "data-table-expand" },
    ],
  }),

  computed: {
    Reminder() {
      if (this.expanded.length > 0) {
        return this.expanded;
      } else {
        return this.classes;
      }
    },
    ...mapGetters(["classes"]),
    ...mapGetters(["schoolsubjects"]),
    ...mapGetters(["studentsClasses"]),
    formattedItems() {
      return this.Reminder.map((item) => ({
        ...item,
        subjectslist: this.parseSubjects(item.subjectslist),
      }));
    },
  },

  watch: {
    selectedGrades(val) {
      this.selectedGrades = val;
    },
    expanded: {
      handler: function () {
        this.updateGrades();
      },
    },
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.$store.dispatch("fetchschoolClasses", this.user.dealerId);
    this.$store.dispatch("fetchschoolsubjects", this.user.dealerId);
    this.$store.dispatch("fetchstudentClasses");
  },

  methods: {
    parseSubjects(subjectslist) {
      if (!subjectslist) return "-";
      try {
        const subjectslistArray = Array.isArray(subjectslist)
        ? subjectslist
        : JSON.parse(subjectslist);
      return subjectslistArray.length > 0
        ? subjectslistArray
            .map((code) => this.getSubjectNameByCode(code))
            .join(", ")
        : "-";
      } catch (e) {
        return "-";
      }
    },
    getSubjectNameByCode(code) {
     const subject = this.schoolsubjects.find((sub) => {
      return sub.subject_code === code;
    });
    return subject.subject_name;
  },
    updateGrades() {
      if (this.expanded.length > 0) {
        this.gradeA = this.schoolstudents.filter((student) => {
          return (
            student.grade === "A" && student.standard === this.expanded[0].class
          );
        });
        this.gradeB = this.schoolstudents.filter((student) => {
          return (
            student.grade === "B" && student.standard === this.expanded[0].class
          );
        });
        this.gradeC = this.schoolstudents.filter((student) => {
          return (
            student.grade === "C" && student.standard === this.expanded[0].class
          );
        });
      } else {
        this.selectedGrades = {};
        this.nohomeworktext = "";
        this.showhomework = false
      }
    },
    isGradeSelected(item, grade) {
      return this.selectedGrades[item.class] === grade;
    },
    getSubject(item) {
      const code = this.schoolsubjects.filter(
        (sub) => sub.subject_code === item
      );
      return code[0].subject_name;
    },
    getUserName(){
      return this.user.userName
    },
    getClass(item){
      const className = this.studentsClasses.filter(
        (classid) => classid.id === parseInt(item)
      );
      return className[0].class;
    },
    SelectGrade(item, grade) {
      this.$set(this.selectedGrades, item.class, grade);
      Vue.axios
        .get(
          "https://robopower.xyz/app/schoolhomeworks/getclasshomeworks?dealerId=" +
            this.user.dealerId +
            "&classId=" +
            item.id +
            "&grade=" +
            grade
        )
        .then((response5) => {
          if (response5.data.length > 0) {
            this.allHomeworks = response5.data;
            this.showhomework = true;
          } else {
            this.showhomework = false;
            this.nohomeworktext = "No HomeWorks";
          }
        });
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius: 20px">
      <v-data-table
        dense
        :headers="headers"
        :items="formattedItems"
        item-key="class"
        sort-by="id"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card
              class="mt-2 mb-2"
              color="#334c3e"
              style="border-radius: 10px; padding: 10px"
              align="center"
            >
              <v-row justify="center" align="center">
                <v-col cols="auto">
                  <v-btn
                    :color="isGradeSelected(item, 'A') ? 'primary' : 'default'"
                    size="x-small"
                    @click="SelectGrade(item, 'A')"
                  >
                    GRADE A
                  </v-btn>
                </v-col>

                <v-col cols="auto">
                  <v-btn
                    :color="isGradeSelected(item, 'B') ? 'primary' : 'default'"
                    size="x-small"
                    @click="SelectGrade(item, 'B')"
                  >
                    GRADE B
                  </v-btn>
                </v-col>

                <v-col cols="auto">
                  <v-btn
                    :color="isGradeSelected(item, 'C') ? 'primary' : 'default'"
                    size="x-small"
                    @click="SelectGrade(item, 'C')"
                  >
                    GRADE C
                  </v-btn>
                </v-col>
              </v-row>
              &nbsp;
              <div v-if="showhomework && allHomeworks.length > 0">
                <v-table theme="dark">
                  <thead>
                    <tr>
                      <th class="text-left">Dealer</th>
                      <th class="text-left">Class</th>
                      <th class="text-left">Subject</th>
                      <th class="text-left">Grade</th>
                      <th class="text-left">Created_Date</th>
                      <th class="text-left">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in allHomeworks" :key="item.id">
                      <td>{{ getUserName() }}</td>
                      <td>{{ getClass(item.class_id) }}</td>
                      <td>{{ getSubject(item.subject_code) }}</td>
                      <td>{{ item.grade }}</td>
                      <td>{{ item.created_date }}</td>
                      <td>{{ item.description }}</td>
                    </tr>
                  </tbody>
                </v-table>
              </div>
              <div v-else>
                <p style="color: aliceblue">{{ nohomeworktext }}</p>
              </div>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2 {
  color: #0ae2e2 !important;
  border-bottom: 1px dashed white;
}
.content1 {
  color: white !important;
  margin-left: 8px;
}
.off-icon {
  color: white;
  background: #00a3a3;
  padding: 2px;
  border-radius: 10px;
}
</style>