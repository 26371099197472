<template>
  <v-container>
    <!-- Header -->
    <v-row>
      <v-col cols="12">
        <h1>
          {{
            this.selectedStatus == "Removed"
              ? TotalCount().removed
              : this.selectedStatus == "Installed"
              ? TotalCount().installed
              : this.selectedStatus == "All"
              ? this.fullclients.length
              : TotalCount().NotAssigned
          }}
          Clients
        </h1>
      </v-col>
    </v-row>

    <!-- Filters -->
    <v-row class="mb-4">
      <v-col cols="6" sm="3">
        <v-select
          :items="dateOptions"
          label="Date Created"
          v-model="selectedDate"
          dense
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-select
          :items="statusOptions"
          label="By Status"
          v-model="selectedStatus"
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Search by Client"
          v-model="searchQuery"
          dense
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Campaign Cards -->
    <v-row>
      <v-col
        v-for="(client, index) in filteredClients"
        :key="index"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card>
          <div class="row" style="display: flex">
            <v-card-title>
              <span :class="['status-chip', client.status]">
                {{ client.status }}
              </span>
            </v-card-title>
            &nbsp;
            <v-card-title
              style="font-weight: bold; font-size: 15px; margin-left: auto"
              >{{ client.sno }}</v-card-title
            >
          </div>
          <v-card-subtitle style="font-weight: bold" class="titleclient">{{
            client.client
          }}</v-card-subtitle>
          <v-card-text>
            <p>
              To: {{ client.calibrationDate }} - From:
              {{ client.nextcmonitordate }}
            </p>
            <p>
              {{ client.regnum }},{{ client.make ? client.make : "-" }},{{
                client.state
              }}
            </p>
            <p><strong>List:</strong> {{ "list" }}</p>
            <div>
              <v-chip
                v-for="(keyword, index) in client.keywords"
                :key="index"
                small
              >
                {{ "keyword" }}
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
import { mapGetters } from "vuex";
import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);
export default {
  data() {
    return {
      // Filter options
      dateOptions: ["Newest First", "Oldest First"],
      statusOptions: ["All", "NotAssigned", "Installed", "Removed"], // Added "All" option
      selectedDate: null,
      selectedStatus: "All", // Default to "All"
      searchQuery: null,
    };
  },
  computed: {
    ...mapGetters(["fullclients"]),
    filteredClients() {
      console.log(this.fullclients, "fullclients");
      const sortedClients = [...this.fullclients].sort((a, b) => {
        const dateA = new Date(a.installDate);
        const dateB = new Date(b.installDate);
        if (this.selectedDate === "Newest First") {
          return dateB - dateA; // Newest first
        } else if (this.selectedDate === "Oldest First") {
          return dateA - dateB; // Oldest first
        }
        return 0;
      });

      return sortedClients.filter((client) => {
        const matchesStatus =
          this.selectedStatus === "All" ||
          client.status === this.selectedStatus;

        const matchesSearch =
          !this.searchQuery ||
          client.client
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          client.sno.includes(this.searchQuery);
        return matchesStatus && matchesSearch;
      });
    },
  },
  created() {
    this.fetchInvoices();
  },
  mounted() {
    this.$store.dispatch("fetchfullClients");
  },
  methods: {
    TotalCount() {
      var removedclients = this.fullclients.filter((clientt) => {
        return clientt.status === "Removed";
      });
      var NotAssignedClients = this.fullclients.filter((clientt) => {
        return clientt.status === "NotAssigned";
      });
      var InstalledClients = this.fullclients.filter((clientt) => {
        return clientt.status === "Installed";
      });
      return {
        removed: removedclients.length,
        NotAssigned: NotAssignedClients.length,
        installed: InstalledClients.length,
      };
    },
    async fetchInvoices() {
      await Vue.axios
        .get("https://api.stripe.com/v1/invoices?customer=cus_RXf9SGUVI2T4sm", {
          auth: {
            username:
              "sk_test_51L7XzNIKMv34F5OuiIeWTxrLVQf860xMl9pFRVPBNFhL900Z5opPbUtDDPyakxL5FZLpiqaJ9hdbV37Dn7OKrszR00PMr2Wgah",
            password: "",
          },
        })
        .then((response) => {
          console.log(response, "ccccccccccccccc");
        });
    },
  },
};
</script>
  
  <style>
.status-chip {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: white;
  font-weight: bold;
}
.status-chip.NotAssigned {
  background-color: orange;
}
.status-chip.Installed {
  background-color: green;
}
.status-chip.Removed {
  background-color: red;
}
.titleclient {
  width: auto;
  white-space: normal; /* Allow text to wrap to the next line */
  word-wrap: break-word; /* Break long words if necessary */
  font-size: 16px;
  font-weight: bold;
}
</style>
  